import { QueryClient } from '@tanstack/react-query';

export const CacheKeys = {
    getReportTokyoTaxKey: 'getReportTokyoTaxKey',
    getReportKutchanTaxKey: 'getReportKutchanTaxKey',
    getReportBathTaxKey: 'getReportBathTaxKey',
    getPaymentMethodDropdownKey: 'getPaymentMethodDropdownKey',

    // Reports
    getSalesByRoomTypesKey: 'getSalesByRoomTypesKey',
    getOperationStatusReportKey: 'getOperationStatusReportKey',
    getSalesSummaryReportKey: 'getSalesSummaryReportKey',
    getSalesByPlansReportKey: 'getSalesByPlansReportKey',
    getSalesByOtaReportKey: 'getSalesByOtaReportKey',

    // Dropdowns
    getPlanDropdown: 'getPlanDropdownKey',
    getMarketingChannelDropdown: 'getMarketingChannelDropdownKey',

    hotel: 'hotel',
    receiptFilesCount: 'receiptFilesCount',
    receiptFiles: 'receiptFiles',
    facilitySalesReport: 'facilitySalesReport',
    kioskAlmexSetting: 'kioskAlmexSetting',
    getSalesSummaryReceiptDetails: 'getSalesSummaryReceiptDetails',
    getRoomList: 'getRoomList',
    bankAccounts: 'bankAccounts',

    getInventories: 'getInventories',
};

export const MutationKeys = {
    uploadingAccomodationCard: 'uploadingAccomodationCard',
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
        },
    },
});

export const clearCache = (queryKey: string[], exact = true) => {
    queryClient.removeQueries({ queryKey, exact });
    queryClient.invalidateQueries({ queryKey, exact });
};

export default queryClient;
