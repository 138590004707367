import { Form } from 'antd';
import React, { useMemo } from 'react';
import { Controller } from '~plugins/hook-form';
import './SingleDatePicker.scss';
import { DatePicker } from '../DatePicker/DatePicker';
import { Dayjs } from '~plugins/dayjs';
import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import { parseErrorMessage } from '~common/commonFunctions';
import { useAppSelector } from '~hooks';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import { DatePickerType, ErrorMessageType, SupportLanguage } from '~common/constants';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';

interface DatePickerProps extends Omit<PickerDateProps<Dayjs>, 'picker'> {}

type ISingleDatePicker = DatePickerProps & {
    name: string;
    label?: string;
    control: any;
    required?: boolean;
    picker?: string;
};

export function SingleDatePicker(props: ISingleDatePicker) {
    const { required, control, name, picker, defaultValue, label, onChange, ...rest } =
        props;

    const currentLang = useAppSelector(currentLanguageSelector);

    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);
    return (
        <div className="single-date-picker-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message
                        ? fieldState.error?.type === ErrorMessageType.WARNING
                            ? 'warning'
                            : 'error'
                        : '';
                    return (
                        <Form.Item
                            required={required}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <DatePicker
                                {...(rest as any)}
                                {...field}
                                picker={picker}
                                locale={DatePickerLanguage}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    field.onChange(...agrs);
                                }}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

SingleDatePicker.defaultProps = {
    defaultValue: null,
};
