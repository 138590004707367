import { FieldTimeOutlined, UserOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Col, Divider, Form, notification, Popover, Row, Spin } from 'antd';
import classNames from 'classnames';
import { trim } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateFormat, ErrorMessageType, ScheduleViewMode } from '~common/constants';
import { useEscape } from '~common/useHooks';
import {
    InputNumber,
    InputPhoneNumber,
    InputText,
    InputTextArea,
    RangePicker,
    AutoCompleteGuest,
    SingleCollapse,
    SingleDatePicker,
    SingleSelect,
} from '~components';
import {
    FacilityBookingCreateFormField,
    Gender,
} from '~features/facility-booking/constants';
import {
    getFacilityBookingFormId,
    validateBusinessTime,
    validateDateAfterTwoDaysBefore,
    validateStayDateTimes,
} from '~features/facility-booking/helper';
import {
    IFacilityBooking,
    IFacilityBookingCreateBody,
    IFacilityBookingScheduleItem,
    IFacilityBookingUpdate,
    IGetCalculatedAmountQuery,
} from '~features/facility-booking/interfaces';
import {
    createFacilityBooking,
    getCalculatedAmount,
    getFacilityBookingDetail,
    isCalculatingAmountSelector,
    updateFacilityBooking,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    facilityBookingStateSelector,
    fetchFacilityBookingStatisticByDate,
    updateBooking,
} from '~features/facility-booking/reducers/facility-schedule.reducer';
import { createFacilityBookingSchema } from '~features/facility-booking/schema';
import { IGuestDropDown } from '~features/guest/interfaces';
import {
    getGuestListForDropdown,
    guestListForDropDownSelector,
} from '~features/guest/reducers/guest.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './CreateFacilityBookingModal.scss';

type Props = {
    close: () => void;
    booking: IFacilityBookingScheduleItem;
    usePopover: boolean;
};

type IForm = {
    facilityId?: number;
    yomigana?: string | IGuestDropDown;
    fullName?: string;
    birthday?: Dayjs;
    gender?: string;
    memo?: string;
    mobilePhoneNumber?: string | null;
    reservationDuration?: [Dayjs, Dayjs];
    numberOfGuests?: number;
    emailAddress?: string;
};

export const CreateFacilityBookingModal = ({ close, booking, usePopover }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isEditAmount, setIsEditAmount] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [displayTotalAmount, setDisplayTotalAmount] = useState(0);
    const [guestRoom, setGuestRoom] = useState('');
    const { control, handleSubmit, reset, setError, setValue } = useForm({
        resolver: yupResolver(createFacilityBookingSchema),
    });
    const onClose = () => {
        reset();
        setFormBusy(false);
        close();
    };
    const guestDropdownOptions = useAppSelector(guestListForDropDownSelector);
    const [expand, setExpand] = useState(false);
    const [formBusy, setFormBusy] = useState(false);
    const { currentViewMode, facilityList } = useAppSelector(
        facilityBookingStateSelector,
    );
    const [canEditGuest, setCanEditGuest] = useState<boolean>(true);
    const isCalculatingAmount = useAppSelector(isCalculatingAmountSelector);
    const facilityId = useWatch({
        control: control,
        name: 'facilityId',
    });

    const reservationDuration = useWatch({
        control: control,
        name: 'reservationDuration',
    });

    const facilityDropDownOptions = useMemo(() => {
        return facilityList
            .filter((item) => item.parentId)
            .map((facility) => ({
                label: facility.name,
                value: facility.id,
            }));
    }, [facilityList]);

    const _getCalculatedAmount = useCallback(
        async (reservationDuration: any, facilityId: number) => {
            if (reservationDuration && facilityId) {
                const query: IGetCalculatedAmountQuery = {
                    facilityId,
                    startDatetime: parseDate(reservationDuration[0])?.fmYYYYMMDDHHmmss(
                        '-',
                    ),
                    endDatetime: parseDate(reservationDuration[1])?.fmYYYYMMDDHHmmss('-'),
                };
                const response = await dispatch(getCalculatedAmount(query));
                if (getCalculatedAmount.fulfilled.match(response)) {
                    if (response.payload?.success) {
                        const total = response.payload?.data?.amount || 0;
                        setTotalAmount(total);
                        setDisplayTotalAmount(total);
                        setValue('totalAmount', total);
                    }
                } else {
                    notification.error(t('common.somethingWentWrong'));
                }
            }
        },
        [],
    );
    useEscape(onClose);

    const checkBusinessTime = (dataForm: IForm) => {
        if (dataForm.reservationDuration?.length !== 2) return { isValid: false };
        const selectedFacility = facilityList.find(
            (item) => item.id === Number(dataForm.facilityId),
        );
        if (!selectedFacility?.businessEndTime || !selectedFacility?.businessStartTime) {
            return { isValid: false };
        }

        if (
            validateBusinessTime(
                dataForm.reservationDuration,
                selectedFacility?.businessStartTime,
                selectedFacility?.businessEndTime,
            )
        ) {
            return { isValid: true };
        }

        return {
            isValid: false,
            businessStartDateTime: selectedFacility?.businessStartTime,
            businessEndDateTime: selectedFacility?.businessEndTime,
        };
    };

    const onSubmit = () => {
        handleSubmit((dataForm) => {
            // check start time must be greater than or equal to current time
            if (!validateDateAfterTwoDaysBefore(dataForm.reservationDuration?.[0])) {
                setError(
                    'reservationDuration',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('facilityBooking.detail.message.checkInTimeError', {
                            currentTime: customDayjs().subtract(2, 'day').fmYYYYMMDD('-'),
                        }),
                    },
                    { shouldFocus: true },
                );
                return;
            }
            // check end time must be greater than start time 10 minutes
            if (!validateStayDateTimes(dataForm.reservationDuration)) {
                setError(
                    'reservationDuration',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t(
                            'facilityBooking.detail.message.startTimeGreaterEndError',
                        ),
                    },
                    { shouldFocus: true },
                );
                return;
            }
            // check business time of facility type
            const { isValid, businessStartDateTime, businessEndDateTime } =
                checkBusinessTime(dataForm);
            if (!isValid) {
                setError(
                    'reservationDuration',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('facilityBooking.detail.message.businessTimeError', {
                            startTime: businessStartDateTime || '',
                            endTime: businessEndDateTime || '',
                        }),
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const {
                facilityId,
                guestId,
                yomigana,
                numberOfGuests,
                fullName,
                mobilePhoneNumber,
                birthday,
                gender,
                memo,
                reservationDuration,
                emailAddress,
            } = dataForm;

            const body: IFacilityBookingCreateBody = {
                facilityId,
                guestId,
                numberOfGuests: Number(numberOfGuests),
                memo: trim(memo) || null,
                yomigana: guestId ? null : trim(yomigana),
                fullName: guestId ? null : trim(fullName),
                mobilePhoneNumber: guestId ? null : trim(mobilePhoneNumber) || null,
                emailAddress: guestId ? null : emailAddress?.trim() || null,
                gender: guestId ? null : trim(gender) || null,
                birthday:
                    !guestId && birthday ? parseDate(birthday)?.fmYYYYMMDD('-') : null,
                startDatetime: parseDate(reservationDuration[0])?.fmYYYYMMDDHHmmss('-'),
                endDatetime: parseDate(reservationDuration[1])?.fmYYYYMMDDHHmmss('-'),
            };
            if (booking.id) {
                _updateFacilityBooking({ body, id: booking.id });
            } else {
                _createFacilityBooking(body);
            }
        })();
    };

    const updateData = (booking: IFacilityBooking) => {
        if (currentViewMode === ScheduleViewMode.MONTH) {
            dispatch(fetchFacilityBookingStatisticByDate());
        }
        const facility = facilityList.find(
            (item) => item.id === booking.facilityId && item.parentId,
        );
        dispatch(
            updateBooking({
                bookingResponse: {
                    ...booking,
                    facility: {
                        id: booking.facilityId,
                        name: facility?.name || '',
                    },
                },
            }),
        );
    };

    useEffect(() => {
        dispatch(getGuestListForDropdown({ withRoomBooking: true }));
    }, []);

    const _getBookingDetail = useCallback(async () => {
        const response = await dispatch(getFacilityBookingDetail(booking.id));
        if (getFacilityBookingDetail.fulfilled.match(response)) {
            const _booking = response.payload?.data;
            _resetForm({
                facilityId: _booking.facility?.id,
                guestId: _booking.guest?.id,
                yomigana: _booking.guest?.yomigana,
                numberOfGuests: _booking.numberOfGuests,
                totalAmount: _booking.totalAmount,
                reservationDuration: [
                    parseDate(_booking.startDatetime),
                    parseDate(_booking.endDatetime),
                ],
                fullName: _booking.guest?.fullName,
                mobilePhoneNumber: _booking.guest?.mobilePhoneNumber,
                birthday: _booking.guest?.birthday
                    ? parseDate(_booking.guest?.birthday)
                    : null,
                gender: _booking.guest?.gender,
                emailAddress: _booking.guest?.emailAddress,
                memo: _booking.memo || '',
                marketingChannel: _booking.marketingChannel || '',
                paymentId: 0,
            });
        }
    }, [booking]);

    const _resetForm = useCallback((_booking: IFacilityBookingCreateBody) => {
        reset({ ..._booking });
    }, []);

    useEffect(() => {
        if (booking.id) {
            _getBookingDetail();
        } else {
            _resetForm({
                facilityId: booking.facilityId || null,
                guestId: 0,
                yomigana: null,
                numberOfGuests: 1,
                totalAmount: 0,
                reservationDuration: [
                    parseDate(booking.checkInDateTime),
                    parseDate(booking.checkOutDateTime),
                ],
                fullName: '',
                mobilePhoneNumber: '',
                birthday: null,
                gender: '',
                memo: '',
                paymentId: 0,
                emailAddress: null,
            });
            if (
                booking.facilityId &&
                booking.checkInDateTime &&
                booking.checkOutDateTime
            ) {
                _getCalculatedAmount(
                    [
                        parseDate(booking.checkInDateTime),
                        parseDate(booking.checkOutDateTime),
                    ],
                    booking.facilityId,
                );
            }
        }
    }, []);

    const _updateFacilityBooking = useCallback(
        async (formData: IFacilityBookingUpdate) => {
            setFormBusy(true);
            const response = await dispatch(updateFacilityBooking(formData));
            setFormBusy(false);
            if (updateFacilityBooking.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('facilityBooking.createForm.message.update.success'),
                    });
                    onClose();
                    updateData(response.payload.data);
                    return;
                }
                notification.error({
                    message: t('facilityBooking.createForm.message.update.error'),
                    description: response.payload?.errors?.[0].message || '',
                });

                (response.payload?.errors || []).forEach((error) => {
                    if (
                        error.key === FacilityBookingCreateFormField.GUEST_ID ||
                        error.key === FacilityBookingCreateFormField.START_DATE_TIME ||
                        error.key === FacilityBookingCreateFormField.END_DATE_TIME
                    ) {
                        setError(
                            FacilityBookingCreateFormField.RESERVATION_DURATION,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                });
            }
        },
        [],
    );

    const _createFacilityBooking = useCallback(
        async (formData: IFacilityBookingCreateBody) => {
            setFormBusy(true);
            const response = await dispatch(createFacilityBooking(formData));
            setFormBusy(false);
            if (createFacilityBooking.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('facilityBooking.createForm.message.create.success'),
                    });
                    onClose();
                    updateData(response.payload?.data);
                    return;
                }
                notification.error({
                    message: t('facilityBooking.createForm.message.create.error'),
                    description: response.payload?.errors?.[0].message || '',
                });

                (response.payload?.errors || []).forEach((error) => {
                    if (
                        error.key === FacilityBookingCreateFormField.GUEST_ID ||
                        error.key === FacilityBookingCreateFormField.START_DATE_TIME ||
                        error.key === FacilityBookingCreateFormField.END_DATE_TIME
                    ) {
                        setError(
                            FacilityBookingCreateFormField.RESERVATION_DURATION,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                });
            }
        },
        [],
    );

    const disabledBirthDayDate = (current: Dayjs) => {
        // Can not select days after today
        return current.isAfter(customDayjs().subtract(1, 'day'), 'day');
    };

    const disabledDate = (current: Dayjs) => {
        // Can not select days before yesterday
        return current.isBefore(customDayjs().subtract(2, 'day'), 'day');
    };

    const changeGuestYomigana = (value: string | IGuestDropDown) => {
        setCanEditGuest(!(value instanceof Object));
        if (value instanceof Object) {
            setValue(FacilityBookingCreateFormField.YOMIGANA, value.yomigana, {
                shouldValidate: true,
            });
            setValue(FacilityBookingCreateFormField.GUEST_ID, value.id, {
                shouldValidate: true,
            });
            setValue(FacilityBookingCreateFormField.FULL_NAME, value.fullName, {
                shouldValidate: true,
            });
            setValue(
                FacilityBookingCreateFormField.MOBILE_PHONE_NUMBER,
                value.mobilePhoneNumber || value.phoneNumberLandline,
            );
            setValue(FacilityBookingCreateFormField.GENDER, value.gender);
            setValue(
                FacilityBookingCreateFormField.BIRTHDAY,
                value.birthday && parseDate(value.birthday).isValid()
                    ? parseDate(value.birthday)
                    : null,
            );
            setValue(FacilityBookingCreateFormField.EMAIL_ADDRESS, value.emailAddress);
            setGuestRoom(
                (value.rooms || [])?.map((room) => room.name)?.join(', ') as string,
            );
            return;
        }
        setValue(FacilityBookingCreateFormField.YOMIGANA, value);
        setValue(FacilityBookingCreateFormField.GUEST_ID, null);
        setValue(FacilityBookingCreateFormField.FULL_NAME, null);
        setValue(FacilityBookingCreateFormField.MOBILE_PHONE_NUMBER, null);
        setValue(FacilityBookingCreateFormField.GENDER, null);
        setValue(FacilityBookingCreateFormField.BIRTHDAY, null);
        setValue(FacilityBookingCreateFormField.EMAIL_ADDRESS, null);
        setGuestRoom('');
    };
    const cancelEditAmount = () => {
        setIsEditAmount(false);
        setTotalAmount(displayTotalAmount);
        setValue('totalAmount', displayTotalAmount);
    };
    const saveTotalAmount = () => {
        setIsEditAmount(false);
        setDisplayTotalAmount(totalAmount);
    };
    const renderContent = () => {
        return (
            <Spin spinning={isCalculatingAmount}>
                <Card
                    title={t('facilityBooking.createForm.title')}
                    className="create-booking-card"
                >
                    <Form layout="vertical">
                        <div className="booking-card-content">
                            <Row gutter={8}>
                                <Col span={24}>
                                    <SingleSelect
                                        label={t(
                                            'facilityBooking.createForm.form.facility.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.facility.placeholder',
                                        )}
                                        name="facilityId"
                                        id={getFacilityBookingFormId('facilityId')}
                                        control={control}
                                        options={facilityDropDownOptions}
                                        onChange={(value) =>
                                            _getCalculatedAmount(
                                                reservationDuration,
                                                value,
                                            )
                                        }
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <AutoCompleteGuest
                                        label={t(
                                            'facilityBooking.createForm.form.guestYomigana.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.guestYomigana.placeholder',
                                        )}
                                        name="yomigana"
                                        id={getFacilityBookingFormId('yomigana')}
                                        required
                                        allowClear
                                        showSearch={true}
                                        guestOptions={guestDropdownOptions}
                                        onChange={changeGuestYomigana}
                                        control={control}
                                        defaultValue={booking.guest?.id}
                                        guestRoom={guestRoom}
                                        isTriggerParentNode={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <SingleCollapse
                                        expand={expand}
                                        onChange={() => {
                                            setExpand(!expand);
                                        }}
                                        title={t(
                                            'facilityBooking.createForm.form.detailGuestInformation',
                                        )}
                                    >
                                        <Row gutter={8}>
                                            <Col span={24}>
                                                <InputText
                                                    label={t(
                                                        'facilityBooking.createForm.form.guestFullName.label',
                                                    )}
                                                    placeholder={t(
                                                        'facilityBooking.createForm.form.guestFullName.placeholder',
                                                    )}
                                                    name="fullName"
                                                    id={getFacilityBookingFormId(
                                                        'fullName',
                                                    )}
                                                    allowClear
                                                    control={control}
                                                    disabled={!canEditGuest}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={12}>
                                            <Col span={12}>
                                                <SingleDatePicker
                                                    label={t(
                                                        'facilityBooking.createForm.form.guestBirthday.label',
                                                    )}
                                                    placeholder={t(
                                                        'facilityBooking.createForm.form.guestBirthday.placeholder',
                                                    )}
                                                    name="birthday"
                                                    id={getFacilityBookingFormId(
                                                        'birthday',
                                                    )}
                                                    allowClear
                                                    format={DateFormat.YYYY_MM_DD_SLASH}
                                                    disabledDate={disabledBirthDayDate}
                                                    control={control}
                                                    disabled={!canEditGuest}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <SingleSelect
                                                    label={t(
                                                        'facilityBooking.createForm.form.guestGender.label',
                                                    )}
                                                    placeholder={t(
                                                        'facilityBooking.createForm.form.guestGender.placeholder',
                                                    )}
                                                    name="gender"
                                                    id={getFacilityBookingFormId(
                                                        'gender',
                                                    )}
                                                    allowClear
                                                    control={control}
                                                    options={Object.values(Gender).map(
                                                        (gender) => {
                                                            return {
                                                                label: t(
                                                                    `facilityBooking.createForm.guestGender.${gender}`,
                                                                ),
                                                                value: gender,
                                                            };
                                                        },
                                                    )}
                                                    disabled={!canEditGuest}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={8}>
                                            <Col span={24}>
                                                <InputTextArea
                                                    label={t(
                                                        'facilityBooking.createForm.form.guestMemo.label',
                                                    )}
                                                    placeholder={t(
                                                        'facilityBooking.createForm.form.guestMemo.placeholder',
                                                    )}
                                                    allowClear
                                                    name="memo"
                                                    id={getFacilityBookingFormId('memo')}
                                                    control={control}
                                                />
                                            </Col>
                                        </Row>
                                    </SingleCollapse>
                                    <Divider className="divider" />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <InputPhoneNumber
                                        label={t(
                                            'facilityBooking.createForm.form.guestPhoneNumber.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.guestPhoneNumber.placeholder',
                                        )}
                                        allowClear
                                        name="mobilePhoneNumber"
                                        id={getFacilityBookingFormId('mobilePhoneNumber')}
                                        control={control}
                                        disabled={!canEditGuest}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <InputText
                                        label={t(
                                            'facilityBooking.createForm.form.emailAddress.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.emailAddress.placeholder',
                                        )}
                                        allowClear
                                        name="emailAddress"
                                        id={getFacilityBookingFormId('emailAddress')}
                                        control={control}
                                        disabled={!canEditGuest}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <RangePicker
                                        label={`${t(
                                            'facilityBooking.createForm.form.facilityBookingTimePeriod.label',
                                        )} (${t('common.standardTimeTitle')})`}
                                        placeholder={[
                                            t(
                                                'facilityBooking.createForm.form.facilityBookingTimePeriod.placeholder.start',
                                            ),
                                            t(
                                                'facilityBooking.createForm.form.facilityBookingTimePeriod.placeholder.end',
                                            ),
                                        ]}
                                        showTime={true}
                                        name="reservationDuration"
                                        id={getFacilityBookingFormId(
                                            'reservationDuration',
                                        )}
                                        control={control}
                                        format={DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON}
                                        allowClear
                                        required
                                        disabledDate={disabledDate}
                                        onChange={(values) =>
                                            _getCalculatedAmount(values, facilityId)
                                        }
                                        suffixIcon={<FieldTimeOutlined />}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={12}>
                                    <div className="numer-of-people-use-facility">
                                        <div className="label-numer-of-people">
                                            <UserOutlined className="people-icon" />
                                            {t(
                                                'facilityBooking.createForm.form.numberOfGuests.label',
                                            )}
                                        </div>
                                        <InputNumber
                                            label={''}
                                            placeholder={t(
                                                'facilityBooking.createForm.form.numberOfGuests.placeholder',
                                            )}
                                            name="numberOfGuests"
                                            control={control}
                                            isShowIconArrow={true}
                                            id={getFacilityBookingFormId(
                                                'numberOfGuests',
                                            )}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                            key={'facility-booking-footer'}
                            className="create-facility-booking-form-footer"
                        >
                            <div className="facility-booking-total-price">
                                <label>
                                    {t('facilityBooking.createForm.form.total.label')}
                                </label>
                                <div className="total-number">
                                    <p className="price-number">
                                        {t('facilityBooking.createForm.priceNumber', {
                                            priceNumber: totalAmount,
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className="button-group dung">
                                <Button key="cancel" onClick={onClose}>
                                    {t('common.buttonCancelText')}
                                </Button>
                                <Button
                                    key="submit"
                                    type="primary"
                                    className={classNames('', {
                                        'submit-button': usePopover,
                                    })}
                                    loading={formBusy}
                                    onClick={onSubmit}
                                    disabled={isEditAmount}
                                    id={getFacilityBookingFormId('btn-submit')}
                                >
                                    {t(
                                        'facilityBooking.createForm.button.createFacilityBooking',
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Card>
            </Spin>
        );
    };
    if (!usePopover) {
        return renderContent();
    }
    return (
        <Popover
            content={renderContent()}
            trigger="click"
            open={true}
            overlayClassName="create-facility-booking-popover"
            onOpenChange={onClose}
            placement="left"
        ></Popover>
    );
};

CreateFacilityBookingModal.defaultProps = {
    usePopover: true,
};
