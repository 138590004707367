import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row } from 'antd';
import { parseDate, parseTime } from '~plugins/dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filterExportCSVBody, parseDateTime } from '~common/commonFunctions';
import { DateFormat } from '~common/constants';
import { useEscape } from '~common/useHooks';
import {
    InputPhoneNumber,
    InputText,
    RangePicker,
    RightDrawerLayout,
    SingleSelect,
} from '~components';
import {
    getPlanDropdown,
    planDropdownSelector,
} from '~features/plan/reducers/plan.reducer';
import {
    RoomBookingItemBookingStatus,
    roomBookingListQueryDefault,
} from '~features/room-booking/constants';
import {
    fetchMarketingChannelDropDown,
    marketingChannelDropDownsSelector,
    roomBookingStateSelector,
    setAdvanceSearchListQuery,
    setIsAdvanceSearch,
    setIsShowAdvanceSearchTags,
    setRoomBookingListQuery,
    setShowAdvanceSearchForm,
} from '~features/room-booking/reducers/room-booking.reducer';
import { advanceSearchSchema } from '~features/room-booking/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './AdvanceSearchForm.scss';

function AdvanceSearchForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(advanceSearchSchema),
    });
    const { isShowAdvanceSearchForm, advanceSearchGetListQuery } = useAppSelector(
        roomBookingStateSelector,
    );
    const marketingChannelDropDowns = useAppSelector(marketingChannelDropDownsSelector);
    const planDropdowns = useAppSelector(planDropdownSelector);

    const onCloseDialog = () => {
        dispatch(setShowAdvanceSearchForm(false));
    };

    const onSubmit = () => {
        handleSubmit((values) => {
            let {
                autoGeneratedCode,
                planIds,
                marketingChannelIds,
                memo,
                roomBookingItemStatus,
                guestYomigana,
                guestMobilePhoneNumber,
                endDatePeriod,
                startDatePeriod,
                stayPeriod,
                receptionPeriod,
            } = values;
            const advanceSearchQuery = {
                autoGeneratedCode,
                memo,
                guestYomigana,
                guestMobilePhoneNumber,
                roomBookingItemStatus: roomBookingItemStatus || [],
                planIds: planIds || [],
                marketingChannelIds: marketingChannelIds || [],
                endDatePeriod: formatDateTime(
                    endDatePeriod,
                    DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON,
                ),
                startDatePeriod: formatDateTime(
                    startDatePeriod,
                    DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON,
                ),
                stayPeriod: formatDateTime(
                    stayPeriod,
                    DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON,
                ),
                receptionPeriod: formatDateTime(
                    receptionPeriod,
                    DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_SS_COLON,
                ),
            };
            const query = {
                ...roomBookingListQueryDefault,
                keyword: undefined,
                ...advanceSearchQuery,
            };
            filterExportCSVBody(advanceSearchQuery);
            const isEmptyForm = JSON.stringify(advanceSearchQuery) === JSON.stringify({});
            dispatch(setAdvanceSearchListQuery(advanceSearchQuery));
            dispatch(setIsAdvanceSearch(!isEmptyForm));
            dispatch(setIsShowAdvanceSearchTags(!isEmptyForm));

            filterExportCSVBody(query);
            dispatch(setRoomBookingListQuery(query));
            onClose();
        })();
    };

    const formatDateTime = (
        momentArray: string[] | null,
        format: DateFormat,
    ): string[] => {
        if (momentArray?.length === 2) {
            return [
                parseDateTime(momentArray[0], format),
                parseDateTime(momentArray[1], format),
            ];
        }
        return [];
    };

    const onClose = () => {
        dispatch(setShowAdvanceSearchForm(false));
        reset({ code: '' });
    };
    useEscape(onClose);

    const statusOptions = useMemo(() => {
        // generate status options
        const newStatusOptions = {
            ...RoomBookingItemBookingStatus,
            CANCELLED_NO_SHOW_TRUE: 'cancelled_no_show_true',
            CANCELLED_NO_SHOW_FALSE: 'cancelled_no_show_false',
        };
        const bookingStatus = Object.values(newStatusOptions).filter((status) => {
            return status !== newStatusOptions.ALL;
        });
        return bookingStatus.map((status) => {
            return {
                label: t(`roomBooking.page.bookingStatus.${status}`),
                value: status.toString(),
            };
        });
    }, [t]);

    useEffect(() => {
        // fetch marketing channel drop down
        dispatch(fetchMarketingChannelDropDown({}));
        dispatch(getPlanDropdown({}));
    }, []);

    const marketingChannelOptions = useMemo(() => {
        // generate marketing channel options
        return marketingChannelDropDowns.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    }, [marketingChannelDropDowns]);

    // TODO: check date time here
    const convertStringToDateTimeArray = (
        format: DateFormat,
        dateTiemArray?: string[] | null,
    ) => {
        if (dateTiemArray && dateTiemArray.length === 2) {
            if (format === DateFormat.YYYY_MM_DD_HYPHEN) {
                return [parseDate(dateTiemArray[0]), parseDate(dateTiemArray[1])];
            }
            return [parseTime(dateTiemArray[0]), parseDate(dateTiemArray[1])];
        }
        return [];
    };

    useEffect(() => {
        if (isShowAdvanceSearchForm) {
            reset({
                ...advanceSearchGetListQuery,
                endDatePeriod: convertStringToDateTimeArray(
                    DateFormat.YYYY_MM_DD_HYPHEN,
                    advanceSearchGetListQuery?.endDatePeriod,
                ),
                startDatePeriod: convertStringToDateTimeArray(
                    DateFormat.YYYY_MM_DD_HYPHEN,
                    advanceSearchGetListQuery?.startDatePeriod,
                ),
                stayPeriod: convertStringToDateTimeArray(
                    DateFormat.YYYY_MM_DD_HYPHEN,
                    advanceSearchGetListQuery?.stayPeriod,
                ),
                receptionPeriod: convertStringToDateTimeArray(
                    DateFormat.YYYY_MM_DD_HYPHEN,
                    advanceSearchGetListQuery?.receptionPeriod,
                ),
            });
        }
    }, [isShowAdvanceSearchForm]);

    return (
        <div className="room-booking-advance-search-form-wrapper">
            <RightDrawerLayout
                className="room-booking-advance-search-form-drawer"
                open={isShowAdvanceSearchForm}
                onClose={onCloseDialog}
                onSubmit={onSubmit}
                title={''}
                placement="top"
                getContainer={false}
                zIndex={99}
                footer={
                    <div className="custom-drawer-footer">
                        <Row gutter={8}>
                            <Col span={8} offset={8}>
                                <Button className="cancel-button" onClick={onClose}>
                                    {t('common.buttonCancelText')}
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Button
                                    type="primary"
                                    className="save-button"
                                    onClick={onSubmit}
                                >
                                    {t(
                                        'roomBooking.list.advanceSearchForm.buttonSearchText',
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            >
                <Form layout="vertical">
                    <Row gutter={8} className="guest-info">
                        <Col span={12}>
                            <InputText
                                name="autoGeneratedCode"
                                label={t(
                                    'roomBooking.list.advanceSearchForm.autoGeneratedCode.label',
                                )}
                                placeholder={t(
                                    'roomBooking.list.advanceSearchForm.autoGeneratedCode.placeholder',
                                )}
                                control={control}
                            />
                        </Col>
                        <Col span={12}>
                            <SingleSelect
                                name="planIds"
                                mode="multiple"
                                allowClear
                                showArrow
                                label={t(
                                    'roomBooking.list.advanceSearchForm.planIds.label',
                                )}
                                placeholder={t(
                                    'roomBooking.list.advanceSearchForm.planIds.placeholder',
                                )}
                                defaultValue={[]}
                                options={planDropdowns}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <SingleSelect
                                name="marketingChannelIds"
                                mode="multiple"
                                allowClear
                                showArrow
                                label={t(
                                    'roomBooking.list.advanceSearchForm.marketingChannelIds.label',
                                )}
                                placeholder={t(
                                    'roomBooking.list.advanceSearchForm.marketingChannelIds.placeholder',
                                )}
                                defaultValue={[]}
                                options={marketingChannelOptions}
                                control={control}
                            />
                        </Col>
                        <Col span={12}>
                            <SingleSelect
                                name="roomBookingItemStatus"
                                mode="multiple"
                                allowClear
                                showArrow
                                label={t(
                                    'roomBooking.list.advanceSearchForm.roomBookingItemStatus.label',
                                )}
                                placeholder={t(
                                    'roomBooking.list.advanceSearchForm.roomBookingItemStatus.placeholder',
                                )}
                                defaultValue={[]}
                                options={statusOptions}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row gutter={8} className="guest-info">
                        <Col span={12}>
                            <InputText
                                name="guestYomigana"
                                label={t(
                                    'roomBooking.list.advanceSearchForm.guestYomigana.label',
                                )}
                                placeholder={t(
                                    'roomBooking.list.advanceSearchForm.guestYomigana.placeholder',
                                )}
                                control={control}
                            />
                        </Col>
                        <Col span={12}>
                            <InputPhoneNumber
                                name="guestMobilePhoneNumber"
                                label={t(
                                    'roomBooking.list.advanceSearchForm.guestMobilePhoneNumber.label',
                                )}
                                placeholder={t(
                                    'roomBooking.list.advanceSearchForm.guestMobilePhoneNumber.placeholder',
                                )}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={6}>
                            <RangePicker
                                name="receptionPeriod"
                                label={t(
                                    'roomBooking.list.advanceSearchForm.receptionPeriod.label',
                                )}
                                placeholder={[
                                    t(
                                        'roomBooking.list.advanceSearchForm.receptionPeriod.placeholder',
                                    ),
                                    t(
                                        'roomBooking.list.advanceSearchForm.receptionPeriod.placeholder',
                                    ),
                                ]}
                                format={DateFormat.YYYY_MM_DD_SLASH}
                                control={control}
                            />
                        </Col>
                        <Col span={6}>
                            <RangePicker
                                name="stayPeriod"
                                label={t(
                                    'roomBooking.list.advanceSearchForm.stayPeriod.label',
                                )}
                                placeholder={[
                                    t(
                                        'roomBooking.list.advanceSearchForm.stayPeriod.placeholder',
                                    ),
                                    t(
                                        'roomBooking.list.advanceSearchForm.stayPeriod.placeholder',
                                    ),
                                ]}
                                format={DateFormat.YYYY_MM_DD_SLASH}
                                control={control}
                            />
                        </Col>
                        <Col span={6}>
                            <RangePicker
                                label={t(
                                    'roomBooking.list.advanceSearchForm.startDatePeriod.label',
                                )}
                                name="startDatePeriod"
                                placeholder={[
                                    t(
                                        'roomBooking.list.advanceSearchForm.startDatePeriod.placeholder.selectDate',
                                    ),
                                    t(
                                        'roomBooking.list.advanceSearchForm.startDatePeriod.placeholder.selectDate',
                                    ),
                                ]}
                                control={control}
                                format={DateFormat.YYYY_MM_DD_SLASH}
                            />
                        </Col>
                        <Col span={6}>
                            <RangePicker
                                name="endDatePeriod"
                                label={t(
                                    'roomBooking.list.advanceSearchForm.endDatePeriod.label',
                                )}
                                placeholder={[
                                    t(
                                        'roomBooking.list.advanceSearchForm.endDatePeriod.placeholder.selectDate',
                                    ),
                                    t(
                                        'roomBooking.list.advanceSearchForm.endDatePeriod.placeholder.selectDate',
                                    ),
                                ]}
                                control={control}
                                format={DateFormat.YYYY_MM_DD_SLASH}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputText
                                name="memo"
                                label={t('roomBooking.list.advanceSearchForm.memo.label')}
                                placeholder={t(
                                    'roomBooking.list.advanceSearchForm.memo.placeholder',
                                )}
                                control={control}
                            />
                        </Col>
                    </Row>
                </Form>
            </RightDrawerLayout>
        </div>
    );
}

export default AdvanceSearchForm;
