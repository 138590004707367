import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import { useAppSelector } from '~hooks';
import { paymentSummaryBreadcrumbs } from '~features/report/constants';
import FilterForm from './components/FilterForm/FilterForm';
import PaymentSummaryTable from './components/PaymentSummaryTable/PaymentSummaryTable';
import { showPaymentSummaryTableSelector } from '~features/report/reducers/payment-summary.reducer';

function PaymentSummaryPage() {
    const { t } = useTranslation();
    const showPaymentSummaryTable = useAppSelector(showPaymentSummaryTableSelector);

    return (
        <div>
            <StaffLayoutHeader
                breadcrumbs={[...paymentSummaryBreadcrumbs()]}
                title={t('report.paymentSummary.title')}
            />
            <Layout.Content>
                <div className="payment-summary-page-wrapper">
                    <FilterForm />
                    {showPaymentSummaryTable && <PaymentSummaryTable />}
                </div>
            </Layout.Content>
        </div>
    );
}

export default PaymentSummaryPage;
