import { useTranslation } from 'react-i18next';
import './FilterForm.scss';
import { Button, Form, Select, Switch } from 'antd';
import { SingleDatePicker } from '~components';
import { useEffect, useMemo, useState } from 'react';
import customDayjs, { Dayjs } from '~plugins/dayjs';
import { DatePickerType } from '~common/constants';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { salesByPlansFilterSchema } from '~features/report/schema';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '~queries/queries';
import { reportService } from '~features/report/report.service';
import { IPlanDropdownItem } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import { salesByPlansSelector } from '~features/report/reducers/sales-by-plans.reducer';
import {
    setExportCsv,
    setShowSalesByPlansTable,
} from '~features/report/reducers/sales-by-plans.reducer';
import { reportingServiceV2 } from '~features/report/reportV2.service';
import {
    setIsShowByDaily,
    setSelectedDate,
    setSelectedPlanId,
} from '~features/report/reducers/sales-by-plans.reducer';

function FilterForm() {
    const allOptionValue = 0;
    const { t } = useTranslation();
    const { isExportCsv, isShowSalesByPlansTable } = useAppSelector(salesByPlansSelector);
    const dispatch = useAppDispatch();

    const [date, setDate] = useState<Dayjs>(customDayjs());
    const [planId, setPlanId] = useState<number>(allOptionValue);
    const [showByDay, setShowByDay] = useState(false);

    const { control, setValue } = useForm({
        resolver: yupResolver(salesByPlansFilterSchema),
    });

    useEffect(() => {
        setValue(`day`, customDayjs());
    }, []);

    const onChangeDate = (date: Dayjs | null) => {
        if (date) {
            setDate(date);
        }
    };

    const onChangePlan = (value: number) => {
        setPlanId(value);
    };

    const onChangeDisplayMode = (value: boolean) => {
        setShowByDay(value);
    };

    const fetchPlanDropdown = async () => {
        const response = await reportService.getPlanDropdown();
        if (response.success) {
            const { data } = response;
            const options = data.items.map((item: IPlanDropdownItem) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
            return options;
        } else {
            throw Error(response.message);
        }
    };

    const { data: planDropdown, isFetching: isFetchingPlanDropdown } = useQuery({
        queryKey: [CacheKeys.getPlanDropdown],
        queryFn: fetchPlanDropdown,
        enabled: true,
    });

    const { refetch, isFetching } = useQuery({
        queryKey: [CacheKeys.getSalesByPlansReportKey],
        queryFn: async () => {
            const startDate = date.startOf('month').format('YYYY-MM-DD');
            const endDate = date.endOf('month').format('YYYY-MM-DD');
            const datePeriod = [startDate, endDate];
            const params = {
                datePeriod,
                planId,
                isShownByDaily: showByDay,
            };
            const response = await reportingServiceV2.getSalesByPlansReport(params);
            if (response.success) {
                dispatch(setShowSalesByPlansTable(true));
                return response.data;
            } else {
                throw Error(response.message);
            }
        },
        enabled: false,
    });

    const onClickButtonFilter = () => {
        dispatch(setIsShowByDaily(showByDay));
        dispatch(setSelectedDate(date.format('YYYY-MM-DD')));
        dispatch(setSelectedPlanId(planId));
        refetch();
    };

    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };

    const planOptions = useMemo(() => {
        if (!planDropdown) return [];
        return [
            {
                value: allOptionValue,
                label: t('report.salesByPlans.filterForm.allOption'),
            },
            ...planDropdown,
        ];
    }, [planDropdown]);

    return (
        <div className="report-sales-by-plans-filter-form-wrapper">
            <Form className="filter-form-content">
                <Form.Item label={t('report.salesByPlans.filterForm.pickerLabel')}>
                    <SingleDatePicker
                        placeholder={t('report.placeholder.selectDate')}
                        value={date}
                        allowClear={false}
                        name="day"
                        picker={DatePickerType.MONTH}
                        onChange={onChangeDate}
                        control={control}
                    />
                </Form.Item>
                <Form.Item label={t('report.salesByPlans.filterForm.planLabel')}>
                    <Select
                        onChange={onChangePlan}
                        dropdownMatchSelectWidth={false}
                        className="plan-select"
                        value={planId}
                        loading={isFetchingPlanDropdown}
                    >
                        {planOptions.map((option) => {
                            return (
                                <Select.Option value={option.value} key={option.value}>
                                    {option.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label={t('report.salesByPlans.filterForm.displayMode')}>
                    <Switch checked={showByDay} onChange={onChangeDisplayMode} />
                </Form.Item>
                <Button loading={isFetching} onClick={onClickButtonFilter} type="primary">
                    {t('report.salesByPlans.filterForm.filterButton')}
                </Button>
            </Form>
            {isShowSalesByPlansTable && (
                <Button onClick={exportCSV} className="export-csv-btn">
                    {t('report.exportCSV')}
                </Button>
            )}
        </div>
    );
}

export default FilterForm;
