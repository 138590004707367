import { DeleteOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Pagination, Table } from 'antd';
import type { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    checkUserPermission,
    downloadFile,
    exportCSVFile,
} from '~common/commonFunctions';
import {
    AdminGroup,
    AntdOrderDirection,
    cellAutoGeneratedCodeStyle,
    cellTextErrorStyle,
    CsvOption,
    OrderDirection,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { CsvDropdown, ModalConfirmDeletion } from '~components';
import {
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    i18ExportKey,
    ImportSaleGroupColumn,
    initSaleGroupListQuery,
    OrderBy,
    SaleGroupListColumn,
    MEAL_GROUP,
} from '~features/sale-group/constants';
import {
    IImportSaleGroup,
    ISaleGroup,
    ISaleGroupExportCsvQuery,
} from '~features/sale-group/interfaces';
import {
    fetchSaleGroupList,
    saleGroupListQuerySelector,
    saleGroupListSelector,
    setImportCsvFileName,
    setImportSaleGroupList,
    setSaleGroupListQuery,
    setSelectedSaleGroup,
    setShowForm,
    setShowLoading,
    showLoadingSelector,
    totalPageSelector,
    totalSaleGroupsSelector,
} from '~features/sale-group/reducers/sale-group.reducer';
import { saleGroupsService } from '~features/sale-group/services/sale-group.service';
import { useAppDispatch, useAppSelector } from '~hooks';

import { cloneDeep, orderBy, uniq } from 'lodash';
import ImportCsv from '../../../../../../components/ImportCsv/ImportCsv';
import './SaleGroupList.scss';
import { saleGroupSchema } from '~common/validatorSchema';

interface IDataOutput {
    name: string;
    autoGeneratedCode: string;
}

function SaleGroupList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const saleGroupList = useAppSelector(saleGroupListSelector);
    const saleGroupListQuery = useAppSelector(saleGroupListQuerySelector);
    const pageCount = useAppSelector(totalPageSelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const totalSaleGroups = useAppSelector(totalSaleGroupsSelector);
    const isReadOnly = !checkUserPermission([...AdminGroup]);
    const importCsvRef = useRef<{ onClickImport: () => void }>(null);

    const onChange: TableProps<ISaleGroup>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        dispatch(
            setSaleGroupListQuery({
                ...saleGroupListQuery,
                orderBy: order ? _field || OrderBy.ID : OrderBy.ID,
                orderDirection:
                    order === AntdOrderDirection.ASC
                        ? OrderDirection.ASC
                        : OrderDirection.DESC,
            }),
        );
    };

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onSelectChange = (_selectedRowKeys: React.Key[]) => {
        const saleGroups: ISaleGroup[] = [];
        _selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundSaleGroup = saleGroupList.find(
                (saleGroupList: ISaleGroup) => saleGroupList.id === selectedRowKey,
            );
            if (foundSaleGroup) saleGroups.push(foundSaleGroup);
        });
        setSelectedRowKeys(_selectedRowKeys);
    };

    useEffect(() => {
        return () => {
            dispatch(setSaleGroupListQuery(cloneDeep(initSaleGroupListQuery)));
        };
    }, []);

    const fetchData = () => {
        dispatch(fetchSaleGroupList());
    };

    useEffect(() => {
        if (
            saleGroupList.length === 0 &&
            saleGroupListQuery?.page &&
            saleGroupListQuery?.page > 1
        ) {
            const page = saleGroupListQuery?.page - 1;
            dispatch(setSaleGroupListQuery({ ...saleGroupListQuery, page }));
        }
    }, [saleGroupList]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await saleGroupsService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('saleGroup.list.message.deleteSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [saleGroupListQuery]);

    const onChangePage = (page: number) => {
        dispatch(setSaleGroupListQuery({ ...saleGroupListQuery, page }));
    };

    const handleEdit = () => {
        dispatch(setShowForm(true));
        dispatch(setSelectedSaleGroup(selectedRows?.[0]));
    };

    const onClickOneRow = (record: ISaleGroup) => {
        if (isReadOnly || (record.isSeedData && record.name !== MEAL_GROUP)) {
            return {
                onClick: () => {
                    return;
                },
            };
        }
        return {
            onClick: () => {
                dispatch(setShowForm(true));
                dispatch(setSelectedSaleGroup(record));
            },
        };
    };

    const selectedRows = useMemo(() => {
        const rows: ISaleGroup[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundSaleGroup = saleGroupList.find(
                (saleGroup: ISaleGroup) => saleGroup.id === selectedRowKey,
            );
            if (foundSaleGroup) rows.push(foundSaleGroup);
        });
        return rows;
    }, [selectedRowKeys, saleGroupList]);

    const exportCsv = async (query: ISaleGroupExportCsvQuery) => {
        const response = await saleGroupsService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const sortDataToCsv = (data: ISaleGroup[]) => {
        const newData = data.sort(function (data1, data2) {
            return data2.id - data1.id;
        });
        return newData;
    };

    const convertDataToCsv = (saleGroups: ISaleGroup[]) => {
        const dataOutput: IDataOutput[] = [];
        const newSaleGroups = sortDataToCsv(saleGroups);
        newSaleGroups.forEach((saleGroup) => {
            const data = {
                name: saleGroup.name,
                autoGeneratedCode: saleGroup.autoGeneratedCode,
            };
            if (saleGroup.saleItems) {
                saleGroup.saleItems.forEach((saleItem) => {
                    const cloneData = JSON.parse(JSON.stringify(data));
                    cloneData.saleItemName = saleItem.name;
                    dataOutput.push(cloneData);
                });
            }
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportSaleGroupList = async (saleGroups: ISaleGroup[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const roomDataCsvs = convertDataToCsv(saleGroups);
        await exportCSVFile(exportColumns, filename, roomDataCsvs, i18ExportKey);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all
                await exportCsv({
                    keyword: saleGroupListQuery.keyword,
                    autoGeneratedCode: saleGroupListQuery.autoGeneratedCode,
                    name: saleGroupListQuery.name,
                    orderBy: saleGroupListQuery.orderBy,
                    orderDirection: saleGroupListQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportSaleGroupList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                if (importCsvRef?.current) {
                    importCsvRef.current.onClickImport();
                }
                break;
            }
            default:
                break;
        }
    };

    const showConfirmDialog = () => {
        const canNotDeleteItems = selectedRows.filter((row) => row.isSeedData === true);
        if (canNotDeleteItems?.length) {
            Modal.error({
                title: t('saleGroup.list.confirm.cantDelete'),
                content: (
                    <ul>
                        {canNotDeleteItems?.map((item) => (
                            <li key={item.autoGeneratedCode}>{item.name}</li>
                        ))}
                    </ul>
                ),
                okText: t('common.ok'),
            });
        } else {
            ModalConfirmDeletion({
                title: t('saleGroup.list.delete.title'),
                description: t('saleGroup.list.delete.description'),
                buttonCancelText: t('saleGroup.list.delete.buttonCancelText'),
                buttonDeleteText: t('saleGroup.list.delete.buttonDeleteText'),
                okButtonProps: { danger: true },
                deletedItems: selectedRows.map((row) => row.name),
                onClickButtonDelete: onConfirmDeletion,
            });
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getSaleGroupCellTextStyle = (saleGroup: ISaleGroup) => {
        return saleGroupSchema.isValidSync(saleGroup) ? {} : cellTextErrorStyle;
    };

    const saleGroupColumns: ColumnsType<ISaleGroup> = [
        {
            title: t('saleGroup.list.saleGroupListColumn.id'),
            width: '120px',
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (saleGroup: ISaleGroup) => {
                return <a>{saleGroup.autoGeneratedCode}</a>;
            },
            key: SaleGroupListColumn.ID,
            sorter: true,
        },
        {
            title: t('saleGroup.list.saleGroupListColumn.saleGroupName'),
            width: '40%',
            dataIndex: SaleGroupListColumn.NAME,
            onCell: (saleGroup: ISaleGroup) => {
                return {
                    style: getSaleGroupCellTextStyle(saleGroup),
                };
            },
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('saleGroup.list.saleGroupListColumn.saleItemName'),
            width: '40%',
            onCell: (saleGroup: ISaleGroup) => {
                return {
                    style: getSaleGroupCellTextStyle(saleGroup),
                };
            },
            render: (saleGroup: ISaleGroup) => {
                return (
                    <div>
                        <ul className="sale-item-wrapper">
                            {saleGroup?.saleItems?.map((item) => {
                                return (
                                    <li key={item.autoGeneratedCode}>
                                        <div className="text-truncate">{item.name}</div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            },
            key: SaleGroupListColumn.SALE_ITEM,
        },
    ];
    const handleLoadFile = () => {
        dispatch(setShowLoading(true));
    };
    const setImportSaleGroupCsvFileName = (fileName: string) => {
        dispatch(setImportCsvFileName(fileName));
    };

    const mapSaleGroupDataToImportCsv = (saleGroups: IImportSaleGroup[]) => {
        const saleItemsByName: Record<string, (string | null)[]> = {};
        saleGroups.forEach((saleGroup) => {
            const key = saleGroup.name?.trim() || '';
            if (saleItemsByName[key]) {
                saleItemsByName[key].push(saleGroup.saleItem?.trim() || null);
            } else {
                saleItemsByName[key] = [saleGroup.saleItem?.trim() || null];
            }
        });

        const _importSaleGroups = Object.keys(saleItemsByName)?.map(
            (saleGroupName, index) => {
                return {
                    order: index + 1,
                    name: saleGroupName,
                    saleItems: uniq(saleItemsByName[saleGroupName]),
                };
            },
        );
        dispatch(setImportSaleGroupList(_importSaleGroups));
    };

    return (
        <div className="sale-group-list-wrapper">
            <ImportCsv
                ref={importCsvRef}
                importHeader={[
                    ImportSaleGroupColumn.NAME,
                    ImportSaleGroupColumn.SALE_ITEM,
                ]}
                mapData={mapSaleGroupDataToImportCsv}
                setFilename={setImportSaleGroupCsvFileName}
                handleLoadFile={handleLoadFile}
                previousPage={'/front-settings/sale-group/import-csv'}
            />
            <div className="sale-group-list-header">
                {!isReadOnly && selectedRows.length > 0 && (
                    <div className="sale-groups-header-left">
                        {selectedRowKeys?.length === 1 && (
                            <Button
                                type="text"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={handleEdit}
                            />
                        )}
                        <DeleteOutlined
                            className="size-icon"
                            onClick={showConfirmDialog}
                        />
                    </div>
                )}

                <div className="sale-groups-header-right">
                    <CsvDropdown
                        onChange={onChangeCsvOption}
                        hasSelectedColumns={selectedRows.length > 0}
                        hasImportOption={!isReadOnly}
                    />

                    <ReloadOutlined className="size-icon" onClick={onRefresh} />
                </div>
            </div>

            <Table
                rowSelection={rowSelection}
                columns={saleGroupColumns}
                dataSource={saleGroupList}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                loading={showLoading}
                className="sale-group-list-table"
                rowClassName={(record) => {
                    if (isReadOnly || (record.isSeedData && record.name !== MEAL_GROUP)) {
                        return '';
                    }
                    return 'sale-group-row';
                }}
                scroll={{ y: 400 }}
                onRow={onClickOneRow}
            />
            {pageCount > 1 && (
                <Pagination
                    className="sale-groups-pagination"
                    defaultCurrent={saleGroupListQuery.page}
                    current={saleGroupListQuery.page}
                    total={totalSaleGroups}
                    pageSize={saleGroupListQuery.limit}
                    onChange={onChangePage}
                />
            )}
        </div>
    );
}

export default SaleGroupList;
