import customDayjs, { parseDate } from '~plugins/dayjs';
import i18next from 'i18next';
import { cloneDeep, isEmpty, orderBy, sumBy, uniqBy } from 'lodash';
import {
    IFacilityBookingReceiptDetail,
    IFacilityBookingReceiptItem,
} from '~features/facility-booking/interfaces';
import { Gender } from '~features/guest/constants';
import { IRoom } from '~features/room/interfaces';
import {
    ReceiptByGroupMapIndex,
    ReceiptItemType,
    ReceiptItemDetailType,
    DEFAULT_BILL_PROVISO,
} from './constants';
import {
    IGroup,
    IPrintingReceiptItemDetail,
    IReceipt,
    IReceiptByGroup,
    IReceiptByGroupMap,
    IReceiptItemDetails,
    IRepresentativeGuest,
    IRoomBookingDetail,
    IRoomBookingItemReceipt,
    IRoomBookingItemsReceiptItem,
    ISplitRoomBookingReceiptTableData,
} from './interfaces';

/**
 * @param receipt
 * @returns list of room in the receipt
 */
export const getRoomListFromReceipt = (receipt: IReceipt | null): IRoom[] => {
    if (!receipt) return [];

    const roomList = (receipt.roomBooking.roomBookingItems || [])
        .reduce((roomList: IRoom[], roomBookingItem: IRoomBookingItemReceipt) => {
            const room = roomBookingItem.room;
            if (room) {
                roomList.push(room);
            }
            return roomList;
        }, [])
        .sort((room1: IRoom, room2: IRoom) => room1.name.localeCompare(room2.name));
    return uniqBy(roomList, 'name');
};

/**
 * @param groupList
 * @param receipt
 * @returns group map
 *
 * This function take a group list (room list corresponding to split by room option).
 * Create a group map that have key is the id of the group, value is the receipt splitted to that group
 * The map also have a specific key is 'ALL' that holds the original receipt
 *
 * At first, only key: 'ALL' have data in the receipt, the rest key will holds
 * an empty receipt that have receipt item details (sale item, etc.) is an empty array
 */
export const getSplitReceiptByGroupMap = (
    groupList: IGroup[],
    receipt: IReceipt | null,
    defaultGuestName: string,
    splitBillByRoom?: boolean,
): IReceiptByGroupMap => {
    if (!receipt) return {};

    const emptyReceipt = Object.assign(cloneDeep(receipt), {
        roomBooking: {
            ...receipt.roomBooking,
            roomBookingItems: [
                ...receipt.roomBooking.roomBookingItems.map((roomBookingItem) => {
                    return Object.assign(cloneDeep(roomBookingItem), {
                        receiptItems: roomBookingItem?.receiptItems?.map((item) => ({
                            ...item,
                            receiptItemDetails: [],
                        })),
                    });
                }),
            ],
        },
        facilityBookings: [
            ...receipt.facilityBookings.map((facilityBooking) => {
                return Object.assign(cloneDeep(facilityBooking), {
                    receiptItems: facilityBooking.receiptItems.map((item) => ({
                        ...item,
                        receiptItemDetails: [],
                    })),
                });
            }),
        ],
    });

    const getReceiptByGroupId = (groupId: string, index?: number) => {
        return Object.assign(cloneDeep(receipt), {
            roomBooking: {
                ...receipt.roomBooking,
                roomBookingItems: [
                    ...receipt.roomBooking.roomBookingItems.map((roomBookingItem) => {
                        return Object.assign(cloneDeep(roomBookingItem), {
                            receiptItems: roomBookingItem?.receiptItems?.map((item) => {
                                const _groupId = groupList?.find(
                                    (group) =>
                                        group?.name ===
                                        item?.paymentRoomBookingItem?.room?.name,
                                )?.id;
                                return {
                                    ...item,
                                    receiptItemDetails:
                                        groupId === ReceiptByGroupMapIndex.ALL ||
                                        item?.paymentRoomBookingItem?.room?.id?.toString() ===
                                            groupId ||
                                        (!item?.paymentRoomBookingItem?.room &&
                                            index === 0)
                                            ? item.receiptItemDetails.map((detail) => ({
                                                  ...detail,
                                                  groupId: _groupId || groupList[0]?.id,
                                              }))
                                            : [],
                                };
                            }),
                        });
                    }),
                ],
            },
            facilityBookings: [
                ...receipt.facilityBookings.map((facilityBooking) => {
                    return Object.assign(cloneDeep(facilityBooking), {
                        receiptItems: facilityBooking.receiptItems.map((item) => {
                            const _groupId = groupList?.find(
                                (group) =>
                                    group?.name ===
                                    item?.paymentRoomBookingItem?.room?.name,
                            )?.id;
                            return {
                                ...item,
                                receiptItemDetails:
                                    groupId === ReceiptByGroupMapIndex.ALL ||
                                    item?.paymentRoomBookingItem?.room?.id?.toString() ===
                                        groupId ||
                                    (!item?.paymentRoomBookingItem?.room && index === 0)
                                        ? item.receiptItemDetails.map((detail) => ({
                                              ...detail,
                                              groupId: _groupId || groupList[0]?.id,
                                          }))
                                        : [],
                            };
                        }),
                    });
                }),
            ],
        });
    };

    const getGuestName = (groupId: string) => {
        return receipt?.roomBooking?.roomBookingItems.find((roomBookingItem) => {
            return roomBookingItem?.room?.id.toString() === groupId;
        })?.representativeGuest?.yomigana;
    };

    return groupList.reduce(
        (receiptByGroupMap: IReceiptByGroupMap, group: IGroup, index: number) => {
            receiptByGroupMap[`${group.id}`] = {
                group,
                receipt: splitBillByRoom
                    ? getReceiptByGroupId(group.id, index)
                    : index === 0
                    ? getReceiptByGroupId(ReceiptByGroupMapIndex.ALL)
                    : emptyReceipt,
                guestName: getGuestName(group.id) || defaultGuestName,
                proviso: DEFAULT_BILL_PROVISO,
            };
            return receiptByGroupMap;
        },
        {
            [ReceiptByGroupMapIndex.ALL]: {
                group: null,
                receipt: getReceiptByGroupId(ReceiptByGroupMapIndex.ALL),
                guestName: defaultGuestName,
            },
        } as IReceiptByGroupMap,
    );
};

/**
 * @param receipt
 * @param roomBookingDetail
 *
 * This function generate data for split receipt table from receipt
 */
export const convertSplitReceipt = (
    receipt: IReceipt | null,
    roomBookingDetail: IRoomBookingDetail | null,
): ISplitRoomBookingReceiptTableData[] => {
    // convert  room booking receipt data to table
    const roomBookingReceipts = convertSplitRoomBookingReceipt(
        receipt,
        roomBookingDetail,
    );

    // convert facility booking receipt data to table
    const facilityBookingReceipts = convertSplitFacilityBookingReceipt(receipt);
    return [...roomBookingReceipts, ...facilityBookingReceipts];
};

const getBookingDetail = (
    receiptItemDetail: IReceiptItemDetails,
    roomBookingItem: IRoomBookingItemReceipt,
    numberOfChildren: number,
) => {
    if (receiptItemDetail.type === ReceiptItemDetailType.PAYMENT) {
        return (
            roomBookingItem?.receiptItems?.find(
                (receiptItem) =>
                    !!receiptItem.receiptItemDetails.find(
                        (item) => item.id === receiptItemDetail.id,
                    ),
            )?.paymentMethod?.name || ''
        );
    }

    if (
        receiptItemDetail.type === ReceiptItemDetailType.LOCAL_TAX ||
        receiptItemDetail.type === ReceiptItemDetailType.BATH_TAX
    ) {
        return receiptItemDetail.currentTax?.name || '';
    }

    if (receiptItemDetail.type === ReceiptItemDetailType.STAY_PRICE_CHILDREN) {
        return roomBookingItem.room
            ? i18next.t(
                  'roomBooking.splitReceipt.receiptDetailTab.bookingDetailChildren',
                  {
                      roomName: roomBookingItem.room?.name,
                      childrenTypeName: receiptItemDetail.childrenType?.name || '',
                      numberOfAdults: receiptItemDetail.quantity || 0,
                  },
              )
            : i18next.t(
                  'roomBooking.splitReceipt.receiptDetailTab.bookingDetailChildrenNoRoom',
                  {
                      childrenTypeName: receiptItemDetail.childrenType?.name || '',
                      numberOfAdults: receiptItemDetail.quantity || 0,
                  },
              );
    }

    return roomBookingItem.room
        ? i18next.t('roomBooking.splitReceipt.receiptDetailTab.bookingDetailNoChildren', {
              roomName: roomBookingItem.room?.name,
              numberOfAdults: roomBookingItem.numberOfAdults,
          })
        : i18next.t('roomBooking.splitReceipt.receiptDetailTab.bookingDetailNoRoom', {
              numberOfAdults: roomBookingItem.numberOfAdults,
          });
};

const mapReceiptItemDetail = (
    roomBookingItem: IRoomBookingItemReceipt,
    receiptItem: IRoomBookingItemsReceiptItem,
    receiptItemDetail: IReceiptItemDetails,
    numberOfChildren: number,
    groupRepresentativeGuest: IRepresentativeGuest,
) => {
    const { saleItem, unitPrice = 0, quantity = 0, amount } = receiptItemDetail;
    const bookingDate =
        receiptItem.type === ReceiptItemType.PAYMENT
            ? parseDate(receiptItemDetail.payAt)?.fmYYYYMMDD('-')
            : parseDate(receiptItemDetail.boughtAt)?.fmYYYYMMDD('-');
    const tableDataItem = {
        id: `hol_${roomBookingItem.id}_${receiptItem.id}_${receiptItemDetail.id}`,
        bookingDate,
        groupId: receiptItemDetail.groupId || null,
        bookingDetail:
            receiptItemDetail?.bookingDetail ||
            getBookingDetail(receiptItemDetail, roomBookingItem, numberOfChildren),
        room: roomBookingItem.room,
        guest: roomBookingItem.representativeGuest || groupRepresentativeGuest,
        saleItem,
        unitPrice,
        quantity,
        checkOutRoom: receiptItem?.paymentRoomBookingItem?.room,
        amount: amount || unitPrice * quantity,
        paymentMethod: receiptItem.paymentMethod,
        status: receiptItem.type as ReceiptItemType,
        roomBookingItem,
        receiptItem,
        receiptItemDetail,
        level: 1,
        printDate:
            receiptItemDetail?.printDate || customDayjs(bookingDate).fmYYYYMMDD('-'),
    };
    return tableDataItem;
};

const mapReceiptItemDetailsOfPlan = (
    receiptItem: IRoomBookingItemsReceiptItem,
    numberOfChildren: number,
    groupRepresentativeGuest: IRepresentativeGuest,
    roomBookingItem: IRoomBookingItemReceipt,
) => {
    const tableData: ISplitRoomBookingReceiptTableData[] = [];
    const { receiptItemDetails } = receiptItem;
    const saleItemsOfPlan = receiptItemDetails?.filter(
        (item) => item.type === ReceiptItemDetailType.SALE_ITEM,
    );
    const stayPrice = receiptItemDetails?.find(
        (item) => item.type === ReceiptItemDetailType.STAY_PRICE,
    );
    if (stayPrice) {
        const roomPrice = sumBy([...(saleItemsOfPlan || []), stayPrice], 'amount');
        const saleItemsOfPlanPrice = sumBy(saleItemsOfPlan, 'amount');
        const quantity = receiptItemDetails?.[0]?.quantity || 1;
        const unitPrice = Math.floor((roomPrice - saleItemsOfPlanPrice) / quantity);
        const item = mapReceiptItemDetail(
            roomBookingItem,
            receiptItem,
            stayPrice,
            numberOfChildren,
            groupRepresentativeGuest,
        );
        tableData.push({
            ...item,
            amount: roomPrice,
            quantity,
            unitPrice,
        });
    }
    receiptItemDetails?.forEach((receiptItemDetail) => {
        if (
            receiptItemDetail.type === ReceiptItemDetailType.SALE_ITEM ||
            receiptItemDetail.type === ReceiptItemDetailType.STAY_PRICE
        ) {
            return;
        }
        tableData.push(
            mapReceiptItemDetail(
                roomBookingItem,
                receiptItem,
                receiptItemDetail,
                numberOfChildren,
                groupRepresentativeGuest,
            ),
        );
    });
    return tableData;
};

const mapReceiptItems = (
    roomBookingItem: IRoomBookingItemReceipt,
    numberOfChildren: number,
    groupRepresentativeGuest: IRepresentativeGuest,
) => {
    let tableData: ISplitRoomBookingReceiptTableData[] = [];
    roomBookingItem?.receiptItems?.forEach(
        (receiptItem: IRoomBookingItemsReceiptItem) => {
            if (!receiptItem.receiptItemDetails?.length) return;
            if (receiptItem.plan?.id) {
                const _tableData = mapReceiptItemDetailsOfPlan(
                    receiptItem,
                    numberOfChildren,
                    groupRepresentativeGuest,
                    roomBookingItem,
                );
                tableData.push(..._tableData);
                return;
            }
            receiptItem.receiptItemDetails?.forEach((receiptItemDetail) => {
                tableData.push(
                    mapReceiptItemDetail(
                        roomBookingItem,
                        receiptItem,
                        receiptItemDetail,
                        numberOfChildren,
                        groupRepresentativeGuest,
                    ),
                );
            });
        },
    );
    return orderBy(tableData, ['status', 'bookingDate'], ['desc', 'asc']);
};
export const convertSplitRoomBookingReceipt = (
    receipt: IReceipt | null,
    roomBookingDetail: IRoomBookingDetail | null,
): ISplitRoomBookingReceiptTableData[] => {
    if (!receipt) return [];
    const groupRepresentativeGuest = receipt?.roomBooking?.representativeGuest;
    const roomBookingItems = receipt?.roomBooking?.roomBookingItems || [];
    const splitRoomBookingReceiptItems: ISplitRoomBookingReceiptTableData[] =
        roomBookingItems.reduce(
            (
                splitRoomBookingReceiptItems: ISplitRoomBookingReceiptTableData[],
                roomBookingItem: IRoomBookingItemReceipt,
                roomBookingItemIndex: number,
            ) => {
                if (!roomBookingItem?.receiptItems?.length)
                    return splitRoomBookingReceiptItems;
                const roomBookingItemDetail = roomBookingDetail?.roomBookingItems.find(
                    (item) => item.id === roomBookingItem.id,
                );
                const numberOfChildren = (
                    roomBookingItemDetail?.roomBookingItemChildrenTypes || []
                )?.reduce((total, item) => {
                    total += item.quantity;
                    return total;
                }, 0);
                const receiptItemChildrenData = mapReceiptItems(
                    roomBookingItem,
                    numberOfChildren,
                    groupRepresentativeGuest,
                );

                receiptItemChildrenData.length &&
                    splitRoomBookingReceiptItems.push({
                        id: `hol_${roomBookingItem.id}`,
                        groupId: null,
                        name: `roomBooking.splitReceipt.roomItem`,
                        index: roomBookingItemIndex,
                        autoGeneratedCode: receipt?.roomBooking?.autoGeneratedCode,
                        children: receiptItemChildrenData,
                        roomTax: roomBookingItem.roomTax,
                        level: 0,
                        bookingStatus: roomBookingItem.bookingStatus,
                    });
                return splitRoomBookingReceiptItems;
            },
            [],
        );
    return splitRoomBookingReceiptItems;
};

export const convertSplitFacilityBookingReceipt = (
    receipt: IReceipt | null,
): ISplitRoomBookingReceiptTableData[] => {
    const facilityBookingItems = receipt?.facilityBookings || [];

    const splitRoomBookingReceiptItems: ISplitRoomBookingReceiptTableData[] =
        facilityBookingItems.reduce(
            (
                splitRoomBookingReceiptItems: ISplitRoomBookingReceiptTableData[],
                facilityBookingItem: IFacilityBookingReceiptDetail,
                facilityBookingIndex: number,
            ) => {
                if (!facilityBookingItem.receiptItems.length)
                    return splitRoomBookingReceiptItems;
                const receiptItemChildrenData = facilityBookingItem.receiptItems.reduce(
                    (
                        receiptItemDetailList: ISplitRoomBookingReceiptTableData[],
                        receiptItem: IFacilityBookingReceiptItem,
                    ) => {
                        if (!receiptItem.receiptItemDetails.length)
                            return receiptItemDetailList;
                        receiptItem?.receiptItemDetails.forEach((receiptItemDetail) => {
                            receiptItemDetailList.push({
                                id: `fac_${facilityBookingItem.id}_${receiptItem.id}_${receiptItemDetail.id}`,
                                bookingDate:
                                    receiptItem.type === ReceiptItemType.PAYMENT
                                        ? parseDate(receiptItemDetail.payAt)?.fmYYYYMMDD(
                                              '-',
                                          )
                                        : parseDate(
                                              receiptItemDetail.boughtAt,
                                          )?.fmYYYYMMDD('-'),
                                groupId: receiptItemDetail.groupId || null,
                                room: receiptItem.paymentRoomBookingItem?.room || null,
                                bookingDetail:
                                    receiptItemDetail?.type ===
                                    ReceiptItemDetailType.SALE_ITEM
                                        ? receiptItemDetail?.saleItem?.name
                                        : receiptItemDetail?.type ===
                                          ReceiptItemDetailType.STAY_PRICE
                                        ? facilityBookingItem?.facility?.name
                                        : '',
                                checkOutRoom:
                                    receiptItem?.paymentRoomBookingItem?.room || null,
                                guest: {
                                    ...facilityBookingItem?.guest,
                                    birthday:
                                        facilityBookingItem.guest?.birthday || undefined,
                                    gender: facilityBookingItem?.guest?.gender as Gender,
                                },
                                unitPrice: receiptItemDetail?.unitPrice,
                                quantity: receiptItemDetail?.quantity,
                                amount: receiptItemDetail?.amount,
                                paymentMethod: receiptItem?.paymentMethod,
                                status: receiptItem.type as ReceiptItemType,
                                facilityBooking: facilityBookingItem,
                                receiptItem,
                                receiptItemDetail,
                                level: 1,
                            });
                        });
                        return receiptItemDetailList;
                    },
                    [],
                );

                receiptItemChildrenData.length &&
                    splitRoomBookingReceiptItems.push({
                        id: `fac_${facilityBookingItem.id}`,
                        groupId: null,
                        name: `roomBooking.splitReceipt.facilityItem`,
                        autoGeneratedCode: facilityBookingItem?.autoGeneratedCode,
                        index: facilityBookingIndex,
                        children: receiptItemChildrenData,
                        level: 0,
                        bookingStatus: facilityBookingItem.status,
                    });
                return splitRoomBookingReceiptItems;
            },
            [],
        ) || [];

    return splitRoomBookingReceiptItems;
};

export const isSplitAllReceiptItemDetails = (receipt: IReceipt | null) => {
    if (!receipt) return true;

    for (const roomBookingItem of receipt.roomBooking.roomBookingItems) {
        if (!roomBookingItem?.receiptItems) return true;

        for (const receiptItem of roomBookingItem.receiptItems) {
            for (const receiptItemDetail of receiptItem.receiptItemDetails) {
                if (isEmpty(receiptItemDetail.groupId)) return false;
            }
        }
    }

    return true;
};

export const calculateReceiptTotalTax = (receipt: IReceipt | null) => {
    if (!receipt) return 0;
    const consumptionTax = receipt.totalTax;
    let totalLocalTax = 0;

    receipt.roomBooking?.roomBookingItems?.forEach((roomBookingItem) => {
        roomBookingItem.receiptItems?.forEach((receiptItem) => {
            receiptItem.receiptItemDetails?.forEach((receiptItemDetail) => {
                if (
                    receiptItemDetail.type === ReceiptItemDetailType.LOCAL_TAX ||
                    receiptItemDetail.type === ReceiptItemDetailType.BATH_TAX
                ) {
                    totalLocalTax += receiptItemDetail.amount;
                }
            });
        });
    });
    return consumptionTax + totalLocalTax;
};

export const updateNewReceiptItemDetail = (
    receiptByGroup: IReceiptByGroup,
    updatingItem: ISplitRoomBookingReceiptTableData,
    newReceiptItemDetail: IPrintingReceiptItemDetail,
) => {
    receiptByGroup.receipt.roomBooking.roomBookingItems.forEach(
        (roomBookingItem: IRoomBookingItemReceipt) => {
            const updatingReceiptItem = roomBookingItem?.receiptItems?.find(
                (item: IRoomBookingItemsReceiptItem) =>
                    item.id === updatingItem.receiptItem?.id,
            );
            updatingReceiptItem?.receiptItemDetails.forEach(
                (receiptItemDetail: IReceiptItemDetails) => {
                    if (updatingItem.receiptItemDetail?.id === receiptItemDetail.id) {
                        let unitPrice = receiptItemDetail.unitPrice;
                        if (
                            updatingItem.receiptItemDetail.type ===
                            ReceiptItemDetailType.STAY_PRICE
                        ) {
                            const planItems =
                                updatingReceiptItem?.receiptItemDetails.filter(
                                    (item) =>
                                        item.type === ReceiptItemDetailType.STAY_PRICE ||
                                        item.type === ReceiptItemDetailType.SALE_ITEM,
                                );
                            unitPrice = planItems.reduce(
                                (sum, item) => sum + item.unitPrice,
                                0,
                            );
                        }
                        if (newReceiptItemDetail?.bookingDetail) {
                            receiptItemDetail.bookingDetail =
                                newReceiptItemDetail.bookingDetail;
                        }
                        if (newReceiptItemDetail?.printDate) {
                            receiptItemDetail.printDate = newReceiptItemDetail.printDate;
                        }
                        const newUnitPrice = newReceiptItemDetail?.unitPrice ?? unitPrice;
                        const newQuantity =
                            newReceiptItemDetail?.quantity ?? receiptItemDetail.quantity;
                        const newAmount =
                            newReceiptItemDetail?.amount ?? newUnitPrice * newQuantity;

                        receiptItemDetail.unitPrice = newUnitPrice;
                        receiptItemDetail.quantity = newQuantity;
                        receiptItemDetail.amount = newAmount;
                    }
                },
            );
        },
    );
};
