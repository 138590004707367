const facilityBookingCreateFormEn = {
    title: 'Create facility booking',
    roomName: 'Room name',
    form: {
        facility: {
            label: 'Facility name',
            placeholder: 'Select facility',
        },
        guestYomigana: {
            label: 'Reserver name (Yomigana)',
            placeholder: 'Ex: Yamada Tarou',
        },
        guestPhoneNumber: {
            label: 'Phone number',
            placeholder: 'Input phone number',
        },
        facilityBookingTimePeriod: {
            label: 'Reservation duration',
            placeholder: {
                start: 'Start time',
                end: 'End time',
            },
        },
        numberOfGuests: {
            label: 'Number of guests',
            placeholder: '0',
        },
        total: {
            label: 'Total',
        },
        guestFullName: {
            label: 'Reserver name ',
            placeholder: 'Ex: Yakiso Fuyuma',
        },
        guestBirthday: {
            label: 'Date of birth',
            placeholder: 'yyyy/mm/dd',
        },
        guestGender: {
            label: 'Gender',
            placeholder: 'Select gender',
        },
        guestMemo: {
            label: 'Memo',
            placeholder: 'Please fill out the memo',
        },
        detailGuestInformation: 'Guest detail information',
        marketingChannel: {
            label: 'Marketing channel',
            placeholder: 'Select marketing channel',
        },
        emailAddress: {
            label: 'email address',
            placeholder: 'tsubota.ayane@gmail.com',
        },
    },
    priceNumber: '¥{{priceNumber}}',
    guestGender: {
        male: 'Male',
        female: 'Femail',
        other: 'Other',
    },
    message: {
        create: {
            success: 'Create facility booking success',
            error: 'Create facility booking failed',
        },
        update: {
            success: 'Update facility booking success',
            error: 'Update facility booking failed',
        },
    },
    button: {
        createFacilityBooking: 'Create',
    },
};

export default facilityBookingCreateFormEn;
