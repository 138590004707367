import { Col, Row } from 'antd';
import './PlanOverviewForm.scss';
import { useTranslation } from 'react-i18next';
import { InputText, SingleSelect, InputTextArea, TimeRangePicker } from '~components';
import { DateFormat } from '~common/constants';
import { PlanType } from '~features/plan/constants';
import { getFormPlanId } from '~features/plan/helper';
import { useAppSelector } from '~hooks';
import { taxListDropdownSelector } from '~features/plan/reducers/plan.reducer';
type Iprops = {
    control: any;
};

function PlanOverviewForm({ control }: Iprops) {
    const { t } = useTranslation();
    const taxList = useAppSelector(taxListDropdownSelector);

    return (
        <div className="plan-info-tab-panel-wrapper">
            <div className="form-title">
                <span className="mr-8">{t('plan.create.planOverview.title')}</span>
                <span className="title-required-note">
                    {t('plan.create.planOverview.titleNote')}
                </span>
            </div>
            <Row gutter={24}>
                <Col span={10}>
                    <InputText
                        label={t('plan.create.planOverview.name')}
                        placeholder={t('plan.create.planOverview.namePlaceholder')}
                        name="name"
                        control={control}
                        required
                        id={getFormPlanId('name')}
                    />
                </Col>
                <Col span={10}>
                    <InputTextArea
                        label={t('plan.create.planOverview.memo')}
                        name="memo"
                        control={control}
                        id={getFormPlanId('memo')}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={5}>
                    <SingleSelect
                        label={t('plan.create.planOverview.type')}
                        placeholder={t('plan.create.planOverview.typePlaceholder')}
                        name="planType"
                        defaultValue={PlanType.STAY}
                        control={control}
                        options={Object.values(PlanType).map((val) => ({
                            label: t(`plan.create.planOverview.planTypeOptions.` + val),
                            value: val,
                        }))}
                        id={getFormPlanId('planType')}
                    />
                </Col>
                <Col span={5}>
                    <SingleSelect
                        label={t('plan.create.planOverview.taxSetting')}
                        placeholder={t('plan.create.planOverview.taxPlaceholder')}
                        name="taxId"
                        control={control}
                        options={taxList}
                        id={getFormPlanId('taxId')}
                    />
                </Col>
                <Col span={10} className="standard-times-column">
                    <TimeRangePicker
                        labelStart={t('plan.create.planOverview.checkInTime')}
                        labelEnd={t('plan.create.planOverview.checkOutTime')}
                        placeholder={[
                            t('plan.create.planOverview.checkInTime'),
                            t('plan.create.planOverview.checkOutTime'),
                        ]}
                        allowClear={false}
                        order={false}
                        name={`checkInOutTimes`}
                        control={control}
                        format={DateFormat.HH_MM_COLON}
                        id={getFormPlanId('checkInOutTimes')}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default PlanOverviewForm;
