import { Col, Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { StaffLayoutHeader } from '~components';
import FilterForm from '~features/fo-operation-history/components/FilterForm/FilterForm';
import HistoryList from '~features/fo-operation-history/components/HistoryList/HistoryList';
import SelectedHistoryList from '~features/fo-operation-history/components/SelectedHistoryList/SelectedHistoryList';
import { breadcrumbs } from '~features/fo-operation-history/constants';
import './HistoryPage.scss';

function HistoryListPage() {
    const { t } = useTranslation();
    const { Content } = Layout;

    return (
        <div className="operation-history-page">
            <StaffLayoutHeader
                breadcrumbs={breadcrumbs()}
                title={t('history.list.title')}
            />
            <Content className="history-content">
                <FilterForm />
                <Row gutter={20}>
                    <Col span={12}>
                        <HistoryList />
                    </Col>
                    <Col span={12}>
                        <SelectedHistoryList />
                    </Col>
                </Row>
            </Content>
        </div>
    );
}

export default HistoryListPage;
