import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

const Hours = Array.from(Array(24).keys()).map((hour) => {
    if (hour < 10) {
        return `0${hour}`;
    }
    return `${hour}`;
});
const Minutes = Array.from(Array(60).keys()).map((minute) => {
    if (minute < 10) {
        return `0${minute}`;
    }
    return `${minute}`;
});

type Props = {
    onChange: (value: string) => void;
    defaultValue?: string;
    name: string;
};

export const PickerTimePanel = (props: Props) => {
    const { onChange, defaultValue, name } = props;
    const [time, setTime] = React.useState({
        hour: defaultValue?.split(':')?.[0],
        minute: defaultValue?.split(':')?.[1],
    });
    const hourRef = useRef<any>(null);
    const minuteRef = useRef<any>(null);
    const onSelectHour = (value: string) => {
        setTime((prev) => {
            return {
                ...prev,
                hour: value,
            };
        });
    };

    const onSelectMinute = (value: string) => {
        setTime((prev) => {
            return {
                ...prev,
                minute: value,
            };
        });
    };

    useEffect(() => {
        onChange(`${time.hour || '00'}:${time.minute || '00'}`);
    }, [time]);

    useEffect(() => {
        const hourElement = document.getElementsByClassName(
            name + '-hour-' + time.hour,
        )?.[0];
        hourElement?.scrollIntoView();
        const minuteElement = document.getElementsByClassName(
            name + '-minute-' + time.minute,
        )?.[0];
        minuteElement?.scrollIntoView();
    }, []);

    return (
        <div className="ant-picker-time-panel">
            <div className="ant-picker-content">
                {[Hours, Minutes].map((items, index) => {
                    return (
                        <ul
                            className="ant-picker-time-panel-column"
                            style={{ position: 'relative' }}
                            key={index}
                            ref={(ref) => {
                                if (!index) {
                                    hourRef.current = ref;
                                } else {
                                    minuteRef.current = ref;
                                }
                            }}
                        >
                            {items.map((item) => {
                                return (
                                    <li
                                        key={item}
                                        className={classNames(
                                            'ant-picker-time-panel-cell',
                                            {
                                                'ant-picker-time-panel-cell-selected':
                                                    index === 0
                                                        ? item === time.hour
                                                        : item === time.minute,
                                                [name +
                                                (index === 0 ? '-hour' : '-minute') +
                                                '-' +
                                                item]: true,
                                            },
                                        )}
                                        onClick={() => {
                                            if (index === 0) {
                                                onSelectHour(item);
                                            } else {
                                                onSelectMinute(item);
                                            }
                                        }}
                                    >
                                        <div className="ant-picker-time-panel-cell-inner">
                                            {item}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    );
                })}
            </div>
        </div>
    );
};
