const planDetail = {
    title: 'Plan detail',
    breadcrumbs: {
        home: 'Home',
        frontSetting: 'Front setting',
        plan: 'Plan management',
        planDetail: 'Plan detail',
    },
    editButton: 'Edit',
    tab1: {
        title: 'Plan overview',
        planOverview: {
            title: 'Plan overview',
            name: 'Price calendar',
            detail: 'Plan details',
            planType: 'Plan type',
            taxId: 'Tax setting',
            checkInTime: 'Check in',
            checkOutTime: 'Check out',
        },
        saleItem: {
            title: 'Sales item setting',
            detailTitle: 'Sales items included in plan',
        },
    },
    tab2: {
        title: 'Price setting',
        rankCalendarAndRoomType: {
            title: 'Price setting',
            rankCalendar: 'Price calendar',
            roomType: 'Room type',
        },
        planRoomTypePricing: {
            title: 'Plan setting',
            planGroup: 'Plan group code',
            people: 'Price setting',
            planGroupColumn: {
                code: 'Plan group code',
                codeOTA: 'OTA plan code',
                nameOTA: 'OTA plan name',
            },
            numberOfPeople: 'Number of guests',
            person: 'People',
            manJa: 'JPY',
        },
    },
    planListColumn: {
        id: 'ID',
        planName: 'Plan name',
        planType: 'Plan type',
    },
    confirm: {
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'Delete',
        cancelTitle: 'Do you want to cancel?',
    },
    message: {
        deleteSuccess: 'Plans have been deleted successfully',
    },
};

export default planDetail;
