import { Col, Row } from 'antd';
import { DebouncedFunc } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '~common/constants';
import { TimePickerField } from '~components';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { IRoomBookingItem } from '~features/room-booking/interfaces';
import { parseDate, parseTime } from '~plugins/dayjs';

type Props = {
    control: any;
    bookingItem: IRoomBookingItem;
    fetchRoomDropDown: DebouncedFunc<(roomBookingStayPeriod: string[]) => void>;
    getValues: any;
};
function BookingTimeRow(props: Props) {
    const { control, bookingItem, fetchRoomDropDown, getValues } = props;
    const { t } = useTranslation();

    const changeBookingTime = (value: string, field: string) => {
        const { dateOfStayPeriod, checkInTime, checkOutTime } = getValues();
        if (field === 'checkOutTime') {
            fetchRoomDropDown([
                `${parseDate(dateOfStayPeriod[0]).fmYYYYMMDD(
                    '-',
                )} ${checkInTime.fmHHmmss()}`,
                `${parseDate(dateOfStayPeriod[1]).fmYYYYMMDD('-')} ${parseTime(
                    value,
                ).fmHHmmss()}`,
            ]);
        } else if (field === 'checkInTime') {
            fetchRoomDropDown([
                `${parseDate(dateOfStayPeriod[0]).fmYYYYMMDD('-')} ${parseTime(
                    value,
                ).fmHHmmss()}`,
                `${parseDate(dateOfStayPeriod[1]).fmYYYYMMDD(
                    '-',
                )} ${checkOutTime.fmHHmmss()}`,
            ]);
        }
    };
    const canEditCheckIn = useMemo(() => {
        return bookingItem.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED;
    }, [bookingItem]);

    const canEditCheckOut = useMemo(() => {
        return (
            bookingItem.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED ||
            bookingItem.bookingStatus === RoomBookingItemBookingStatus.CHECKED_IN
        );
    }, [bookingItem]);
    return (
        <Row gutter={12}>
            {canEditCheckIn ? (
                <Col span={12}>
                    <div className="form-item-horizontal">
                        <label className="label-horizontal">
                            {t('roomBooking.detail.bookingItemCard.checkInTime.label')}
                        </label>
                        <TimePickerField
                            label=""
                            name="checkInTime"
                            placeholder={t(
                                'roomBooking.detail.bookingItemCard.checkInTime.placeholder',
                            )}
                            allowClear={false}
                            control={control}
                            format={DateFormat.HH_MM_COLON}
                            onChange={(_, formatString) =>
                                changeBookingTime(formatString, 'checkInTime')
                            }
                        />
                    </div>
                </Col>
            ) : (
                <Col span={12}>
                    <span className="margin-bottom-row-20">{`${t(
                        'roomBooking.detail.bookingItemCard.checkInTime.label',
                    )} ${bookingItem.checkInTime || ''}`}</span>
                </Col>
            )}
            {canEditCheckOut ? (
                <Col span={12}>
                    <div className="form-item-horizontal">
                        <label className="label-horizontal">
                            {t('roomBooking.detail.bookingItemCard.checkOutTime.label')}
                        </label>
                        <TimePickerField
                            label=""
                            name="checkOutTime"
                            placeholder={t(
                                'roomBooking.detail.bookingItemCard.checkOutTime.placeholder',
                            )}
                            allowClear={false}
                            control={control}
                            format={DateFormat.HH_MM_COLON}
                            onChange={(_, formatString) =>
                                changeBookingTime(formatString, 'checkOutTime')
                            }
                        />
                    </div>
                </Col>
            ) : (
                <Col span={12}>
                    <span>{`${t(
                        'roomBooking.detail.bookingItemCard.checkOutTime.label',
                    )} ${bookingItem.checkOutTime || ''}`}</span>
                </Col>
            )}
        </Row>
    );
}
export default BookingTimeRow;
