import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { Button, Form, Select, Switch } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerType } from '~common/constants';
import { SingleDatePicker } from '~components';
import {
    salesByOtaSelector,
    setExportCsv,
    setIsShowByDaily,
    setSelectedDate,
    setSelectedMarketingChannelId,
    setShowSalesByOtaTable,
} from '~features/report/reducers/sales-by-ota.reducer';
import { reportService } from '~features/report/report.service';
import { reportingServiceV2 } from '~features/report/reportV2.service';
import { salesByOtaFilterSchema } from '~features/report/schema';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { CacheKeys } from '~queries/queries';
import { IMarketingChannelDropDownItem } from '../interfaces';
import './FilterForm.scss';

function FilterForm() {
    const allOptionValue = 0;
    const { t } = useTranslation();
    const { isShowSalesByOtaTable } = useAppSelector(salesByOtaSelector);
    const dispatch = useAppDispatch();

    const [date, setDate] = useState<Dayjs>(customDayjs());
    const [marketingChannelId, setMarketingChannelId] = useState<number>(allOptionValue);
    const [showByDay, setShowByDay] = useState(false);

    const { control, setValue } = useForm({
        resolver: yupResolver(salesByOtaFilterSchema),
    });

    useEffect(() => {
        setValue(`day`, customDayjs());
    }, []);

    const onChangeDate = (date: Dayjs | null) => {
        if (date) {
            setDate(date);
        }
    };

    const onChangeMarketingChannel = (value: number) => {
        setMarketingChannelId(value);
    };

    const onChangeDisplayMode = (value: boolean) => {
        setShowByDay(value);
    };

    const fetchMarketingChannelDropdown = async () => {
        const response = await reportService.getMarketingChannelsDropdown();
        if (response.success) {
            const { data } = response;
            const options = data.items.map((item: IMarketingChannelDropDownItem) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });
            return options;
        } else {
            throw Error(response.message);
        }
    };

    const { data: marketingChannelDropdown, isFetching: isFetchingDropdown } = useQuery({
        queryKey: [CacheKeys.getMarketingChannelDropdown],
        queryFn: fetchMarketingChannelDropdown,
        enabled: true,
    });

    const { refetch, isFetching } = useQuery({
        queryKey: [CacheKeys.getSalesByOtaReportKey],
        queryFn: async () => {
            const startDate = date.startOf('month').format('YYYY-MM-DD');
            const endDate = date.endOf('month').format('YYYY-MM-DD');
            const datePeriod = [startDate, endDate];
            const params = {
                datePeriod,
                marketingChannelId,
                isShownByDaily: showByDay,
            };
            const response = await reportingServiceV2.getSalesByOtaReport(params);
            if (response.success) {
                dispatch(setShowSalesByOtaTable(true));
                return response.data;
            } else {
                throw Error(response.message);
            }
        },
        enabled: false,
    });

    const onClickButtonFilter = () => {
        dispatch(setIsShowByDaily(showByDay));
        dispatch(setSelectedDate(date.format('YYYY-MM-DD')));
        dispatch(setSelectedMarketingChannelId(marketingChannelId));
        refetch();
    };

    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };

    const marketingChannelOptions = useMemo(() => {
        if (!marketingChannelDropdown) return [];
        return [
            {
                value: allOptionValue,
                label: t('report.salesByPlans.filterForm.allOption'),
            },
            ...marketingChannelDropdown,
        ];
    }, [marketingChannelDropdown]);

    return (
        <div className="report-sales-by-ota-filter-form-wrapper">
            <Form className="filter-form-content">
                <Form.Item label={t('report.salesByOta.filterForm.pickerLabel')}>
                    <SingleDatePicker
                        placeholder={t('report.placeholder.selectDate')}
                        value={date}
                        allowClear={false}
                        name="day"
                        picker={DatePickerType.MONTH}
                        onChange={onChangeDate}
                        control={control}
                    />
                </Form.Item>
                <Form.Item label={t('report.salesByOta.filterForm.otaLabel')}>
                    <Select
                        onChange={onChangeMarketingChannel}
                        dropdownMatchSelectWidth={false}
                        className="marketing-channel-select"
                        value={marketingChannelId}
                        loading={isFetchingDropdown}
                    >
                        {marketingChannelOptions.map((option) => {
                            return (
                                <Select.Option value={option.value} key={option.value}>
                                    {option.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label={t('report.salesByOta.filterForm.displayMode')}>
                    <Switch checked={showByDay} onChange={onChangeDisplayMode} />
                </Form.Item>
                <Button loading={isFetching} onClick={onClickButtonFilter} type="primary">
                    {t('report.salesByOta.filterForm.filterButton')}
                </Button>
            </Form>
            {isShowSalesByOtaTable && (
                <Button onClick={exportCSV} className="export-csv-btn">
                    {t('report.exportCSV')}
                </Button>
            )}
        </div>
    );
}

export default FilterForm;
