import {
    ReceiptItemDetailType,
    SplitPaymentMethods,
    TAX_10_PERCENTAGE,
    TAX_8_PERCENTAGE,
} from './../../../../constants';
import customDayjs, { parseDate } from '~plugins/dayjs';
import i18next from 'i18next';
import { Content, PageSize, TDocumentDefinitions } from 'pdfmake/interfaces';
import {
    formatMoney,
    makeRowWithCellSpan,
    textToChunksWithMaxLength,
} from '~common/commonFunctions';
import {
    PrintingOrientation,
    PrintingPaperSize,
    TaxType,
    SupportLanguage,
} from '~common/constants';
import { convertSplitReceipt } from '~features/room-booking/helper.split-receipt';
import {
    IPaymentMethodList,
    IReceiptByGroup,
    IReceiptItemDetails,
    IRoomBookingDetail,
    ISplitRoomBookingReceiptTableData,
    TaxSummary,
} from './../../../../interfaces';
import { ReceiptItemType } from '~features/room-booking/constants';
import { calculateSalesTaxAmount, getAutoGeneratedCode, isCanceledBooking } from '~features/room-booking/helper';
import { BankAccountResponseBody, IHotel } from '~features/hotel/interfaces';
import { orderBy, sumBy } from 'lodash';

interface IOptions {
    orientation: PrintingOrientation;
    setTotalPage: (totalPage: number) => void;
    paperSize: PrintingPaperSize;
}

const { t } = i18next;

export const generateSplitRoomBookingReceiptPdf = (
    receiptByGroupList: IReceiptByGroup[],
    roomBookingDetail: IRoomBookingDetail | null,
    options: IOptions,
    currentLanguage: SupportLanguage,
    totalPaidAmount: Record<string, number>,
    hotel: IHotel | null | undefined,
    updateTotalSales?: (totals: number[]) => void,
    isInvoice?: boolean,
    bankAccounts?: BankAccountResponseBody[],
): TDocumentDefinitions => {
    const {
        orientation = PrintingOrientation.PORTRAIT,
        setTotalPage,
        paperSize = PrintingPaperSize.A4,
    } = options;

    let content: Content[] = [];

    const TotalSales: number[] = [];
    for (let i = 1, n = receiptByGroupList.length; i <= n; i += 1) {
        const { content: newContent, totalPayment } = generateSplitRoomBookingContent(
            receiptByGroupList[i - 1],
            roomBookingDetail,
            orientation,
            hotel,
            i !== n,
            currentLanguage,
            totalPaidAmount[receiptByGroupList[i - 1]!.group!.id] || 0,
            isInvoice,
            bankAccounts,
        ) as { content: Content; totalPayment: number };

        content = content.concat(newContent);
        TotalSales.push(totalPayment);
    }
    if (updateTotalSales) updateTotalSales(TotalSales);

    return {
        pageSize: paperSize.toUpperCase() as PageSize,
        pageOrientation: orientation,
        pageMargins: [28, 48, 24, 10],
        header: (currentPage, pageCount) => ({
            columns: [
                {
                    stack: [
                        {
                            text: `${currentPage}/${pageCount}`,
                            alignment: 'right',
                        },
                    ],
                },
            ],
            style: 'pageHeader',
            margin: [40, 12, 24, 0],
        }),
        content,
        footer: (currentPage, pageCount) => {
            setTotalPage(pageCount);
            return {
                text: '',
            };
        },
        styles: {
            pageHeader: {
                fontSize: 10,
            },
            header: {
                fontSize: 18,
                bold: true,
            },
        },
    };
};

const getPaymentMethodList = (data: ISplitRoomBookingReceiptTableData[]) => {
    const paymentMethodList: IPaymentMethodList[] = [];

    data.forEach((item) => {
        if (item.status === ReceiptItemType.PAYMENT && item.paymentMethod) {
            paymentMethodList.push({
                paymentMethod: item.paymentMethod,
                quantity: 1,
                amount: item.amount || 0,
                bookingDate: item.bookingDate,
                bookingDetail: item.bookingDetail,
                printDate: item.printDate,
            });
        }
    });

    return paymentMethodList;
};

const generateSplitRoomBookingContent = (
    receiptByGroup: IReceiptByGroup,
    roomBookingDetail: IRoomBookingDetail | null,
    orientation: PrintingOrientation,
    hotel: IHotel | null | undefined,
    pageBreak?: boolean,
    currentLanguage?: SupportLanguage,
    totalPaidAmount?: number,
    isInvoice?: boolean,
    bankAccounts?: BankAccountResponseBody[],
) => {
    const NUMBER_OF_COLUMNS = 20;

    const mapSplitReceipt = convertSplitReceipt(
        receiptByGroup?.receipt,
        roomBookingDetail,
    );

    const convertedTableData = mapSplitReceipt.reduce(
        (data: ISplitRoomBookingReceiptTableData[], item) => {
            if (isCanceledBooking(item.bookingStatus)) return data;
            data.push(...(item.children || []));
            return orderBy(data, ['bookingDate'], ['asc']);
        },
        [],
    );

    const stayPeriod = (() => {
        let startDate = '';
        let endDate = '';

        convertedTableData.forEach((item) => {
            const { startDateOfStay, endDateOfStay } = item.roomBookingItem ?? {};
            if (!startDate && !endDate) {
                startDate = startDateOfStay || '';
                endDate = endDateOfStay || '';
                return;
            }
            if (startDate && parseDate(startDate).isAfter(parseDate(startDateOfStay))) {
                startDate = startDateOfStay || '';
            }
            if (endDate && parseDate(endDate).isAfter(endDateOfStay)) {
                endDate = endDateOfStay || '';
            }
        });
        return { startDate, endDate };
    })();

    const paymentMethodList = getPaymentMethodList(convertedTableData);

    const prepaidAmountList = paymentMethodList.filter((item) => {
        const { paymentMethod } = item;
        return paymentMethod.name.includes(SplitPaymentMethods.PREPAID);
    });

    const paidAmountList = paymentMethodList.filter((item) => {
        const { paymentMethod } = item;
        return !paymentMethod.name.includes(SplitPaymentMethods.PREPAID);
    });

    const getReceiptItemTax = (receiptItem: ISplitRoomBookingReceiptTableData) => {
        const taxes: TaxSummary[] = [];
        const { receiptItemDetail } = receiptItem;
        switch (receiptItemDetail?.type) {
            case ReceiptItemDetailType.BATH_TAX:
            case ReceiptItemDetailType.LOCAL_TAX:
                taxes.push({ taxAmount: receiptItem.amount || 0 });
                break;
            case ReceiptItemDetailType.SALE_ITEM:
                taxes.push({
                    taxAmount: receiptItemDetail?.saleItemTax || 0,
                    taxableAmount: receiptItemDetail?.amount || 0,
                    taxType: receiptItemDetail?.saleItem?.tax?.type,
                });
                break;
            case ReceiptItemDetailType.STAY_PRICE_CHILDREN:
                taxes.push({
                    taxAmount: receiptItemDetail?.stayPriceTax || 0,
                    taxableAmount: receiptItemDetail?.amount || 0,
                    taxType:
                        receiptItem?.roomBookingItem?.pricingSettings?.[0]?.planTax?.type,
                });
                break;
            case ReceiptItemDetailType.STAY_PRICE: {
                const saleItemsOfPlan = (
                    receiptItem.receiptItem?.receiptItemDetails as IReceiptItemDetails[]
                )?.filter((item) => item.type === ReceiptItemDetailType.SALE_ITEM);
                saleItemsOfPlan.forEach((item) => {
                    taxes.push({
                        taxAmount: item.saleItemTax || 0,
                        taxableAmount: item.amount || 0,
                        taxType: item.saleItem?.tax?.type,
                    });
                });
                taxes.push({
                    taxAmount: receiptItemDetail?.stayPriceTax || 0,
                    taxableAmount: receiptItemDetail?.amount || 0,
                    taxType:
                        receiptItem?.roomBookingItem?.pricingSettings?.[0]?.planTax?.type,
                });
                break;
            }
        }
        return taxes;
    };

    const totalPrice = (() => {
        let totalSale = 0;
        let totalPayment = 0;
        let totalOtherTax = 0;
        let total10PercentTax = 0;
        let total8PercentTax = 0;
        let total10PercentSale = 0;
        let total8PercentSale = 0;

        convertedTableData.forEach((receiptItem) => {
            if (
                receiptItem.status === ReceiptItemType.PAYMENT &&
                receiptItem.paymentMethod &&
                !receiptItem.paymentMethod.name.includes(SplitPaymentMethods.PREPAID)
            ) {
                totalPayment += receiptItem?.amount || 0;
                return;
            }
            if (receiptItem.status === ReceiptItemType.RECEIPT) {
                totalSale += receiptItem?.amount || 0;
            }

            const taxes = getReceiptItemTax(receiptItem);
            taxes.forEach(({ taxType, taxAmount, taxableAmount }) => {
                if (taxType === TaxType.TAX_1) {
                    total10PercentTax += taxAmount || 0;
                    total10PercentSale += taxableAmount || 0;
                    return;
                }
                if (taxType === TaxType.TAX_2) {
                    total8PercentTax += taxAmount || 0;
                    total8PercentSale += taxableAmount || 0;
                    return;
                }
                totalOtherTax += taxAmount || 0;
            });
        });

        let totalPrepaidAmount = sumBy(prepaidAmountList, 'amount');
        if (total10PercentSale !== 0) {
            if (totalPrepaidAmount >= total10PercentSale) {
                totalPrepaidAmount = total10PercentSale - totalPrepaidAmount;
                total10PercentSale = 0;
            } else {
                total10PercentSale = total10PercentSale - totalPrepaidAmount;
            }
        }

        /* Only apply tax reduction to 10% items
        if (total8PercentSale !== 0) {
            if (
                totalPrepaidAmount < 0 &&
                Math.abs(totalPrepaidAmount) >= total8PercentSale
            ) {
                totalPrepaidAmount = total8PercentSale - Math.abs(totalPrepaidAmount);
                total8PercentSale = 0;
            } else {
                totalPrepaidAmount = total8PercentSale - Math.abs(totalPrepaidAmount);
                total8PercentSale = Math.abs(totalPrepaidAmount);
            }
        }

        if (totalOtherTax !== 0) {
            if (totalPrepaidAmount < 0 && Math.abs(totalPrepaidAmount) <= totalOtherTax) {
                totalOtherTax = totalOtherTax - Math.abs(totalPrepaidAmount);
            }
        }
        */
        total8PercentTax = calculateSalesTaxAmount(1.08, total8PercentSale);
        total10PercentTax = calculateSalesTaxAmount(1.1, total10PercentSale);

        return {
            totalSale,
            totalPayment,
            totalOtherTax,
            total10PercentTax,
            total8PercentTax,
            total10PercentSale,
            total8PercentSale,
        };
    })();

    const isReceiptItemHasSaleItemWithTaxValue8 = (
        item: ISplitRoomBookingReceiptTableData,
    ): boolean => {
        if (!item || !item.receiptItem || !item.receiptItem.receiptItemDetails) {
            return false;
        }

        const { saleItem, receiptItemDetail } = item;
        const { type } = receiptItemDetail || {};

        if (saleItem && saleItem.tax && saleItem.tax.pricingValue === TAX_8_PERCENTAGE) {
            return true;
        }

        if (
            type === ReceiptItemDetailType.LOCAL_TAX ||
            type === ReceiptItemDetailType.BATH_TAX
        ) {
            return false;
        }

        const hasTaxValue8 = item.receiptItem.receiptItemDetails.some(
            (detail) =>
                detail.saleItem &&
                detail.saleItem.tax &&
                detail.saleItem.tax.pricingValue === TAX_8_PERCENTAGE,
        );

        return hasTaxValue8;
    };

    const generateBookingDetail = (item: ISplitRoomBookingReceiptTableData) => {
        if (
            !hotel?.isRegisteredNumberNotObtained &&
            isReceiptItemHasSaleItemWithTaxValue8(item)
        ) {
            return `${item.saleItem?.name || item.bookingDetail}※`;
        }

        return `${item.saleItem?.name || item.bookingDetail}`;
    };

    return {
        content: [
            {
                stack: [
                    {
                        text: `${isInvoice ? t('roomBooking.splitReceipt.print.invoice') : t('roomBooking.splitReceipt.print.receipt')}`,
                        alignment: 'center',
                        style: 'header',
                        margin: [0, -16, 0, 0],
                    },
                ],
            },
            {
                stack: [
                    {
                        text: `${t(
                            'roomBooking.splitReceipt.print.issueDate',
                        )}: ${customDayjs().fmYYYYMMDDHHmmJp()}`,
                        alignment: 'right',
                    },
                    {
                        text: `${
                            isInvoice ?
                                t(
                                    'roomBooking.splitReceipt.print.invoiceNumber',
                                ) + ': IV' :
                                t(
                                    'roomBooking.splitReceipt.print.receiptNumber',
                                ) + ': RC'
                        }-${getAutoGeneratedCode(
                            roomBookingDetail?.autoGeneratedCode || '',
                        )}-${receiptByGroup.logNumber}`,
                        alignment: 'right',
                        margin: [0, 0, 0, 20],
                    },
                ],
            },
            {
                columns: [
                    {
                        stack: [
                            {
                                text: receiptByGroup.guestName || '\n',
                            },
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: 0,
                                        y1: 0,
                                        x2: 200,
                                        y2: 0,
                                        lineColor: '#00A0C3',
                                    },
                                ],
                            },
                        ],
                        width: 218,
                    },
                    {
                        text: `${t('roomBooking.splitReceipt.print.nameSubText')}`,
                        bold: true,
                        width: 50,
                    },
                    {
                        stack: [
                            {
                                text: hotel?.name ?? '',
                                bold: true,
                            },
                            hotel?.companyName && {
                                text: hotel?.companyName ?? '',
                                margin: [0, 0, 0, 0],
                            },
                            {
                                text: hotel?.postalCode ? `〒${hotel?.postalCode}` : '',
                                margin: [0, 0, 0, 0],
                            },
                            {
                                text: textToChunksWithMaxLength(
                                    hotel?.address,
                                    28,
                                    true,
                                ).join(`\n`),
                            },
                            {
                                text: hotel?.picPhoneNumber ? `TEL：${hotel?.picPhoneNumber}` : '',
                                margin: [0, 0, 0, 0],
                            },
                            {
                                text: !hotel?.isRegisteredNumberNotObtained
                                    ? `${t(
                                          'roomBooking.splitReceipt.print.registeredNumber',
                                      )}: ${hotel?.registeredNumber || ''}`
                                    : null,
                                margin: [0, 0, 0, 0],
                            },
                        ],
                        width: '*',
                    },
                ],
            },
            {
                text: isInvoice ? `${t('roomBooking.splitReceipt.print.invoiceAmount')}` : `${t('roomBooking.splitReceipt.print.amount')}`,
                bold: true,
                alignment: 'left',
                fontSize: 14,
            },
            {
                table: {
                    widths: [190],
                    body: [
                        [
                            {
                                columns: [
                                    {
                                        text:
                                            `￥ ${formatMoney(
                                                sumBy(paidAmountList, 'amount')
                                            )}` ?? '\n',
                                        bold: true,
                                        alignment: 'left',
                                        fontSize: 18,
                                    },
                                ],
                            },
                        ],
                    ],
                },
                layout: {
                    hLineWidth: (i: number) => i === 1 ? 2 : 0,
                    vLineWidth: () => 0,
                    hLineColor: '#00A0C3',
                },
                margin: [0, 0, 0, 10],
            },
            ...(!isInvoice ? [
                {
                    columns: [
                        {
                            width: '*',
                            text: `${t(
                                'roomBooking.splitReceipt.print.provisoSubText',
                            )}          ${
                                receiptByGroup.proviso?.trim()
                                    ? receiptByGroup.proviso +
                                    t(
                                        'roomBooking.splitReceipt.billToTab.item.billProvisoSubText',
                                    )
                                    : ''
                            }`,
                            lineHeight: 0.9,
                        },
                        {
                            width: 'auto',
                            table: {
                                widths: [56],
                                heights: [56],
                                body: [
                                    [
                                        {
                                            text:
                                                currentLanguage === SupportLanguage.JA
                                                    ? textToChunksWithMaxLength(
                                                        `${t(
                                                            'roomBooking.splitReceipt.print.stampPlaceholder',
                                                        )}`,
                                                        2,
                                                        true,
                                                    ).join(`\n`)
                                                    : null,
                                            margin: [15, 10, 0, 0],
                                        },
                                    ],
                                ],
                            },
                            layout: {
                                hLineStyle: () => ({
                                    dash: { length: 3, space: 3 },
                                }),
                                vLineStyle: () => ({
                                    dash: { length: 3, space: 3 },
                                }),
                                hLineColor: '#D8D9D9',
                                vLineColor: '#D8D9D9',
                            },
                        },
                    ],
                    margin: [0, 0, 0, 0],
                },
            ] : []),
            {
                columns: [
                    {
                        stack: [
                            {
                                text: `${t(
                                    'roomBooking.splitReceipt.print.reservationNumber',
                                )}`,
                                bold: true,
                            },
                            {
                                text: `${t(
                                    'roomBooking.splitReceipt.print.reservation',
                                )} ${
                                    getAutoGeneratedCode(
                                        roomBookingDetail?.autoGeneratedCode || '',
                                    ) || ''
                                }`,
                            },
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: 0,
                                        y1: 0,
                                        x2: 200,
                                        y2: 0,
                                        lineColor: '#00A0C3',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        stack: [
                            {
                                text: `${t('roomBooking.splitReceipt.print.stayPeriod')}`,
                                bold: true,
                            },
                            {
                                columns: [
                                    {
                                        width: 'auto',
                                        text: `${t(
                                            'roomBooking.splitReceipt.print.dateText',
                                        )}`,
                                        margin: [0, 0, 20, 0],
                                    },
                                    {
                                        width: '*',
                                        text: `${parseDate(
                                            stayPeriod.startDate,
                                        )?.fmYYYYMMDDJp()} 〜 ${parseDate(
                                            stayPeriod.endDate,
                                        )?.fmYYYYMMDDJp()}`,
                                    },
                                ],
                            },
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: 0,
                                        y1: 0,
                                        x2: 260,
                                        y2: 0,
                                        lineColor: '#00A0C3',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                text: `${t('roomBooking.splitReceipt.print.bookingDetails')}`,
                bold: true,
                margin: [0, 20, 0, 5],
            },
            {
                table: {
                    widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                        .map(() => '*')
                        .concat('auto'),
                    body: [
                        makeRowWithCellSpan([
                            {
                                colSpan: 4,
                                stack: [
                                    {
                                        text: `${t(
                                            'roomBooking.splitReceipt.print.table.bookingDate',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 7,
                                stack: [
                                    {
                                        text: `${t(
                                            'roomBooking.splitReceipt.print.table.bookingDetail',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: `${t(
                                            'roomBooking.splitReceipt.print.table.unitPrice',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: `${t(
                                            'roomBooking.splitReceipt.print.table.quantity',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: isInvoice ? `${t('roomBooking.splitReceipt.print.invoiceAmount')}` : `${t('roomBooking.splitReceipt.print.amount')}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                        ]),
                        ...convertedTableData
                            .filter((item) => item.status !== ReceiptItemType.PAYMENT)
                            .map((item) => {
                                return makeRowWithCellSpan([
                                    {
                                        colSpan: 4,
                                        stack: [
                                            {
                                                text: parseDate(
                                                    item?.printDate || item.bookingDate,
                                                )?.fmYYYYMMDDJp(),
                                                alignment: 'center',
                                            },
                                        ],
                                    },
                                    {
                                        colSpan: 7,
                                        stack: [
                                            {
                                                text: textToChunksWithMaxLength(
                                                    generateBookingDetail(item),
                                                    22,
                                                    true,
                                                ),
                                                alignment: 'left',
                                            },
                                        ],
                                    },
                                    {
                                        colSpan: 3,
                                        stack: [
                                            {
                                                text:
                                                    item?.unitPrice || item?.unitPrice === 0
                                                        ? `￥${formatMoney(
                                                              item.unitPrice,
                                                          )}`
                                                        : '',
                                                alignment: 'right',
                                                margin: [0, 0, 5, 0],
                                            },
                                        ],
                                    },
                                    {
                                        colSpan: 3,
                                        stack: [
                                            {
                                                text: item.quantity,
                                                alignment: 'center',
                                            },
                                        ],
                                    },
                                    {
                                        colSpan: 3,
                                        stack: [
                                            {
                                                text:
                                                    item?.amount || item?.amount === 0
                                                        ? `￥${formatMoney(item.amount)}`
                                                        : '',
                                                alignment: 'right',
                                                margin: [0, 0, 5, 0],
                                            },
                                        ],
                                    },
                                ]);
                            }),
                        makeRowWithCellSpan([
                            {
                                colSpan: 4,
                                stack: [
                                    {
                                        text: '',
                                    },
                                ],
                            },
                            {
                                colSpan: 7,
                                stack: [
                                    {
                                        text: '',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: '',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: t(
                                            'roomBooking.splitReceipt.print.table.total',
                                        ),
                                        alignment: 'right',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: `￥${formatMoney(totalPrice.totalSale)}`,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                        ]),
                        makeRowWithCellSpan([
                            {
                                colSpan: 4,
                                stack: [
                                    {
                                        text: '',
                                    },
                                ],
                            },
                            {
                                colSpan: 7,
                                stack: [
                                    {
                                        text: '',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: '',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: t(
                                            'roomBooking.splitReceipt.print.totalTaxAmount',
                                        ),
                                        alignment: 'right',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: `￥${formatMoney(totalPrice.total10PercentTax + totalPrice.total8PercentTax + totalPrice.totalOtherTax)}`,
                                        bold: true,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                        ]),
                    ],
                },
                layout: {
                    hLineColor: '#D8D9D9',
                    vLineColor: '#D8D9D9',
                    fillColor: (rowIndex: number) => rowIndex === 0 ? '#E6F6F9' : '#FFFFFF',
                },
            },
            {
                columns: [
                    {
                        stack: [
                            {
                                text: `※ ${t(
                                    'roomBooking.splitReceipt.print.reducedSalesTaxRate',
                                )}`,
                                alignment: 'left',
                                margin: [0, 5, 0, 5],
                            },
                        ]
                    },
                ],
            },
            {
                table: {
                    widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                        .map(() => 11)
                        .concat(0),
                    body: [
                        makeRowWithCellSpan([
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `${TAX_10_PERCENTAGE}% ${t(
                                            'roomBooking.splitReceipt.print.target',
                                        )}`,
                                        alignment: 'left',
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `￥${formatMoney(
                                            totalPrice.total10PercentSale - totalPrice.total10PercentTax,
                                        )}`,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: t(
                                            'roomBooking.splitReceipt.print.taxAmount',
                                        ),
                                        alignment: 'left',
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `￥${formatMoney(
                                            totalPrice.total10PercentTax,
                                        )}`,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                        ]),
                        makeRowWithCellSpan([
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `${TAX_8_PERCENTAGE}% ${t(
                                            'roomBooking.splitReceipt.print.target',
                                        )}`,
                                        alignment: 'left',
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `￥${formatMoney(
                                            totalPrice.total8PercentSale - totalPrice.total8PercentTax,
                                        )}`,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: t(
                                            'roomBooking.splitReceipt.print.taxAmount',
                                        ),
                                        alignment: 'left',
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `￥${formatMoney(
                                            totalPrice.total8PercentTax,
                                        )}`,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                        ]),
                        makeRowWithCellSpan([
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: t(
                                            'roomBooking.splitReceipt.print.otherTaxAmount',
                                        ),
                                        alignment: 'left',
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `￥${formatMoney(
                                            totalPrice.totalOtherTax,
                                        )}`,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: t(
                                            'roomBooking.splitReceipt.print.taxAmount',
                                        ),
                                        alignment: 'left',
                                    },
                                ],
                            },
                            {
                                colSpan: 5,
                                stack: [
                                    {
                                        text: `￥${formatMoney(
                                            totalPrice.totalOtherTax,
                                        )}`,
                                        alignment: 'right',
                                        margin: [0, 0, 5, 0],
                                    },
                                ],
                            },
                        ]),
                    ],
                },
                layout: {
                    hLineColor: '#D8D9D9',
                    vLineColor: '#D8D9D9',
                    fillColor: () => {
                        return '#FFFFFF';
                    },
                },
            },
            ...(prepaidAmountList.length
                ? [
                      {
                          text: `${t('roomBooking.splitReceipt.print.prepaidAmount')}`,
                          bold: true,
                          margin: [0, 20, 0, 5],
                      },
                      {
                          table: {
                              widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                                  .map(() => '*')
                                  .concat('auto'),
                              body: [
                                  makeRowWithCellSpan([
                                      {
                                          colSpan: 4,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.bookingDate',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 7,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.bookingDetail',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 3,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.unitPrice',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 3,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.quantity',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 3,
                                          stack: [
                                              {
                                                  text: isInvoice ? `${t('roomBooking.splitReceipt.print.invoiceAmount')}` : `${t('roomBooking.splitReceipt.print.amount')}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                  ]),
                                  ...prepaidAmountList.map((prepaidAmountItem) => {
                                      return makeRowWithCellSpan([
                                          {
                                              colSpan: 4,
                                              stack: [
                                                  {
                                                      text: parseDate(
                                                          prepaidAmountItem?.printDate ||
                                                              prepaidAmountItem.bookingDate,
                                                      )?.fmYYYYMMDDJp(),
                                                      alignment: 'center',
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 7,
                                              stack: [
                                                  {
                                                      text:
                                                          prepaidAmountItem.bookingDetail ||
                                                          prepaidAmountItem.paymentMethod
                                                              .name,
                                                      alignment: 'left',
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 3,
                                              stack: [
                                                  {
                                                      text: '',
                                                      alignment: 'right',
                                                      margin: [0, 0, 5, 0],
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 3,
                                              stack: [
                                                  {
                                                      text: prepaidAmountItem.quantity,
                                                      alignment: 'center',
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 3,
                                              stack: [
                                                  {
                                                      text: `￥${formatMoney(
                                                          prepaidAmountItem.amount,
                                                      )}`,
                                                      alignment: 'right',
                                                      margin: [0, 0, 5, 0],
                                                  },
                                              ],
                                          },
                                      ]);
                                  }),
                              ],
                          },
                          layout: {
                              hLineColor: '#D8D9D9',
                              vLineColor: '#D8D9D9',
                              fillColor: (rowIndex: number) => {
                                  return rowIndex === 0 || rowIndex === 4
                                      ? '#E6F6F9'
                                      : '#FFFFFF';
                              },
                          },
                      },
                  ]
                : []),
            ...(paidAmountList.length
                ? [
                      {
                          text: `${t('roomBooking.splitReceipt.print.paidAmount')}`,
                          bold: true,
                          margin: [0, 20, 0, 5],
                      },
                      {
                          table: {
                              widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                                  .map(() => '*')
                                  .concat('auto'),
                              body: [
                                  makeRowWithCellSpan([
                                      {
                                          colSpan: 4,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.bookingDate',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 7,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.bookingDetail',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 3,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.unitPrice',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 3,
                                          stack: [
                                              {
                                                  text: `${t(
                                                      'roomBooking.splitReceipt.print.table.quantity',
                                                  )}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                      {
                                          colSpan: 3,
                                          stack: [
                                              {
                                                  text: isInvoice ? `${t('roomBooking.splitReceipt.print.invoiceAmount')}` : `${t('roomBooking.splitReceipt.print.amount')}`,
                                                  alignment: 'center',
                                              },
                                          ],
                                      },
                                  ]),
                                  ...paidAmountList.map((paidAmountListItem) => {
                                      return makeRowWithCellSpan([
                                          {
                                              colSpan: 4,
                                              stack: [
                                                  {
                                                      text: parseDate(
                                                          paidAmountListItem?.printDate ||
                                                              paidAmountListItem.bookingDate,
                                                      )?.fmYYYYMMDDJp(),
                                                      alignment: 'center',
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 7,
                                              stack: [
                                                  {
                                                      text:
                                                          paidAmountListItem.bookingDetail ||
                                                          paidAmountListItem.paymentMethod
                                                              .name,
                                                      alignment: 'left',
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 3,
                                              stack: [
                                                  {
                                                      text: '',
                                                      alignment: 'right',
                                                      margin: [0, 0, 5, 0],
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 3,
                                              stack: [
                                                  {
                                                      text: paidAmountListItem.quantity,
                                                      alignment: 'center',
                                                  },
                                              ],
                                          },
                                          {
                                              colSpan: 3,
                                              stack: [
                                                  {
                                                      text: `￥${formatMoney(
                                                          paidAmountListItem.amount,
                                                      )}`,
                                                      alignment: 'right',
                                                      margin: [0, 0, 5, 0],
                                                  },
                                              ],
                                          },
                                      ]);
                                  }),
                              ],
                          },
                          layout: {
                              hLineColor: '#D8D9D9',
                              vLineColor: '#D8D9D9',
                              fillColor: (rowIndex: number) => {
                                  return rowIndex === 0 || rowIndex === 4
                                      ? '#E6F6F9'
                                      : '#FFFFFF';
                              },
                          },
                      },
                  ]
                : []),
            ...(isInvoice && bankAccounts?.length ? [
                {
                    text: `${t('roomBooking.splitReceipt.print.bankAccounts')}`,
                    bold: true,
                    margin: [0, 20, 0, 5],
                },
                {
                    table: {
                        widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                            .map(() => '*')
                            .concat('auto'),
                        body: [
                            makeRowWithCellSpan([
                                {
                                    colSpan: 20,
                                    stack: [{
                                        text: bankAccounts.map(bankAccount =>
                                            `${bankAccount.bankName} ${t('roomBooking.splitReceipt.print.bank')}  ` +
                                            `${bankAccount.branchName} ${t('roomBooking.splitReceipt.print.branch')}   ` +
                                            `${t('roomBooking.splitReceipt.print.accountNumber')} ${bankAccount.accountNumber}   ` +
                                            `${t('roomBooking.splitReceipt.print.accountOwner')} ${bankAccount.accountOwner}\n`
                                        )
                                        .join('')
                                    }, {
                                        text: t('roomBooking.splitReceipt.print.bankAccountNote')
                                    }]
                                }
                            ]),
                        ],
                    },
                    layout: {
                        hLineColor: '#D8D9D9',
                        vLineColor: '#D8D9D9',
                    },
                },
            ] : []),
            ...(pageBreak ? [{
                text: '',
                pageBreak: 'after',
            }] : []),
        ],
        totalPayment: totalPrice.totalSale,
    };
};
