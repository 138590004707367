import i18next from '~plugins/i18next/i18n';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
    DEFAULT_ORDER_DIRECTION,
    TaxType,
    TllRank,
    TllRankDropdown,
} from '~common/constants';

export enum OrderBy {
    ID = 'id',
    NAME = 'name',
    AUTO_GENERATED_CODE = 'autoGeneratedCode',
    PLAN_TYPE = 'planType',
}

export const planListBreadcrumbs = () => [
    {
        text: i18next.t('plan.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('plan.list.breadcrumbs.frontSetting'),
    },
    {
        text: i18next.t('plan.list.breadcrumbs.plan'),
    },
];

export const planCreateBreadrumbs = () => [
    ...planListBreadcrumbs(),
    {
        text: i18next.t('plan.list.breadcrumbs.createPlan'),
    },
];

export const planDetailBreadrumbs = () => [
    ...planListBreadcrumbs(),
    {
        text: i18next.t('plan.detail.breadcrumbs.planDetail'),
    },
];

export const planUpdateBreadrumbs = () => [
    ...planListBreadcrumbs(),
    {
        text: i18next.t('plan.list.breadcrumbs.updatePlan'),
    },
];

export const enum PlanListColumn {
    ID = 'autoGeneratedCode',
    NAME = 'name',
    PLAN_TYPE = 'planType',
    CHECK_IN_TIME = 'checkInTime',
    CHECK_OUT_TIME = 'checkOutTime',
}

export const initPlanListQuery = {
    keyword: '',
    name: '',
    autoGeneratedCode: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: OrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    page: DEFAULT_FIRST_PAGE,
};

export enum SearchField {
    ALL = 'keyword',
    NAME = 'name',
}

export enum PlanDetailPageTabPane {
    PLAN_INFORMATION_TAB = 'planInformationTab',
    PLAN_PRICING_TAB = 'planPricingTab',
}

export enum PlanType {
    STAY = 'stay',
    DAY_USE = 'day_use',
}

export const enum PlanSaleItemListColumn {
    ID = 'id',
    NAME = 'name',
    PRICE_ITEM = 'priceItem',
    NUMBER_OF_PEOPLE = 'numberOfPeople',
    IS_FOR_ONE_PEOPLE = 'isForOnePeople',
    IS_FIRST_DAY_ONLY = 'isFirstDayOnly',
    TOTAL_PRICES = 'totalPrices',
    ACTION = 'action',
}

export enum PlanCreatePagePricingRankTabPane {
    PLAN_CREATE_PRICING_RANK = 'planCreatePagePricingRank',
}

export const planTaxTypes = [TaxType.TAX_1, TaxType.TAX_2, TaxType.TAX_3];

export const MAX_QUANTITY = 99;
export const MAX_LENGTH_PRICE = 10;

export enum PlanRankStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export const defaultPlanRoomTypePricings = TllRankDropdown.map((tllRank, index) => {
    return {
        id: index,
        feId: +`${Date.now()}${Math.random() * 1000}`,
        peopleCount: 0,
        tllRank: tllRank.value as TllRank,
        price: null,
    };
});
