import { yupResolver } from '@hookform/resolvers/yup';
import { mapValues } from 'lodash';
import { lazy, object } from 'yup';
import {
    INPUT_TEXT_MAX_LENGTH,
    MAX_INTEGER,
    MAX_PRICE,
    MEMO_MAX_LENGTH,
    MIN_PRICE,
    Regex,
} from '~common/constants';
import yup from '~plugins/yup';
import {
    MAX_CHILDREN,
    MAX_QUANTITY,
    ReceiptItemDetailType,
    ReceiptItemType,
} from './constants';
import { StopRoomCause } from '~features/room-management/constants';
export const tentativeReservationSchema = yup.object().shape({
    name: yup.string().required(),
});

export const tentativeReservationSchemaResolver = yupResolver(tentativeReservationSchema);

export const advanceSearchSchema = yup.object().shape({
    autoGeneratedCode: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
    planIds: yup.array().of(yup.number().optional()),
    marketingChannelIds: yup.array().of(yup.number().optional()),
    roomBookingItemStatus: yup.array().of(yup.string().optional()),
    guestYomigana: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
    guestMobilePhoneNumber: yup.string().isPhone(),
    memo: yup.string().max(INPUT_TEXT_MAX_LENGTH).optional(),
});
export const bookingSchema = yup.object().shape({
    standardCapacity: yup.number(),
    plan: yup.number().required(),
    numberOfAdults: yup
        .string()
        .minFullAndHalfWidth(1)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .required(),
    numberOfFemale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfMale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfOtherGenderGuest: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    roomTypeId: yup.number().required(),
    isDayUse: yup.boolean(),
    stayingDate: yup.array().of(yup.mixed().required()).required(),
    time: yup.mixed().when('isDayUse', {
        is: true,
        then: yup.mixed().required(),
    }),
    children: yup.array().of(
        yup
            .string()
            .transform((value) => (isNaN(value) ? null : value))
            .minFullAndHalfWidth(0)
            .maxFullAndHalfWidth(MAX_CHILDREN)
            .nullable()
            .optional(),
    ),
});

export const groupBookingSchema = yup.object().shape({
    groupBookingItems: yup.array().of(
        yup
            .object()
            .shape({
                numberOfAvailableRooms: yup.number(),
                roomTypeId: yup.number().required().min(1),
                numberOfRooms: yup.number().min(1).required(),
                plan: yup.number().required(),
                standardCapacity: yup.number(),
                numberOfAdults: yup
                    .string()
                    .minFullAndHalfWidth(1)
                    .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
                    .required(),
                numberOfFemale: yup
                    .string()
                    .minFullAndHalfWidth(0)
                    .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
                    .optional()
                    .nullable(),
                numberOfMale: yup
                    .string()
                    .minFullAndHalfWidth(0)
                    .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
                    .optional()
                    .nullable(),
                numberOfOtherGenderGuest: yup
                    .string()
                    .minFullAndHalfWidth(0)
                    .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
                    .optional()
                    .nullable(),
                children: yup.array().of(
                    yup
                        .string()
                        .transform((value) => (isNaN(value) ? null : value))
                        .minFullAndHalfWidth(0)
                        .maxFullAndHalfWidth(MAX_CHILDREN)
                        .nullable()
                        .optional(),
                ),
                isDayUse: yup.boolean(),
                stayingDate: yup.array().of(yup.mixed().required()).required(),
                time: yup.mixed().when('isDayUse', {
                    is: true,
                    then: yup.mixed().required(),
                }),
            })
            .required(),
    ),
});

export const bookingSchemaResolver = yupResolver(bookingSchema);

export const createBookingSchema = yup.object().shape({
    isReserverTheRepresentative: yup.boolean().nullable(),
    representativeGuest: yup.object().shape({
        emailAddress: yup
            .string()
            .isEmail()
            .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
            .trim()
            .max(INPUT_TEXT_MAX_LENGTH)
            .nullable()
            .optional(),
        fullName: yup
            .string()
            .max(INPUT_TEXT_MAX_LENGTH)
            .hasNoSpecialCharacters()
            .nullable()
            .optional(),
        id: yup
            .number()
            .transform((val: number) => {
                return isNaN(val) ? null : val;
            })
            .nullable(),
        yomigana: yup
            .mixed()
            .transform((value) => {
                if (value instanceof Object) {
                    return value?.yomigana;
                }
                return value;
            })
            .when('id', {
                is: null,
                then: yup
                    .string()
                    .trim()
                    .hasNoSpecialCharacters()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .required(),
            })
            .required(),
        phone: yup.string().trim().isPhone().nullable().optional(),
    }),
    reserverGuest: yup.object().when('isReserverTheRepresentative', {
        is: false,
        then: yup.object().shape({
            emailAddress: yup
                .string()
                .isEmail()
                .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
                .trim()
                .max(INPUT_TEXT_MAX_LENGTH)
                .nullable()
                .optional(),
            fullName: yup
                .string()
                .hasNoSpecialCharacters()
                .max(INPUT_TEXT_MAX_LENGTH)
                .nullable()
                .optional(),
            id: yup
                .number()
                .transform((val: number) => {
                    return isNaN(val) ? null : val;
                })
                .nullable(),
            yomigana: yup
                .mixed()
                .nullable()
                .transform((value) => {
                    if (value instanceof Object) {
                        return value?.yomigana;
                    }
                    return value;
                })
                .when('id', {
                    is: null,
                    then: yup
                        .string()
                        .trim()
                        .hasNoSpecialCharacters()
                        .max(INPUT_TEXT_MAX_LENGTH)
                        .required(),
                })
                .required(),
            phone: yup.string().trim().isPhone().nullable().optional(),
        }),
    }),
    marketingChannelId: yup.number().required(),
    birthday: yup.object().nullable(),
    gender: yup.string().nullable().optional(),
    memo: yup.string().max(MEMO_MAX_LENGTH).nullable(),
    otaMemo: yup.string().max(MEMO_MAX_LENGTH).nullable(),
    members: yup.array().of(
        yup.object().shape({
            id: yup.number().nullable(),
            fullName: yup.string().max(INPUT_TEXT_MAX_LENGTH).nullable(),
            yomigana: yup
                .mixed()
                .nullable()
                .transform((value) => {
                    if (value instanceof Object) {
                        return value?.yomigana;
                    }
                    return value;
                })
                .when('id', {
                    is: null,
                    then: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).nullable(),
                }),
            phone: yup.string().trim().isPhone().nullable().optional(),
            gender: yup.string().nullable(),
            birthday: yup.object().nullable(),
            emailAddress: yup
                .string()
                .isEmail()
                .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
                .trim()
                .max(INPUT_TEXT_MAX_LENGTH)
                .nullable()
                .optional(),
        }),
    ),
    bookings: yup.array().of(
        yup.object().shape({
            standardCapacity: yup.number(),
            numberOfAdults: yup
                .string()
                .minFullAndHalfWidth(1)
                .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
                .required(),
            isRepresentativeRoom: yup.boolean().nullable(),
            roomTypeId: yup.number().required(),
            planId: yup.number().required(),
            room: yup.object().shape({
                id: yup.number().nullable().optional(),
                name: yup.string().nullable().optional(),
            }),
            plan: yup.object().shape({
                id: yup.number().nullable().optional(),
                name: yup.string().nullable().optional(),
            }),
            roomType: yup.object().shape({
                id: yup.number().nullable().optional(),
                name: yup.string().nullable().optional(),
            }),
            checkInTime: yup.mixed().required(),
            checkOutTime: yup.mixed().required(),
            children: yup.array().of(
                yup
                    .string()
                    .transform((value) => (isNaN(value) ? null : value))
                    .minFullAndHalfWidth(0)
                    .maxFullAndHalfWidth(MAX_CHILDREN)
                    .nullable()
                    .optional(),
            ),
        }),
    ),
});

export const updateBookingSchema = yup.object().shape({
    isReserverTheRepresentative: yup.boolean().nullable(),
    representativeGuest: yup.object().shape({
        emailAddress: yup
            .string()
            .trim()
            .isEmail()
            .max(INPUT_TEXT_MAX_LENGTH)
            .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
            .nullable()
            .optional(),
        fullName: yup
            .string()
            .hasNoSpecialCharacters()
            .max(INPUT_TEXT_MAX_LENGTH)
            .nullable()
            .optional(),
        id: yup
            .number()
            .transform((val: number) => {
                return isNaN(val) ? null : val;
            })
            .nullable(),
        yomigana: yup
            .mixed()
            .transform((value) => {
                if (value instanceof Object) {
                    return value?.yomigana;
                }
                return value;
            })
            .when('id', {
                is: null,
                then: yup
                    .string()
                    .trim()
                    .hasNoSpecialCharacters()
                    .max(INPUT_TEXT_MAX_LENGTH)
                    .required(),
            })
            .required(),
        phone: yup.string().trim().isPhone().nullable().optional(),
    }),
    reserverGuest: yup.object().when('isReserverTheRepresentative', {
        is: false,
        then: yup.object().shape({
            emailAddress: yup
                .string()
                .isEmail()
                .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
                .trim()
                .max(INPUT_TEXT_MAX_LENGTH)
                .nullable()
                .optional(),
            fullName: yup.string().max(INPUT_TEXT_MAX_LENGTH).nullable().optional(),
            id: yup
                .number()
                .transform((val: number) => {
                    return isNaN(val) ? null : val;
                })
                .nullable(),
            yomigana: yup
                .mixed()
                .nullable()
                .transform((value) => {
                    if (value instanceof Object) {
                        return value?.yomigana;
                    }
                    return value;
                })
                .when('id', {
                    is: null,
                    then: yup
                        .string()
                        .hasNoSpecialCharacters()
                        .trim()
                        .max(INPUT_TEXT_MAX_LENGTH)
                        .required(),
                })
                .required(),
            phone: yup.string().trim().isPhone().nullable().optional(),
        }),
    }),
    marketingChannelId: yup.number().required(),
    birthday: yup.object().nullable(),
    gender: yup.string().nullable().optional(),
    memo: yup.string().max(MEMO_MAX_LENGTH).nullable(),
    otaMemo: yup.string().max(MEMO_MAX_LENGTH).nullable(),
    members: yup.array().of(
        yup.object().shape({
            id: yup.number().nullable(),
            fullName: yup.string().max(INPUT_TEXT_MAX_LENGTH).nullable(),
            yomigana: yup
                .mixed()
                .nullable()
                .transform((value) => {
                    if (value instanceof Object) {
                        return value?.yomigana;
                    }
                    return value;
                })
                .when('id', {
                    is: null,
                    then: yup.string().trim().max(INPUT_TEXT_MAX_LENGTH).nullable(),
                }),
            phone: yup.string().trim().isPhone().nullable().optional(),
            gender: yup.string().nullable(),
            birthday: yup.object().nullable(),
            emailAddress: yup
                .string()
                .isEmail()
                .trim()
                .max(INPUT_TEXT_MAX_LENGTH)
                .hasNoSpecialCharacters(Regex.EMAIL_SPECIAL_CHARACTERS)
                .nullable()
                .optional(),
        }),
    ),
});

export const createBookingSchemaResolver = yupResolver(createBookingSchema);
export const updateBookingSchemaResolver = yupResolver(updateBookingSchema);

export const updateRoomBookingItemSchema = yup.object().shape({
    representativeGuestId: yup.number().integer().positive().nullable(),
    isRepresentativeRoom: yup.boolean(),
    roomId: yup.number().positive().nullable(),
    roomTypeId: yup.number().positive().required(),
    dateOfStayPeriod: yup.array().of(yup.string().optional()).required(),
    checkInTime: yup.mixed().required(),
    checkOutTime: yup.mixed().required(),
    bookingStatus: yup.string().required(),
    planId: yup.number().strict().integer().positive().required(),
    numberOfAdults: yup
        .string()
        .maxCapacityOfRoomTypeString(yup.ref<number>('standardCapacity'))
        .minFullAndHalfWidth(1)
        .required(),
    numberOfFemale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfMale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfOtherGenderGuest: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    standardCapacity: yup.number(),
    children: yup.array().of(
        yup
            .string()
            .transform((value) => (isNaN(value) ? null : value))
            .minFullAndHalfWidth(0)
            .maxFullAndHalfWidth(MAX_CHILDREN)
            .nullable()
            .optional(),
    ),
});

export const updateRoomBookingItemListModeSchema = yup.object().shape({
    representativeGuestId: yup.number().integer().positive().nullable(),
    roomId: yup.number().positive().nullable(),
    dateOfStayPeriod: yup.array().of(yup.string().optional()).required(),
    bookingStatus: yup.string().required(),
    planId: yup.number().strict().integer().positive().nullable(),
    numberOfAdults: yup
        .string()
        .minFullAndHalfWidth(1)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .required(),
    standardCapacity: yup.number(),
    numberOfFemale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfMale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfOtherGenderGuest: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    children: yup.array().of(
        yup
            .string()
            .transform((value) => (isNaN(value) ? null : value))
            .minFullAndHalfWidth(0)
            .maxFullAndHalfWidth(MAX_CHILDREN)
            .nullable()
            .optional(),
    ),
});

const temporaryBookingItemSchema = yup.object().shape({
    roomTypeId: yup.number().required(),
    planId: yup.number().required(),
    standardCapacity: yup.number(),
    numberOfAdults: yup
        .string()
        .minFullAndHalfWidth(1)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .required(),
    numberOfFemale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfMale: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    numberOfOtherGenderGuest: yup
        .string()
        .minFullAndHalfWidth(0)
        .maxCapacityOfRoomTypeString(yup.ref('standardCapacity'))
        .optional()
        .nullable(),
    bookingTimes: yup.array().of(yup.string().optional()).required(),
    children: yup.array().of(
        yup
            .string()
            .transform((value) => (isNaN(value) ? null : value))
            .minFullAndHalfWidth(0)
            .maxFullAndHalfWidth(MAX_CHILDREN)
            .nullable()
            .optional(),
    ),
});

export const temporaryBookingItemSchemaResolver = yupResolver(temporaryBookingItemSchema);

export const receiptSchema = lazy((obj) => {
    return object(
        mapValues(obj, () =>
            yup.object({
                type: yup.string().nullable().optional(),
                receiptItemDetailType: yup.string().nullable().optional(),
                unitPrice: yup.string().when('receiptItemDetailType', {
                    is: (receiptItemDetailType: string) =>
                        !!receiptItemDetailType &&
                        receiptItemDetailType !== ReceiptItemDetailType.PAYMENT,
                    then: yup
                        .string()
                        .minPriceFullAndHalfWidth(MIN_PRICE)
                        .maxPriceFullAndHalfWidth(MAX_PRICE)
                        .required(),
                }),
                quantity: yup.string().when('receiptItemDetailType', {
                    is: (receiptItemDetailType: string) =>
                        !!receiptItemDetailType &&
                        receiptItemDetailType !== ReceiptItemDetailType.PAYMENT,
                    then: yup
                        .string()
                        .minFullAndHalfWidth(0)
                        .maxFullAndHalfWidth(MAX_QUANTITY)
                        .required(),
                }),
                amount: yup.string().when('receiptItemDetailType', {
                    is: (receiptItemDetailType: string) => !!receiptItemDetailType,
                    then: yup
                        .string()
                        .minPriceFullAndHalfWidth(MIN_PRICE)
                        .maxPriceFullAndHalfWidth(MAX_PRICE)
                        .required(),
                }),
                saleItemId: yup.number().when('isAddition', {
                    is: true,
                    then: yup.number().when('type', {
                        is: ReceiptItemType.RECEIPT,
                        then: yup.number().required(),
                    }),
                    otherwise: yup.number().when('receiptItemDetailType', {
                        is: ReceiptItemDetailType.SALE_ITEM,
                        then: yup.number().required(),
                    }),
                }),
                paymentMethodId: yup.number().when('type', {
                    is: ReceiptItemType.PAYMENT,
                    then: yup.number().required(),
                }),
                bookingDate: yup.mixed().nullable(),
            }),
        ),
    );
});

export const updateSplitReceiptSchema = lazy((obj) => {
    return object(
        mapValues(obj, () =>
            yup.object({
                saleItemId: yup.number().required(),
                unitPrice: yup
                    .string()
                    .minPriceFullAndHalfWidth(MIN_PRICE)
                    .maxPriceFullAndHalfWidth(MAX_PRICE)
                    .required(),
                quantity: yup.string().required(),
            }),
        ),
    );
});
const checkInRoomBookingSchema = yup.object().shape({
    roomTypeId: yup.number().optional(),
    roomId: yup.number().required(),
    paymentMethodCheckIns: yup.array().of(
        yup.object().shape({
            paymentMethodId: yup.number().required(),
            amount: yup
                .string()
                .minPriceFullAndHalfWidth(MIN_PRICE)
                .maxPriceFullAndHalfWidth(MAX_PRICE)
                .required(),
        }),
    ),
});

export const checkInRoomBookingResolver = yupResolver(checkInRoomBookingSchema);

export const paymentRoomBookingSchema = yup.object().shape({
    bookingId: yup.string().required(),
    paymentAmount: yup
        .string()
        .minPriceFullAndHalfWidth(MIN_PRICE)
        .maxPriceFullAndHalfWidth(MAX_INTEGER)
        .required(),
    paymentMethodId: yup.number().required(),
});

export const importXmlSchema = yup.object().shape({
    AllotmentBookingReport: yup.array().of(
        yup.object().shape({
            TransactionType: yup.array().of(
                yup.object().shape({
                    DataClassification: yup
                        .array()
                        .of(yup.string().optional())
                        .required(),
                    DataID: yup.array().of(yup.string().optional()).required(),
                }),
            ),
            AccommodationInformation: yup.array().of(
                yup.object().shape({
                    AccommodationName: yup.array().of(yup.string().optional()).required(),
                    AccommodationCode: yup.array().of(yup.string().optional()).optional(),
                }),
            ),
            SalesOfficeInformation: yup.array().of(
                yup.object().shape({
                    SalesOfficeCompanyName: yup
                        .array()
                        .of(yup.string().optional())
                        .required(),
                    SalesOfficeCode: yup.array().of(yup.string().optional()).optional(),
                }),
            ),
            BasicInformation: yup.array().of(
                yup.object().shape({
                    TravelAgencyBookingNumber: yup
                        .array()
                        .of(yup.string().optional())
                        .required(),
                    TravelAgencyBookingDate: yup
                        .array()
                        .of(yup.string().optional())
                        .required(),
                    TravelAgencyBookingTime: yup
                        .array()
                        .of(yup.string().optional())
                        .required(),
                    GuestOrGroupNameSingleByte: yup
                        .array()
                        .of(yup.string().optional())
                        .required(),
                    GuestOrGroupNameKanjiName: yup
                        .array()
                        .of(yup.string().optional())
                        .optional(),
                    CheckInTime: yup.array().of(yup.string().optional()).optional(),
                    CheckOutTime: yup.array().of(yup.string().optional()).optional(),
                    Nights: yup.array().of(yup.string().optional()).optional(),
                    PackagePlanCode: yup
                        .array()
                        .of(yup.string().max(MAX_INTEGER).optional())
                        .optional(),
                    OtherServiceInformation: yup
                        .array()
                        .of(yup.string().optional())
                        .optional(),
                    PackagePlanName: yup.array().of(yup.string().optional()).required(),
                }),
            ),
            RoomAndGuestInformation: yup.array().of(
                yup.object().shape({
                    RoomAndGuestList: yup.array().of(
                        yup.object().shape({
                            RoomInformation: yup.array().of(
                                yup.object().shape({
                                    RoomTypeCode: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .required(),
                                    RoomTypeName: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PerRoomPaxCount: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    RoomPaxMaleCount: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    RoomPaxFemaleCount: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    RoomChildA70Count: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    RoomChildB50Count: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    RoomChildC30Count: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    RoomChildDNoneCount: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                }),
                            ),
                            RoomRateInformation: yup.array().of(
                                yup.object().shape({
                                    RoomDate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .required(),
                                    TotalPerRoomRate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PerPaxRate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PerChildA70Rate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PerChildB50Rate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PerChildC30Rate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PerChildDRate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                }),
                            ),
                        }),
                    ),
                }),
            ),
            RisaplsInformation: yup.array().of(
                yup.object().shape({
                    RisaplsCommonInformation: yup.array().of(
                        yup.object().shape({
                            Basic: yup.array().of(
                                yup.object().shape({
                                    SalesOfficeCompanyCode: yup
                                        .array()
                                        .of(yup.string().required())
                                        .required(),
                                    PhoneNumber: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    Email: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PostalCode: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    Address: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    RepresentativeGendar: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                }),
                            ),
                            Option: yup.array().of(
                                yup.object().shape({
                                    OptionDate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    Name: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    OptionCount: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    OptionRate: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    OptionCode: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                }),
                            ),
                            Member: yup.array().of(
                                yup.object().shape({
                                    UserName: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    UserKana: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    UserTel: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    UserMailAddr: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    UserZip: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    UserAddr: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    UserGendar: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                }),
                            ),
                            BasicRate: yup.array().of(
                                yup.object().shape({
                                    PointsDiscountList: yup.array().of(
                                        yup.object().shape({
                                            PointsDiscountName: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            PointsDiscount: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                        }),
                                    ),
                                }),
                            ),
                            OtherInfo: yup.array().of(
                                yup.object().shape({
                                    AdditionalInformation: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                }),
                            ),
                            RoomAndRoomRateInformation: yup.array().of(
                                yup.object().shape({
                                    RoomInformation: yup.array().of(
                                        yup.object().shape({
                                            RoomTypeCode: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .required(),
                                            PlanGroupCode: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .required(),
                                            NetRmTypeGroupCode: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .required(),
                                            RoomTypeName: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            PerRoomPaxCount: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomPaxMaleCount: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomPaxFemaleCount: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomChildA70Count: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomChildB50Count: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomChildC30Count: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomChildDNoneCount: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                        }),
                                    ),
                                    RoomRateInformation: yup.array().of(
                                        yup.object().shape({
                                            RoomDate: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .required(),
                                            TotalPerRoomRate: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            PerPaxRate: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            PerChildA70Rate: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            PerChildB50Rate: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            PerChildC30Rate: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            PerChildDRate: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomRateChildA70Request: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomRateChildB50Request: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomRateChildC30Request: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                            RoomRateChildDNoneRequest: yup
                                                .array()
                                                .of(yup.string().optional())
                                                .optional(),
                                        }),
                                    ),
                                }),
                            ),
                        }),
                    ),
                    AgentNativeInformation: yup.array().of(
                        yup.object().shape({
                            Extend: yup.array().of(
                                yup.object().shape({
                                    PointDiv: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    PointName: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    Points: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    TotalAccommodationDecleasePoints: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    TotalAccommodationConsumptionTax: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                    AmountClaimed: yup
                                        .array()
                                        .of(yup.string().optional())
                                        .optional(),
                                }),
                            ),
                        }),
                    ),
                }),
            ),
            BasicRateInformation: yup.array().of(
                yup.object().shape({
                    Payment: yup.array().of(yup.string().optional()).optional(),
                    TotalAccommodationCharge: yup
                        .array()
                        .of(yup.string().optional())
                        .optional(),
                }),
            ),
        }),
    ),
});

export const updateBillToSchema = yup.object().shape({
    billName: yup.string().max(120).nullable(),
    billProviso: yup.string().max(INPUT_TEXT_MAX_LENGTH).nullable(),
});

export const blockRoomFormSchema = yup.object().shape({
    roomTypeId: yup.number().required(),
    date: yup.array().length(2).required(),
    cause: yup
        .mixed<
            | StopRoomCause.INVENTORY_ADJUSTMENT
            | StopRoomCause.ROOM_ISSUES
            | StopRoomCause.OTHERS
        >()
        .oneOf([
            StopRoomCause.INVENTORY_ADJUSTMENT,
            StopRoomCause.ROOM_ISSUES,
            StopRoomCause.OTHERS,
        ])
        .required(),
    remark: yup.string().max(MEMO_MAX_LENGTH),
});
export const blockRoomFormSchemaResolver = yupResolver(blockRoomFormSchema);

export const bulkPaymentGroupBookingSchema = yup.object().shape({
    paymentDate: yup.string().required(),
    paymentMethodId: yup.number().required(),
});
