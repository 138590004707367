import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useAppSelector } from '~hooks';
import PlanRoomTypePricingsForm from './PlanRoomTypePricingsForm/PlanRoomTypePricingsForm';
import './PlanRoomTypePricingsTab.scss';
import { roomTypeDropDownsSelector } from '~features/plan/reducers/plan.reducer';
import { IRoomTypeDropdown } from '~features/room-type/interfaces';
import { IPlanRoomType, IPlanRoomTypeError } from '~features/plan/interfaces';
import { reopenPlanRoomTypeTll } from '~features/plan/helper';

type Iprops = {
    control: any;
    errors: any;
    indexErrorTab: number | undefined;
    setValue: any;
    roomTypeIds: number[];
    planRoomTypes: IPlanRoomType[];
};

function PlanRoomTypePricingsTab({
    control,
    errors,
    indexErrorTab,
    setValue,
    roomTypeIds,
    planRoomTypes,
}: Iprops) {
    const [roomTypes, setRoomTypes] = useState<IRoomTypeDropdown[]>([]);
    const [activeKeyTab, setActiveKeyTab] = useState<string>('');
    const roomTypeDropdown = useAppSelector(roomTypeDropDownsSelector);
    useEffect(() => {
        const roomTypes: IRoomTypeDropdown[] = [];
        const roomTypeTll = reopenPlanRoomTypeTll(planRoomTypes);
        const roomTypeDropdownOption = [...roomTypeDropdown, ...roomTypeTll];

        roomTypeIds.forEach((roomTypeId) => {
            const findRoomType = roomTypeDropdownOption.find(
                (item) => item.id === roomTypeId,
            );
            if (findRoomType) roomTypes.push(findRoomType);
        });
        if (indexErrorTab) {
            setActiveKeyTab(`${roomTypes?.[indexErrorTab]?.id}`);
        } else {
            setActiveKeyTab(`${roomTypes?.[0]?.id || roomTypeIds?.[0]}`);
        }
        setRoomTypes(roomTypes);
    }, [roomTypeIds, roomTypeDropdown]);

    useEffect(() => {
        const indexRoomTypeErrors: number[] = [];
        errors?.planRoomTypes?.forEach(
            (planRoomType: IPlanRoomTypeError, _index: number) => {
                if (planRoomType?.planGroupCode) {
                    indexRoomTypeErrors.push(_index);
                }
            },
        );
        if (indexRoomTypeErrors.length) {
            setActiveKeyTab(`${roomTypes?.[indexRoomTypeErrors[0]]?.id}`);
        }
        if (indexErrorTab) {
            setActiveKeyTab(`${roomTypes?.[indexErrorTab]?.id}`);
        }
    }, [errors, indexErrorTab]);

    const onChangeTab = (tab: string) => {
        setActiveKeyTab(tab);
    };

    return (
        <Tabs
            activeKey={activeKeyTab}
            onChange={onChangeTab}
            items={roomTypes.map((roomType, index) => {
                return {
                    key: `${roomType.id}`,
                    label: roomType?.name,
                    children: (
                        <PlanRoomTypePricingsForm
                            indexPlanRoomType={index}
                            planRoomType={planRoomTypes[index]}
                            roomType={roomType}
                            control={control}
                            setValue={setValue}
                        />
                    ),
                };
            })}
        ></Tabs>
    );
}

export default PlanRoomTypePricingsTab;
