import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PlanOverviewForm from './PlanOverviewForm/PlanOverviewForm';
import SaleItemsForm from './SaleItemsForm/SaleItemsForm';
import { createPlanStep1Schema } from '~features/plan/schema';
import { IErrorPlan, IPlanCreateBody, IPlanSaleItem } from '~features/plan/interfaces';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import './Step1.scss';
import {
    selectedPlanSelector,
    setFormStep,
    setSelectedPlan,
    taxListDropdownSelector,
} from '~features/plan/reducers/plan.reducer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '~hooks';
import { parseDate, parseTime } from '~plugins/dayjs';
import { cloneDeep } from 'lodash';
import { scrollToClass } from '~features/plan/util';
import { ErrorMessageType } from '~common/constants';
import localStorageAuthService from '~common/authStorage';

const DEFAULT_TAX_NAME = '課税10%';

type Props = {
    error: IErrorPlan;
};

function Step1(props: Props, ref: Ref<unknown>) {
    const dispatch = useDispatch();
    const selectedPlan = useAppSelector(selectedPlanSelector);
    const taxList = useAppSelector(taxListDropdownSelector);

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        setError,
        formState: { isValid },
    } = useForm<IPlanCreateBody>({
        resolver: yupResolver(createPlanStep1Schema),
        shouldFocusError: true,
    });
    const [planSaleItemList, setPlanSaleItemList] = useState<IPlanSaleItem[]>([]);

    useEffect(() => {
        if (props.error.key === 'name') {
            setError('name', {
                type: 'manual',
                message: props.error.message,
            });
        } else if (props.error.key === 'taxId') {
            setError('taxId', {
                type: 'manual',
                message: props.error.message,
            });
        } else if (props.error.key === 'saleItemId' && props.error.order) {
            setError(
                `planSaleItems.${props.error.order - 1}.${props.error.key}`,
                {
                    type: ErrorMessageType.MANUAL,
                    message: props.error.message,
                },
                { shouldFocus: true },
            );
        } else if (props.error.key === 'price' && props.error.order) {
            setError(
                `planSaleItems.${props.error.order - 1}.${props.error.key}`,
                {
                    type: ErrorMessageType.MANUAL,
                    message: props.error.message,
                },
                { shouldFocus: true },
            );
        }
    }, [props.error]);

    useEffect(() => {
        const clonePlanSaleItemList = cloneDeep(selectedPlan?.planSaleItems || []);
        if (clonePlanSaleItemList.length) {
            clonePlanSaleItemList?.map((item) => {
                if (item.isForOnePeople) {
                    item.quantity = null;
                }
            });
        }
        const selectedHotel = localStorageAuthService.getSelectedHotel();
        reset({
            name: selectedPlan?.name,
            memo: selectedPlan?.memo,
            planType: selectedPlan?.planType,
            taxId: selectedPlan?.taxId,
            checkInOutTimes:
                selectedPlan?.checkInTime && selectedPlan?.checkOutTime
                    ? [
                          parseTime(selectedPlan?.checkInTime),
                          parseTime(selectedPlan?.checkOutTime),
                      ]
                    : [
                          parseTime(selectedHotel?.standardCheckInTime || ''),
                          parseTime(selectedHotel?.standardCheckOutTime || ''),
                      ],
            planSaleItems: clonePlanSaleItemList || [],
        });
        setPlanSaleItemList(clonePlanSaleItemList || []);
    }, [selectedPlan]);

    useEffect(() => {
        if (selectedPlan?.taxId) {
            return;
        }
        if (taxList.length) {
            const defaultTax = taxList.find((tax) => tax.label === DEFAULT_TAX_NAME);
            if (defaultTax) {
                setValue('taxId', +defaultTax?.value);
            } else {
                setValue('taxId', +taxList?.[0]?.value);
            }
        }
    }, [taxList, selectedPlan]);

    const onChangeSaleItem = (
        saleItem: ISaleItemDropdown,
        index: number,
        isDisable?: boolean,
    ) => {
        if (isDisable) {
            setValue(`planSaleItems.${index}.quantity`, null);
        } else {
            setValue(`planSaleItems.${index}.quantity`, 1);
        }
        setValue(`planSaleItems.${index}.saleItemId`, saleItem?.id);
        setValue(`planSaleItems.${index}.price`, saleItem?.price || 0);
        const clonePlanSaleItemList = cloneDeep(planSaleItemList);
        clonePlanSaleItemList[index].saleItemId = saleItem?.id;
        clonePlanSaleItemList[index].price = saleItem?.price || 0;
        clonePlanSaleItemList[index].quantity = isDisable ? undefined : 1;
        clonePlanSaleItemList[index].saleItem = saleItem;
        setPlanSaleItemList(cloneDeep(clonePlanSaleItemList));
    };

    const onClickIsForOnePeople = (value: boolean, index: number) => {
        const clonePlanSaleItemList = cloneDeep(planSaleItemList);
        clonePlanSaleItemList[index].isForOnePeople = value;
        if (value) {
            setValue(`planSaleItems.${index}.quantity`, null);
            clonePlanSaleItemList[index].quantity = null;
        }
        setPlanSaleItemList(cloneDeep(clonePlanSaleItemList));
    };

    const onClickIsFirstDayOnly = (value: boolean, index: number) => {
        const clonePlanSaleItemList = cloneDeep(planSaleItemList);
        clonePlanSaleItemList[index].isFirstDayOnly = value;
        setPlanSaleItemList(cloneDeep(clonePlanSaleItemList));
    };

    const onChangeSaleItemPrice = (value: number, index: number) => {
        const clonePlanSaleItemList = cloneDeep(planSaleItemList);
        clonePlanSaleItemList[index].price = value;

        setPlanSaleItemList(cloneDeep(clonePlanSaleItemList));
    };
    const onChangeSaleQuantity = (value: number, index: number) => {
        const clonePlanSaleItemList = cloneDeep(planSaleItemList);
        clonePlanSaleItemList[index].quantity = value;

        setPlanSaleItemList(cloneDeep(clonePlanSaleItemList));
    };

    const onDeleteSaleItem = async (planSaleItem: IPlanSaleItem) => {
        const newSaleItemList =
            planSaleItemList.filter((item) => {
                return item?.feId !== planSaleItem.feId;
            }) || [];
        setValue(`planSaleItems`, newSaleItemList);
        setPlanSaleItemList(newSaleItemList);
    };

    const onAddSaleItem = () => {
        const newSaleItemList = [
            ...planSaleItemList,
            {
                saleItemId: null,
                price: null,
                quantity: null,
                isForOnePeople: false,
                isFirstDayOnly: false,
                feId: +`${Date.now()}${Math.random() * 1000}`,
            },
        ];
        setPlanSaleItemList(newSaleItemList);
        setValue(`planSaleItems`, newSaleItemList);
    };

    useImperativeHandle(ref, () => ({
        // this function is used in parent component
        onSubmitStep1() {
            handleSubmit((data) => {
                const planSaleItems = (data.planSaleItems || []).map((item) => ({
                    ...item,
                    price: Number(item.price),
                    quantity: Number(item.quantity),
                }));
                dispatch(
                    setSelectedPlan({
                        ...selectedPlan,
                        name: data.name,
                        memo: data.memo,
                        planType: data.planType,
                        taxId: data.taxId,
                        checkInTime: parseDate(data.checkInOutTimes?.[0])?.fmHHmm(),
                        checkOutTime: parseDate(data.checkInOutTimes?.[1])?.fmHHmm(),
                        planSaleItems,
                    }),
                );
                dispatch(setFormStep(2));
            })();
            if (!isValid) {
                scrollToClass('plan-info-tab-panel-wrapper');
            }
        },
    }));

    return (
        <div className="create-plan-step1">
            <Form layout="vertical">
                <Row gutter={24}>
                    <Col span={24}>
                        <PlanOverviewForm control={control} />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <SaleItemsForm
                            control={control}
                            planSaleItemList={planSaleItemList}
                            onAddSaleItem={onAddSaleItem}
                            onChangeSaleItem={onChangeSaleItem}
                            onClickIsForOnePeople={onClickIsForOnePeople}
                            onClickIsFirstDayOnly={onClickIsFirstDayOnly}
                            onChangeSaleItemPrice={onChangeSaleItemPrice}
                            onChangeSaleQuantity={onChangeSaleQuantity}
                            onDeleteSaleItem={onDeleteSaleItem}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default forwardRef(Step1);
