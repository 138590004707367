import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Calendar } from '~components';
import './RankCalendarDetail.scss';

import { Dayjs, parseDate, parseTime, todayDayjs } from '~plugins/dayjs';
import { useAppSelector } from '~hooks';
import { selectedRankCalendarDetailSelector } from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { useEffect, useState } from 'react';
import CalendarHeader from '~features/rank-calendar/components/CalendarHeader/CalendarHeader';
import { DECEMBER, JANUARY, TllRank } from '~common/constants';
import { CalendarDay } from '~features/rank-calendar/constants';
import { getPriorityRank } from '~features/rank-calendar/helper';

function RankCalendarDetail() {
    const { t } = useTranslation();
    const { Title } = Typography;
    const [rankCalendarYear, setRankCalendarYear] = useState(todayDayjs.year());
    const [rankCalendarMonth, setRankCalendarMonth] = useState(todayDayjs.month() + 1);
    const [calendarDate, setCalendarDate] = useState(
        parseDate(
            `${rankCalendarYear}-${rankCalendarMonth}-${todayDayjs
                .startOf('month')
                .date()}`,
        ),
    );
    const rankCalendarDetail = useAppSelector(selectedRankCalendarDetailSelector);

    useEffect(() => {
        setCalendarDate(
            parseDate(
                `${rankCalendarYear}-${rankCalendarMonth}-${todayDayjs
                    .startOf('month')
                    .date()}`,
            ),
        );
    }, [rankCalendarMonth, rankCalendarYear, rankCalendarDetail?.id]);

    const onClickButtonPrevious = () => {
        if (rankCalendarMonth === JANUARY) {
            setRankCalendarMonth(DECEMBER);
            setRankCalendarYear(rankCalendarYear - 1);
            return;
        }
        setRankCalendarMonth(rankCalendarMonth - 1);
    };

    const onClickButtonNext = () => {
        if (rankCalendarMonth === DECEMBER) {
            setRankCalendarMonth(JANUARY);
            setRankCalendarYear(rankCalendarYear + 1);
            return;
        }
        setRankCalendarMonth(rankCalendarMonth + 1);
    };

    const headerRender = () => {
        return (
            <>
                <CalendarHeader
                    rankCalendarMonth={rankCalendarMonth}
                    rankCalendarYear={rankCalendarYear}
                    onClickButtonPrevious={onClickButtonPrevious}
                    onClickButtonNext={onClickButtonNext}
                />
                <div className="rank-calendar-day-list">
                    {Object.values(CalendarDay).map((day) => {
                        return (
                            <p className="rank-calendar-day" key={day}>
                                {t(`rankCalendar.detail.calendarDay.${day}`)}
                            </p>
                        );
                    })}
                </div>
            </>
        );
    };

    const dateCellRender = (date: Dayjs) => {
        if (date.month() + 1 !== rankCalendarMonth) {
            return;
        }
        let rank = '' as TllRank;
        switch (date.day()) {
            case 1:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    null,
                    CalendarDay.MONDAY,
                );
                break;
            case 2:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    null,
                    CalendarDay.TUESDAY,
                );
                break;
            case 3:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    null,
                    CalendarDay.WEDNESDAY,
                );
                break;
            case 4:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    null,
                    CalendarDay.THURSDAY,
                );
                break;
            case 5:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    null,
                    CalendarDay.FRIDAY,
                );
                break;
            case 6:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    null,
                    CalendarDay.SATURDAY,
                );
                break;
            case 0:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    null,
                    CalendarDay.SUNDAY,
                );
                break;
            default:
                break;
        }
        return <div className="date-cell-item">{rank}</div>;
    };

    const onSelectDate = (date: Dayjs) => {
        if (date.month() + 1 !== rankCalendarMonth) {
            setRankCalendarMonth(date.month() + 1);
        }
        if (date.year() !== rankCalendarYear) {
            setRankCalendarYear(date.year());
        }
    };

    return (
        <div className="rank-calendar-detail-wrapper">
            <Title level={5} className="rank-calendar-detail-title">
                {t('rankCalendar.detail.detaiViewTitle')}
            </Title>
            <Calendar
                mode="month"
                headerRender={headerRender}
                dateCellRender={dateCellRender}
                value={calendarDate}
                onSelect={onSelectDate}
                className="rank-calendar-detail-content"
            />
        </div>
    );
}

export default RankCalendarDetail;
