import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '~common/commonFunctions';
import { DateFormat, SupportLanguage, dayList } from '~common/constants';
import { ReportTableId } from '~features/report/constants';
import { parseDate } from '~plugins/dayjs';
import { CacheKeys } from '~queries/queries';
import { IOperationStatusResponse } from '../../interface';
import './OperationStatusTable.scss';

function OperationStatusTable() {
    const { t, i18n } = useTranslation();

    const { data, isLoading, isRefetching } = useQuery({
        queryKey: [CacheKeys.getOperationStatusReportKey],
        enabled: false,
    });

    const formatPercent = (percent: number) => {
        const isInteger = Number.isInteger(percent * 100);
        return isInteger ? percent * 100 : Number((percent * 100).toFixed(2));
    };

    const isJaLanguage = useMemo(() => {
        return i18n.language === SupportLanguage.JA;
    }, [i18n.language]);

    const dataSource = useMemo(() => {
        if (!data) return [];
        const res = (data as IOperationStatusResponse[]).map((item: any) => {
            return {
                ...item,
                key: item.date,
            };
        });
        return res;
    }, [data]);

    const operationStatusColumns: ColumnsType<IOperationStatusResponse[]> = [
        {
            title: t('report.operationStatus.table.date'),
            render: (item: IOperationStatusResponse) => {
                const dayOfWeek = parseDate(item.date).day();
                return (
                    <div
                        className={classNames('', {
                            saturday: dayOfWeek === 6,
                            sunday: dayOfWeek === 0,
                        })}
                    >
                        <span>
                            {parseDate(item.date).format(DateFormat.MM_DD_YYYY_SLASH)}
                        </span>
                    </div>
                );
            },
        },
        {
            title: t('report.operationStatus.table.totalRooms'),
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>{Number(item.current.totalAvailableRooms) || 0}</span>
                        <span className="previous-operation">
                            ({Number(item.previousYear.totalAvailableRooms) || 0})
                        </span>
                    </div>
                );
            },
        },
        {
            title: t('report.operationStatus.table.totalBookings'),
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>{Number(item.current?.totalBookings) || 0}</span>
                        <span className="previous-operation">
                            ({Number(item.previousYear?.totalBookings) || 0})
                        </span>
                    </div>
                );
            },
        },
        {
            title: t('report.operationStatus.table.totalAdult'),
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>{Number(item.current?.totalAdults) || 0}</span>
                        <span className="previous-operation">
                            ({Number(item.previousYear?.totalAdults) || 0})
                        </span>
                    </div>
                );
            },
        },
        {
            title: t('report.operationStatus.table.totalChildren'),
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>{Number(item.current?.totalChildren) || 0}</span>
                        <span className="previous-operation">
                            ({Number(item.previousYear?.totalChildren) || 0})
                        </span>
                    </div>
                );
            },
        },
        {
            title: t('report.operationStatus.table.sales'),
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>
                            {t('common.yen', {
                                number: formatMoney(
                                    Number(item.current?.totalRevenue) || 0,
                                ),
                            })}
                        </span>
                        <span className="previous-operation">
                            (
                            {t('common.yen', {
                                number: formatMoney(
                                    Number(item.previousYear?.totalRevenue) || 0,
                                ),
                            })}
                            )
                        </span>
                    </div>
                );
            },
        },
        {
            title: () => {
                return (
                    <span className={classNames({ 'thin-text': isJaLanguage })}>
                        {t('report.operationStatus.table.adr')}
                    </span>
                );
            },
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>
                            {t('common.yen', {
                                number: formatMoney(
                                    Number(item.current?.averageDailyRate) || 0,
                                ),
                            })}
                        </span>
                        <span className="previous-operation">
                            (
                            {t('common.yen', {
                                number: formatMoney(
                                    Number(item.previousYear?.averageDailyRate) || 0,
                                ),
                            })}
                            )
                        </span>
                    </div>
                );
            },
        },
        {
            title: () => {
                return (
                    <span className={classNames({ 'thin-text': isJaLanguage })}>
                        {t('report.operationStatus.table.revPAR')}
                    </span>
                );
            },
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>
                            {t('common.yen', {
                                number: formatMoney(
                                    Number(item.current.revenuePerAvailableRoom) || 0,
                                ),
                            })}
                        </span>
                        <span className="previous-operation">
                            (
                            {t('common.yen', {
                                number: formatMoney(
                                    Number(item.previousYear.revenuePerAvailableRoom) ||
                                        0,
                                ),
                            })}
                            )
                        </span>
                    </div>
                );
            },
        },
        {
            title: t('report.operationStatus.table.numberOfRoomsRate'),
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>
                            {formatPercent(Number(item.current.occupancyRate || 0))}%
                        </span>
                        <span className="previous-operation">
                            ({formatPercent(Number(item.previousYear.occupancyRate || 0))}
                            %)
                        </span>
                    </div>
                );
            },
        },
        {
            title: t('report.operationStatus.table.adultCapacityRate'),
            render: (item: IOperationStatusResponse) => {
                return (
                    <div>
                        <span>
                            {formatPercent(Number(item.current.averageRoomCapacity || 0))}
                            %
                        </span>
                        <span className="previous-operation">
                            (
                            {formatPercent(
                                Number(item.previousYear.averageRoomCapacity || 0),
                            )}
                            %)
                        </span>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="operation-status-table-wrapper">
            <Table
                columns={operationStatusColumns}
                dataSource={dataSource}
                loading={isLoading || isRefetching}
                rowKey="date"
                size="middle"
                pagination={false}
                id={ReportTableId.OPERATION_STATUS}
            />
        </div>
    );
}

export default OperationStatusTable;
