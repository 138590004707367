import React, { useMemo } from 'react';
import { Form } from 'antd';
import { Controller } from '~plugins/hook-form';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import './TimeRangePicker.scss';
import { parseErrorMessage } from '~common/commonFunctions';
import { TimeRangePickerProps } from '../TimePicker/TimePicker';
import { DatePicker } from '../DatePicker/DatePicker';
import { SupportLanguage } from '~common/constants';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import { useAppSelector } from '~hooks';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';

type ITimeRangePicker = TimeRangePickerProps & {
    name: string;
    labelStart?: string;
    labelEnd?: string;
    control: any;
    required: boolean;
    tooltip?: LabelTooltipType;
    order?: boolean;
};

export function TimeRangePicker(props: ITimeRangePicker) {
    const {
        control,
        name,
        defaultValue,
        labelStart,
        labelEnd,
        onChange,
        required,
        tooltip,
        order,
        id,
        ...rest
    } = props;
    const currentLang = useAppSelector(currentLanguageSelector);
    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);
    return (
        <div className="time-range-picker-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    return (
                        <Form.Item
                            label={
                                labelStart || labelEnd ? (
                                    <div className="time-picker-label">
                                        {!!labelStart && (
                                            <div className="time-picker-label-start">
                                                {labelStart}
                                            </div>
                                        )}
                                        {!!labelEnd && (
                                            <div className="time-picker-label-end">
                                                {labelEnd}
                                            </div>
                                        )}
                                    </div>
                                ) : null
                            }
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            tooltip={tooltip}
                            required={required}
                        >
                            <div id={id}>
                                <DatePicker.RangePicker
                                    {...(rest as any)}
                                    {...field}
                                    picker="time"
                                    order={order}
                                    onChange={(...agrs) => {
                                        if (onChange) {
                                            onChange(...agrs);
                                        }
                                        field.onChange(...agrs);
                                    }}
                                    locale={DatePickerLanguage}
                                />
                            </div>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

TimeRangePicker.defaultProps = {
    defaultValue: '',
    required: false,
    order: true,
};
