import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SearchField } from '~features/sale-item/constants';
import { setSearchField } from '~features/sale-item/reducers/sale-item.reducer';

const { Option } = Select;

function SelectField() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleChange = (columnName: SearchField) => {
        dispatch(setSearchField(columnName));
    };

    return (
        <Select
            defaultValue={SearchField.ALL}
            onChange={handleChange}
            dropdownMatchSelectWidth={false}
        >
            {Object.values(SearchField).map((columnName, index) => {
                return (
                    <Option value={columnName} key={index}>
                        {t(`saleItem.list.searchColumn.${columnName}`)}
                    </Option>
                );
            })}
        </Select>
    );
}

export default SelectField;
