import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, notification, Row, Switch } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import localStorageAuthService from '~common/authStorage';
import {
    DateFormat,
    EmitterGlobalEvent,
    ErrorMessageType,
    HttpStatus,
} from '~common/constants';
import { showConfirm } from '~common/notification';
import {
    InputNumber,
    InputPassword,
    InputPhoneNumber,
    InputPostalCode,
    InputText,
    RightDrawerLayout,
    TimeRangePicker,
} from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate, parseTime } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { useMitt } from '~plugins/mitt';
import { DefaultCheckInOutTime, HotelPermission, HotelStatus } from '../../constants';
import {
    createHotel,
    fetchHotelList,
    formBusySelector,
    getHotelDetail,
    selectedHotelSelector,
    setSelectedHotel,
    setShowForm,
    showFormSelector,
    updateHotel,
} from '../../hotel.reducer';
import { IHotelCreateBody, IHotelUpdateRequest } from '../../interfaces';
import { hotelFormSchema } from '../../schema';
import './HotelForm.scss';

function HotelForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { control, handleSubmit, reset, getValues, setValue, setError } = useForm({
        resolver: yupResolver(hotelFormSchema),
    });
    const selectedHotel = useAppSelector(selectedHotelSelector);
    const formBusy = useAppSelector(formBusySelector);
    const showForm = useAppSelector(showFormSelector);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isPmsHotel, setIsPmsHotel] = useState<boolean>(false);
    const makeFormData = (): IHotelCreateBody => {
        const {
            name,
            address,
            picName,
            picEmailAddress,
            picPhoneNumber,
            numberOfRoom,
            postalCode,
            standardTimes,
            peCsrHotelCode,
            tllPmsUserId,
            tllPmsPassword,
            tllSystemId,
        } = getValues();
        return {
            name,
            peCsrHotelCode,
            tllPmsUserId,
            tllPmsPassword,
            tllSystemId,
            address: address ? address : null,
            picName: picName ? picName : null,
            picEmailAddress: picEmailAddress ? picEmailAddress : null,
            picPhoneNumber: picPhoneNumber ? picPhoneNumber : null,
            postalCode: postalCode ? postalCode : null,
            numberOfRoom: Number(numberOfRoom) ? Number(numberOfRoom) : null,
            standardCheckInTime: standardTimes?.length
                ? parseDate(standardTimes[0])?.fmHHmm()
                : null,
            standardCheckOutTime: standardTimes?.length
                ? parseDate(standardTimes[1])?.fmHHmm()
                : null,
        };
    };
    const { emitter } = useMitt();

    useEffect(() => {
        if (!selectedHotel) {
            setValue(`standardTimes`, [
                parseTime(DefaultCheckInOutTime.CHECK_IN_TIME),
                parseTime(DefaultCheckInOutTime.CHECK_OUT_TIME),
            ]);
        }
    }, [showForm]);

    const _createHotel = useCallback(async (formData: IHotelCreateBody) => {
        const response = await dispatch(createHotel(formData));
        if (createHotel.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('hotel.form.create.success'),
                });
                emitter.emit(EmitterGlobalEvent.LOCAL_STORAGE_CHANGE);
                closeDialog(true);
                return;
            }
            notification.error({
                message: t('hotel.form.create.error'),
                description: response.payload.errors?.[0]?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                setError(
                    error.key,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
    }, []);

    const initFormData = (data: IHotelCreateBody) => {
        setIsActive(data?.status === HotelStatus.ACTIVE);
        setIsPmsHotel(data?.permission === HotelPermission.PMS);

        const formData = {
            peCsrHotelCode: data?.peCsrHotelCode,
            tllSystemId: data?.tllSystemId,
            tllPmsUserId: data?.tllPmsUserId,
            tllPmsPassword: data?.tllPmsPassword,
            name: data?.name,
            address: data?.address,
            picName: data?.picName,
            status: data?.status,
            permission: data?.permission,
            numberOfRoom: data?.numberOfRoom,
            picEmailAddress: data?.picEmailAddress,
            picPhoneNumber: data?.picPhoneNumber,
            postalCode: data?.postalCode,
            standardTimes:
                data?.standardCheckInTime && data?.standardCheckOutTime
                    ? [
                          parseTime(data?.standardCheckInTime),
                          parseTime(data?.standardCheckOutTime),
                      ]
                    : [],
        };
        reset(formData);
    };

    useEffect(() => {
        if (showForm) {
            if (selectedHotel?.id) {
                _getHotelDetail();
            }
        } else {
            // reset form
            reset({
                name: '',
                peCsrHotelCode: '',
            });
        }
    }, [showForm, dispatch, selectedHotel]);

    const _getHotelDetail = useCallback(async () => {
        if (!selectedHotel?.id) return;
        const response = await dispatch(getHotelDetail(selectedHotel.id));
        if (getHotelDetail.fulfilled.match(response)) {
            const { success, data, message } = response.payload;
            if (success) {
                initFormData({
                    peCsrHotelCode: data?.peCsrHotelCode,
                    tllSystemId: data?.tllSystemId,
                    tllPmsUserId: data?.tllPmsUserId,
                    tllPmsPassword: data?.tllPmsPassword,
                    name: data?.name,
                    address: data?.address,
                    picName: data?.picName,
                    status: data?.status,
                    permission: data?.permission,
                    numberOfRoom: data?.numberOfRoom,
                    picEmailAddress: data?.picEmailAddress,
                    picPhoneNumber: data?.picPhoneNumber,
                    postalCode: data?.postalCode,
                    standardCheckInTime: data?.standardCheckInTime,
                    standardCheckOutTime: data?.standardCheckOutTime,
                });
            } else {
                notification.error({
                    message: message || '',
                });
            }
        }
    }, [selectedHotel, updateHotel, reset]);

    const _updateHotel = useCallback(async (formData: IHotelUpdateRequest) => {
        const response = await dispatch(updateHotel(formData));
        if (updateHotel.fulfilled.match(response)) {
            if (response?.payload?.success) {
                notification.success({
                    message: t('hotel.form.update.success'),
                });
                localStorageAuthService.setHotel(response?.payload?.data);
                emitter.emit(EmitterGlobalEvent.LOCAL_STORAGE_CHANGE);
                closeDialog(true);
                return;
            }
            if (response?.payload?.status === HttpStatus.ITEM_NOT_FOUND) {
                closeDialog(true);
            }
            notification.error({
                message: t('hotel.form.update.error'),
                description: response?.payload?.errors?.[0].message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                setError(
                    error.key,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
    }, []);

    const closeDialog = (reload?: boolean) => {
        setIsActive(true);
        setIsPmsHotel(false);
        dispatch(setShowForm(false));
        dispatch(setSelectedHotel(null));
        reset();
        if (reload) {
            dispatch(fetchHotelList());
        }
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('hotel.form.confirm.cancelTitle'),
            cancelText: t('hotel.form.confirm.buttonCancelText'),
            okText: t('hotel.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };

    const onSubmit = () => {
        handleSubmit((value) => {
            const _formData = makeFormData();
            _formData.permission = isPmsHotel
                ? HotelPermission.PMS
                : HotelPermission.HOTEL;
            if (selectedHotel) {
                _formData.status = isActive ? HotelStatus.ACTIVE : HotelStatus.INACTIVE;
                _updateHotel({
                    id: selectedHotel.id,
                    body: _formData,
                });
            } else {
                _createHotel(_formData);
            }
        })();
    };
    const changeStatus = () => {
        setIsActive(!isActive);
    };

    const changePermission = (value: boolean) => {
        setIsPmsHotel(!isPmsHotel);
    };

    return (
        <RightDrawerLayout
            open={showForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={
                selectedHotel?.id
                    ? t('hotel.form.update.title')
                    : t('hotel.form.create.title')
            }
            submitText={t('hotel.form.submitButton')}
            cancelText={t('hotel.form.cancelButton')}
            className="create-hotel-drawer"
            busy={formBusy}
            loading={formBusy}
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                <Row className="input-row" gutter={24}>
                    <Col span={12}>
                        <InputText
                            label={t('hotel.form.peCsrHotelCode')}
                            placeholder={t('hotel.form.peCsrHotelCodeInput')}
                            name="peCsrHotelCode"
                            control={control}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <InputText
                            label={t('hotel.form.hotelName')}
                            placeholder={t('hotel.form.hotelNameInput')}
                            name="name"
                            control={control}
                            required
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={24}>
                    <Col span={12}>
                        <InputText
                            label={t('hotel.form.tllPmsUserId')}
                            placeholder={t('hotel.form.tllPmsUserIdInput')}
                            name="tllPmsUserId"
                            control={control}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <InputPassword
                            label={t('hotel.form.tllPmsPassword')}
                            placeholder={t('hotel.form.tllPmsPasswordInput')}
                            name="tllPmsPassword"
                            control={control}
                            required
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={24}>
                    <Col span={12}>
                        <InputText
                            label={t('hotel.form.picName')}
                            placeholder={t('hotel.form.picNameInput')}
                            name="picName"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={24}>
                    <Col span={12}>
                        <InputPostalCode
                            label={t('hotel.form.postalCode')}
                            placeholder={t('hotel.form.postalCodeInput')}
                            name="postalCode"
                            control={control}
                        />
                    </Col>
                    <Col span={12}>
                        <InputText
                            label={t('hotel.form.address')}
                            placeholder={t('hotel.form.addressInput')}
                            name="address"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={24}>
                    <Col span={12}>
                        <InputPhoneNumber
                            label={t('hotel.form.picPhoneNumber')}
                            placeholder={t('hotel.form.picPhoneNumberInput')}
                            name="picPhoneNumber"
                            control={control}
                        />
                    </Col>
                    <Col span={12}>
                        <InputText
                            label={t('hotel.form.picEmailAddress')}
                            placeholder={t('hotel.form.picEmailAddressInput')}
                            name="picEmailAddress"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={24}>
                    <Col span={12}>
                        <InputNumber
                            label={t('hotel.form.numberOfRoom')}
                            placeholder={t('hotel.form.numberOfRoomInput')}
                            name="numberOfRoom"
                            control={control}
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={24}>
                    <Col span={12} className="standard-times-column">
                        <TimeRangePicker
                            labelStart={t('hotel.form.standardCheckInTime')}
                            labelEnd={t('hotel.form.standardCheckOutTime')}
                            placeholder={[
                                t('hotel.form.standardCheckInTimeInput'),
                                t('hotel.form.standardCheckOutTimeInput'),
                            ]}
                            name={`standardTimes`}
                            allowClear={false}
                            order={false}
                            control={control}
                            format={DateFormat.HH_MM_COLON}
                        />
                    </Col>
                </Row>
                <Row className="input-row" gutter={16}>
                    <Col span={12}>
                        <InputText
                            label={t('hotel.form.tllSystemId')}
                            placeholder={t('hotel.form.tllSystemIdInput')}
                            name="tllSystemId"
                            control={control}
                            required
                        />
                    </Col>
                </Row>
                <Row className="input-row">
                    {selectedHotel?.id && (
                        <Col span={3}>
                            <Form.Item
                                label={
                                    isActive
                                        ? t('hotel.list.hotelListColumn.tagStatus.active')
                                        : t(
                                              'hotel.list.hotelListColumn.tagStatus.inactive',
                                          )
                                }
                            >
                                <Switch checked={isActive} onChange={changeStatus} />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item
                            label={t('hotel.list.hotelListColumn.tagPermission.pms')}
                        >
                            <Switch checked={isPmsHotel} onChange={changePermission} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default HotelForm;
