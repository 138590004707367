import { Card, Table } from 'antd';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { forEach } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    AutoCompleteGuest,
    InputPhoneNumber,
    InputText,
    SingleDatePicker,
    SingleSelect,
} from '~components';
import { Gender } from '~features/guest/constants';
import { IGuestDropDown } from '~features/guest/interfaces';
import { guestListForDropDownSelector } from '~features/guest/reducers/guest.reducer';
import { getCreateBookingFormPageId } from '~features/room-booking/helper';
import { IMemberAttachment } from '~features/room-booking/interfaces';
import { useAppSelector } from '~hooks';
import './MembersCard.scss';

const { Column } = Table;

type Props = {
    control: any;
    setValue: any;
    isFrozen?: boolean;
};

export const MembersCard = ({ control, setValue, isFrozen }: Props) => {
    const { t } = useTranslation();
    const guestDropdownOptions = useAppSelector(guestListForDropDownSelector);
    const [canEditGuestMembers, setCanEditGuestMembers] = useState<
        Record<number, boolean>
    >([]);

    const changeGuestYomigana = (
        value: string | IGuestDropDown,
        index: number,
        guestId?: number | null,
    ) => {
        if (value instanceof Object) {
            setValue(`members.${index}.id`, value.id);
            setValue(`members.${index}.yomigana`, value.yomigana);
            setValue(`members.${index}.fullName`, value.fullName);
            setValue(
                `members.${index}.phone`,
                value.mobilePhoneNumber || value.phoneNumberLandline,
                {
                    shouldValidate: true,
                },
            );
            setValue(`members.${index}.gender`, value.gender);
            setValue(`members.${index}.emailAddress`, value.emailAddress, {
                shouldValidate: true,
            });
            setValue(
                `members.${index}.birthday`,
                value.birthday && parseDate(value.birthday).isValid()
                    ? parseDate(value.birthday)
                    : null,
            );
            return;
        }
        if (guestId) {
            setValue(`members.${index}.yomigana`, value);
            setValue(`members.${index}.id`, null);
            setValue(`members.${index}.fullName`, null);
            setValue(`members.${index}.phone`, null);
            setValue(`members.${index}.gender`, null);
            setValue(`members.${index}.emailAddress`, null, { shouldValidate: true });
            setValue(`members.${index}.birthday`, null);
        } else {
            setValue(`members.${index}.yomigana`, value);
            setValue(`members.${index}.id`, null);
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const representativeGuestId = useWatch({
        control,
        name: 'representativeGuest.id',
    });
    const members = useWatch({
        control,
        name: 'members',
    });

    useEffect(() => {
        const canEditGuestMembers: Record<number, boolean> = [];
        forEach(members, (member, index: number) => {
            canEditGuestMembers[index] = !member.id;
        });
        setCanEditGuestMembers(canEditGuestMembers);
    }, [members]);

    const selectedGuestIds: number[] = useMemo(() => {
        const ids = [];
        if (representativeGuestId) {
            ids.push(representativeGuestId);
        }
        if (members?.length) {
            ids.push(
                ...members
                    .filter((member: IMemberAttachment) => member.id)
                    .map((member: IMemberAttachment) => member.id),
            );
        }
        return ids;
    }, [representativeGuestId, members]);

    return (
        <Card className="members-card">
            <div className="members-card-title">
                <span>{t('roomBooking.createBooking.membersTitle')}</span>
            </div>
            <div className="members-card-content">
                <Table
                    pagination={false}
                    rowKey={(item) => `${item.index}`}
                    dataSource={members}
                    scroll={{ x: 1500 }}
                    className="table-scrollbar"
                >
                    <Column
                        align="left"
                        width={50}
                        title={`#`}
                        dataIndex=""
                        key={'id'}
                        className="column-id"
                        fixed="left"
                        render={(item) => {
                            return <div key={item.index}>{item.index}</div>;
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.createBooking.label.yomigana')}`}
                        dataIndex=""
                        className="members-card-column"
                        key={'yomigana'}
                        render={(_, item, index) => {
                            return (
                                <AutoCompleteGuest
                                    label={''}
                                    placeholder={t(
                                        'facilityBooking.createForm.form.guestYomigana.placeholder',
                                    )}
                                    overlayClassName="guest-info-tab-panel-wrapper__select-guest"
                                    name={`members.${index}.yomigana`}
                                    id={getCreateBookingFormPageId(
                                        `members.${index}.yomigana`,
                                    )}
                                    required
                                    allowClear
                                    showSearch={true}
                                    guestOptions={guestDropdownOptions}
                                    defaultValue={
                                        members[index]?.id || members[index]?.yomigana
                                    }
                                    onChange={(value) =>
                                        changeGuestYomigana(
                                            value,
                                            index,
                                            members[index]?.id,
                                        )
                                    }
                                    dropdownMatchSelectWidth={500}
                                    control={control}
                                    disableValues={selectedGuestIds}
                                    disabled={isFrozen}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.createBooking.label.name')}`}
                        dataIndex=""
                        key="name"
                        className="members-card-column"
                        render={(_, item, index) => {
                            return (
                                <InputText
                                    label=""
                                    key={index}
                                    allowClear
                                    placeholder={t('roomBooking.form.placeholder.name')}
                                    name={`members.${index}.fullName`}
                                    id={getCreateBookingFormPageId(
                                        `members.${index}.fullName`,
                                    )}
                                    control={control}
                                    disabled={!canEditGuestMembers[index] || isFrozen}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.createBooking.label.gender')}`}
                        dataIndex=""
                        key={'gender'}
                        className="members-card-column"
                        render={(_, item, index) => {
                            return (
                                <SingleSelect
                                    label=""
                                    key={index}
                                    allowClear
                                    placeholder={t('roomBooking.form.placeholder.gender')}
                                    name={`members.${index}.gender`}
                                    id={getCreateBookingFormPageId(
                                        `members.${index}.gender`,
                                    )}
                                    control={control}
                                    options={Object.values(Gender).map((val) => ({
                                        label: t(`common.gender.` + val),
                                        value: val,
                                    }))}
                                    disabled={!canEditGuestMembers[index] || isFrozen}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.createBooking.label.birthday')}`}
                        dataIndex=""
                        key={'birthday'}
                        className="members-card-column"
                        render={(_, item, index) => {
                            return (
                                <SingleDatePicker
                                    label=""
                                    placeholder={t(
                                        'roomBooking.form.placeholder.birthday',
                                    )}
                                    name={`members.${index}.birthday`}
                                    id={getCreateBookingFormPageId(
                                        `members.${index}.birthday`,
                                    )}
                                    control={control}
                                    allowClear
                                    disabledDate={(current) => {
                                        return (
                                            current &&
                                            current > customDayjs().subtract(1, 'day')
                                        );
                                    }}
                                    disabled={!canEditGuestMembers[index] || isFrozen}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.createBooking.label.phone')}`}
                        dataIndex=""
                        key="phone"
                        className="members-card-column"
                        render={(_, item, index) => {
                            return (
                                <InputPhoneNumber
                                    label=""
                                    key={index}
                                    allowClear
                                    placeholder={t('roomBooking.form.placeholder.phone')}
                                    name={`members.${index}.phone`}
                                    id={getCreateBookingFormPageId(
                                        `members.${index}.phone`,
                                    )}
                                    control={control}
                                    disabled={!canEditGuestMembers[index] || isFrozen}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.createBooking.label.emailAddress')}`}
                        dataIndex=""
                        key="emailAddress"
                        className="members-card-column"
                        render={(_, item, index) => {
                            return (
                                <InputText
                                    label=""
                                    key={index}
                                    allowClear
                                    placeholder={t('roomBooking.form.placeholder.email')}
                                    name={`members.${index}.emailAddress`}
                                    id={getCreateBookingFormPageId(
                                        `members.${index}.emailAddress`,
                                    )}
                                    control={control}
                                    disabled={!canEditGuestMembers[index] || isFrozen}
                                />
                            );
                        }}
                    />
                </Table>
            </div>
        </Card>
    );
};
