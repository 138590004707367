const hotelForm = {
    peCsrHotelCode: 'Hotel ID',
    peCsrHotelCodeInput: 'Example : 0001',
    hotelName: 'Hotel Name',
    hotelNameInput: 'Example : Tabist Hotel',
    picName: 'Name of person in-charge',
    picNameInput: 'Ex: Yamada Tarou',
    postalCode: 'Postal Code',
    postalCodeInput: 'Ex: 1057501',
    address: 'Address',
    addressInput:
        'Ex: Tokyo, Minato-ku, kaigan 1-7-1, Tokyo Port City Takeshiba, WeWork11F',
    picPhoneNumber: 'Phone number',
    picPhoneNumberInput: 'Ex: 0701234567',
    picEmailAddress: 'Email address',
    picEmailAddressInput: 'Ex: yamadatarou@gmail.com',
    tllSystemId: 'Site Controller System ID',
    tllSystemIdInput: 'Ex: 0701234533',
    tllPmsUserId: 'Site Controller Interlink ID',
    tllPmsUserIdInput: 'Ex: 0001',
    tllPmsPassword: 'Site Controller Interlink Password',
    tllPmsPasswordInput: 'Please enter password',
    numberOfRoom: 'Number of room',
    numberOfRoomInput: 'Ex: 1000',
    standardCheckInTime: 'Default check-in time',
    standardCheckInTimeInput: 'Select time',
    standardCheckOutTime: 'Default check-out time',
    standardCheckOutTimeInput: 'Select time',
    active: 'Status',
    create: {
        title: 'Create new hotel',
        success: 'Hotel have been created succcessful!',
        error: 'Hotel have been created unsucccessful!',
    },
    update: {
        title: 'Hotel Details',
        active: 'Active',
        success: 'Hotel have been updated succcessful!',
        error: 'Hotel have been updated  unsucccessful',
    },
    confirm: {
        cancelTitle: 'Do you want to cancel?',
        buttonCancelText: 'Cancel',
        buttonDeleteText: 'OK',
    },
    nameDuplicated: 'Name is duplicated',
    submitButton: 'Submit',
    cancelButton: 'Cancel',
};
export default hotelForm;
