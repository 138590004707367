import React from 'react';
import { Popover, Form } from 'antd';
import './TimePickerCustom.scss';
import { ClockCircleOutlined, SwapRightOutlined } from '@ant-design/icons';
import { Controller } from '~plugins/hook-form';
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel';
import { parseErrorMessage } from '~common/commonFunctions';
import { PickerTimePanel } from './PickerTimePanel';
import classNames from 'classnames';
import { Dayjs, parseDate, parseTime } from '~plugins/dayjs';

type ITimeRangePicker = {
    name: string;
    defaultValue?: (string | null | Dayjs)[];
    labelStart?: string;
    labelEnd?: string;
    control: any;
    required?: boolean;
    useDayjs?: boolean;
    tooltip?: LabelTooltipType;
    placeholder?: string[];
    onChange?: (value: Dayjs[]) => void;
};

export const TimeRangePickerCustom = (props: ITimeRangePicker) => {
    const {
        control,
        name,
        defaultValue,
        labelStart,
        labelEnd,
        onChange,
        required,
        tooltip,
        placeholder,
        useDayjs,
    } = props;

    const [isFocus, setIsFocus] = React.useState(false);

    return (
        <div className="time-picker-custom-wrapper">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message ? 'error' : '';
                    const [start, end] = field.value || ['', ''];
                    const inputStart = useDayjs
                        ? start
                            ? parseDate(start).format('HH:mm')
                            : ''
                        : start || '';
                    const inputEnd = useDayjs
                        ? end
                            ? parseDate(end).format('HH:mm')
                            : ''
                        : end || '';
                    return (
                        <Form.Item
                            label={
                                labelStart || labelEnd ? (
                                    <div className="time-picker-label">
                                        {!!labelStart && (
                                            <div className="time-picker-label-start">
                                                {labelStart}
                                            </div>
                                        )}
                                        {!!labelEnd && (
                                            <div className="time-picker-label-end">
                                                {labelEnd}
                                            </div>
                                        )}
                                    </div>
                                ) : null
                            }
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                            tooltip={tooltip}
                            required={required}
                        >
                            <div
                                className={classNames('ant-picker ant-picker-range', {
                                    'ant-picker-focused': isFocus,
                                })}
                            >
                                <div className="ant-picker-input">
                                    <Popover
                                        overlayClassName="time-picker-custom-popover"
                                        placement="bottomLeft"
                                        content={
                                            <PickerTimePanel
                                                name={name + '_start'}
                                                defaultValue={inputStart}
                                                onChange={(val) => {
                                                    const oldValue = field.value || [
                                                        '',
                                                        '',
                                                    ];
                                                    oldValue[0] = useDayjs
                                                        ? parseTime(val)
                                                        : val;
                                                    field.onChange(oldValue);
                                                    if (onChange) onChange(oldValue);
                                                }}
                                            />
                                        }
                                        trigger="click"
                                        onOpenChange={(open) => {
                                            if (!open) {
                                                setIsFocus(false);
                                            }
                                        }}
                                    >
                                        <input
                                            onFocus={() => {
                                                setIsFocus(true);
                                            }}
                                            value={inputStart}
                                            onChange={(e) => {}}
                                            placeholder={placeholder?.[0]}
                                        />
                                    </Popover>
                                </div>
                                <div className="ant-picker-range-separator">
                                    <span
                                        aria-label="to"
                                        className="ant-picker-separator"
                                    >
                                        <SwapRightOutlined />
                                    </span>
                                </div>
                                <div className="ant-picker-input">
                                    <Popover
                                        overlayClassName="time-picker-custom-popover"
                                        placement="bottomLeft"
                                        content={
                                            <PickerTimePanel
                                                name={name + '_end'}
                                                defaultValue={inputEnd}
                                                onChange={(val) => {
                                                    const oldValue = field.value || [
                                                        '',
                                                        '',
                                                    ];
                                                    oldValue[1] = useDayjs
                                                        ? parseTime(val)
                                                        : val;
                                                    field.onChange(oldValue);
                                                    if (onChange) onChange(oldValue);
                                                }}
                                            />
                                        }
                                        trigger="click"
                                        onOpenChange={(open) => {
                                            if (!open) {
                                                setIsFocus(false);
                                            }
                                        }}
                                    >
                                        <input
                                            onFocus={() => {
                                                setIsFocus(true);
                                            }}
                                            value={inputEnd}
                                            onChange={(e) => {}}
                                            placeholder={placeholder?.[1]}
                                        />
                                    </Popover>
                                </div>
                                <span className="ant-picker-suffix">
                                    <ClockCircleOutlined />
                                </span>
                            </div>
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
};
