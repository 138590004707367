import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '~hooks';
import './PlanOverview.scss';
import { selectedPlanSelector } from '~features/plan/reducers/plan.reducer';
import { PlanType } from '~features/plan/constants';
function PlanOverview() {
    const { t } = useTranslation();
    const selectedPlanDetail = useAppSelector(selectedPlanSelector);
    return (
        <div className="plan-detail-basic-info">
            <div className="form-title">
                <span className="mr-8">{t('plan.detail.tab1.planOverview.title')}</span>
            </div>
            <div className="plan-detail-basic-info-content">
                <Row>
                    <Col span={4} className="plan-detail-label">
                        {t('plan.detail.tab1.planOverview.name')}
                    </Col>
                    <Col span={20}>{selectedPlanDetail?.name}</Col>
                </Row>
                <Row>
                    <Col span={4} className="plan-detail-label">
                        {t('plan.detail.tab1.planOverview.detail')}
                    </Col>
                    <Col span={20}>{selectedPlanDetail?.memo}</Col>
                </Row>
                <Row>
                    <Col span={4} className="plan-detail-label">
                        {t('plan.detail.tab1.planOverview.planType')}
                    </Col>
                    <Col span={5}>
                        {selectedPlanDetail?.planType === PlanType.STAY
                            ? t('plan.create.planOverview.planTypeOptions.stay')
                            : t('plan.create.planOverview.planTypeOptions.day_use')}
                    </Col>
                    <Col span={4} className="plan-detail-label">
                        {t('plan.detail.tab1.planOverview.taxId')}
                    </Col>
                    <Col span={9}>{selectedPlanDetail?.tax?.name}</Col>
                </Row>
                <Row>
                    <Col span={4} className="plan-detail-label">
                        {t('plan.detail.tab1.planOverview.checkInTime')}
                    </Col>
                    <Col span={5}>{selectedPlanDetail?.checkInTime}</Col>
                    <Col span={4} className="plan-detail-label">
                        {t('plan.detail.tab1.planOverview.checkOutTime')}
                    </Col>
                    <Col span={9}>{selectedPlanDetail?.checkOutTime}</Col>
                </Row>
            </div>
        </div>
    );
}

export default PlanOverview;
