import { FacilityBookingStatusColor } from './../facility-booking/constants';
import {
    RoomBookingItemBookingStatusColor,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';

export enum RoomManagementStatus {
    ALL = 'all',
    STAY = 'stay',
    VACANCY = 'vacancy',
    STOP_SELLING = 'stop_selling',
}

export enum RoomOccupationStatus {
    CHECKED_OUT = 'checked_out',
    EMPTY = 'empty',
    OCCUPIED = 'occupied',
}

export enum RoomManagementEvent {
    CHANGE_SELLING_STATUS_ROOM = `change_selling_status_room `,
}

export const RoomManagementStatusColor = {
    [RoomManagementStatus.STAY]: '#3A9554',
    [RoomManagementStatus.VACANCY]: '#FFFFFF',
    [RoomManagementStatus.STOP_SELLING]: '#C53C3C',
    ...RoomBookingItemBookingStatusColor,
    ...FacilityBookingStatusColor,
};

export const roomManagementFilterStatus = [
    RoomManagementStatus.ALL,
    RoomManagementStatus.VACANCY,
    RoomBookingItemBookingStatus.NOT_ARRIVED,
    RoomBookingItemBookingStatus.CHECKED_IN,
    RoomBookingItemBookingStatus.CHECKED_OUT,
    RoomManagementStatus.STOP_SELLING,
];

export const facilityManagementFilterStatus = [
    RoomManagementStatus.ALL,
    RoomManagementStatus.STAY,
    RoomManagementStatus.VACANCY,
];

export enum StopRoomCause {
    INVENTORY_ADJUSTMENT = 'INVENTORY_ADJUSTMENT',
    ROOM_ISSUES = 'ROOM_ISSUES',
    OTHERS = 'OTHERS',
}

export enum StopSellingStatus {
    VACANT = 'vacant',
    UNAVAILABLE = 'unavailable',
}

export const MAX_LENGTH_MEMO = 240;

export const DEFAULT_TIME_ZONE = 'Asia/Tokyo';
