import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDropDownOption } from '~common/interfaces';
import { RangePicker, SingleSelect } from '~components';
import { IStopSellingFormItem } from '~features/inventory/interfaces';
import { IRoomDropDown } from '~features/room/interfaces';
import { todayDayjs } from '~plugins/dayjs';
import './StopSellingFormItem.scss';

type IProps = {
    control: any;
    setValue: any;
    index: number;
    item: IStopSellingFormItem;
    roomDropDowns: IRoomDropDown[];
    roomTypeOptions: IDropDownOption[];
    disableRoomTypeIds: number[];
    removeStopSellingItem: (index: number) => void;
    onChangeRoomType: (index: number, roomTypeId: number) => void;
};

function StopSellingFormItem(prop: IProps) {
    const {
        control,
        setValue,
        index,
        item,
        roomTypeOptions,
        roomDropDowns,
        disableRoomTypeIds,
        removeStopSellingItem,
        onChangeRoomType,
    } = prop;
    const { t } = useTranslation();
    const [currentRoomType, setCurrentRoomType] = useState<number | undefined>(
        item.roomTypeId || undefined,
    );

    useEffect(() => {
        setCurrentRoomType(item.roomTypeId || undefined);
    }, [item]);

    const changeRoomType = (value: number) => {
        setCurrentRoomType(value);
        if (value) {
            setValue(`items.${index}.roomIds`, []);
        }
        onChangeRoomType(index, value);
    };

    const changeRoom = (values: number[]) => {
        if (!values.length) return;
        const findRoom = roomDropDowns.find((room) => {
            return room.id === values[0];
        });
        setValue(`items.${index}.roomTypeId`, findRoom?.roomTypeId);
        setCurrentRoomType(findRoom?.roomTypeId);
        if (findRoom?.roomTypeId) onChangeRoomType(index, findRoom?.roomTypeId);
    };

    const removeItem = (index: number) => {
        removeStopSellingItem(index);
    };

    const roomOptions = useMemo(() => {
        if (!currentRoomType) {
            const _roomDropDowns = roomDropDowns.filter((room) => {
                return !disableRoomTypeIds.includes(room.roomTypeId);
            });
            return _roomDropDowns.map((room) => {
                return {
                    value: room.id,
                    label: room.name,
                };
            });
        }

        return roomDropDowns
            .filter((room) => {
                return room.roomTypeId === currentRoomType;
            })
            .map((room) => {
                return {
                    value: room.id,
                    label: room.name,
                };
            });
    }, [roomDropDowns, currentRoomType, disableRoomTypeIds]);

    return (
        <div className="stop-selling-form-item-wrapper">
            <Row>
                <Col span={22}>
                    <SingleSelect
                        onChange={changeRoomType}
                        name={`items.${index}.roomTypeId`}
                        label={t('inventory.stopSelling.bulkForm.roomTypeId.label')}
                        placeholder={t(
                            'inventory.stopSelling.bulkForm.roomTypeId.placeholder',
                        )}
                        required={true}
                        control={control}
                        options={roomTypeOptions}
                        showSearch={true}
                        allowClear
                        disableValues={disableRoomTypeIds}
                        defaultValue={item.roomTypeId || undefined}
                    />
                </Col>
                <Col span={2}>
                    <div className="delete-form-item">
                        <Button
                            className="delete-item-button"
                            type="text"
                            shape="circle"
                            onClick={() => removeItem(index)}
                            icon={<DeleteOutlined />}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={22}>
                    <SingleSelect
                        name={`items.${index}.roomIds`}
                        label={''}
                        placeholder={t(
                            'inventory.stopSelling.bulkForm.roomId.placeholder',
                        )}
                        control={control}
                        options={roomOptions}
                        defaultValue={item.roomIds || []}
                        showSearch={true}
                        mode="multiple"
                        allowClear
                        showArrow
                        onChange={changeRoom}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={22}>
                    <RangePicker
                        name={`items.${index}.rangeDay`}
                        label={''}
                        placeholder={[
                            t(
                                'inventory.stopSelling.bulkForm.rangeDay.placeholder.start',
                            ),
                            t('inventory.stopSelling.bulkForm.rangeDay.placeholder.end'),
                        ]}
                        control={control}
                        allowClear
                        disabledDate={(current) => {
                            return current <= todayDayjs;
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default StopSellingFormItem;
