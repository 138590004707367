import {
    AuditLogActions,
    AuditLogModules,
} from '~features/fo-operation-history/constants';

const historyList = {
    breadcrumbs: {
        home: 'Home',
        frontOffice: 'Front Office Setting',
        history: 'History',
    },
    title: 'History',
    columns: {
        createdAt: 'Date',
        picName: 'PIC',
        activityTitle: 'Activity',
    },
    header: {
        activityContent: {
            create: '{{targetObjectKey}} has been created',
            bulkCreate: 'Some {{targetObjectKey}}(s) have been created',
            update: '{{targetObjectKey}} has been changed',
            bulkUpdate: 'Some {{targetObjectKey}}(s) have been changed',
            updateStatus: '{{targetObjectKey}}(s) status have been changed',
            delete: '{{targetObjectKey}} has been deleted',
            bulkDelete: 'Some {{targetObjectKey}} have been deleted',
            checkIn: '{{targetObjectKey}} has been check in',
            importXMLCreateRoomBooking: 'Import XML create {{targetObjectKey}}',
            importXMLModifyRoomBooking: 'Import XML modify {{targetObjectKey}}',
            specialCases: {
                roomBookingItem: {
                    create: 'Room Booking Item has been created',
                    update: 'Room Booking Item has been changed',
                    bulkUpdate: 'Some Room Booking Item(s) have been changed',
                    bulkDelete: 'Some Room Booking Item have been deleted',
                    updateStatus: 'Room Booking Item(s) status have been changed',
                },
                receipt: {
                    bulkUpdate: 'Some Receipt(s) have been changed',
                },
                receiptItemDetail: {
                    bulkDelete: 'Some Receipt Item Detail have been deleted',
                },
                overbooking: {
                    create: 'Overbooking has been created',
                },
            },
        },
    },
    searchColumns: {
        // picName: '担当者',
        activityTitle: 'Activity',
        createdByEmail: 'PIC email',
    },
    detail: 'Detail',
    noData: 'No data',
    operationContents: 'Operation content',
    exportColumns: {
        createdAt: 'Date',
        picName: 'PIC',
        actionTitle: 'Activity',
        createdByEmail: 'PIC email',
    },
    activityTitle: {
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_BOOKING}`]:
            'Create Room booking',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_BOOKING}`]:
            'Update Room booking',
        [`${AuditLogActions.DELETE}${AuditLogModules.ROOM_BOOKING}`]:
            'Delete Room booking',
        [`${AuditLogActions.IMPORT_XML_CREATE}${AuditLogModules.ROOM_BOOKING}`]:
            'Import XML Create Room Booking',
        [`${AuditLogActions.IMPORT_XML_MODIFY}${AuditLogModules.ROOM_BOOKING}`]:
            'Import XML Modify Room Booking',
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            'Create Room Booking Item ',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            'Bulk Update Room Booking Item ',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            'Update room booking item',
        [`${AuditLogActions.UPDATE_STATUS}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            'Update room booking item status',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ROOM_BOOKING_ITEM}`]:
            'Bulk delete Room Booking Item ',
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]:
            'Create Room Booking Item Temporary',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]:
            'Update Room Booking Item Temporary',
        [`${AuditLogActions.DELETE}${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]:
            'Delete Room Booking Item Temporary',
        [`${AuditLogActions.CREATE}${AuditLogModules.CHILDREN_TYPE}`]:
            'Create Children Type',
        [`${AuditLogActions.UPDATE}${AuditLogModules.CHILDREN_TYPE}`]:
            'Update Children Type',
        [`${AuditLogActions.DELETE}${AuditLogModules.CHILDREN_TYPE}`]:
            'Delete Children Type',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.CHILDREN_TYPE}`]:
            'Bulk Delete Children Type',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.FACILITY}`]:
            'Bulk Create Facility',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.FACILITY}`]:
            'Bulk Update Facility',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.FACILITY}`]:
            'Bulk Delete Facility',
        [`${AuditLogActions.CREATE}${AuditLogModules.FACILITY_TYPE}`]:
            'Create Facility Type',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.FACILITY_TYPE}`]:
            'Bulk Update Facility Type',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.FACILITY_TYPE}`]:
            'Bulk Delete Facility Type',
        [`${AuditLogActions.CREATE}${AuditLogModules.GUEST_CLASSIFICATION}`]:
            'Create Guest Classification',
        [`${AuditLogActions.UPDATE}${AuditLogModules.GUEST_CLASSIFICATION}`]:
            'Update Guest Classification',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.GUEST_CLASSIFICATION}`]:
            'Bulk Delete Guest Classification',
        [`${AuditLogActions.CREATE}${AuditLogModules.GUEST}`]: 'Create Guest',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.GUEST}`]: 'Bulk Update Guest',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.GUEST}`]: 'Bulk Delete Guest',
        [`${AuditLogActions.CREATE}${AuditLogModules.INDICATOR}`]: 'Create Indicator',
        [`${AuditLogActions.UPDATE}${AuditLogModules.INDICATOR}`]: 'Update Indicator',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.INDICATOR}`]:
            'Bulk Delete Indicator',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.MARKETING_CHANEL}`]:
            'Bulk Create Marketing Channel',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.MARKETING_CHANEL}`]:
            'Bulk Update Marketing Channel',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.MARKETING_CHANEL}`]:
            'Bulk Delete Marketing Channel',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.MARKETING_CHANEL_GROUP}`]:
            'Bulk Create Marketing Group',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.MARKETING_CHANEL_GROUP}`]:
            'Bulk Update Marketing Group',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.MARKETING_CHANEL_GROUP}`]:
            'Bulk Delete Marketing Group',
        [`${AuditLogActions.CREATE}${AuditLogModules.ROOM_TYPE}`]: 'Create Room Type',
        [`${AuditLogActions.UPDATE}${AuditLogModules.ROOM_TYPE}`]: 'Update Room Type',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ROOM_TYPE}`]:
            'Bulk Delete Room Type',
        [`${AuditLogActions.CREATE}${AuditLogModules.FACILITY_BOOKING}`]:
            'Create Facility booking',
        [`${AuditLogActions.UPDATE}${AuditLogModules.FACILITY_BOOKING}`]:
            'Update Facility booking',
        [`${AuditLogActions.UPDATE_STATUS}${AuditLogModules.FACILITY_BOOKING}`]:
            'Update Status Facility booking',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.FACILITY_BOOKING}`]:
            'Bulk Delete Facility booking',
        [`${AuditLogActions.CHECK_IN}${AuditLogModules.FACILITY_BOOKING}`]:
            'Check in Facility booking',
        [`${AuditLogActions.CREATE}${AuditLogModules.PAYMENT_METHOD}`]:
            'Create Payment Method',
        [`${AuditLogActions.UPDATE}${AuditLogModules.PAYMENT_METHOD}`]:
            'Update Payment Method',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.PAYMENT_METHOD}`]:
            'Bulk Delete Payment Method',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.ROOM}`]: 'Bulk Create Room',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.ROOM}`]: 'Bulk Update Room',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.ROOM}`]: 'Bulk Delete Room',
        [`${AuditLogActions.CREATE}${AuditLogModules.SALE_GROUP}`]: 'Create Sale Group',
        [`${AuditLogActions.UPDATE}${AuditLogModules.SALE_GROUP}`]: 'Update Sale Group',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.SALE_GROUP}`]:
            'Bulk Delete Sale Group',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.SALE_ITEM}`]:
            'Bulk Create Sale Item',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.SALE_ITEM}`]:
            'Bulk Update Sale Item',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.SALE_ITEM}`]:
            'Bulk Delete Sale Item',
        [`${AuditLogActions.CREATE}${AuditLogModules.PLAN}`]: 'Create Plan',
        [`${AuditLogActions.UPDATE}${AuditLogModules.PLAN}`]: 'Update Plan',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.PLAN}`]: 'Bulk Delete Plan',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.TAX}`]: 'Bulk Update Tax',
        [`${AuditLogActions.CREATE}${AuditLogModules.PLAN_RANK_CALENDAR}`]:
            'Create Plan Rank Calendar',
        [`${AuditLogActions.BULK_UPDATE}${AuditLogModules.PLAN_RANK_CALENDAR}`]:
            'Bulk Update Plan Rank Calendar',
        [`${AuditLogActions.BULK_DELETE}${AuditLogModules.PLAN_RANK_CALENDAR}`]:
            'Bulk Delete Plan Rank Calendar',
        [`${AuditLogActions.UPDATE}${AuditLogModules.RECEIPT}`]: 'Update Receipt',
        [`${AuditLogActions.BULK_CREATE}${AuditLogModules.STOPPING_INVENTORY}`]:
            'Bulk update inventory',
        [`${AuditLogActions.CREATE}${AuditLogModules.STOPPING_INVENTORY}`]:
            'Update inventory',
        [`${AuditLogActions.UPDATE}${AuditLogModules.HOTEL}`]: 'Update Hotel',
        [`${AuditLogActions.CREATE}${AuditLogModules.OVERBOOKING}`]:
            'Create A Booking Despite Insufficient Inventory',
    },
    filter: {
        CREATE: 'Create',
        UPDATE: 'Update',
        DELETE: 'Delete',
        CHECK_IN: 'Check in',
        CHECK_OUT: 'Check out',
        CANCEL: 'Cancel',
        [`${AuditLogModules.CHILDREN_TYPE}`]: 'Children Type',
        [`${AuditLogModules.FACILITY}`]: 'Facility',
        [`${AuditLogModules.FACILITY_BOOKING}`]: 'Facility Booking',
        [`${AuditLogModules.FACILITY_TYPE}`]: 'Facility Type',
        [`${AuditLogModules.GUEST}`]: 'Guest',
        [`${AuditLogModules.GUEST_CLASSIFICATION}`]: 'Guest Classification',
        [`${AuditLogModules.HOTEL}`]: 'Hotel',
        [`${AuditLogModules.INDICATOR}`]: 'Indicator',
        [`${AuditLogModules.MARKETING_CHANEL}`]: 'Marketing Channel',
        [`${AuditLogModules.MARKETING_CHANEL_GROUP}`]: 'Marketing Channel Group',
        [`${AuditLogModules.NOTIFICATION}`]: 'Notification',
        [`${AuditLogModules.PAYMENT_METHOD}`]: 'Payment Method',
        [`${AuditLogModules.PLAN}`]: 'Plan',
        [`${AuditLogModules.PLAN_RANK_CALENDAR}`]: 'Plan Rank Calendar',
        [`${AuditLogModules.RECEIPT}`]: 'Receipt',
        [`${AuditLogModules.RECEIPT_ITEM_DETAIL}`]: 'Receipt Item Detail',
        [`${AuditLogModules.ROOM}`]: 'Room',
        [`${AuditLogModules.ROOM_BOOKING}`]: 'Room Booking',
        [`${AuditLogModules.ROOM_TYPE}`]: 'Room Type',
        [`${AuditLogModules.SALE_GROUP}`]: 'Sale Group',
        [`${AuditLogModules.SALE_ITEM}`]: 'Sale Item',
        [`${AuditLogModules.STOPPING_INVENTORY}`]: 'Inventory',
        [`${AuditLogModules.TAG}`]: 'Tag',
        [`${AuditLogModules.TAX}`]: 'Tax',
        [`${AuditLogModules.ROOM_BOOKING_ITEM}`]: 'Room Booking Item',
        [`${AuditLogModules.ROOM_BOOKING_ITEM_TEMPORARY}`]: 'Temporary Booking',
        [`${AuditLogModules.OVERBOOKING}`]: 'Overbooking',
    },
};

export default historyList;
