import { TooltipPlacement } from 'antd/lib/tooltip';
import { forEach } from 'lodash';
import { Emitter } from 'mitt';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { EmitterGlobalEvent } from '~common/constants';
import { IDropDownOption } from '~common/interfaces';
import {
    IPosition,
    IRoomBookingItem,
    IRoomBookingSchedule,
} from '~features/room-booking/interfaces';
import i18next from '~plugins/i18next/i18n';
import { useMitt } from '~plugins/mitt';
import store from '~plugins/redux-toolkit/store';
import { CleaningStatus, RoomBookingItemBookingStatus } from './constants';
import { getBookingScheduleDefault } from './model';
import RoomBookingItemDetailPopover from './pages/RoomBookingDetailPage/components/RoomBookingDetailTabPane/RoomBookingItemDetailPopover/RoomBookingItemDetailPopover';
import { DetailBookingModal } from './pages/SchedulePage/components/DetailBookingModal/DetailBookingModal';
import customDayjs from '~plugins/dayjs';
import ScheduleCellPopup from './pages/SchedulePage/components/ScheduleCellPopup/ScheduleCellPopup';

export function showCreateTempBookingModal(
    booking: IRoomBookingSchedule,
    element: HTMLElement,
    onClose: () => void,
    isBlockedRoomsSelecting: boolean = false,
) {
    const root = ReactDOM.createRoot(element);
    root.render(
        <Provider store={store}>
            <ScheduleCellPopup
                booking={booking}
                isBlockedRoomsSelecting={isBlockedRoomsSelecting}
                onClose={() => {
                    if (onClose) {
                        onClose();
                    }
                    root.unmount();
                }}
            />
        </Provider>,
    );
}

export function showBookingDetailModal(options: {
    booking: IRoomBookingSchedule;
    element: HTMLElement;
    onClose?: () => void;
    navigate?: NavigateFunction;
    emitter?: Emitter<any>;
    onUnassignBooking?: (booking: IRoomBookingSchedule) => void;
    onUnassignBookingSuccess?: (booking: IRoomBookingSchedule) => void;
    onUnassignBookingError?: (booking: IRoomBookingSchedule) => void;
    isShowEditBtn?: boolean;
    onUpdateBookingSuccess?: (booking: IRoomBookingItem) => void;
    position?: { pageX: number; pageY: number };
}) {
    removeSelection();
    const { element, onClose, position, ...rest } = options;
    const root = ReactDOM.createRoot(element);
    root.render(
        <Provider store={store}>
            <DetailBookingModal
                {...rest}
                onClose={() => {
                    if (onClose) {
                        onClose();
                    }
                    root.unmount();
                }}
                element={element}
                position={position}
            />
        </Provider>,
    );
}

export function showRoomBookingItemDetailModal(options: {
    bookingItem: IRoomBookingItem;
    roomBookingId: number;
    element: HTMLElement;
    isFromTll?: boolean;
    placement?: TooltipPlacement;
    isSingleBooking?: boolean;
    isFrozen?: boolean;
    onClose?: () => void;
    navigate?: NavigateFunction;
    onUpdateSuccess?: (booking: IRoomBookingItem) => void;
    onUpdateStatusSuccess?: (booking: IRoomBookingItem) => void;
}) {
    removeSelection();
    const {
        bookingItem,
        roomBookingId,
        element,
        placement,
        isFromTll,
        isSingleBooking,
        isFrozen,
        onClose,
        onUpdateSuccess,
        onUpdateStatusSuccess,
    } = options;
    const root = ReactDOM.createRoot(element);
    root.render(
        <Provider store={store}>
            <RoomBookingItemDetailPopover
                bookingItem={bookingItem}
                placement={placement}
                roomBookingId={roomBookingId}
                isSingleBooking={isSingleBooking}
                isFromTll={isFromTll}
                isFrozen={isFrozen}
                onUpdateSuccess={onUpdateSuccess}
                onUpdateStatusSuccess={onUpdateStatusSuccess}
                onClose={() => {
                    if (onClose) {
                        onClose();
                    }
                    root.unmount();
                }}
            />
        </Provider>,
    );
}

export function scrollToTmpBooking(id: string) {
    scroller.scrollTo(id, {
        duration: 300,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'staff-layout-content',
        offset: -200,
    });
}

export function roomBookingItemBookingStatusOptions(): IDropDownOption[] {
    const statusMenus = Object.values(RoomBookingItemBookingStatus).filter((status) => {
        if (status === RoomBookingItemBookingStatus.ALL) return false;
        return true;
    });
    const items = statusMenus.map((status) => {
        return {
            label: i18next.t(`roomBooking.page.bookingStatus.${status}`),
            value: status.toString(),
        };
    });
    return items;
}
export const getCellList = (panelId: string) => {
    const cellList: Record<string, HTMLElement> = {};
    const contents = document.getElementsByClassName(panelId);
    forEach(contents, (element) => {
        const x = (element as HTMLElement).dataset.x;
        const y = (element as HTMLElement).dataset.y;
        cellList[`${y}-${x}`] = element as HTMLElement;
    });
    return cellList;
};

export const removeSelection = () => {
    const cellList = document.getElementsByClassName('cell-content') || [];
    forEach(cellList, (element) => {
        element.classList.remove('selection', 'selection-blocked', 'day-invalid');
    });
};

export const updateStyleForCellSelected = (
    panelId: string,
    startPosition: IPosition,
    x: number,
    isBlockedRoomSelected?: boolean,
) => {
    removeSelection();
    selectHorizontal(panelId, startPosition, x, isBlockedRoomSelected);
};

export const selectHorizontal = (
    panelId: string,
    startPosition: IPosition,
    x: number,
    isBlockedRoomSelected?: boolean,
) => {
    const list = getCellList(panelId);
    const _x = startPosition.x;
    const _y = startPosition.y;
    const start = _x > x ? x : _x;
    const end = _x > x ? _x : x;

    for (let index = start; index <= end; index++) {
        if (!list[`${_y}-${index}`]) continue;
        const cell = list[`${_y}-${index}`];
        isBlockedRoomSelected
            ? cell?.classList.add('selection-blocked')
            : cell?.classList.add('selection');
    }
};

export const getElementFromPosition = (options: {
    panelId: string;
    startPosition: IPosition;
    currentCursor: IPosition;
}) => {
    const { startPosition, currentCursor, panelId } = options;
    const start = startPosition.x > currentCursor.x ? currentCursor.x : startPosition.x;
    const end = startPosition.x < currentCursor.x ? currentCursor.x : startPosition.x;
    const list = getCellList(panelId);
    const elementStart = list[`${currentCursor.y}-${start}`];
    const elementEnd = list[`${currentCursor.y}-${end}`];
    return { elementStart, elementEnd };
};

export function endSelect(
    options: {
        panelId: string;
        startPosition: IPosition;
        currentCursor: IPosition;
        onClose?: () => void;
    },
    isBlockedRoomsSelecting: boolean,
) {
    const { elementStart, elementEnd } = getElementFromPosition(options);

    if (elementStart && elementEnd) {
        const roomId = elementStart.dataset.room_id;
        const typeId = elementStart.dataset.room_type_id;
        const startDate = elementStart.dataset.day;
        const endDate = elementEnd.dataset.day;
        const booking: IRoomBookingSchedule = {
            ...getBookingScheduleDefault(),
            stayingStartDate: startDate || '',
            stayingEndDate: endDate || '',
            status: RoomBookingItemBookingStatus.NOT_ARRIVED,
            room: {
                id: Number(roomId),
                name: '',
                cleaningStatus: CleaningStatus.UNCLEANED,
            },
            roomType: {
                id: Number(typeId),
                name: '',
            },
            roomKey: `${typeId}-${roomId}`,
        };
        showCreateTempBookingModal(
            booking,
            elementStart,
            () => {
                options.onClose && options.onClose();
                removeSelection();
            },
            isBlockedRoomsSelecting,
        );
    }
}

export function getDateFromElement(options: {
    panelId: string;
    startPosition: IPosition;
    currentCursor: IPosition;
}) {
    const { elementStart, elementEnd } = getElementFromPosition(options);
    const startDate = elementStart?.dataset.day;
    const endDate = elementEnd?.dataset.day;
    const startTime = elementStart.dataset.hours;
    const endTime = elementEnd.dataset.hours;
    return {
        startDate,
        endDate,
        startTime,
        endTime,
    };
}

export function endSelectHour(options: {
    panelId: string;
    startPosition: IPosition;
    currentCursor: IPosition;
}) {
    const { elementStart, elementEnd } = getElementFromPosition(options);

    if (elementStart && elementEnd) {
        // TODO: end select facility week view
        const roomId = elementStart.dataset.room_id;
        const typeId = elementStart.dataset.room_type_id;
        const startDate = elementStart.dataset.day;
        const endDate = elementEnd.dataset.day;
        const startTime = elementStart.dataset.hours;
        const endTime = elementEnd.dataset.hours;
        const booking: IRoomBookingSchedule = {
            ...getBookingScheduleDefault(),
            stayingStartDate: startDate || '',
            stayingEndDate: endDate || '',
            checkInTime: startTime || '',
            checkOutTime: endTime || '',
            status: RoomBookingItemBookingStatus.NOT_ARRIVED,
            room: {
                id: Number(roomId),
                name: '',
                cleaningStatus: CleaningStatus.UNCLEANED,
            },
            roomType: {
                id: Number(typeId),
                name: '',
            },
            isDayUse: true,
            roomKey: `${typeId}-${roomId}`,
        };
        showCreateTempBookingModal(booking, elementStart, () => {
            removeSelection();
        });
    } else {
        removeSelection();
    }
}

export const useScheduleEvent = () => {
    const today = customDayjs().fmYYYYMMDD();
    const { emitter } = useMitt();
    const triggerScroll = (val: boolean) => {
        emitter.emit(EmitterGlobalEvent.TRIGGER_SCROLL, val);
    };

    return {
        triggerScroll,
        emitter,
        today,
    };
};
