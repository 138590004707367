import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, Menu } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { IMenuItem } from '~common/interfaces';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import localStorageAuthService from '~common/authStorage';
import {
    MainMenu,
    PmsMenu,
    channelSettingsMenu,
    integrationSettings,
    othersSettingsMenu,
    planSettingsMenu,
    pmsBOSettings,
    pmsFacilityBookingMenus,
    pmsMenus,
    earningsReportMenu,
    depositsAndWithdrawalReportMenu,
    othersReportMenu,
    pmsRoomBookingMenus,
    roomEquipmentSettingsMenu,
    salesDepositTaxSettingsMenu,
    smtpSettings,
} from '../menu';
import classNames from 'classnames';
import { useIsMobile } from '~common/useHooks';
import i18next from '~plugins/i18next/i18n';
import { ReactComponent as DynamicPricingLogo } from '~assets/images/dynamic-pricing-icon.svg';
import { MenuInfo } from 'rc-menu/lib/interface';
import OtherMenu from './OtherMenu/OtherMenu';
import './SiderMenu.scss';

const { Sider } = Layout;

interface ISiderMenu {
    handleOnCloseDrawer?: () => void;
}

const SiderMenu: React.FC<ISiderMenu> = ({ handleOnCloseDrawer }) => {
    const [activeItemKeys, setActiveItemKeys] = useState<string[]>([]);

    const [openItemKey, setOpenItemKey] = useState<string[]>([]);
    const [collapsed, setIsCollapsed] = useState<boolean>(true);
    const isMobile = useIsMobile();
    const location = window.location;

    //TODO: once Hotel Smart integration is done, please remove tempPmsMenus
    const tempPmsMenus =
        process.env.REACT_APP_HOTEL_SMART === 'true'
            ? pmsMenus(isMobile)
            : pmsMenus(isMobile).filter((item) => item.key !== PmsMenu.EXTERNAL_TOOLS);

    //Appends '/' to the start of a string if not present
    const getPath = (path: string) => {
        if (path.charAt(0) !== '/') {
            return '/' + path;
        }
        return path;
    };

    //This re-renders when the route changes
    useEffect(() => {
        const pathnameSplits = getPath(location.pathname).split('/');
        const paths: string[] = [];
        for (let index = 1; index < pathnameSplits.length; index++) {
            paths.push(getPath(pathnameSplits.slice(1, index + 1).join('/')));
        }
        const groupMenus = [
            ...pmsBOSettings,
            ...pmsRoomBookingMenus,
            ...pmsFacilityBookingMenus,
            //TODO: once Hotel Smart integration is done, please remove tempPmsMenus
            // ...pmsMenus,
            ...tempPmsMenus,
            ...smtpSettings,
            ...integrationSettings,
            ...roomEquipmentSettingsMenu,
            ...planSettingsMenu,
            ...salesDepositTaxSettingsMenu,
            ...channelSettingsMenu,
            ...othersSettingsMenu,
            ...earningsReportMenu,
            ...depositsAndWithdrawalReportMenu,
            ...othersReportMenu,
        ];
        let activeItemKey = '';
        for (let index = paths.length - 1; index >= 0; index--) {
            const activeItem = groupMenus.find((item) => {
                if (!item.to) return false;
                return getPath(item.to) === paths[index];
            });
            if (activeItem) {
                activeItemKey = activeItem.key;
                break;
            }
            if (paths[index] === '/room-cleaning') {
                activeItemKey = PmsMenu.ROOM_MANAGEMENT;
                break;
            }
        }
        setActiveItemKeys([activeItemKey]);
    }, [location, location.pathname]);

    const generatePmsMenus = (list: IMenuItem[]): ItemType[] => {
        return list.map((item) => {
            const sidebar = { ...item };
            delete sidebar.permissions;
            delete sidebar.requireHotel;
            if (sidebar.children) {
                return {
                    ...sidebar,
                    children: generatePmsMenus(sidebar.children),
                };
            }
            return {
                ...sidebar,
                label: <Link to={sidebar.to || '/'}>{sidebar.label}</Link>,
            };
        });
    };

    const filterSidebarPermissions = (list: IMenuItem[]): IMenuItem[] => {
        const sidebarList: IMenuItem[] = [];
        list.forEach((item) => {
            let sidebar = { ...item };
            if (checkUserPermission(item.permissions || [])) {
                if (item.children) {
                    const childrenSidebar = filterSidebarPermissions(item.children);
                    sidebar = {
                        ...sidebar,
                        children: childrenSidebar,
                    };
                }
                sidebarList.push(sidebar);
            }
        });

        return sidebarList;
    };

    const filterRequireHotel = (list: IMenuItem[]): IMenuItem[] => {
        const sidebarList: IMenuItem[] = [];
        const hotelId = localStorageAuthService.getHotelId();
        if (!hotelId) {
            list.forEach((item) => {
                let sidebar = { ...item };
                if (!sidebar.requireHotel) {
                    if (item.children) {
                        const childrenSidebar = filterRequireHotel(item.children);
                        sidebar = {
                            ...sidebar,
                            children: childrenSidebar,
                        };
                    }
                    sidebarList.push(sidebar);
                }
            });
            return sidebarList;
        }
        return list;
    };

    const handleClickCollapseButton = () => {
        setIsCollapsed(!collapsed);
        if (isMobile && handleOnCloseDrawer) {
            handleOnCloseDrawer();
        }
    };

    const collapseButton = useMemo(() => {
        return collapsed && !isMobile ? <CaretRightOutlined /> : <CaretLeftOutlined />;
    }, [collapsed]);

    const siderBottomMenus = useMemo(() => {
        const logoHeight = collapsed && !isMobile ? 32 : 20;
        return [
            {
                label: i18next.t('common.sidebar.pricing'),
                key: MainMenu.PRICING,
                icon: <DynamicPricingLogo style={{ height: logoHeight }} />,
            },
        ];
    }, [collapsed]);

    const handleClickMenu = useCallback((info: MenuInfo) => {
        switch (info.key) {
            case MainMenu.PRICING:
                window.location.href = 'https://price.tabist.co.jp/';
                break;
            default:
                break;
        }
    }, []);

    return (
        <Sider
            className="sider-container"
            collapsed={isMobile ? false : collapsed}
            collapsedWidth={56}
        >
            <div
                className={classNames('sider-content_wrapper', {
                    isMobile: isMobile,
                })}
            >
                <div className="sider-main-menu_container">
                    <div className="sider-top-menu_container">
                        <div
                            className={classNames('sider_collapse-button', {
                                collapsed: collapsed,
                                isMobile: isMobile,
                            })}
                            onClick={handleClickCollapseButton}
                        >
                            {collapseButton}
                        </div>
                        <Menu
                            className="sider_top-menu"
                            mode="vertical"
                            items={generatePmsMenus(
                                filterSidebarPermissions(
                                    filterRequireHotel(tempPmsMenus),
                                ),
                            )}
                            triggerSubMenuAction={isMobile ? 'click' : 'hover'}
                            selectedKeys={activeItemKeys}
                            openKeys={openItemKey}
                            onOpenChange={(e) => {
                                const hasManyPmsKeySelected = e
                                    .filter((key) => {
                                        return key;
                                    })
                                    .every((key) => {
                                        return Object.values(PmsMenu).includes(
                                            key as PmsMenu,
                                        );
                                    });
                                if (hasManyPmsKeySelected) {
                                    setOpenItemKey([e[e.length - 1]]);
                                } else {
                                    setOpenItemKey(e);
                                }
                            }}
                        />
                    </div>
                    <div className="sider_divider" />
                    <div
                        className={classNames('sider-bottom-menu_container', {
                            collapsed: collapsed && !isMobile,
                        })}
                    >
                        <Menu
                            className={classNames('sider_bottom-menu', {
                                collapsed: collapsed,
                                isMobile: isMobile,
                            })}
                            items={siderBottomMenus}
                            onClick={handleClickMenu}
                            selectable={false}
                        />
                    </div>
                </div>
                <div className="sider-other-menu_container">
                    <OtherMenu collapsed={collapsed} />
                </div>
            </div>
        </Sider>
    );
};

export default SiderMenu;
