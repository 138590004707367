import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';

export enum DashboardControlOverlay {
    bookingOverview = 'bookingOverview',
    bookingList = 'bookingList',
    notification = 'notification',
    roomOccupancy = 'roomOccupancy',
    contact = 'contact',
}

export enum BookingStatusType {
    newBooking = 'new-booking',
    willCheckIn = 'will-check-in',
}
export enum BookingType {
    room = 'room-booking',
    facility = 'facility-booking',
}

export const StatusColor = {
    [RoomBookingItemBookingStatus.CANCELLED]: '#C53C3C',
    [RoomBookingItemBookingStatus.NOT_ARRIVED]: '#FAAD14',
    [RoomBookingItemBookingStatus.CHECKED_IN]: '#3A9554',
    [RoomBookingItemBookingStatus.CHECKED_OUT]: '#F6F6F6',
    [FacilityBookingStatus.STARTED]: '#52C41A',
    [FacilityBookingStatus.RESERVED]: '#ECB31D',
    [FacilityBookingStatus.FINISHED]: '#D9D9D9',
};

export enum AvailableRoomTypeColor {
    DARK_YELLOW_BROWNISH = '#87650B',
    RED = '#C53C3C',
    BLUE = '#1C3E86',
}

export enum BookingStatusColor {
    WILL_CHECK_IN = '#88bd97',
    CHECKED_IN = '#3A9554',
    WILL_CHECK_OUT = '#ECB31D',
    CHECKED_OUT = '#D8D9D9',
}

export const DEFAULT_LIMIT_FOR_LIST_DASHBOARD = 1000;

export const HEADER_HEIGHT = 55;
export const MIN_GRID_HEIGHT = 160;
export const MAX_GRID_HEIGHT = 550;
export const MIN_GRID_WIDTH = 1330;
export const SCROLL_BAR_HEIGHT = 8;

export enum DashboardColumns {
    ID = 'id',
    FULL_NAME = 'fullName',
    START_DATE_OF_STAY = 'startDateOfStay',
    START_TIME_DATE = 'startDatetime',
    ROOM = 'room',
    FACILITY = 'facility',
    TOTAL_AMOUNT = 'totalAmount',
    STATUS = 'status',
    BOOKING_STATUS = 'bookingStatus',
    CREATED_AT = 'createdAt',
    MEMO = 'memo',
    ROOM_CLEAN_STATUS = 'roomCleanStatus',
}
