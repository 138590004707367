import { Button } from 'antd';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '~common/constants';
import { SingleDatePicker } from '~components';
import { ReportTableCsvFileName, ReportTableId } from '~features/report/constants';
import { exportCSVReport } from '~features/report/helper';
import {
    getUnpaidDetailList,
    setSelectDate,
    setShowUnpaidDetailTable,
    unpaidDetailSelector,
} from '~features/report/reducers/unpaid-detail.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import './FilterForm.scss';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { unpaidDetailFilterSchema } from '~features/report/schema';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { showLoadingUnpaidDetailTable, showUnpaidDetailTable } =
        useAppSelector(unpaidDetailSelector);
    const [selectedDate, setSelectedDate] = useState<Dayjs>(customDayjs());
    const { control, setValue } = useForm({
        resolver: yupResolver(unpaidDetailFilterSchema),
    });
    useEffect(() => {
        dispatch(setShowUnpaidDetailTable(false));
        setValue(`day`, customDayjs());
    }, []);

    const changeDate = (date: Dayjs | null) => {
        if (date) {
            setSelectedDate(date);
        }
    };
    const onClickButtonFilter = async () => {
        await dispatch(
            getUnpaidDetailList({
                startTime: selectedDate?.startOf('day')?.fmYYYYMMDDHHmmss(),
                endTime: selectedDate?.endOf('day')?.fmYYYYMMDDHHmmss(),
            }),
        );
        dispatch(setSelectDate(parseDate(selectedDate).fmYYYYMMDD()));
        if (!showUnpaidDetailTable) {
            dispatch(setShowUnpaidDetailTable(true));
        }
    };

    const exportCSV = () => {
        exportCSVReport(
            ReportTableId.UNPAID_DETAIL,
            `${ReportTableCsvFileName.UNPAID_DETAIL}.csv`,
        );
    };

    return (
        <div className="report-unpaid-detail-filter-form-wrapper">
            <div className="filter-form-content">
                <span>{t('report.unpaidDetail.filterForm.dateLabel')}</span>
                <SingleDatePicker
                    value={selectedDate}
                    format={DateFormat.YYYY_MM_DD_HYPHEN}
                    allowClear={false}
                    onChange={changeDate}
                    placeholder={t('report.placeholder.selectDate')}
                    name={'day'}
                    control={control}
                />
                <Button
                    type="primary"
                    onClick={onClickButtonFilter}
                    loading={showLoadingUnpaidDetailTable}
                >
                    {t('report.unpaidDetail.filterForm.filterButton')}
                </Button>
            </div>
            {showUnpaidDetailTable && (
                <Button onClick={exportCSV}>{t('report.exportCSV')}</Button>
            )}
        </div>
    );
}

export default FilterForm;
