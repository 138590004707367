import { Button, Select } from 'antd';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat, DatePickerType } from '~common/constants';
import { SingleDatePicker } from '~components';
import { marketingChannelDropDownListSelector } from '~features/marketing-channel/marketing-channel.reducer';
import {
    OperationStatusChannel,
    ReportTableCsvFileName,
    ReportTableId,
} from '~features/report/constants';
import {
    operationStatusStateSelector,
    setIsShowTable,
    setOperationStatusGetListQuery,
} from '~features/report/reducers/report-operation-status.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import './FilterForm.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { operationStatusFilterSchema } from '~features/report/schema';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '~queries/queries';
import { reportingServiceV2 } from '~features/report/reportV2.service';
import dayjs from '~plugins/dayjs';
import { exportCSVReport } from '~features/report/helper';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedDate, setSelectedDate] = useState<string>(customDayjs().fmYYYYMMDD());
    const [selectedMarketingChannelId, setSelectedMarketingChannelId] = useState<string>(
        OperationStatusChannel.ALL,
    );
    const { operationStatusGetListQuery, isShowTable } = useAppSelector(
        operationStatusStateSelector,
    );
    const marketingChannelDropDownList = useAppSelector(
        marketingChannelDropDownListSelector,
    );

    const { refetch, isLoading } = useQuery({
        queryKey: [CacheKeys.getOperationStatusReportKey],
        queryFn: async () => {
            const startDate = dayjs(selectedDate)
                .startOf('month')
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss');
            const endDate = dayjs(selectedDate)
                .endOf('month')
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss');
            const datePeriod = [startDate, endDate];
            const data = await reportingServiceV2.getOperationStatusReport({
                datePeriod,
                marketingChannelId:
                    selectedMarketingChannelId !== OperationStatusChannel.ALL
                        ? selectedMarketingChannelId
                        : undefined,
            });

            if (data.success) {
                return data.data;
            }

            throw Error(data.message);
        },
        enabled: false,
    });

    const { control, setValue } = useForm({
        resolver: yupResolver(operationStatusFilterSchema),
    });

    useEffect(() => {
        setValue(`day`, customDayjs());
    }, []);

    const marketingChannelOptions = useMemo(() => {
        return marketingChannelDropDownList.map((channel) => {
            return { value: channel.id, label: channel.name };
        });
    }, [marketingChannelDropDownList]);

    const changeDate = (date: Dayjs | null, dateString: string) => {
        setSelectedDate(dateString);
    };

    const handleChange = (value: string) => {
        setSelectedMarketingChannelId(value);
    };

    const clickDisplayButton = async () => {
        dispatch(setIsShowTable(true));
        await refetch();
    };

    const exportCSV = () => {
        exportCSVReport(
            ReportTableId.OPERATION_STATUS,
            `${ReportTableCsvFileName.OPERATION_STATUS}.csv`,
        );
    };

    return (
        <div className="filter-operation-status-wrapper">
            <div className="filter-left-block">
                <span className="month-label">
                    {t('report.operationStatus.filterForm.dateLabel')}
                </span>

                <SingleDatePicker
                    value={parseDate(selectedDate)}
                    format={DateFormat.YYYY_MM_HYPHEN}
                    allowClear={false}
                    onChange={changeDate}
                    picker={DatePickerType.MONTH}
                    placeholder={t('report.placeholder.selectMonth')}
                    name={'day'}
                    control={control}
                />
                <span className="channel-label">
                    {t('report.operationStatus.filterForm.channelLabel')}
                </span>
                <Select
                    defaultValue={OperationStatusChannel.ALL}
                    value={selectedMarketingChannelId}
                    onChange={handleChange}
                    className="marketing-channel-select"
                    options={[
                        {
                            value: OperationStatusChannel.ALL,
                            label: t('report.operationStatus.filterForm.channel.all'),
                        },
                        ...marketingChannelOptions,
                    ]}
                />
                <Button
                    type="primary"
                    className="display-button"
                    onClick={clickDisplayButton}
                    loading={isLoading}
                >
                    {t('report.operationStatus.filterForm.displayButton')}
                </Button>
            </div>
            <div className="filter-right-block">
                {isShowTable && (
                    <Button onClick={exportCSV}>{t('report.exportCSV')}</Button>
                )}
                <span>{t('report.operationStatus.filterForm.noteInformation')}</span>
            </div>
        </div>
    );
}

export default FilterForm;
