const roomCleaningList = {
    title: 'Room management',
    breadcrumbs: {
        home: 'Home',
        roomCleaning: 'Room management',
    },
    tabs: {
        roomManagement: 'Room indicator',
        cleaningList: 'Cleaning list',
    },
    cleanAllRoomBtn: 'Clean all rooms',
    cleanSelectedRoom: 'Clean selected rooms',
    todayBtn: 'Today',
    roomCleaningListColumn: {
        roomName: 'Room name',
        roomType: 'Room type',
        cleaningStatus: 'Cleaning status',
        memo: 'Memo',
        stayDate: 'C/I - C/O Date',
        occupationStatus: 'Status',
    },
    cleanStatus: {
        notCleaned: 'Uncleaned',
        cleaned: 'Cleaned',
    },
    memoInput: 'Enter memo',
    confirm: {
        titleCleanSelectedRoom: 'Do you want to clean selected room?',
        titleCleanAllRoom: 'Do you want to clean all rooms?',
        titleSwitchStatus: 'Do you want to change status?',
        titleUpdateMemo: 'Do you want to update memo?',
        titleCleanSelectedMemo: 'Do you want to clean selected memo?',
        titleCleanAllMemo: 'Do you want to clean all memo?',
        titleDirtySelectedRoom: 'Do you want to dirty selected room?',
        titleDirtyAllRoom: 'Do you want to dirty all room?',
        cancelTitle: 'Do you want to cancel?',
        buttonCancelText: 'No',
        buttonDeleteText: 'Yes',
    },
    updateSuccessMessage: 'Room cleanings have been updated succcessful!',
    cleaningOption: {
        dirtyAllRoom: 'dirty all room',
        clearAllRoomMemo: 'clean all room memo',
        dirtyRoomSelection: 'dirty selection',
        clearRoomMemoSelection: 'clean memo selection',
    },
    roomStatus: {
        occupied: 'Occupied',
        empty: 'Empty',
        checked_out: 'Checked out',
    },
    columns: {
        roomName: 'Room',
        status: 'Status',
        cleaningStatus: 'Cleaning status',
    },
    filter: {
        all: 'All',
        notCleaned: 'Uncleaned',
        noData: 'No data',
    },
    action: {
        addMemo: 'Add memo',
        editMemo: 'Edit memo',
        delete: 'Delete memo',
        save: 'Save',
        cancel: 'Cancel',
        loadMore: 'Load more',
    },
};

export default roomCleaningList;
