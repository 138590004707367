import { Form } from 'antd';
import React, { useMemo } from 'react';
import { Controller } from '~plugins/hook-form';
import './RangePicker.scss';
import { DatePicker } from '../DatePicker/DatePicker';
import { Dayjs } from '~plugins/dayjs';
import { RangePickerDateProps } from 'antd/es/date-picker/generatePicker';
import { parseErrorMessage } from '~common/commonFunctions';
import classNames from 'classnames';
import { useAppSelector } from '~hooks';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import { ErrorMessageType, SupportLanguage } from '~common/constants';

export interface DatePickerProps extends Omit<RangePickerDateProps<Dayjs>, 'picker'> {
    picker?: string;
}

type IRangePicker = DatePickerProps & {
    name: string;
    label: string | React.ReactNode;
    control: any;
    required?: boolean;
    hideHelpText?: boolean;
};

export function RangePicker(props: IRangePicker) {
    const {
        required,
        control,
        name,
        defaultValue,
        label,
        hideHelpText,
        onChange,
        ...rest
    } = props;
    const currentLang = useAppSelector(currentLanguageSelector);

    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);
    return (
        <div
            className={classNames('range-picker-wrapper', {
                'hide-help-text': hideHelpText,
            })}
        >
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    const { message = '' } = fieldState.error || {};
                    const status = message
                        ? fieldState.error?.type === ErrorMessageType.WARNING
                            ? 'warning'
                            : 'error'
                        : '';
                    return (
                        <Form.Item
                            required={required}
                            label={label}
                            validateStatus={status}
                            help={parseErrorMessage(message)}
                        >
                            <DatePicker.RangePicker
                                {...field}
                                {...(rest as any)}
                                onChange={(...agrs) => {
                                    if (onChange) {
                                        onChange(...agrs);
                                    }
                                    field.onChange(...agrs);
                                }}
                                locale={DatePickerLanguage}
                            />
                        </Form.Item>
                    );
                }}
            />
        </div>
    );
}

RangePicker.defaultProps = {
    defaultValue: null,
};
