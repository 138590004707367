import { OrderDirection } from '~common/constants';
import './ColumnSorter.scss';

interface IProp {
    orderDirection?: OrderDirection | null;
    isSorted?: boolean;
}

export function ColumnSorter(props: IProp) {
    return (
        <span className="ant-table-column-sorter ant-table-column-sorter-full column-sorter-wrapper">
            <span className="ant-table-column-sorter-inner">
                <span
                    role="presentation"
                    aria-label="caret-up"
                    className="anticon anticon-caret-up ant-table-column-sorter-up"
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="caret-up"
                        width="1em"
                        height="1em"
                        fill={
                            props.isSorted && props.orderDirection === OrderDirection.ASC
                                ? '#00a0c3'
                                : 'currentColor'
                        }
                        aria-hidden="true"
                    >
                        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                    </svg>
                </span>
                <span
                    role="presentation"
                    aria-label="caret-down"
                    className="anticon anticon-caret-down ant-table-column-sorter-down"
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="caret-down"
                        width="1em"
                        height="1em"
                        fill={
                            props.isSorted && props.orderDirection === OrderDirection.DESC
                                ? '#00a0c3'
                                : 'currentColor'
                        }
                        aria-hidden="true"
                    >
                        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                    </svg>
                </span>
            </span>
        </span>
    );
}
