import { Button, Col, Form, notification, Row, Switch } from 'antd';
import { useForm } from '~plugins/hook-form';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
    InputPrice,
    InputText,
    RightDrawerLayout,
    SelectSaleItem,
    TimeRangePickerCustom,
} from '~components';
import {
    createFacilityType,
    fetchFacilityTypeList,
    formBusySelector,
    setIsShowCreateForm,
    showCreateFormSelector,
} from '~features/facility-type/facility-type.reducer';
import {
    getSaleItemListForDropdown,
    saleItemListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import './CreateFacilityTypeForm.scss';
import { ErrorMessageType } from '~common/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFacilityTypeSchema } from '~features/facility-type/schema';
import { useCallback, useEffect, useState } from 'react';
import { IFacilityTypeCreate, ISalesItem } from '~features/facility-type/interfaces';
import { InputNumberGroup } from './InputNumberGroup/FacilityTypePrice';
import { showConfirm } from '~common/notification';
import compact from 'lodash/compact';
import { cloneDeep } from 'lodash';
import { getFacilityTypeFormId } from '~features/facility-type/helper';
import { BUSINESS_TIME_GAP } from '~features/facility-type/constants';
import { timeToMinutes } from '~common/commonFunctions';
import { useEscape } from '~common/useHooks';

function CreateFacilityTypeForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [toggleFlatRate, setToggleFlatRate] = useState(true);
    const formBusy = useAppSelector(formBusySelector);
    const isShowForm = useAppSelector(showCreateFormSelector);
    const saleItemOptions = useAppSelector(saleItemListDropdownSelector);
    const [salesItemList, setSalesItemList] = useState<ISalesItem[]>([
        {
            feId: `0_${Date.now().toString()}`,
        },
    ]);

    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(createFacilityTypeSchema),
    });

    const closeDialog = (reload?: boolean) => {
        dispatch(setIsShowCreateForm(false));
        reset({ timeUnit: { hour: null, minute: null } });
        setToggleFlatRate(true);
        setSalesItemList([
            {
                feId: `0_${Date.now().toString()}`,
            },
        ]);
        if (reload) {
            dispatch(fetchFacilityTypeList());
        }
    };

    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
    }, []);

    const _createFacilityType = useCallback(async (formData: IFacilityTypeCreate) => {
        const response = await dispatch(createFacilityType(formData));

        if (createFacilityType.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('facilityType.form.message.create.success'),
                });
                closeDialog(true);
                return;
            }
            notification.error({
                message: t('facilityType.form.message.create.error'),
                description: response.payload?.errors?.[0]?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                setError(
                    error.key,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
    }, []);

    const onSubmit = () => {
        handleSubmit((values) => {
            let duration =
                (Number(values?.timeUnit?.hour) || 0) * 3600 +
                (Number(values?.timeUnit?.minute) || 0) * 60;

            if (!toggleFlatRate && duration === 0) {
                setError(
                    `timeUnit`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('facilityType.form.message.durationError'),
                    },
                    { shouldFocus: true },
                );
                return;
            }

            const { name, abbreviation, businessTimes, price } = values;
            const [startTime, endTime] = businessTimes || [];
            if (
                startTime &&
                endTime &&
                timeToMinutes(endTime) - timeToMinutes(startTime) < BUSINESS_TIME_GAP
            ) {
                setError(
                    `businessTimes`,
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('facilityType.form.message.businessTimesError'),
                    },
                    { shouldFocus: true },
                );
                return;
            }
            const saleItemIds = compact(
                salesItemList.map((saleItem) => saleItem.salesId),
            );
            const facilityTypeBody: IFacilityTypeCreate = {
                name,
                abbreviation,
                price: Number(price),
                duration: toggleFlatRate || duration === 0 ? null : duration,
                businessStartTime: startTime,
                businessEndTime: endTime,
                saleItemIds: saleItemIds,
            };
            _createFacilityType(facilityTypeBody);
        })();
    };

    const addSalesItem = () => {
        setSalesItemList([
            ...salesItemList,
            {
                salesId: 0,
                feId: Date.now().toString(),
            },
        ]);
    };

    const onRemoveSalesItem = (feId?: string) => {
        if (!feId) return;
        const items = salesItemList.filter((item) => {
            return item.feId !== feId;
        });
        setSalesItemList(items);
    };

    const changeSalesItem = (value: ISalesItem, index: number) => {
        const cloneSaleItemList = cloneDeep(salesItemList);
        cloneSaleItemList[index].salesId = value.id;
        setSalesItemList(cloneDeep(cloneSaleItemList));
    };

    const changeFlatRate = (checked: boolean) => {
        setToggleFlatRate(checked);
    };

    const _showConfirm = () => {
        showConfirm({
            title: t('facility.updateForm.form.confirm.cancelTitle'),
            cancelText: t('common.cancel'),
            okText: t('common.ok'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    return (
        <RightDrawerLayout
            open={isShowForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={t('facilityType.form.title')}
            cancelText={t('common.buttonCancelText')}
            submitText={t('facilityType.form.buttons.submit')}
            className="create-facility-type-drawer"
            busy={formBusy}
            loading={formBusy}
            submitId={getFacilityTypeFormId('btn-submit')}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                scrollToFirstError
                className="create-facility-form-wrapper"
            >
                <Row gutter={12}>
                    <Col span={24}>
                        <InputText
                            label={t('facilityType.form.name.label')}
                            placeholder={t('facilityType.form.name.placeholder')}
                            required={true}
                            name="name"
                            control={control}
                            id={getFacilityTypeFormId('name')}
                        />
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <InputText
                            label={t('facilityType.form.abbreviation.label')}
                            placeholder={t('facilityType.form.abbreviation.placeholder')}
                            name="abbreviation"
                            required={true}
                            control={control}
                            id={getFacilityTypeFormId('abbreviation')}
                        />
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <TimeRangePickerCustom
                            labelStart={t('facilityType.form.businessTime.label')}
                            placeholder={[
                                t('facilityType.form.businessTime.placeholder.start'),
                                t('facilityType.form.businessTime.placeholder.end'),
                            ]}
                            name="businessTimes"
                            required={true}
                            control={control}
                        />
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={2}>
                        <Form.Item
                            label={t('facilityType.form.switchFixedAmount.label')}
                            className="switch-amount"
                            name={'toggleFlatRate'}
                        >
                            <Switch
                                checked={toggleFlatRate}
                                id={getFacilityTypeFormId('toggleFlatRate')}
                                onChange={changeFlatRate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={toggleFlatRate ? 14 : 11}>
                        <InputPrice
                            label={
                                toggleFlatRate
                                    ? t('facilityType.form.flatRate.label')
                                    : t('facilityType.form.basicChargeHour.label')
                            }
                            placeholder={t('facilityType.form.flatRate.placeholder')}
                            name="price"
                            id={getFacilityTypeFormId('price')}
                            addonAfter={t('facilityType.form.unitPrice')}
                            required={true}
                            control={control}
                        />
                    </Col>
                    {!toggleFlatRate && (
                        <Col span={11}>
                            <InputNumberGroup
                                label={t('facilityType.form.timeUnit')}
                                placeholder={'0'}
                                name="timeUnit"
                                required={true}
                                addonAfter={[
                                    t('facilityType.form.hourUnit'),
                                    t('facilityType.form.minutesUnit'),
                                ]}
                                control={control}
                                id={getFacilityTypeFormId('timeUnit')}
                            />
                        </Col>
                    )}
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        {salesItemList.map((item, index) => {
                            return (
                                <div key={item.feId} className="sale-item-row">
                                    <div className="sale-item-select">
                                        <SelectSaleItem
                                            label={
                                                index === 0
                                                    ? t(
                                                          'facilityType.form.salesItem.label',
                                                      )
                                                    : ''
                                            }
                                            placeholder={t(
                                                'facilityType.form.salesItem.placeholder',
                                            )}
                                            onChange={(value) =>
                                                changeSalesItem(value, index)
                                            }
                                            name={`salesItems.${index}`}
                                            id={getFacilityTypeFormId(
                                                `salesItems.${index}`,
                                            )}
                                            control={control}
                                            showArrow={false}
                                            showSearch={true}
                                            optionLabelProp="label"
                                            filterOption={(input, option) => {
                                                return (
                                                    (option!.label ||
                                                        '') as unknown as string
                                                )
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase());
                                            }}
                                            selectedValue={
                                                salesItemList?.[index]?.salesId ||
                                                undefined
                                            }
                                            selectedSaleItemIds={compact(
                                                salesItemList.map(
                                                    (saleItem) => saleItem.salesId,
                                                ),
                                            )}
                                        />
                                    </div>
                                    <div className="delete-sale-item-button">
                                        <Form.Item
                                            label={index === 0 ? ' ' : null}
                                            className="btn-delete"
                                        >
                                            <DeleteOutlined
                                                id={getFacilityTypeFormId(
                                                    `onRemoveSalesItem.${index}`,
                                                )}
                                                onClick={() => {
                                                    onRemoveSalesItem(item.feId);
                                                }}
                                                className="delete-sale-item-icon"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            );
                        })}
                        <Button
                            type="dashed"
                            block
                            className="addition"
                            disabled={
                                salesItemList.length >= 99 ||
                                saleItemOptions.length <= salesItemList.length
                            }
                            onClick={addSalesItem}
                            id={getFacilityTypeFormId(`btn-add-more`)}
                        >
                            <PlusCircleOutlined />
                            {t('facilityType.form.buttons.addSalesItem')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default CreateFacilityTypeForm;
