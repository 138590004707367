import { Button, Radio, RadioChangeEvent } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleDatePicker } from '~components';
import { AggregationMethod } from '~features/report/constants';
import {
    getPaymentSummary,
    setExportCsv,
    setSelectedAggregationMethod,
    setSelectedDate,
    setShowPaymentSummaryTable,
    showPaymentSummaryTableSelector,
} from '~features/report/reducers/payment-summary.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import './FilterForm.scss';
import { DatePickerType } from '~common/constants';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { paymentSummaryFilterSchema } from '~features/report/schema';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showPaymentSummaryTable = useAppSelector(showPaymentSummaryTableSelector);

    const [aggregationMethod, setAggregationMethod] = useState(AggregationMethod.DAILY);
    const [date, setDate] = useState<string>(customDayjs().fmYYYYMMDD());

    const { control, setValue } = useForm({
        resolver: yupResolver(paymentSummaryFilterSchema),
    });

    useEffect(() => {
        dispatch(setShowPaymentSummaryTable(false));
        setAggregationMethod(AggregationMethod.DAILY);
        setValue(`day`, customDayjs());
    }, []);

    const changeDate = (date: Dayjs | null, dateString: string) => {
        setDate(dateString);
    };

    const onChangeAggregationMethod = (e: RadioChangeEvent) => {
        setAggregationMethod(e.target.value);
    };

    const fetchData = (date: string) => {
        let startDate = '';
        let endDate = '';
        if (aggregationMethod === AggregationMethod.MONTH) {
            startDate = parseDate(date).startOf('month')?.fmYYYYMMDDHHmmss();
            endDate = parseDate(date).endOf('month')?.fmYYYYMMDDHHmmss();
        } else {
            startDate = parseDate(date).startOf('day')?.fmYYYYMMDDHHmmss();
            endDate = parseDate(date).endOf('day')?.fmYYYYMMDDHHmmss();
        }

        dispatch(
            setSelectedDate([parseDate(date)?.fmYYYYMMDD(), parseDate(date)?.fmYYYYMM()]),
        );
        dispatch(setSelectedAggregationMethod(aggregationMethod));
        dispatch(
            getPaymentSummary({
                datePeriod: [startDate, endDate],
            }),
        );
    };

    const onClickButtonFilter = async () => {
        fetchData(date);
    };
    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };
    return (
        <div className="report-payment-summary-filter-form-wrapper">
            <div className="left-filter-form">
                <span>
                    {t('report.paymentSummary.filterForm.aggregationMethod.label')} :
                </span>
                <Radio.Group
                    value={aggregationMethod}
                    onChange={onChangeAggregationMethod}
                >
                    {Object.values(AggregationMethod).map((value) => {
                        return (
                            <Radio.Button value={value} key={value}>
                                {t(
                                    `report.paymentSummary.filterForm.aggregationMethod.${value}`,
                                )}
                            </Radio.Button>
                        );
                    })}
                </Radio.Group>
                <span className="label">
                    {aggregationMethod === AggregationMethod.DAILY
                        ? t('report.paymentSummary.filterForm.dateLabel')
                        : t('report.paymentSummary.filterForm.monthLabel')}
                    :
                </span>
                <SingleDatePicker
                    value={parseDate(date)}
                    allowClear={false}
                    picker={
                        aggregationMethod === AggregationMethod.DAILY
                            ? DatePickerType.DATE
                            : DatePickerType.MONTH
                    }
                    onChange={changeDate}
                    placeholder={
                        aggregationMethod === AggregationMethod.DAILY
                            ? t('report.placeholder.selectDate')
                            : t('report.placeholder.selectMonth')
                    }
                    name={'day'}
                    control={control}
                />
                <Button
                    type="primary"
                    className="display-button"
                    onClick={onClickButtonFilter}
                >
                    {t('report.paymentSummary.filterForm.filterButton')}
                </Button>
            </div>
            {showPaymentSummaryTable && (
                <Button onClick={exportCSV} className="export-csv-btn">
                    {t('report.exportCSV')}
                </Button>
            )}
        </div>
    );
}

export default FilterForm;
