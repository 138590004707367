import { Dayjs } from '~plugins/dayjs';
import * as React from 'react';
import { DatePicker } from '../DatePicker/DatePicker';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import { RangePickerDateProps } from 'antd/es/date-picker/generatePicker';

export interface TimePickerProps extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}
export interface TimeRangePickerProps
    extends Omit<RangePickerDateProps<Dayjs>, 'picker'> {}

export const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
    return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

TimePicker.displayName = 'TimePicker';
