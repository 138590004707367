import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Popconfirm, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';

import './ChangeStatusDropDown.scss';

type Prop = {
    onChangeStatus: (status: string) => void;
    type?: 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed' | undefined;
};

function ChangeStatusDropDown(props: Prop) {
    const { onChangeStatus: changeBookingStatusConfirm, type = 'default' } = props;
    const { t } = useTranslation();

    const [selectedBookingStatus, setSelectedBookingStatus] = useState<string>('');
    const [openChangeStatusPopConfirm, setOpenChangeStatusPopConfirm] = useState(false);
    const [statusOptions, setStatusOptions] = useState<ItemType[]>([]);

    const handleChangStatus = (key: string) => {
        setOpenChangeStatusPopConfirm(true);
        setSelectedBookingStatus(key);
    };

    useEffect(() => {
        let statusMenus = Object.values(RoomBookingItemBookingStatus).filter((status) => {
            return status !== RoomBookingItemBookingStatus.ALL;
        });
        const items = statusMenus.map((status) => {
            return {
                label: t(`roomBooking.page.bookingStatus.${status}`),
                key: status.toString(),
                onClick: () => {
                    handleChangStatus(status.toString());
                },
            };
        });
        setStatusOptions(items);
    }, [t]);

    const handleVisibleChange = (isVisible: boolean) => {
        if (openChangeStatusPopConfirm) {
            setOpenChangeStatusPopConfirm(isVisible);
        } else {
            setOpenChangeStatusPopConfirm(false);
        }
    };

    const changeBookingStatusCancel = () => {
        setOpenChangeStatusPopConfirm(false);
    };

    return (
        <Popconfirm
            title={
                <div className="change-booking-status-title">
                    <span className="title-status-pop-confirm">
                        {t('roomBooking.list.statusPopConfirm.title')}
                    </span>
                    <br />
                    {t('roomBooking.list.statusPopConfirm.content', {
                        status: t(
                            `roomBooking.page.bookingStatus.${selectedBookingStatus}`,
                        ),
                    })}
                </div>
            }
            open={openChangeStatusPopConfirm}
            placement="bottomRight"
            overlayClassName="change-booking-status-popconfirm"
            okText={t('roomBooking.list.statusPopConfirm.okText')}
            cancelText={t('roomBooking.list.statusPopConfirm.cancelText')}
            onOpenChange={handleVisibleChange}
            onConfirm={() => changeBookingStatusConfirm(selectedBookingStatus)}
            onCancel={changeBookingStatusCancel}
        >
            <Dropdown menu={{ items: statusOptions }} className="status-dropdown">
                <Button type={type}>
                    <Space>
                        {t('roomBooking.list.dropdown.title')}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </Popconfirm>
    );
}

export default ChangeStatusDropDown;
