import { Pagination, Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '~common/commonFunctions';
import {
    AntdOrderDirection,
    DEFAULT_FIRST_PAGE,
    OrderDirection,
    SupportLanguage,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import {
    roomClosingStateSelector,
    setRoomClosingListQuery,
    totalRoomClosingPageSelector,
} from '~features/closing-booking/reducers/closing-booking.reducer';
import {
    RoomBookingColumn,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import { convertToRoomBookingTableData } from '~features/room-booking/helper';
import { IRoomBookingTable } from '~features/room-booking/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './RoomClosingList.scss';
import classNames from 'classnames';

function RoomClosingList() {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        showLoading,
        closingBookingListQuery: roomClosingListQuery,
        totalRoomClosings,
        roomClosingList,
    } = useAppSelector(roomClosingStateSelector);

    const pageCount = useAppSelector(totalRoomClosingPageSelector);

    const roomClosingTableData: IRoomBookingTable[] = useMemo(() => {
        return convertToRoomBookingTableData(roomClosingList);
    }, [roomClosingList]);

    const isJaLanguage = useMemo(() => {
        return i18n.language === SupportLanguage.JA;
    }, [i18n.language]);

    const onChange: TableProps<IRoomBookingTable>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;

        dispatch(
            setRoomClosingListQuery({
                ...roomClosingListQuery,
                page: DEFAULT_FIRST_PAGE,
                orderBy: order ? _field || RoomBookingColumn.ID : RoomBookingColumn.ID,
                orderDirection:
                    order === AntdOrderDirection.ASC
                        ? OrderDirection.ASC
                        : OrderDirection.DESC,
            }),
        );
    };

    const onChangePage = (page: number) => {
        dispatch(setRoomClosingListQuery({ ...roomClosingListQuery, page }));
    };

    const roomClosingColumns: ColumnsType<IRoomBookingTable> = [
        {
            title: t('roomBooking.list.column.id'),
            onCell: () => {
                return {
                    style: { color: '#1C3E86' },
                };
            },
            render: (room: IRoomBookingTable) => {
                return <span>{room.autoGeneratedCode}</span>;
            },
            width: '160px',
            key: RoomBookingColumn.AUTO_GENERATED_CODE,
            sorter: true,
            fixed: 'left',
            ellipsis: true,
        },
        {
            title: t('roomBooking.list.column.guestYomigana'),
            render: (room: IRoomBookingTable) => {
                return (
                    <div className="text-truncate">
                        {room?.reserverGuest?.yomigana ||
                            room?.representativeGuest?.yomigana ||
                            ''}
                    </div>
                );
            },
            key: RoomBookingColumn.YOMIGANA,
            fixed: 'left',
            sorter: true,
        },
        {
            title: t('roomBooking.list.column.guestFullName'),
            render: (room: IRoomBookingTable) => {
                return (
                    <div className="text-truncate">
                        {room?.reserverGuest?.fullName ||
                            room.representativeGuest?.fullName ||
                            ''}
                    </div>
                );
            },
            key: RoomBookingColumn.GUEST_NAME,
            sorter: true,
        },
        {
            title: t('roomBooking.list.column.category'),
            render: (room: IRoomBookingTable) => {
                return (
                    <div className="text-cuts-dots">
                        {t(`roomBooking.list.bookingCategory.${room.category}`) || ''}
                    </div>
                );
            },
            key: RoomBookingColumn.CATEGORY,
            sorter: true,
        },
        {
            title: t('roomBooking.list.column.status'),
            render: (room: IRoomBookingTable) => {
                return (
                    <div>
                        {room.bookingStatus ? (
                            <span>
                                {room.bookingStatus ===
                                RoomBookingItemBookingStatus.NOT_ARRIVED
                                    ? t(
                                          'closingBooking.page.closingStatus.not_checked_in',
                                      )
                                    : t(
                                          'closingBooking.page.closingStatus.not_checked_out',
                                      )}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
            sorter: true,
        },
        {
            title: t('roomBooking.list.column.roomName'),
            render: (roomBooking: IRoomBookingTable) => {
                return (
                    <div className="text-truncate">{roomBooking.room?.name || ''}</div>
                );
            },
            ellipsis: true,
        },
        {
            title: () => {
                return (
                    <span className={classNames({ 'title-time': isJaLanguage })}>
                        {t('roomBooking.list.column.checkInTime')}
                    </span>
                );
            },
            render: (room: IRoomBookingTable) => {
                return (
                    <span>
                        {room?.startDateOfStay
                            ? parseDate(room?.startDateOfStay)?.fmYYYYMMDD('-')
                            : ''}
                    </span>
                );
            },
            sorter: true,
        },
        {
            title: () => {
                return (
                    <span className={classNames({ 'title-time': isJaLanguage })}>
                        {t('roomBooking.list.column.endDateOfStay')}
                    </span>
                );
            },
            render: (room: IRoomBookingTable) => {
                return (
                    <span>
                        {room.endDateOfStay
                            ? parseDate(room.endDateOfStay)?.fmYYYYMMDD('-')
                            : ''}
                    </span>
                );
            },
            sorter: true,
        },

        {
            title: t('roomBooking.list.column.payment'),
            dataIndex: RoomBookingColumn.PAYMENT,
            sorter: true,
        },
        {
            title: t('roomBooking.list.column.paymentNumber'),
            render: (room: IRoomBookingTable) => {
                return (
                    room.autoGeneratedCode && (
                        <div>￥{room.price ? `${formatMoney(room.price)}` : 0}</div>
                    )
                );
            },
            sorter: true,
        },
    ];

    const showRoomBookingDetail = (room: IRoomBookingTable) => {
        if (room.roomBookingId) {
            navigate(`/room-booking/${room.roomBookingId}/detail`);
        }
    };

    return (
        <div className="room-closing-list">
            <Table
                columns={roomClosingColumns}
                dataSource={roomClosingTableData}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                rowClassName="row-expandable"
                loading={showLoading}
                scroll={{ x: 1200 }}
                className="table-scrollbar"
                onRow={(record) => {
                    return {
                        // click row
                        onClick: () => {
                            showRoomBookingDetail(record);
                        },
                    };
                }}
            />

            {pageCount > 1 && (
                <Pagination
                    className="closing-booking-pagination"
                    defaultCurrent={roomClosingListQuery.page}
                    current={roomClosingListQuery.page}
                    total={totalRoomClosings}
                    pageSize={roomClosingListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default RoomClosingList;
