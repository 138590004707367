import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Button, Table, Tooltip, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from 'antd/lib/table/Column';
import { InputNumber, Checkbox, SelectSaleItem, InputPrice } from '~components';
import { IPlanSaleItem } from '~features/plan/interfaces';
import { PlanSaleItemListColumn } from '~features/plan/constants';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import './SaleItemsForm.scss';
import {
    getSaleItemListForDropdown,
    saleItemListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { getFormPlanId, currencyFormat } from '~features/plan/helper';
import compact from 'lodash/compact';

type Props = {
    onAddSaleItem: () => void;
    control: any;
    planSaleItemList: IPlanSaleItem[];
    onChangeSaleItem: (
        saleItem: ISaleItemDropdown,
        index: number,
        isDisable?: boolean,
    ) => void;
    onClickIsForOnePeople: (value: boolean, index: number) => void;
    onClickIsFirstDayOnly: (value: boolean, index: number) => void;
    onChangeSaleItemPrice: (value: number, index: number) => void;
    onChangeSaleQuantity: (value: number, index: number) => void;
    onDeleteSaleItem: (planSaleItem: IPlanSaleItem, index: number) => void;
};

function SaleItemsForm({
    control,
    planSaleItemList,
    onAddSaleItem,
    onChangeSaleItem,
    onClickIsForOnePeople,
    onClickIsFirstDayOnly,
    onChangeSaleItemPrice,
    onChangeSaleQuantity,
    onDeleteSaleItem,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const saleItemOptions = useAppSelector(saleItemListDropdownSelector);
    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
    }, []);
    return (
        <div className="plan-sale-item-form-list-wrapper">
            <div className="plan-sale-item-list">
                <div className="form-title">
                    <span className="mr-8">{t('plan.create.saleItemsForm.title')}</span>
                </div>
                <span>{t('plan.create.saleItemsForm.describeItemPlan')}</span>
                <Table
                    className="plan-sale-item-table-list"
                    dataSource={planSaleItemList}
                    pagination={false}
                    locale={{
                        emptyText: <div></div>,
                    }}
                    rowKey="feId"
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={onAddSaleItem}
                            className="button-add"
                            disabled={planSaleItemList.length >= saleItemOptions.length}
                            id={getFormPlanId('btn-add')}
                        >
                            <PlusCircleOutlined className="right-register-form-icon" />
                            <span>{t('plan.create.saleItemsForm.addSaleItem')}</span>
                        </Button>
                    )}
                >
                    <Column
                        align="left"
                        width={50}
                        title={`${t('plan.create.saleItemsForm.columns.id')}`}
                        dataIndex=""
                        key={PlanSaleItemListColumn.ID}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            return <div>{index + 1}</div>;
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.name')}`}
                        width={232}
                        ellipsis={true}
                        dataIndex=""
                        key={PlanSaleItemListColumn.NAME}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            const isDisable =
                                planSaleItemList?.[index]?.isForOnePeople || false;
                            return (
                                <SelectSaleItem
                                    label=""
                                    placeholder={t(
                                        'plan.create.saleItemsForm.columns.nameInput',
                                    )}
                                    name={`planSaleItems.${index}.saleItemId`}
                                    id={getFormPlanId(
                                        `planSaleItems.${index}.saleItemId`,
                                    )}
                                    control={control}
                                    required
                                    dropdownStyle={{
                                        minWidth: '240px',
                                    }}
                                    showArrow={false}
                                    showSearch={true}
                                    optionLabelProp="label"
                                    filterOption={(input, option) => {
                                        return (
                                            (option!.label || '') as unknown as string
                                        )
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                    onChange={(value) => {
                                        if (onChangeSaleItem) {
                                            onChangeSaleItem(value, index, isDisable);
                                        }
                                    }}
                                    selectedValue={
                                        planSaleItemList?.[index]?.saleItemId || undefined
                                    }
                                    selectedSaleItemIds={compact(
                                        planSaleItemList.map(
                                            (planSaleItem) => planSaleItem.saleItemId,
                                        ),
                                    )}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.price')}`}
                        width={232}
                        dataIndex=""
                        key={PlanSaleItemListColumn.PRICE_ITEM}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            return (
                                <div className="sale-item-input-wrapper">
                                    <InputPrice
                                        label=""
                                        placeholder={t(
                                            'plan.create.saleItemsForm.columns.priceInput',
                                        )}
                                        name={`planSaleItems.${index}.price`}
                                        id={getFormPlanId(`planSaleItems.${index}.price`)}
                                        control={control}
                                        onChange={(e) => {
                                            onChangeSaleItemPrice(
                                                Number(e.target.value),
                                                index,
                                            );
                                        }}
                                        addonAfter={
                                            <Tag
                                                className="sale-item-pricing"
                                                color="default"
                                            >
                                                {t('plan.create.yen')}
                                            </Tag>
                                        }
                                    />
                                </div>
                            );
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.quantity')}`}
                        width={140}
                        dataIndex=""
                        key={PlanSaleItemListColumn.NUMBER_OF_PEOPLE}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            const isDisable =
                                planSaleItemList?.[index]?.isForOnePeople || false;
                            return (
                                <InputNumber
                                    label=""
                                    placeholder={t(
                                        'plan.create.saleItemsForm.columns.quantity',
                                    )}
                                    isShowIconArrow={!isDisable}
                                    name={`planSaleItems.${index}.quantity`}
                                    id={getFormPlanId(`planSaleItems.${index}.quantity`)}
                                    control={control}
                                    onChange={(e) => {
                                        onChangeSaleQuantity(
                                            Number(e.target.value),
                                            index,
                                        );
                                    }}
                                    disabled={isDisable}
                                />
                            );
                        }}
                    />
                    <Column
                        title={
                            <Tooltip
                                title={t(
                                    'plan.create.saleItemsForm.columns.isForOnePeopleHover',
                                )}
                                color="#000000BF"
                            >
                                <span className="title-hover">
                                    {`${t(
                                        'plan.create.saleItemsForm.columns.isForOnePeople',
                                    )}`}
                                    <QuestionCircleOutlined />
                                </span>
                            </Tooltip>
                        }
                        width={120}
                        dataIndex=""
                        align="center"
                        key={PlanSaleItemListColumn.IS_FOR_ONE_PEOPLE}
                        render={(planSaleItem: IPlanSaleItem, item, index) => {
                            return (
                                <Checkbox
                                    name={`planSaleItems.${index}.isForOnePeople`}
                                    control={control}
                                    label=""
                                    required={false}
                                    onChange={(e) => {
                                        onClickIsForOnePeople(e.target.checked, index);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column
                        title={
                            <Tooltip
                                title={t(
                                    'plan.create.saleItemsForm.columns.isFirstDayOnlyHover',
                                )}
                                color="#000000BF"
                            >
                                <span className="title-hover">
                                    {`${t(
                                        'plan.create.saleItemsForm.columns.isFirstDayOnly',
                                    )}`}
                                    <QuestionCircleOutlined />
                                </span>
                            </Tooltip>
                        }
                        width={120}
                        dataIndex=""
                        align="center"
                        key={PlanSaleItemListColumn.IS_FIRST_DAY_ONLY}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            return (
                                <Checkbox
                                    name={`planSaleItems.${index}.isFirstDayOnly`}
                                    control={control}
                                    label=""
                                    required={false}
                                    onChange={(e) => {
                                        onClickIsFirstDayOnly(e.target.checked, index);
                                    }}
                                />
                            );
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.totalPrices')}`}
                        width={150}
                        dataIndex=""
                        key={PlanSaleItemListColumn.TOTAL_PRICES}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            const isDisable =
                                planSaleItemList?.[index]?.isForOnePeople || false;
                            return (
                                <span>
                                    {isDisable
                                        ? currencyFormat(
                                              planSaleItemList[index].price || 0,
                                          )
                                        : currencyFormat(
                                              (planSaleItemList[index].price || 0) *
                                                  (planSaleItemList[index].quantity || 0),
                                          )}
                                </span>
                            );
                        }}
                    />
                    <Column
                        title={`${t('plan.create.saleItemsForm.columns.action')}`}
                        align="center"
                        width={60}
                        dataIndex=""
                        key={PlanSaleItemListColumn.ACTION}
                        render={(saleItem: IPlanSaleItem, item, index) => {
                            return (
                                <Button
                                    type="text"
                                    id={getFormPlanId(`btn-delete.${index}`)}
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        onDeleteSaleItem(saleItem, index);
                                    }}
                                />
                            );
                        }}
                    />
                </Table>
            </div>
        </div>
    );
}

export default SaleItemsForm;
