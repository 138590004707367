import React, { useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { CsvOption } from '~common/constants';
import './CsvDropdown.scss';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

interface IProp {
    onChange: (value: CsvOption) => void;
    hasSelectedColumns?: boolean;
    additionalOptions?: { label: string; key: string }[];
    hasImportOption?: boolean;
}

export function CsvDropdown(props: IProp) {
    const { t } = useTranslation();
    const csvOptions: ItemType[] = props.hasSelectedColumns
        ? [
              {
                  label: t('common.csvDropdown.exportSelection'),
                  key: CsvOption.EXPORT_SELECTION,
              },
              {
                  label: t('common.csvDropdown.exportAll'),
                  key: CsvOption.EXPORT_ALL,
              },
          ]
        : [
              {
                  label: t('common.csvDropdown.exportAll'),
                  key: CsvOption.EXPORT_ALL,
              },
          ];

    if (props.hasImportOption) {
        csvOptions.unshift({
            label: t('common.csvDropdown.importSelection'),
            key: CsvOption.IMPORT,
        });
    }

    const onChange = (value: CsvOption) => {
        props.onChange(value);
    };

    const items = useMemo(() => {
        const _options = props.additionalOptions
            ? [...csvOptions, ...props.additionalOptions]
            : csvOptions;
        return _options.map((option: any) => ({
            key: option.key,
            label: option.label,
            onClick: () => onChange(option.key),
        }));
    }, [csvOptions, props.additionalOptions]);

    return (
        <Dropdown
            className="csv-dropdown-wrapper"
            menu={{ items }}
            placement="bottomLeft"
        >
            <Button size="small">
                <Space>
                    {t('common.csvDropdown.csvFile')}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}

CsvDropdown.defaultProps = {
    hasImportOption: true,
};
