import { Form, Col, Row, notification } from 'antd';
import { InputText, SingleSelect, InputTextArea } from '~components';
import { Ref, forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import './NotificationForm.scss';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createNotificationSchema } from '~features/admin-notification/schema';
import { INotificationCreateBody } from '~features/admin-notification/interfaces';
import { hotelDropdownOptionsSelector } from '~features/hotel/hotel.reducer';
import { tagListDropdownSelector } from '~features/tag/reducers/tag.reducer';
import {
    createNotification,
    selectedNotificationSelector,
    updateNotification,
    setSelectedNotification,
} from '~features/admin-notification/reducers/notification.reducer';
import { ITag } from '~features/tag/interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { showConfirm } from '~common/notification';
import { ErrorMessageType, INPUT_TEXT_MAX_LENGTH } from '~common/constants';

function NotificationForm(_props: unknown, ref: Ref<unknown>) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { notificationId } = useParams();
    const { control, handleSubmit, getValues, reset, setError } = useForm({
        resolver: yupResolver(createNotificationSchema),
    });
    const tagOptions = useAppSelector(tagListDropdownSelector);
    const hotelOptions = useAppSelector(hotelDropdownOptionsSelector);
    const selectedNotification = useAppSelector(selectedNotificationSelector);

    useEffect(() => {
        reset({
            title: selectedNotification?.title,
            hotelIds: selectedNotification?.hotels.map((item) => item.id),
            tags: selectedNotification?.tags.map((item) => item.id),
            content: selectedNotification?.content,
        });
    }, [selectedNotification]);

    const closeForm = () => {
        dispatch(setSelectedNotification(null));
        reset({});
        navigate('/bo-settings/notification');
    };

    const makeFormData = (): INotificationCreateBody => {
        const { title, content, hotelIds, tags } = getValues();
        const tagIds = tags.filter((item: number | string) => {
            return typeof item === 'number';
        });
        const newTags = tags.filter((item: number | string) => {
            return typeof item === 'string';
        });
        const oldTags = tagOptions
            .filter((item: ITag) => {
                return tagIds.includes(item.id);
            })
            .map((tag) => tag.tag);
        const tagList = [...newTags, ...oldTags];
        return {
            title,
            content,
            hotelIds,
            tags: tagList,
        };
    };
    const _createNotification = useCallback(async (formData: INotificationCreateBody) => {
        const response = await dispatch(createNotification(formData));
        if (createNotification.fulfilled.match(response)) {
            if (response.payload.success) {
                notification.success({
                    message: t('notification.form.create.success'),
                });
                closeForm();
                return;
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                });
            }
            notification.error({
                message: t('notification.form.create.error'),
                description: response.payload.errors?.[0]?.message,
            });
        }
    }, []);

    const _updateNotification = useCallback(async (formData: INotificationCreateBody) => {
        const response = await dispatch(
            updateNotification({ id: Number(notificationId), formData }),
        );
        if (updateNotification.fulfilled.match(response)) {
            if (response.payload.success) {
                notification.success({
                    message: t('notification.form.update.success'),
                });
                closeForm();
                return;
            } else {
                (response.payload?.errors || []).forEach((error) => {
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                });
            }
            notification.error({
                message: t('notification.form.update.error'),
                description: response.payload.errors?.[0].message,
            });
        }
    }, []);

    useImperativeHandle(ref, () => ({
        onSubmitForm() {
            handleSubmit((data) => {
                const { tags } = data;
                if (tags.length) {
                    const newTags = tags.filter((item: number | string) => {
                        return typeof item === 'string';
                    });
                    if (
                        newTags.some(
                            (newTag: string) => newTag.length > INPUT_TEXT_MAX_LENGTH,
                        )
                    ) {
                        setError(
                            `tags`,
                            {
                                type: ErrorMessageType.MANUAL,
                                message: t('notification.form.tagLenghError', {
                                    maxLength: INPUT_TEXT_MAX_LENGTH,
                                }),
                            },
                            { shouldFocus: true },
                        );
                        return;
                    }
                }
                const _formData = makeFormData();
                if (notificationId) {
                    _updateNotification(_formData);
                } else {
                    _createNotification(_formData);
                }
            })();
        },
        onCancelForm() {
            showConfirm({
                title: t('notification.form.confirm.cancelTitle'),
                cancelText: t('notification.form.confirm.buttonCancelText'),
                okText: t('notification.form.confirm.buttonDeleteText'),
                onOk() {
                    closeForm();
                },
            });
        },
    }));

    useEffect(() => {
        return () => {
            closeForm();
        };
    }, []);

    return (
        <div className="create-notification-wrapper">
            <div className="create-notification-content">
                <div className="form-title">
                    <span className="mr-8">
                        {t('notification.form.informationTitle')}
                    </span>
                </div>
                <Form layout="vertical">
                    <Row gutter={24}>
                        <Col span={10}>
                            <InputText
                                label={t('notification.form.title')}
                                placeholder={t('notification.form.titleInput')}
                                name="title"
                                control={control}
                                required
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={10}>
                            <SingleSelect
                                label={t('notification.form.tag')}
                                placeholder={t('notification.form.tag')}
                                name="tags"
                                allowClear
                                mode="tags"
                                defaultValue={[]}
                                showArrow={false}
                                showSearch={true}
                                options={tagOptions.map((item) => ({
                                    label: item.tag,
                                    value: item.id,
                                }))}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={10}>
                            <SingleSelect
                                name="hotelIds"
                                mode="multiple"
                                allowClear
                                required
                                label={t('notification.form.hotelDesignation')}
                                placeholder={t(
                                    'notification.form.hotelDesignationPlaceHolder',
                                )}
                                defaultValue={[]}
                                options={hotelOptions.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                }))}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={20}>
                            <InputTextArea
                                className="notification-detail"
                                label={t('notification.form.detail')}
                                placeholder={t('notification.form.detailInput')}
                                required
                                name="content"
                                control={control}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default forwardRef(NotificationForm);
