import './FacilityList.scss';
import { Button, notification, Pagination, Table } from 'antd';
import { ReloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { ModalConfirmDeletion, CsvDropdown } from '~components';
import { AntdOrderDirection, CsvOption, OrderDirection } from '~common/constants';
import type { TableProps } from 'antd/es/table';
import { ISorter } from '~common/interfaces';
import {
    CSV_EXPORT_FILE_NAME,
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    FacilityColumn,
    i18ExportKey,
    IFacilityOrderBy,
} from '~features/facility/constants';
import {
    facilityListQuerySelector,
    facilityListSelector,
    fetchFacilityList,
    getFacilityListByIds,
    resetState,
    setFacilityListQuery,
    setIsShowUpdateFacilityForm,
    showLoadingSelector,
    totalFacilitiesSelector,
    totalPageSelector,
} from '~features/facility/facility.reducer';
import { IFacility, IFacilityExportCsvQuery } from '~features/facility/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import { downloadFile, exportCSVFile } from '~common/commonFunctions';
import { facilityService } from '~features/facility/services/facility.api';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { orderBy } from 'lodash';

interface ICSVData {
    name: string;
    autoGeneratedCode: string;
    facilityType: string;
}

function FacilityList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const facilityListQuery = useAppSelector(facilityListQuerySelector);
    const facilityList = useAppSelector(facilityListSelector);
    const totalFacilities = useAppSelector(totalFacilitiesSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const loading = useAppSelector(showLoadingSelector);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (
            facilityList.length === 0 &&
            facilityListQuery?.page &&
            facilityListQuery?.page > 1
        ) {
            const page = facilityListQuery?.page - 1;
            dispatch(setFacilityListQuery({ ...facilityListQuery, page }));
        }
    }, [facilityList]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        return facilityList.filter((facility) => selectedRowKeys.includes(facility.id));
    }, [selectedRowKeys, facilityList]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await facilityService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('facility.list.deleteFacilitiesSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            okButtonProps: { danger: true },
            deletedItems: selectedRows.map((facilityType) =>
                facilityType.name.toString(),
            ),
            buttonCancelText: t('facility.list.delete.buttonCancelText'),
            buttonDeleteText: t('facility.list.delete.buttonDeleteText'),
            onClickButtonDelete: onConfirmDeletion,
        });
    };
    const exportCsv = async (query: IFacilityExportCsvQuery) => {
        const response = await facilityService.exportCsv(query);
        if (response.success) {
            downloadFile(CSV_EXPORT_FILE_NAME, response.data.filePath);
        }
    };

    const convertDataToCsv = (facilities: IFacility[]) => {
        const dataOutput: ICSVData[] = [];
        facilities.forEach((facility) => {
            const data = {
                name: facility.name,
                autoGeneratedCode: facility.autoGeneratedCode,
                facilityType: facility.facilityType.name,
            };
            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportFacilityList = async (facilities: IFacility[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const roomDataCsvs = convertDataToCsv(facilities);
        await exportCSVFile(exportColumns, filename, roomDataCsvs, i18ExportKey);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all
                await exportCsv({
                    keyword: facilityListQuery.keyword,
                    name: facilityListQuery.name,
                    autoGeneratedCode: facilityListQuery.autoGeneratedCode,
                    facilityTypeName: facilityListQuery.facilityTypeName,
                    orderBy: facilityListQuery.orderBy,
                    orderDirection: facilityListQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportFacilityList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };

    const onChange: TableProps<IFacility>['onChange'] = (pagination, filters, sorter) => {
        // this is for pagination, sort, filter logic later
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setFacilityListQuery({
                    ...facilityListQuery,
                    orderBy: IFacilityOrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setFacilityListQuery({
                    ...facilityListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onChangePage = (page: number) => {
        dispatch(setFacilityListQuery({ ...facilityListQuery, page }));
    };

    const fetchData = () => {
        dispatch(fetchFacilityList());
    };

    useEffect(() => {
        fetchData();
    }, [facilityListQuery]);

    const refreshList = () => {
        fetchData();
    };

    const onEdit = () => {
        dispatch(setIsShowUpdateFacilityForm(true));
        const selectedFacilityIds = selectedRows.map((facility) => facility.id);
        dispatch(getFacilityListByIds(selectedFacilityIds));
    };

    const editRecord = (facility: IFacility) => {
        dispatch(setIsShowUpdateFacilityForm(true));
        dispatch(getFacilityListByIds([facility.id]));
    };

    const facilityColumns: ColumnsType<IFacility> = [
        {
            title: t('facility.list.columns.id'),
            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            render: (facility: IFacility) => {
                return <span>{facility.autoGeneratedCode}</span>;
            },
            width: '120px',
            key: FacilityColumn.AUTO_GENERATED_CODE,
            sorter: true,
        },
        {
            title: t('facility.list.columns.name'),
            dataIndex: FacilityColumn.NAME,
            sorter: true,
            ellipsis: true,
        },
        {
            title: t('facility.list.columns.typeName'),
            render: (facility: IFacility) => {
                return <span>{facility.facilityType.name}</span>;
            },
            key: FacilityColumn.FACILITY_TYPE_NAME,
            sorter: true,
        },
    ];

    return (
        <div className="facility-list-wrapper">
            <div className="facility-list-header">
                {selectedRows.length > 0 && (
                    <div className="list-header-left">
                        <Button
                            type="text"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onEdit}
                        />
                        <Button
                            type="text"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={showConfirmDialog}
                        />
                    </div>
                )}

                <div className="list-header-right">
                    <CsvDropdown
                        onChange={onChangeCsvOption}
                        hasSelectedColumns={selectedRows.length > 0}
                        hasImportOption={false}
                    />
                    <ReloadOutlined className="size-icon" onClick={refreshList} />
                </div>
            </div>

            <Table
                locale={{
                    triggerDesc: t('dashboard.sort.desc'),
                    triggerAsc: t('dashboard.sort.asc'),
                    cancelSort: t('dashboard.sort.cancel'),
                }}
                rowSelection={rowSelection}
                columns={facilityColumns}
                dataSource={facilityList}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                loading={loading}
                scroll={{ y: 400 }}
                rowClassName="facility-row"
                onRow={(record) => {
                    return {
                        // click row
                        onClick: () => {
                            editRecord(record);
                        },
                    };
                }}
            />

            {pageCount > 1 && (
                <Pagination
                    className="facility-pagination"
                    defaultCurrent={facilityListQuery.page}
                    current={facilityListQuery.page}
                    total={totalFacilities}
                    pageSize={facilityListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default FacilityList;
