import { FieldTimeOutlined, UserOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row, notification } from 'antd';
import { trim } from 'lodash';
import { RangeType } from 'rc-picker/lib/RangePicker';
import { EventValue } from 'rc-picker/lib/interface';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateFormat, ErrorMessageType } from '~common/constants';
import {
    AutoCompleteGuest,
    InputNumber,
    InputPhoneNumber,
    InputText,
    InputTextArea,
    RangePicker,
    SingleCollapse,
    SingleDatePicker,
    SingleSelect,
} from '~components';
import {
    FacilityBookingCreateFormField,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import {
    disabledBirthDayDate,
    range,
    validateBusinessTime,
    validateDateAfterTwoDaysBefore,
    validateStayDateTimes,
} from '~features/facility-booking/helper';
import {
    IFacilityBooking,
    IFacilityBookingCreateBody,
    IFacilityBookingUpdate,
    IGetCalculatedAmountQuery,
} from '~features/facility-booking/interfaces';
import {
    getCalculatedAmount,
    setIsShowUpdateFacilityBookingForm,
    updateFacilityBooking,
    updateMemoBooking,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import { createFacilityBookingSchema } from '~features/facility-booking/schema';
import {
    facilityDropDownOptionsSelector,
    fetchFacilityDropDown,
} from '~features/facility/facility.reducer';
import { Gender } from '~features/guest/constants';
import { IGuestDropDown } from '~features/guest/interfaces';
import {
    getGuestListForDropdown,
    guestListForDropDownSelector,
} from '~features/guest/reducers/guest.reducer';
import { IUpdateMemoBookingBody } from '~features/room-booking/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './UpdateFacilityBookingForm.scss';

type IForm = {
    facilityId?: number;
    yomigana?: string | IGuestDropDown;
    fullName?: string;
    birthday?: Dayjs;
    gender?: string;
    memo?: string;
    mobilePhoneNumber?: string;
    reservationDuration?: [Dayjs, Dayjs];
    numberOfGuests?: number;
    emailAddress?: string;
};

type Props = {
    onUpdateSuccess?: (item?: IFacilityBooking) => void;
    onChangeAmount?: (amount: number) => void;
    booking: IFacilityBooking | null;
    isShowForm: boolean;
    className?: string;
};

function UpdateFacilityBookingForm(props: Props, ref: any) {
    const { onUpdateSuccess, onChangeAmount, booking, isShowForm, className } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [expand, setExpand] = useState(false);
    const [canEditGuest, setCanEditGuest] = useState<boolean>(false);
    const [guestRoom, setGuestRoom] = useState('');

    const facilityDropDownOptions = useAppSelector(facilityDropDownOptionsSelector);
    const guestDropdownOptions = useAppSelector(guestListForDropDownSelector);

    const { control, handleSubmit, reset, setError, setValue, getValues } = useForm({
        resolver: yupResolver(createFacilityBookingSchema),
    });

    const facilityId = useWatch({
        control: control,
        name: 'facilityId',
    });

    const reservationDuration = useWatch({
        control: control,
        name: 'reservationDuration',
    });

    const disabledTime = (date: EventValue<Dayjs>, type: RangeType) => {
        if (
            type === 'end' &&
            booking?.status === FacilityBookingStatus.STARTED &&
            parseDate(date).isSame(booking.startDatetime, 'day')
        ) {
            const startDatetime = parseDate(booking.startDatetime);
            const hour = startDatetime.hour();
            const minute = startDatetime.minute();
            const selectHour = parseDate(date).hour();
            const selectMinute = parseDate(date).minute();

            if (selectHour === hour) {
                return {
                    disabledHours: () => range(0, minute === 59 ? hour + 1 : hour),
                    disabledMinutes: () => range(0, minute),
                };
            }

            return {
                disabledHours: () =>
                    range(0, selectMinute < minute || minute === 59 ? hour + 1 : hour),
            };
        }
        return {};
    };

    const _getCalculatedAmount = useCallback(
        async (reservationDuration: any, facilityId: number, bookingId?: number) => {
            if (reservationDuration && facilityId && bookingId) {
                const query: IGetCalculatedAmountQuery = {
                    facilityId,
                    bookingId,
                    startDatetime: parseDate(reservationDuration[0])?.fmYYYYMMDDHHmmss(
                        '-',
                    ),
                    endDatetime: parseDate(reservationDuration[1])?.fmYYYYMMDDHHmmss('-'),
                };
                const response = await dispatch(getCalculatedAmount(query));
                if (getCalculatedAmount.fulfilled.match(response)) {
                    if (response.payload?.success) {
                        const total = response.payload?.data?.amount || 0;
                        setValue('totalAmount', total);
                        onChangeAmount?.(total);
                    }
                } else {
                    notification.error(t('common.somethingWentWrong'));
                }
            }
        },
        [],
    );

    const checkBusinessTime = (formData: IForm) => {
        if (formData.reservationDuration?.length !== 2) return false;

        if (
            !booking?.facilityType?.businessEndTime ||
            !booking?.facilityType?.businessStartTime
        ) {
            return false;
        }
        if (
            !validateBusinessTime(
                formData.reservationDuration,
                booking?.facilityType?.businessStartTime,
                booking?.facilityType?.businessEndTime,
            )
        ) {
            setError(
                'reservationDuration',
                {
                    type: ErrorMessageType.MANUAL,
                    message: t('facilityBooking.detail.message.businessTimeError', {
                        startTime: booking?.facilityType?.businessStartTime,
                        endTime: booking?.facilityType?.businessEndTime,
                    }),
                },
                { shouldFocus: true },
            );
            return false;
        }
        return true;
    };

    const submitForm = () => {
        handleSubmit((formData) => {
            const {
                facilityId,
                guestId,
                yomigana,
                numberOfGuests,
                fullName,
                mobilePhoneNumber,
                birthday,
                gender,
                memo,
                reservationDuration,
                emailAddress,
            } = formData;
            if (
                booking?.status === FacilityBookingStatus.FINISHED ||
                booking?.status === FacilityBookingStatus.CANCELLED
            ) {
                _updateMemoFacilityBooking({
                    id: booking.id,
                    body: { memo: trim(memo) || null },
                });
                return;
            }
            const startDatetimeBooking = parseDate(
                booking?.startDatetime,
            ).fmYYYYMMDDHHmmss();

            if (booking?.status !== FacilityBookingStatus.STARTED) {
                // check start time must be after 2 days before
                if (
                    !validateDateAfterTwoDaysBefore(formData.reservationDuration?.[0]) &&
                    startDatetimeBooking !==
                        formData.reservationDuration?.[0].fmYYYYMMDDHHmmss()
                ) {
                    setError(
                        'reservationDuration',
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t(
                                'facilityBooking.detail.message.checkInTimeError',
                                {
                                    currentTime: customDayjs()
                                        .subtract(2, 'day')
                                        .fmYYYYMMDD('-'),
                                },
                            ),
                        },
                        { shouldFocus: true },
                    );
                    return;
                }
            }

            const endDatetimeBooking = parseDate(booking?.endDatetime).fmYYYYMMDDHHmmss();
            // check end time must be after 2 days before
            if (
                !validateDateAfterTwoDaysBefore(formData.reservationDuration?.[1]) &&
                endDatetimeBooking !==
                    formData.reservationDuration?.[1].fmYYYYMMDDHHmmss()
            ) {
                setError(
                    'reservationDuration',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('facilityBooking.detail.message.checkInOutError', {
                            currentTime: customDayjs().fmYYYYMMDDHHmm('-'),
                        }),
                    },
                    { shouldFocus: true },
                );
                return;
            }

            // check end time must be greater than start time 10 minutes
            if (
                !validateStayDateTimes([
                    booking?.status === FacilityBookingStatus.STARTED
                        ? booking?.startDatetime
                        : formData.reservationDuration?.[0],
                    formData.reservationDuration?.[1],
                ])
            ) {
                setError(
                    'reservationDuration',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t(
                            'facilityBooking.detail.message.startTimeGreaterEndError',
                        ),
                    },
                    { shouldFocus: true },
                );
                return;
            }

            // check business time
            if (!checkBusinessTime(formData)) return;

            const body: IFacilityBookingCreateBody = {
                facilityId,
                guestId,
                numberOfGuests: Number(numberOfGuests),
                memo: trim(memo) || null,
                yomigana: guestId ? null : trim(yomigana),
                fullName: guestId ? null : trim(fullName),
                emailAddress: guestId ? null : emailAddress?.trim() || null,
                mobilePhoneNumber: guestId ? null : trim(mobilePhoneNumber) || null,
                gender: guestId ? null : trim(gender) || null,
                birthday:
                    !guestId && birthday ? parseDate(birthday)?.fmYYYYMMDD('-') : null,
                startDatetime: canEdit([
                    FacilityBookingStatus.FINISHED,
                    FacilityBookingStatus.STARTED,
                ])
                    ? parseDate(reservationDuration[0])?.fmYYYYMMDDHHmmss('-')
                    : parseDate(booking?.startDatetime)?.fmYYYYMMDDHHmmss('-'),
                endDatetime: parseDate(reservationDuration[1])?.fmYYYYMMDDHHmmss('-'),
            };
            if (booking?.id) {
                _updateFacilityBooking({
                    id: booking?.id,
                    body,
                });
            }
        })();
    };

    const _updateFacilityBooking = useCallback(
        async (updateBooking: IFacilityBookingUpdate) => {
            const response = await dispatch(updateFacilityBooking(updateBooking));
            if (updateFacilityBooking.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('facilityBooking.detail.message.updateSuccess'),
                    });
                    if (updateBooking.body?.guestId) {
                        onCloseModal();
                    } else {
                        onCloseModal(true);
                    }
                    onUpdateSuccess?.({
                        ...response.payload?.data,
                        totalAmount: +getValues('totalAmount'),
                    });
                    return;
                }
                notification.error({
                    message: t('facilityBooking.detail.message.updateError'),
                    description: response.payload?.errors?.[0].message || '',
                });

                (response.payload?.errors || []).forEach((error) => {
                    if (
                        error.key === FacilityBookingCreateFormField.START_DATE_TIME ||
                        error.key === FacilityBookingCreateFormField.END_DATE_TIME
                    ) {
                        setError(
                            FacilityBookingCreateFormField.RESERVATION_DURATION,
                            { type: ErrorMessageType.MANUAL, message: error.message },
                            { shouldFocus: true },
                        );
                    }
                    setError(
                        error.key,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                });
            }
        },
        [],
    );
    const _updateMemoFacilityBooking = useCallback(
        async (updateBooking: IUpdateMemoBookingBody) => {
            const response = await dispatch(updateMemoBooking(updateBooking));
            if (updateMemoBooking.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('facilityBooking.detail.message.updateSuccess'),
                    });
                    onCloseModal();
                    onUpdateSuccess?.();
                    return;
                }
                notification.error({
                    message: t('facilityBooking.detail.message.updateError'),
                    description: response.payload?.errors?.[0]?.message || '',
                });
            }
        },
        [],
    );

    const onCloseModal = (isFetchData?: boolean) => {
        dispatch(setIsShowUpdateFacilityBookingForm(false));
        setCanEditGuest(false);
        reset();
        if (isFetchData) {
            dispatch(getGuestListForDropdown({ withRoomBooking: true }));
        }
    };

    const changeGuestYomigana = (value: string | IGuestDropDown) => {
        setCanEditGuest(!(value instanceof Object));
        if (value instanceof Object) {
            setValue(FacilityBookingCreateFormField.YOMIGANA, value.yomigana, {
                shouldValidate: true,
            });
            setValue(FacilityBookingCreateFormField.GUEST_ID, value.id, {
                shouldValidate: true,
            });
            setValue(FacilityBookingCreateFormField.FULL_NAME, value.fullName, {
                shouldValidate: true,
            });
            setValue(
                FacilityBookingCreateFormField.MOBILE_PHONE_NUMBER,
                value.mobilePhoneNumber || value.phoneNumberLandline,
            );
            setValue(FacilityBookingCreateFormField.GENDER, value.gender);
            setValue(
                FacilityBookingCreateFormField.BIRTHDAY,
                value.birthday && parseDate(value.birthday).isValid()
                    ? parseDate(value.birthday)?.fmYYYYMMDD('-')
                    : null,
            );
            setValue(FacilityBookingCreateFormField.EMAIL_ADDRESS, value.emailAddress);
            setGuestRoom(
                (value.rooms || [])?.map((room) => room.name)?.join(', ') as string,
            );
            return;
        }
        setValue(FacilityBookingCreateFormField.YOMIGANA, value);
        setValue(FacilityBookingCreateFormField.GUEST_ID, null);
        setValue(FacilityBookingCreateFormField.FULL_NAME, null);
        setValue(FacilityBookingCreateFormField.MOBILE_PHONE_NUMBER, null);
        setValue(FacilityBookingCreateFormField.GENDER, null);
        setValue(FacilityBookingCreateFormField.BIRTHDAY, null);
        setValue(FacilityBookingCreateFormField.EMAIL_ADDRESS, null);
        setGuestRoom('');
    };

    const initForm = () => {
        reset({
            facilityId: booking?.facility?.id,
            yomigana: booking?.guest?.id,
            guestId: booking?.guest?.id,
            fullName: booking?.guest?.fullName,
            mobilePhoneNumber:
                booking?.guest?.mobilePhoneNumber || booking?.guest?.phoneNumberLandline,
            birthday: booking?.guest?.birthday
                ? parseDate(booking?.guest?.birthday)
                : null,
            gender: booking?.guest?.gender,
            emailAddress: booking?.guest?.emailAddress,
            memo: booking?.memo,
            reservationDuration: [
                parseDate(parseDate(booking?.startDatetime)?.fmYYYYMMDDHHmm()),
                parseDate(parseDate(booking?.endDatetime)?.fmYYYYMMDDHHmm()),
            ],
            numberOfGuests: booking?.numberOfGuests,
            totalAmount: booking?.totalAmount,
        });
    };

    useEffect(() => {
        if (booking && isShowForm) {
            const guest = guestDropdownOptions.find(
                (guest) => guest.id === booking?.guestId,
            );
            if (guest) {
                const roomsName = guest.rooms?.map((room) => room.name).join(', ');
                setGuestRoom(roomsName || '');
            }
        }
    }, [booking, isShowForm, guestDropdownOptions]);

    useEffect(() => {
        if (booking && isShowForm) {
            initForm();
        }
    }, [booking, isShowForm]);

    useEffect(() => {
        dispatch(getGuestListForDropdown({ withRoomBooking: true }));
    }, []);

    useEffect(() => {
        if (booking?.facilityType?.id) {
            dispatch(
                fetchFacilityDropDown({
                    facilityTypeId: booking?.facilityType?.id,
                }),
            );
        }
    }, [booking]);

    const canEdit = (status: FacilityBookingStatus[]) => {
        return !status.includes(
            (booking?.status || FacilityBookingStatus.ALL) as FacilityBookingStatus,
        );
    };

    useImperativeHandle(ref, () => ({
        // this function is used in parent component
        onSubmit() {
            submitForm();
        },
        onCloseForm() {
            onCloseModal();
        },
    }));

    return (
        <Form layout="vertical" autoComplete="off" scrollToFirstError>
            <div className={`update-facility-booking-form ${className || ''}`}>
                <Row gutter={8}>
                    <Col span={24}>
                        <SingleSelect
                            label={t('facilityBooking.createForm.form.facility.label')}
                            placeholder={t(
                                'facilityBooking.createForm.form.facility.placeholder',
                            )}
                            name="facilityId"
                            control={control}
                            options={facilityDropDownOptions}
                            required
                            disabled={canEdit([
                                FacilityBookingStatus.RESERVED,
                                FacilityBookingStatus.STARTED,
                            ])}
                            onChange={(value) =>
                                _getCalculatedAmount(
                                    reservationDuration,
                                    value,
                                    booking?.id,
                                )
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <AutoCompleteGuest
                            label={t(
                                'facilityBooking.createForm.form.guestYomigana.label',
                            )}
                            placeholder={t(
                                'facilityBooking.createForm.form.guestYomigana.placeholder',
                            )}
                            name="yomigana"
                            required
                            allowClear
                            showSearch={true}
                            guestOptions={guestDropdownOptions}
                            defaultValue={isShowForm ? booking?.guest?.id : ''}
                            onChange={changeGuestYomigana}
                            control={control}
                            guestRoom={guestRoom}
                            disabled={canEdit([FacilityBookingStatus.RESERVED])}
                            isTriggerParentNode={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <SingleCollapse
                            expand={expand}
                            onChange={() => {
                                setExpand(!expand);
                            }}
                            title={t(
                                'facilityBooking.createForm.form.detailGuestInformation',
                            )}
                        >
                            <Row gutter={8}>
                                <Col span={24}>
                                    <InputText
                                        label={t(
                                            'facilityBooking.createForm.form.guestFullName.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.guestFullName.placeholder',
                                        )}
                                        name="fullName"
                                        allowClear
                                        control={control}
                                        disabled={
                                            !canEditGuest ||
                                            canEdit([FacilityBookingStatus.RESERVED])
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <SingleDatePicker
                                        label={t(
                                            'facilityBooking.createForm.form.guestBirthday.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.guestBirthday.placeholder',
                                        )}
                                        name="birthday"
                                        allowClear
                                        format={DateFormat.YYYY_MM_DD_SLASH}
                                        disabledDate={disabledBirthDayDate}
                                        control={control}
                                        disabled={
                                            !canEditGuest ||
                                            canEdit([FacilityBookingStatus.RESERVED])
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <SingleSelect
                                        label={t(
                                            'facilityBooking.createForm.form.guestGender.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.guestGender.placeholder',
                                        )}
                                        name="gender"
                                        allowClear
                                        control={control}
                                        options={Object.values(Gender).map((gender) => {
                                            return {
                                                label: t(
                                                    `facilityBooking.createForm.guestGender.${gender}`,
                                                ),
                                                value: gender,
                                            };
                                        })}
                                        disabled={
                                            !canEditGuest ||
                                            canEdit([FacilityBookingStatus.RESERVED])
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <InputTextArea
                                        label={t(
                                            'facilityBooking.createForm.form.guestMemo.label',
                                        )}
                                        placeholder={t(
                                            'facilityBooking.createForm.form.guestMemo.placeholder',
                                        )}
                                        allowClear
                                        name="memo"
                                        control={control}
                                    />
                                </Col>
                            </Row>
                        </SingleCollapse>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <InputPhoneNumber
                            label={t(
                                'facilityBooking.createForm.form.guestPhoneNumber.label',
                            )}
                            placeholder={t(
                                'facilityBooking.createForm.form.guestPhoneNumber.placeholder',
                            )}
                            allowClear
                            name="mobilePhoneNumber"
                            control={control}
                            disabled={
                                !canEditGuest || canEdit([FacilityBookingStatus.RESERVED])
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <InputText
                            label={t(
                                'facilityBooking.createForm.form.emailAddress.label',
                            )}
                            placeholder={t(
                                'facilityBooking.createForm.form.emailAddress.placeholder',
                            )}
                            allowClear
                            name="emailAddress"
                            control={control}
                            disabled={
                                !canEditGuest || canEdit([FacilityBookingStatus.RESERVED])
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <RangePicker
                            label={`${t(
                                'facilityBooking.createForm.form.facilityBookingTimePeriod.label',
                            )} (${t('common.standardTimeTitle')})`}
                            placeholder={[
                                t(
                                    'facilityBooking.createForm.form.facilityBookingTimePeriod.placeholder.start',
                                ),
                                t(
                                    'facilityBooking.createForm.form.facilityBookingTimePeriod.placeholder.end',
                                ),
                            ]}
                            showTime={true}
                            name="reservationDuration"
                            control={control}
                            format={DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON}
                            allowClear={false}
                            required
                            disabledDate={(current) =>
                                current.isBefore(customDayjs().subtract(2, 'day'), 'day')
                            }
                            disabledTime={disabledTime}
                            suffixIcon={<FieldTimeOutlined />}
                            disabled={[
                                canEdit([FacilityBookingStatus.RESERVED]),
                                !canEdit([
                                    FacilityBookingStatus.FINISHED,
                                    FacilityBookingStatus.CANCELLED,
                                ]),
                            ]}
                            onChange={(values) =>
                                _getCalculatedAmount(values, facilityId, booking?.id)
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <div className="number-of-people-use-facility">
                            <div className="label-number-of-people">
                                <UserOutlined className="people-icon" />
                                {t(
                                    'facilityBooking.createForm.form.numberOfGuests.label',
                                )}
                            </div>
                            <InputNumber
                                label={''}
                                placeholder={t(
                                    'facilityBooking.createForm.form.numberOfGuests.placeholder',
                                )}
                                name="numberOfGuests"
                                control={control}
                                isShowIconArrow={true}
                                disabled={
                                    !canEdit([
                                        FacilityBookingStatus.FINISHED,
                                        FacilityBookingStatus.CANCELLED,
                                    ])
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

export default forwardRef(UpdateFacilityBookingForm);
