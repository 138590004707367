import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import { Col, Row, Tag, notification } from 'antd';
import classNames from 'classnames';
import { debounce, intersectionBy, sumBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessageType } from '~common/constants';
import { usePrevious } from '~common/useHooks';
import { InputText, SingleSelect, TimePickerField } from '~components';
import { childrenStateSelector } from '~features/children-type/reducers/children.reducer';
import { PlanType } from '~features/plan/constants';
import { planDropdownActiveSelector } from '~features/plan/reducers/plan.reducer';
import { ChildrenInput } from '~features/room-booking/components/ChildrenInput/ChildrenInput';
import InputAdult from '~features/room-booking/components/InputAdult/InputAdult';
import {
    FormTab,
    InputAdultFieldName,
    RoomBookingEvent,
} from '~features/room-booking/constants';
import {
    getChildrenObject,
    getCreateBookingFormPageId,
} from '~features/room-booking/helper';
import {
    IGetBookingPrice,
    IMemberAttachment,
    IRoomBookingSchedule,
} from '~features/room-booking/interfaces';
import { guestDefault } from '~features/room-booking/model';
import {
    createBookingStateSelector,
    getBookingPrice,
    updateBookingPrice,
} from '~features/room-booking/reducers/create-booking.reducer';
import { scheduleStateSelector } from '~features/room-booking/reducers/schedule.reducer';
import { IRoomDropDown, IRoomGetListDropDownQuery } from '~features/room/interfaces';
import { getListForDropDown } from '~features/room/room.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate, parseTime } from '~plugins/dayjs';
import { useMitt } from '~plugins/mitt';

type Props = {
    booking: IRoomBookingSchedule;
    control: any;
    getValues: any;
    watch: any;
    setValue: any;
    setError: any;
    clearErrors: any;
    index: number;
};

function BookingForm({
    booking,
    control,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    index,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [currentPlan, setCurrentPlan] = React.useState<number | null>(booking.plan.id);
    const { children } = useAppSelector(childrenStateSelector);
    const { roomTypesDropdown } = useAppSelector(scheduleStateSelector);
    const plansOptions = useAppSelector(planDropdownActiveSelector);
    const { isCheckingPlan } = useAppSelector(createBookingStateSelector);

    const [currentRoomType, setCurrentRoomType] = React.useState<number | null>(
        booking.roomType?.id,
    );
    const [isOpen, setIsOpen] = React.useState(false);
    const [childrenList, setChildrenList] = React.useState<Record<string, number>>();
    const [roomDropDownList, setRoomDropDownList] = useState<IRoomDropDown[]>([]);

    const { emitter } = useMitt();
    const previousChildrenList = usePrevious(childrenList);

    const getFieldName = (name: string) => {
        return `bookings.${index}.${name}`;
    };
    const _planId = useWatch({
        control,
        name: getFieldName('planId'),
    });
    const _roomTypeId = useWatch({
        control,
        name: getFieldName('roomTypeId'),
    });
    const _childrenList = useWatch({
        control,
        name: getFieldName('children'),
    });

    const _numberOfAdults = useWatch({
        control,
        name: getFieldName('numberOfAdults'),
    });

    const _numberOfMale = useWatch({
        control: control,
        name: getFieldName('numberOfMale'),
    });
    const _numberOfFemale = useWatch({
        control: control,
        name: getFieldName('numberOfFemale'),
    });
    const _numberOfOther = useWatch({
        control: control,
        name: getFieldName('numberOfOtherGenderGuest'),
    });

    const setFieldValue = (
        field: string,
        value: { id: number | null; name: string | null },
    ) => {
        setValue(`${getFieldName(field)}.id`, value?.id || null);
        setValue(`${getFieldName(field)}.name`, value?.name || '');
    };

    const rooms = useMemo(() => {
        const findRoomType = roomTypesDropdown?.find(
            (item) => item.id === currentRoomType,
        );
        setFieldValue('roomType', {
            id: findRoomType?.id || null,
            name: findRoomType?.name || '',
        });

        const _rooms = findRoomType?.rooms || [];
        const _roomDropDownList = intersectionBy(_rooms, roomDropDownList, 'id');
        const findRoom = _roomDropDownList.find((room) => room.id === booking.room?.id);
        if (!findRoom && !!booking.room?.id) {
            _roomDropDownList.unshift({ id: booking.room?.id, name: booking.room?.name });
        }
        return _roomDropDownList.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [roomTypesDropdown, currentRoomType, roomDropDownList]);

    const roomsTypes = useMemo(() => {
        const plan = plansOptions.find((option) => {
            return option.value === currentPlan;
        });
        setFieldValue('plan', {
            id: Number(plan?.value) || null,
            name: plan?.label || '',
        });
        if (currentPlan) {
            return roomTypesDropdown
                .filter((roomType) => {
                    return plan?.roomTypeIds?.includes(roomType.id);
                })
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
        }

        return roomTypesDropdown.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [roomTypesDropdown, currentPlan]);

    const planDropDownOptions = useMemo(() => {
        if (currentRoomType)
            return plansOptions.filter((plan) => {
                const hasRoomType = plan.roomTypeIds?.includes(currentRoomType);
                const hasPlanType = booking.isDayUse
                    ? plan.type === PlanType.DAY_USE
                    : plan.type === PlanType.STAY;
                return hasRoomType && hasPlanType;
            });
        return plansOptions;
    }, [plansOptions, currentRoomType, booking]);

    const date = useMemo(() => {
        const start = parseDate(booking.stayingStartDate)?.fmYYYYMMDD('/');
        const end = parseDate(booking.stayingEndDate)?.fmYYYYMMDD('/');
        return `${start} - ${end}`;
    }, [booking]);

    const _getBookingPrice = debounce(async () => {
        const roomTypeId = getValues(getFieldName('roomTypeId'));
        const planId = getValues(getFieldName('planId'));
        const numberOfAdults = getValues(getFieldName('numberOfAdults'));
        if (!roomTypeId || !planId || !numberOfAdults) return;
        const query: IGetBookingPrice = {
            roomTypeId: roomTypeId,
            startDateOfStay: booking.stayingStartDate,
            endDateOfStay: booking.stayingEndDate,
            numberOfAdults: Number(numberOfAdults),
            planId: planId,
            childrenTypeQuantities: children.map((item, index) => ({
                childrenTypeId: item.id,
                quantity: childrenList?.['id_' + item.id] || 0,
            })),
            id: booking.id,
        };
        const response = await dispatch(getBookingPrice(query));
        if (getBookingPrice.fulfilled.match(response)) {
            const { data } = response.payload;
            if (!data) {
                notification.error({
                    message:
                        response?.payload?.message ||
                        t('roomBooking.createBooking.message.calculateAmountError'),
                });
                setError(getFieldName('planId'), {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.planInvalidPrice'),
                });
                return;
            }
            clearErrors(getFieldName('planId'));
            const { amount = 0, tax = 0 } = data;
            dispatch(updateBookingPrice({ id: booking.id, price: amount, tax: tax }));
        }
    }, 200);

    const fetchRoomDropDown = debounce(async (query: IRoomGetListDropDownQuery) => {
        const response = await dispatch(getListForDropDown(query));
        if (getListForDropDown.fulfilled?.match(response)) {
            if (response.payload?.success) {
                setRoomDropDownList(response.payload?.data?.items || []);
            }
        }
    }, 500);

    useEffect(() => {
        const roomGetListDropDownQuery = {
            roomBookingItemId: booking.id,
            roomBookingStayPeriod: [
                parseDate(
                    `${booking.stayingStartDate} ${booking.checkInTime}`,
                ).fmYYYYMMDDHHmmss(),
                parseDate(
                    `${booking.stayingEndDate} ${booking.checkOutTime}`,
                ).fmYYYYMMDDHHmmss(),
            ],
            isDayUse: booking.isDayUse,
        };
        fetchRoomDropDown(roomGetListDropDownQuery);
        setValue(getFieldName('roomTypeId'), booking.roomType?.id);
        setValue(getFieldName('roomId'), booking.room?.id);
        setValue(getFieldName('planId'), booking.plan?.id);
        setValue(getFieldName('numberOfAdults'), booking.numberOfAdults || null);
        setValue(getFieldName('numberOfMale'), booking.numberOfMale || null);
        setValue(getFieldName('numberOfFemale'), booking.numberOfFemale || null);
        setValue(
            getFieldName('numberOfOtherGenderGuest'),
            booking.numberOfOtherGenderGuest || null,
        );
        setFieldValue('room', { id: booking.room?.id, name: booking.room?.name });
    }, []);

    useEffect(() => {
        setChildrenList(getChildrenObject(booking.children));
    }, [booking]);

    const isValidNumberOfAdult = () => {
        const roomTypeId = getValues(getFieldName('roomTypeId'));
        if (!roomTypeId || !_numberOfAdults) return true;
        const selectedRoomType = roomTypesDropdown.find((roomType) => {
            return roomType.id === roomTypeId;
        });
        if (!_numberOfAdults) {
            return false;
        }
        return Number(_numberOfAdults) <= (selectedRoomType?.standardCapacity || 0);
    };

    useEffect(() => {
        if (
            previousChildrenList &&
            JSON.stringify(previousChildrenList) !== JSON.stringify(_childrenList)
        ) {
            _getBookingPrice();
        }
    }, [_childrenList]);

    useEffect(() => {
        if (childrenList) _getBookingPrice();
    }, [_planId, _roomTypeId]);

    useEffect(() => {
        if (!_numberOfAdults) {
            return;
        }
        if (isValidNumberOfAdult()) {
            addMembers();
            _getBookingPrice();
        }
    }, [_numberOfAdults]);

    useEffect(() => {
        if (!_numberOfMale && !_numberOfFemale && !_numberOfOther) {
            return;
        }
        const totalGuest =
            Number(_numberOfMale) + Number(_numberOfFemale) + Number(_numberOfOther);
        setValue(getFieldName('numberOfAdults'), totalGuest, { shouldValidate: true });
    }, [_numberOfMale, _numberOfFemale, _numberOfOther, setValue]);

    useEffect(() => {
        if (isCheckingPlan) return;
        const selectedRoomType = roomTypesDropdown.find((roomType) => {
            return roomType.id === currentRoomType;
        });
        setValue(
            getFieldName('standardCapacity'),
            selectedRoomType?.standardCapacity || 0,
        );
    }, [currentRoomType, isCheckingPlan]);

    const addMembers = () => {
        const members = (getValues('members') || []) as IMemberAttachment[];
        const numberOfAdults = sumBy(
            getValues('bookings'),
            (item: { numberOfAdults: string }) => Number(item.numberOfAdults),
        );
        const filterMembers = members.filter((member) => !!member.yomigana);
        const total = numberOfAdults - 1;
        if (!total && filterMembers.length === 0) {
            setValue('members', []);
            emitter.emit(RoomBookingEvent.CHANGE_TAB, FormTab.BASIC_INFO);
            return;
        }
        const count = total - members.length;
        if (!count) return;
        if (count > 0) {
            let start = members.length + 1;
            for (let i = 0; i < count; i++) {
                members.push({
                    ...guestDefault,
                    index: start + i,
                });
            }
            setValue('members', members);
            return;
        }

        const _filterMembers = filterMembers.map((item, index) => {
            return { ...item, index: index + 1 };
        });
        let start = _filterMembers.length + 1;
        const additionalMembers = Array.from(
            {
                length: total - _filterMembers.length,
            },
            (_, index) => ({
                ...guestDefault,
                birthday: null,
                index: start + index,
            }),
        );

        setValue('members', [..._filterMembers, ...additionalMembers], {
            shouldValidate: true,
        });
    };

    const checkInTime = useWatch({
        control: control,
        name: getFieldName('checkInTime'),
    });

    const checkOutTime = useWatch({
        control: control,
        name: getFieldName('checkOutTime'),
    });

    const changeBookingTime = (value: string, field: string) => {
        if (field === 'checkOutTime') {
            const roomGetListDropDownQuery = {
                roomBookingItemId: booking.id,
                roomBookingStayPeriod: [
                    `${booking.stayingStartDate} ${
                        checkInTime?.fmHHmmss() || '00:00:00'
                    }`,
                    `${booking.stayingEndDate} ${parseTime(value).fmHHmmss()}`,
                ],
                isDayUse: booking.isDayUse,
            };
            fetchRoomDropDown(roomGetListDropDownQuery);
        } else if (field === 'checkInTime') {
            const roomGetListDropDownQuery = {
                roomBookingItemId: booking.id,
                roomBookingStayPeriod: [
                    `${booking.stayingStartDate} ${parseTime(value).fmHHmmss()}`,
                    `${booking.stayingEndDate} ${checkOutTime?.fmHHmmss() || '00:00:00'}`,
                ],
                isDayUse: booking.isDayUse,
            };
            fetchRoomDropDown(roomGetListDropDownQuery);
        }
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Tag icon={<CalendarOutlined />}>{date}</Tag>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={12}>
                    <Row gutter={8}>
                        <Col span={8}>
                            <div className="pt-5 mb-5">C/I:</div>
                        </Col>
                        <Col span={16}>
                            <TimePickerField
                                label={''}
                                placeholder={t('roomBooking.page.guestInfo.checkIn')}
                                name={getFieldName('checkInTime')}
                                id={getCreateBookingFormPageId(
                                    getFieldName('checkInTime'),
                                )}
                                control={control}
                                defaultValue={
                                    booking.checkInTime
                                        ? parseTime(booking.checkInTime)
                                        : undefined
                                }
                                key="checkInTime"
                                format="HH:mm"
                                suffixIcon={null}
                                onChange={(_, formatString) =>
                                    changeBookingTime(formatString, 'checkInTime')
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row gutter={8}>
                        <Col span={8}>
                            <div className="pt-5">C/O:</div>
                        </Col>
                        <Col span={16}>
                            <TimePickerField
                                label={''}
                                defaultValue={
                                    booking.checkOutTime
                                        ? parseTime(booking.checkOutTime)
                                        : undefined
                                }
                                placeholder={t('roomBooking.page.guestInfo.checkOut')}
                                name={getFieldName('checkOutTime')}
                                id={getCreateBookingFormPageId(
                                    getFieldName('checkOutTime'),
                                )}
                                control={control}
                                key="checkOutTime"
                                format="HH:mm"
                                suffixIcon={null}
                                onChange={(_, formatString) =>
                                    changeBookingTime(formatString, 'checkOutTime')
                                }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <SingleSelect
                        control={control}
                        options={roomsTypes}
                        name={getFieldName('roomTypeId')}
                        id={getCreateBookingFormPageId(getFieldName('roomTypeId'))}
                        label=""
                        onChange={(value) => {
                            setCurrentRoomType(value);
                            setValue(getFieldName('roomId'), null);
                            setFieldValue('room', { id: null, name: '' });
                        }}
                        allowClear
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <SingleSelect
                        control={control}
                        options={rooms}
                        name={getFieldName('roomId')}
                        id={getCreateBookingFormPageId(getFieldName('roomId'))}
                        label=""
                        placeholder={t('roomBooking.form.placeholder.roomId')}
                        allowClear
                        onChange={(value) => {
                            const findRoom = rooms.find(
                                (option) => option.value === value,
                            );
                            setFieldValue('room', {
                                id: findRoom?.value || null,
                                name: findRoom?.label || '',
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <SingleSelect
                        control={control}
                        options={planDropDownOptions}
                        name={getFieldName('planId')}
                        id={getCreateBookingFormPageId(getFieldName('planId'))}
                        label=""
                        allowClear
                        onChange={(value) => {
                            setCurrentPlan(value);
                        }}
                    />
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={12}>
                    <InputAdult
                        label={t('roomBooking.form.scheduleBooking.adult')}
                        control={control}
                        setValue={setValue}
                        totalFieldId={getCreateBookingFormPageId('numberOfAdults')}
                        required={true}
                        isHorizontal={true}
                        className="booking-form-adult-input"
                        fieldName={Object.keys(InputAdultFieldName).reduce(
                            (fieldName, key) => {
                                return {
                                    ...fieldName,
                                    [key]: getFieldName(InputAdultFieldName[key]),
                                };
                            },
                            {},
                        )}
                        autoSetTotalGuest={false}
                    />
                </Col>
                <Col span={12}>
                    <Row gutter={8}>
                        <Col span={8}>
                            <div className="pt-5">
                                {t('roomBooking.form.scheduleBooking.child')}
                            </div>
                        </Col>
                        <Col span={16}>
                            <div>
                                <InputText
                                    label={''}
                                    name={getFieldName('childrenCount')}
                                    control={control}
                                    defaultValue={sumBy(
                                        booking.children,
                                        (child) => child.quantity,
                                    )}
                                    id={getCreateBookingFormPageId(
                                        getFieldName('childrenCount'),
                                    )}
                                />

                                <div
                                    className="input-overlay"
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    <DownOutlined
                                        className={classNames({
                                            'expand-icon': true,
                                            active: isOpen,
                                            deactive: !isOpen,
                                        })}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <ChildrenInput
                        isOpen={isOpen}
                        control={control}
                        name={(index) => getFieldName(`children.${index}`)}
                        id={(index) =>
                            getCreateBookingFormPageId(getFieldName(`children.${index}`))
                        }
                        defaultValue={getChildrenObject(booking.children)}
                        onChange={(value, total) => {
                            setValue(getFieldName('childrenCount'), total.toString());
                            setChildrenList(value);
                        }}
                        className="booking-form-children-input"
                    />
                </Col>
            </Row>
        </>
    );
}

export default BookingForm;
