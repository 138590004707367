import { Layout, Radio, RadioChangeEvent, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from '@tanstack/react-query';
import { StaffLayoutHeader } from '~components';
import { salesSummaryBreadcrumbs } from '~features/report/constants';
import FilterForm from '~features/report/pages/SalesSummaryPage/components/FilterForm/FilterForm';
import RoomSalesSummaryTable from '~features/report/pages/SalesSummaryPage/components/Table/RoomSalesSummaryTable';
import { useAppSelector } from '~hooks';
import FacilitySalesSummaryTable from './components/Table/FacilitySalesSummaryTable';
import { reportSalesSummarySelector } from '~features/report/reducers/sales-summary.reducer';
import ReceiptSummary from './components/ReceiptSummary/ReceiptSummary';
import { CacheKeys } from '~queries/queries';

export type SaleReportType = 'room' | 'facility';
function SalesSummaryPage() {
    const { t } = useTranslation();
    const [type, setType] = useState<SaleReportType>('room');
    const options = [
        {
            label: t('report.salesSummary.tab.roomBooking'),
            value: 'room',
        },
        {
            label: t('report.salesSummary.tab.facilityBooking'),
            value: 'facility',
        },
    ];
    const {
        showSalesSummaryTable,
        showFacilitySalesSummaryTable,
        showLoadingSalesSummaryTable,
    } = useAppSelector(reportSalesSummarySelector);

    const isFetchingReceiptSummaryData = useIsFetching({
        queryKey: [CacheKeys.getSalesSummaryReceiptDetails],
    });

    return (
        <div className="sales-summary-page">
            <StaffLayoutHeader
                breadcrumbs={salesSummaryBreadcrumbs()}
                title={t('report.salesSummary.title')}
            />
            <Layout.Content>
                <div className="sales-summary-page-wrapper">
                    <div className="sales-summary-page-content">
                        <Radio.Group
                            style={{ marginBottom: -1, paddingLeft: '24px' }}
                            options={options}
                            onChange={({ target: { value } }: RadioChangeEvent) => {
                                setType(value);
                            }}
                            value={type}
                            optionType="button"
                        />
                        <FilterForm salesReportType={type} />
                        {showSalesSummaryTable && type === 'room' && (
                            <Spin
                                spinning={
                                    showLoadingSalesSummaryTable ||
                                    Boolean(isFetchingReceiptSummaryData)
                                }
                            >
                                <RoomSalesSummaryTable />
                                {/* Note: Temporarily hide receipt summary table */}
                                {/* <ReceiptSummary /> */}
                            </Spin>
                        )}
                        {showFacilitySalesSummaryTable && type === 'facility' && (
                            <FacilitySalesSummaryTable />
                        )}
                    </div>
                </div>
            </Layout.Content>
        </div>
    );
}

export default SalesSummaryPage;
