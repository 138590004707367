import { Button, notification, Pagination, Table, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './RankCalendarList.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import { ColumnsType } from 'antd/lib/table';
import { IRankCalendar } from '~features/rank-calendar/interfaces';
import { useTranslation } from 'react-i18next';
import {
    initRankCalendarListQuery,
    OrderBy,
    RankCalendarColumn,
    Status,
} from '~features/rank-calendar/constants';
import { useEffect, useMemo, useState } from 'react';
import {
    fetchRankCalendarList,
    rankCalendarListQuerySelector,
    rankCalendarListSelector,
    setRankCalendarListQuery,
    showLoadingListSelector,
    totalPageSelector,
    totalRankCalendarSelector,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import { TableProps } from 'antd/es/table';
import { ISorter } from '~common/interfaces';
import {
    AdminGroup,
    AntdOrderDirection,
    cellTextErrorStyle,
    DEFAULT_ORDER_DIRECTION,
    OrderDirection,
} from '~common/constants';
import { useNavigate } from 'react-router-dom';
import { ModalConfirmDeletion, TextTruncate } from '~components';
import { rankCalendarService } from '~features/rank-calendar/services/rank-calendar.service';
import cloneDeep from 'lodash/cloneDeep';
import { checkUserPermission } from '~common/commonFunctions';
import { rankCalendarSchema } from '~common/validatorSchema';

function PlanRankCalendarList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const showLoadingList = useAppSelector(showLoadingListSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const rankCalendarListQuery = useAppSelector(rankCalendarListQuerySelector);
    const totalRankCalendar = useAppSelector(totalRankCalendarSelector);
    const rankCalendarList: IRankCalendar[] = useAppSelector(rankCalendarListSelector);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const isReadOnly = !checkUserPermission([...AdminGroup]);

    useEffect(() => {
        return () => {
            dispatch(setRankCalendarListQuery(cloneDeep(initRankCalendarListQuery)));
        };
    }, []);

    const fetchData = () => {
        dispatch(fetchRankCalendarList());
    };

    useEffect(() => {
        fetchData();
    }, [rankCalendarListQuery]);

    useEffect(() => {
        if (
            rankCalendarList.length === 0 &&
            (rankCalendarListQuery?.page as number) > 1
        ) {
            dispatch(
                setRankCalendarListQuery({
                    ...rankCalendarListQuery,
                    page: pageCount || 1,
                }),
            );
        }
    }, [rankCalendarList]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: IRankCalendar[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundRankCalendar = rankCalendarList.find(
                (rankCalendar) => rankCalendar.id === selectedRowKey,
            );
            if (foundRankCalendar) rows.push(foundRankCalendar);
        });
        return rows;
    }, [selectedRowKeys]);

    const onChangePage = (page: number) => {
        setSelectedRowKeys([]);
        dispatch(setRankCalendarListQuery({ ...rankCalendarListQuery, page }));
    };

    const onChange: TableProps<IRankCalendar>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setRankCalendarListQuery({
                    ...rankCalendarListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }

        if (!order) {
            dispatch(
                setRankCalendarListQuery({
                    ...rankCalendarListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: DEFAULT_ORDER_DIRECTION,
                }),
            );
        }
    };

    const showRankCalendarDetail = (rankCalendar: IRankCalendar) => {
        if (typeof rankCalendar.id === 'number') {
            navigate(`${rankCalendar.id}/detail`);
        }
    };

    const onClickOneRow = (record: IRankCalendar) => {
        return {
            onClick: () => {
                showRankCalendarDetail(record);
            },
        };
    };
    const onConfirmDeletion = async () => {
        const includeActiveRankCalendar = selectedRows.some((rankCalendar) => {
            return rankCalendar.status === Status.ACTIVE;
        });

        const includeInitRankCalendar = selectedRows.some((rankCalendar) => {
            return rankCalendar.isSeedData;
        });
        if (includeActiveRankCalendar) {
            notification.error({
                message: t('rankCalendar.list.message.cannotDeleteRankCalendar'),
            });
            return;
        }

        if (includeInitRankCalendar) {
            notification.error({
                message: t('rankCalendar.list.message.cannotDeleteInitRankCalendar'),
            });
            return;
        }
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });

        const response = await rankCalendarService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('rankCalendar.list.message.deleteRankCalendarsSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            okButtonProps: { danger: true },
            deletedItems: selectedRows.map((rankCalendar) => {
                return `${rankCalendar.name}`;
            }),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const getRankCalendarCellTextStyle = (rankCalendar: IRankCalendar) => {
        return rankCalendarSchema.isValidSync(rankCalendar) ? {} : cellTextErrorStyle;
    };

    const rankCalendarColumns: ColumnsType<IRankCalendar> = [
        {
            title: t('rankCalendar.list.rankCalendarColumn.id'),
            key: RankCalendarColumn.AUTO_GENERATED_CODE,
            sorter: true,
            width: '120px',
            dataIndex: RankCalendarColumn.AUTO_GENERATED_CODE,
            render: (autoGeneratedCode: string) => {
                return <a>{autoGeneratedCode}</a>;
            },
        },
        {
            title: t('rankCalendar.list.rankCalendarColumn.calendarName'),
            width: '50%',
            key: RankCalendarColumn.NAME,
            ellipsis: true,
            sorter: true,
            dataIndex: RankCalendarColumn.NAME,
            onCell: (rankCalendar: IRankCalendar) => {
                return {
                    style: getRankCalendarCellTextStyle(rankCalendar),
                };
            },
        },
        {
            title: t('rankCalendar.list.rankCalendarColumn.status'),
            width: '30%',
            key: RankCalendarColumn.STATUS,
            dataIndex: RankCalendarColumn.STATUS,
            onCell: (rankCalendar: IRankCalendar) => {
                return {
                    style: getRankCalendarCellTextStyle(rankCalendar),
                };
            },
            render: (status: Status) => {
                return (
                    <Tag color={status === Status.ACTIVE ? 'green' : 'red'}>
                        {t(`rankCalendar.list.status.${status}`)}
                    </Tag>
                );
            },
        },
    ];
    return (
        <>
            <div className="rank-calendar-list-wrapper">
                <div className="rank-calendar-list-header">
                    {!isReadOnly && selectedRows.length > 0 && (
                        <div className="rank-calendar-header-left">
                            <Button type="text" className="btn">
                                <DeleteOutlined onClick={showConfirmDialog} />
                            </Button>
                        </div>
                    )}
                </div>
                <Table
                    className="rank-calendar-table"
                    columns={rankCalendarColumns}
                    rowSelection={rowSelection}
                    pagination={false}
                    dataSource={rankCalendarList}
                    loading={showLoadingList}
                    onChange={onChange}
                    rowKey="id"
                    onRow={onClickOneRow}
                />
            </div>
            {pageCount > 1 && (
                <Pagination
                    defaultCurrent={rankCalendarListQuery.page}
                    current={rankCalendarListQuery.page}
                    total={totalRankCalendar}
                    pageSize={rankCalendarListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                    className="rank-calendar-list-pagination"
                />
            )}
        </>
    );
}

export default PlanRankCalendarList;
