import i18next from 'i18next';
import { DEFAULT_LIMIT_FOR_PAGINATION, DEFAULT_ORDER_DIRECTION } from '~common/constants';

export const breadcrumbs = () => [
    {
        text: i18next.t('history.list.breadcrumbs.home'),
    },
    {
        text: i18next.t('history.list.breadcrumbs.frontOffice'),
    },
    {
        text: i18next.t('history.list.breadcrumbs.history'),
    },
];

export enum SearchField {
    // PIC_NAME = 'picName',
    CREATED_BY_EMAIL = 'createdByEmail',
    ACTIVITY_TITLE = 'activityTitle',
}

export enum SelectSearchQuery {
    ACTION = 'action',
    TARGET_OBJECT_KEY = 'targetObjectKey',
}

export enum HistoryOrderBy {
    ID = 'id',
    CREATED_AT = 'createdAt',
    PIC_NAME = 'picName',
    ACTION = 'action',
}

export enum AuditLogType {
    BO = 'BO',
    FO = 'FO',
}

export const initHistoryListQuery = {
    keyword: '',
    page: 1,
    name: '',
    limit: DEFAULT_LIMIT_FOR_PAGINATION,
    orderBy: HistoryOrderBy.ID,
    orderDirection: DEFAULT_ORDER_DIRECTION,
    type: AuditLogType.FO,
};

export const enum HistoryColumn {
    CREATED_AT = 'createdAt',
    PIC_NAME = 'picName',
    ACTIVITY_TITLE = 'activityTitle',
    CREATED_BY_EMAIL = 'createdByEmail',
}

export enum AuditLogActions {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
    BULK_CREATE = 'bulkCreate',
    BULK_UPDATE = 'bulkUpdate',
    BULK_DELETE = 'bulkDelete',
    CHECK_IN = 'checkIn',
    CHECK_OUT = 'checkOut',
    UPDATE_STATUS = 'updateStatus',
    CANCEL = 'cancel',
    IMPORT_XML_CREATE = 'importXML-CreateRoomBooking',
    IMPORT_XML_MODIFY = 'importXML-ModifyRoomBooking',
}

export enum AuditLogModules {
    CHILDREN_TYPE = 'childrenType',
    FACILITY = 'facility',
    FACILITY_BOOKING = 'facilityBooking',
    FACILITY_TYPE = 'facilityType',
    GUEST = 'guest',
    GUEST_CLASSIFICATION = 'guestClassification',
    HOTEL = 'hotel',
    INDICATOR = 'indicator',
    MARKETING_CHANEL = 'marketingChannel',
    MARKETING_CHANEL_GROUP = 'marketingChannelGroup',
    NOTIFICATION = 'notification',
    PAYMENT_METHOD = 'paymentMethod',
    PLAN = 'plan',
    PLAN_RANK_CALENDAR = 'planRankCalendar',
    RECEIPT = 'receipt',
    RECEIPT_ITEM_DETAIL = 'receiptItemDetail',
    ROOM = 'room',
    ROOM_BOOKING = 'roomBooking',
    ROOM_BOOKING_ITEM = 'roomBookingItem',
    ROOM_BOOKING_ITEM_TEMPORARY = 'roomBookingItemTemporary',
    ROOM_TYPE = 'roomType',
    SALE_GROUP = 'saleGroup',
    SALE_ITEM = 'saleItem',
    STOPPING_INVENTORY = 'stoppingInventory',
    TAG = 'tag',
    TAX = 'tax',
    OVERBOOKING = 'overbooking',
}

export const EXPORT_CSV_FILE_NAME_CSV = 'history.csv';
export const EXPORT_CSV_FILE_NAME = 'history';

export const exportColumns = ['createdAt', 'createdByEmail', 'actionTitle'];
export const i18ExportKey = 'history.list';

export enum ReceiptItemBookingType {
    ROOM_BOOKING = 'room_booking',
    ROOM_BOOKING_ITEM = 'room_booking_item',
    FACILITY_BOOKING = 'facility_booking',
}
export enum ReceiptItemDetailType {
    STAY_PRICE = 'stay_price',
    SALE_ITEM = 'sale_item',
    OTHER_TAX = 'other_tax',
}

export enum ReceiptItemType {
    RECEIPT = 'receipt',
    PAYMENT = 'payment',
}
export enum RoomBookingItemBookingStatus {
    NOT_ARRIVED = 'not_arrived',
    CHECKED_IN = 'checked_in',
    CHECKED_OUT = 'checked_out',
    CANCELLED = 'cancelled',
}

export const getHeaderActivityContent = (action: string, key: string) => {
    const targetObject = {
        childrenType: 'Children Type',
        facility: 'Facility',
        facilityBooking: 'Facility Booking',
        facilityType: 'Facility Type',
        guest: 'Guest',
        guestClassification: 'Guest Classification',
        hotel: 'Hotel',
        indicator: 'Indicator',
        marketingChannel: 'Marketing Channel',
        marketingChannelGroup: 'Marketing Channel Group',
        paymentMethod: 'Payment Method',
        plan: 'Plan',
        planRankCalendar: 'Plan Rank Calendar',
        receipt: 'Receipt',
        receiptItemDetail: 'Receipt Item Detail',
        room: 'Room',
        roomBooking: 'Room Booking',
        roomBookingItem: 'Room Booking Item',
        roomBookingItemTemporary: 'Room Booking Item Temporary',
        roomType: 'Room Type',
        saleGroup: 'Sale Group',
        saleItem: 'Sale Item',
        stoppingInventory: 'Stopping Inventory',
        tax: 'Tax',
        generalSetting: 'General Setting',
        adminNotification: 'Admin Notification',
        overbooking: 'Overbooking',
    };

    switch (action) {
        case 'create':
            if (key === 'roomBookingItem' || key === 'overbooking') {
                return i18next.t(
                    `history.list.header.activityContent.specialCases.${key}.create`,
                );
            }
            return i18next.t('history.list.header.activityContent.create', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        case 'bulkCreate':
            return i18next.t('history.list.header.activityContent.bulkCreate', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        case 'update':
            if (key === 'roomBookingItem') {
                return i18next.t(
                    'history.list.header.activityContent.specialCases.roomBookingItem.update',
                );
            }
            return i18next.t('history.list.header.activityContent.update', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        case 'bulkUpdate': {
            if (key === 'receipt') {
                return i18next.t(
                    'history.list.header.activityContent.specialCases.receipt.bulkUpdate',
                );
            }
            if (key === 'roomBookingItem') {
                return i18next.t(
                    'history.list.header.activityContent.specialCases.roomBookingItem.bulkUpdate',
                );
            }
            return i18next.t('history.list.header.activityContent.bulkUpdate', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        }
        case 'updateStatus':
            if (key === 'roomBookingItem') {
                return i18next.t(
                    'history.list.header.activityContent.specialCases.roomBookingItem.updateStatus',
                );
            }
            return i18next.t('history.list.header.activityContent.updateStatus', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        case 'delete':
            return i18next.t('history.list.header.activityContent.delete', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        case 'bulkDelete': {
            if (key === 'receiptItemDetail') {
                return i18next.t(
                    'history.list.header.activityContent.specialCases.receiptItemDetail.bulkDelete',
                );
            }
            if (key === 'roomBookingItem') {
                return i18next.t(
                    'history.list.header.activityContent.specialCases.roomBookingItem.bulkDelete',
                );
            }
            return i18next.t('history.list.header.activityContent.bulkDelete', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        }
        case 'checkIn':
            return i18next.t('history.list.header.activityContent.checkIn', {
                targetObjectKey: targetObject[key as keyof typeof targetObject],
            });
        case 'importXML-CreateRoomBooking':
            return i18next.t(
                'history.list.header.activityContent.importXMLCreateRoomBooking',
                { targetObjectKey: targetObject[key as keyof typeof targetObject] },
            );
        case 'importXML-ModifyRoomBooking':
            return i18next.t(
                'history.list.header.activityContent.importXMLModifyRoomBooking',
                { targetObjectKey: targetObject[key as keyof typeof targetObject] },
            );
        default:
            return;
    }
};
