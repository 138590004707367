import {
    CalendarOutlined,
    LeftOutlined,
    RightOutlined,
    StopOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '~components';
import {
    getStoppingInventoryData,
    inventoryStopSellingStateSelector,
    setCurrentDate,
    setIsShowBulkStopSellingForm,
    setSelectedDate,
} from '~features/inventory/reducers/inventory-stop-selling.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';

import { checkUserPermission } from '~common/commonFunctions';
import { AdminGroup, SupportLanguage, UserGroup } from '~common/constants';
import { useThrottle } from '~common/useHooks';
import { Dayjs, parseDate, todayDayjs } from '~plugins/dayjs';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { currentLanguageSelector } from '~common/reducers/app.reducer';

export const TimeControl = () => {
    const { t } = useTranslation();
    const { currentDate } = useAppSelector(inventoryStopSellingStateSelector);
    const currentLang = useAppSelector(currentLanguageSelector);
    const dispatch = useAppDispatch();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { throttle } = useThrottle();

    const onBackDate = () => {
        throttle(() => {
            let _currentDate = parseDate(currentDate).subtract(1, 'month');
            if (_currentDate.month() === todayDayjs.month()) {
                _currentDate = todayDayjs;
            }
            dispatch(setCurrentDate(_currentDate?.fmYYYYMMDD()));
            dispatch(getStoppingInventoryData());
        }, 1000);
    };
    const onNextDate = () => {
        throttle(() => {
            dispatch(
                setCurrentDate(parseDate(currentDate).add(1, 'month')?.fmYYYYMMDD()),
            );
            dispatch(getStoppingInventoryData());
        }, 1000);
    };

    const selectOneDate = (date: Dayjs) => {
        setShowDatePicker(false);
        dispatch(setSelectedDate(date.fmYYYYMMDD()));
        if (!parseDate(date).isSame(parseDate(currentDate), 'month')) {
            dispatch(
                setCurrentDate(
                    parseDate(date).isSame(todayDayjs, 'month')
                        ? todayDayjs.fmYYYYMMDD()
                        : date.startOf('month')?.fmYYYYMMDD(),
                ),
            );
            dispatch(getStoppingInventoryData());
        }
    };

    const openDatePickerChange = (open: boolean) => {
        setShowDatePicker(open);
    };

    const disabledDate = (current: Dayjs) => {
        return current.isBefore(todayDayjs);
    };

    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const openBulkStopSellingModal = () => {
        dispatch(setIsShowBulkStopSellingForm(true));
    };

    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);
    return (
        <div className="stop-selling-time-control-wrapper">
            <div className="action-form-top">
                <div>
                    <Button
                        type="link"
                        className="text-button"
                        onClick={() => {
                            setShowDatePicker(true);
                        }}
                    >
                        <CalendarOutlined />
                        {t('inventory.list.dateDesignation')}
                    </Button>
                    <DatePicker
                        className="date-picker"
                        open={showDatePicker}
                        onSelect={selectOneDate}
                        disabledDate={disabledDate}
                        onOpenChange={openDatePickerChange}
                        locale={DatePickerLanguage}
                    />
                </div>
                <div>
                    <Button
                        className="previous-button"
                        onClick={onBackDate}
                        disabled={
                            parseDate(currentDate)?.fmYYYYMM() <= todayDayjs.fmYYYYMM()
                        }
                    >
                        <LeftOutlined />
                    </Button>
                    <Button className="next-button" onClick={onNextDate}>
                        <RightOutlined />
                    </Button>
                </div>
            </div>
            <div className="action-form-bottom">
                {!isReadOnly && (
                    <>
                        <Button
                            type="link"
                            className="text-button stop-selling-setting-button"
                            disabled={true}
                        >
                            <StopOutlined />
                            <span className="label-button">
                                {t('inventory.list.stopSellingButton')}
                            </span>
                        </Button>
                        <Button
                            type="link"
                            className="text-button"
                            onClick={openBulkStopSellingModal}
                        >
                            <StopOutlined />
                            {t('inventory.stopSelling.buttons.bulkSale')}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
