import { Badge, Layout, Spin, Tabs } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StaffLayoutHeader } from '~components';
import { getPlanDropdown } from '~features/plan/reducers/plan.reducer';
import { RoomBookingDetailPageTabPane } from '~features/room-booking/constants';
import {
    fetchHotelSmartUrlDetail,
    fetchRoomBookingDetail,
    getRoomBookingReceipt,
    roomBookingStateSelector,
    selectedRoomBookingDetailSelector,
    setActiveDetailTabPane,
    showLoadingSelector,
    setHsUrlDetail,
    fetchHotelSmartEditHitsory,
    setHsEditHistory,
    setIsHsUrlDetailSync,
} from '~features/room-booking/reducers/room-booking.reducer';
import { getRoomTypeListForDropdown } from '~features/room-type/room-type.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { CheckinModal } from '../SchedulePage/components/CheckinModal/CheckinModal';
import RoomBookingDetailTabPane from './components/RoomBookingDetailTabPane/RoomBookingDetailTabPane';
import RoomBookingReceiptTabPane from './components/RoomBookingReceiptTabPane/RoomBookingReceiptTabPane';
import './RoomBookingDetailPage.scss';
import { getCountStayedGuest } from '~features/guest/reducers/guest.reducer';
import {
    getHotelSmartConfig,
    setHotelSmart,
} from '~features/integrations/reducers/integrations.reducer';

const { Content } = Layout;

function RoomBookingDetailPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector(showLoadingSelector);
    const { roomBookingId } = useParams();
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const { activeDetailTabPane } = useAppSelector(roomBookingStateSelector);

    const breadcrumbs = () => [
        {
            text: t('roomBooking.list.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.list.breadcrumbs.roomBooking'),
        },
        {
            text: t('roomBooking.detail.breadcrumbs.roomBookingDetail'),
        },
    ];

    const numberOfBookingItems = useMemo(() => {
        return selectedRoomBookingDetail?.roomBookingItems?.length || 0;
    }, [selectedRoomBookingDetail]);
    const parsedRoomBookingId = Number(roomBookingId);

    const _getDetail = useCallback(async (id: number) => {
        const response = await dispatch(fetchRoomBookingDetail(id));
        if (
            fetchRoomBookingDetail.fulfilled.match(response) &&
            response.payload?.success
        ) {
            const booking = response.payload?.data;
            const isFromTll = !!booking?.tllDataId;
            dispatch(getPlanDropdown({ includeDataFromTll: isFromTll }));
            dispatch(getRoomTypeListForDropdown({}));
            dispatch(getCountStayedGuest(booking.representativeGuest?.id));
        }
    }, []);

    const _getReceipt = useCallback(async (id: number) => {
        const response = await dispatch(getRoomBookingReceipt(id));
        if (
            getRoomBookingReceipt.fulfilled.match(response) &&
            response.payload?.success
        ) {
            return;
        }

        navigate('/404');
    }, []);

    const changeDetailTab = (activeKey: string) => {
        if (activeKey === RoomBookingDetailPageTabPane.ROOM_BOOKING_RECEIPT_TAB_PANE) {
            _getReceipt(parsedRoomBookingId);
        }
        dispatch(setActiveDetailTabPane(activeKey));
    };

    const fetchHSUrlData = async (id: number) => {
        const response = await dispatch(fetchHotelSmartUrlDetail(id));
        if (
            fetchHotelSmartUrlDetail.fulfilled.match(response) &&
            response.payload?.data
        ) {
            dispatch(setIsHsUrlDetailSync(true));
            dispatch(setHsUrlDetail(response.payload.data));
        }
    };

    const fetchHSEditHistoryData = async (id: number) => {
        const response = await dispatch(fetchHotelSmartEditHitsory(id));
        if (
            fetchHotelSmartEditHitsory.fulfilled.match(response) &&
            response.payload?.data?.data?.length > 0
        ) {
            dispatch(setHsEditHistory(response.payload?.data));
        }
    };

    const fetchHSData = async () => {
        const response = await dispatch(getHotelSmartConfig());
        if (getHotelSmartConfig.fulfilled.match(response) && response.payload?.success) {
            dispatch(setHotelSmart(response.payload.data));
        }
    };

    useEffect(() => {
        if (isNaN(parsedRoomBookingId)) {
            navigate('/404');
        }
        fetchHSData();
        _getDetail(parsedRoomBookingId);
        fetchHSUrlData(parsedRoomBookingId);
        fetchHSEditHistoryData(parsedRoomBookingId);

        return () => {
            dispatch(
                setActiveDetailTabPane(
                    RoomBookingDetailPageTabPane.ROOM_BOOKING_LIST_TAB_PANE,
                ),
            );
        };
    }, [roomBookingId]);

    const getRoomBookingDetail = () => {
        _getDetail(parsedRoomBookingId);
    };

    return (
        <div className="room-booking-detail-page">
            <Spin spinning={isLoading}>
                <StaffLayoutHeader
                    breadcrumbs={breadcrumbs()}
                    title={t('roomBooking.detail.breadcrumbs.roomBookingDetail')}
                />
                <Content>
                    <Tabs
                        onChange={changeDetailTab}
                        activeKey={activeDetailTabPane}
                        items={[
                            {
                                key: RoomBookingDetailPageTabPane.ROOM_BOOKING_LIST_TAB_PANE,
                                label: (
                                    <span className="room-booking-detail-tab-pane">
                                        {t('roomBooking.detail.detailPagetabs.itemList')}

                                        {numberOfBookingItems > 1 && (
                                            <Badge
                                                className="number-of-booking-items"
                                                count={numberOfBookingItems}
                                                style={{
                                                    backgroundColor: '#F6F6F6',
                                                    color: '#555757',
                                                }}
                                            />
                                        )}
                                    </span>
                                ),
                                children: (
                                    <RoomBookingDetailTabPane
                                        changeDetailTab={changeDetailTab}
                                    />
                                ),
                            },
                            {
                                key: RoomBookingDetailPageTabPane.ROOM_BOOKING_RECEIPT_TAB_PANE,
                                label: t('roomBooking.detail.detailPagetabs.receipt'),
                                children: <RoomBookingReceiptTabPane />,
                            },
                        ]}
                    />
                    <CheckinModal
                        onChangeBookingStatusSuccess={getRoomBookingDetail}
                        isLoadedData={true}
                    />
                </Content>
            </Spin>
        </div>
    );
}

export default RoomBookingDetailPage;
