import { Layout } from 'antd';
import { SearchOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Select, Tooltip } from 'antd';
import { forEach } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDown from '~assets/images/arrow-down.svg';
import localStorageAuthService from '~common/authStorage';
import {
    AdminGroup,
    DEFAULT_DELAY_TIME_ON_SEARCH_INPUT,
    EmitterGlobalEvent,
    GA_KEYWORD_CONTENT,
    UserGroup,
} from '~common/constants';
import { getProfile } from '~features/auth/reducers/login.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useMitt } from '~plugins/mitt';
import { NotificationLayout } from './Notification/Notification';
import { TemporaryBookingLayout } from './TemporaryBookingDropdown/TemporaryBookingDropdown';
import './Topbar.scss';
import { setShowCreateBookingForm } from '~features/room-booking/reducers/schedule.reducer';
import { useNavigate } from 'react-router-dom';
import { checkUserPermission } from '~common/commonFunctions';
import { InputSearch } from '~components';
import {
    setKeyword,
    bookingSearchKeywordSelector,
    setBookingSearchKeyword,
    setIsShowInputSearch,
    isShowInputSearchSelector,
    setDateTypeBookingSearch,
    dateTypeBookingSearchSelector,
    bookingIteamSearchStatusSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import { DateType } from '~features/room-booking/constants';
import { trackSearchKeyword } from '~plugins/analytics';
import { CreateBookingButton } from '~features/room-booking/pages/SchedulePage/components/CreateBookingButton/CreateBookingButton';
import { useSelector } from 'react-redux';
import { selectedHotelSettingSelector } from '~features/hotel/hotel.reducer';
import { useIsMobile } from '~common/useHooks';
import classNames from 'classnames';
import { ReactComponent as PMSLogo } from '~assets/images/pms-header-logo.svg';

const { Option } = Select;
const { Header } = Layout;

interface ITopbar {
    handleClickDrawer?: () => void;
}

export function Topbar(props: ITopbar) {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [items, setItems] = useState<
        { id: number; autoGeneratedCode: string; name: string }[]
    >([]);
    const prevDateType = useRef(DateType.ALL);
    const bookingSearchKeyword = useAppSelector(bookingSearchKeywordSelector);
    const dateTypeBookingSearch = useAppSelector(dateTypeBookingSearchSelector);
    const isShowInputSearch = useAppSelector(isShowInputSearchSelector);
    const bookingItemSearchStatus = useAppSelector(bookingIteamSearchStatusSelector);
    const [selectedHotelId, setSelectedHotelId] = useState<number | null>(null);
    const selectedHotel = useSelector(selectedHotelSettingSelector);
    const [keywordHotel, setKeywordHotel] = useState<string>('');
    const inputRef = useRef<InputRef>(null);
    const { emitter } = useMitt();
    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeywordHotel(event.target.value);
    };

    const isReadOnly = useMemo(() => {
        return !checkUserPermission([
            ...AdminGroup,
            UserGroup.HOTEL_OWNER,
            UserGroup.HOTEL_OPERATOR,
            UserGroup.HOTEL_FRONT,
        ]);
    }, []);

    useEffect(() => {
        const pmsHotels = localStorageAuthService.getUser().pmsHotels || [];
        const selectedHotelId = +localStorageAuthService.getHotelId();
        setItems(
            pmsHotels.map((item) => ({
                id: item.id,
                autoGeneratedCode: item.autoGeneratedCode,
                name: item.name,
            })),
        );
        setSelectedHotelId(selectedHotelId);
    }, [selectedHotel?.name]);

    useEffect(() => {
        emitter.on(EmitterGlobalEvent.LOCAL_STORAGE_CHANGE, (updateHotelId) => {
            updateTopBar(updateHotelId);
        });

        return () => {
            emitter.off(EmitterGlobalEvent.LOCAL_STORAGE_CHANGE);
        };
    }, []);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            const pmsHotels = localStorageAuthService.getUser().pmsHotels || [];
            const filteredHotels: {
                id: number;
                autoGeneratedCode: string;
                name: string;
            }[] = [];
            forEach(pmsHotels, (hotel) => {
                if (
                    hotel.autoGeneratedCode
                        .toLowerCase()
                        .includes(keywordHotel.toLowerCase()) ||
                    hotel.name.toLowerCase().includes(keywordHotel.toLowerCase()) ||
                    hotel?.peCsrHotelCode
                        ?.toLowerCase()
                        ?.includes(keywordHotel.toLowerCase())
                ) {
                    filteredHotels.push({
                        id: hotel.id,
                        autoGeneratedCode: hotel.autoGeneratedCode,
                        name: hotel.name,
                    });
                }
            });
            setItems(filteredHotels);
        }, DEFAULT_DELAY_TIME_ON_SEARCH_INPUT);
        return () => clearTimeout(timeOutId);
    }, [keywordHotel]);
    const handleChange = (value: number) => {
        localStorageAuthService.setHotelId(value);
        if (window.location.pathname === '/bo-settings/hotel') {
            window.location.reload();
        } else {
            window.location.href = '/dashboard';
        }
    };

    const updateTopBar = useCallback(async (updateHotelId = false) => {
        const profileResponse = await dispatch(getProfile());
        if (getProfile.fulfilled.match(profileResponse)) {
            const loginUser = profileResponse.payload?.data;
            if (updateHotelId && loginUser?.pmsHotels?.[0]?.id) {
                localStorageAuthService.setHotelId(loginUser.pmsHotels[0]?.id);
                const selectedHotelId = +localStorageAuthService.getHotelId();
                setSelectedHotelId(selectedHotelId);
            }
            localStorageAuthService.setUser(loginUser);
            const pmsHotels = localStorageAuthService.getUser().pmsHotels || [];
            setItems(
                pmsHotels.map((item) => ({
                    id: item.id,
                    autoGeneratedCode: item.autoGeneratedCode,
                    name: item.name,
                })),
            );
        }
    }, []);

    const createReservation = () => {
        dispatch(setShowCreateBookingForm(true));
        navigate('/room-booking/schedule');
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSearch = (keyword: string) => {
        const status = bookingItemSearchStatus;
        navigate('/room-booking');
        dispatch(setBookingSearchKeyword(bookingSearchKeyword));
        dispatch(setDateTypeBookingSearch(DateType.ALL));
        dispatch(setKeyword({ bookingSearchKeyword, status }));
        trackSearchKeyword(bookingSearchKeyword || '', GA_KEYWORD_CONTENT.ROOM_BOOKING);
    };

    const onChangeBookingKeywordSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setBookingSearchKeyword(e.target.value));
        if (e.target.value && dateTypeBookingSearch !== DateType.ALL) {
            prevDateType.current = dateTypeBookingSearch;
            dispatch(setDateTypeBookingSearch(DateType.ALL));
        } else if (!e.target.value) {
            dispatch(setDateTypeBookingSearch(prevDateType.current));
            prevDateType.current = DateType.ALL;
        }
    };

    const onClickShowInputSearch = () => {
        dispatch(setIsShowInputSearch(true));
    };

    return (
        <Header className="staff-header">
            <div
                className={classNames('staff-header_wrapper', {
                    isMobile: isMobile,
                })}
            >
                <div className="staff-header_left-items">
                    {isMobile && (
                        <MenuOutlined
                            className="staff-header_menu-icon"
                            onClick={props.handleClickDrawer}
                        />
                    )}
                    <PMSLogo />
                </div>
                <ul className="staff-header-list">
                    {!isMobile && !isReadOnly && (
                        <>
                            <li className="staff-header-item mr-16">
                                {isShowInputSearch ? (
                                    <InputSearch
                                        placeholder={t('common.searchPlaceholder')}
                                        onSearch={onSearch}
                                        onChange={onChangeBookingKeywordSearch}
                                        value={bookingSearchKeyword}
                                        maxLength={30}
                                        autoFocus
                                    />
                                ) : (
                                    <Button
                                        onClick={onClickShowInputSearch}
                                        className="search-icon-btn"
                                    >
                                        <SearchOutlined />
                                    </Button>
                                )}
                            </li>
                            <li className="staff-header-item mr-16">
                                <CreateBookingButton
                                    createReservation={createReservation}
                                />
                            </li>
                            <li className="staff-header-item">
                                <TemporaryBookingLayout />
                            </li>
                            <li className="staff-header-item">
                                <NotificationLayout />
                            </li>
                        </>
                    )}
                    <li className="staff-header-item staff-header-hotel-select">
                        <Select
                            style={{ width: 160, height: '100%' }}
                            dropdownStyle={{
                                width: 372,
                                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                                borderRadius: 2,
                            }}
                            value={selectedHotelId}
                            optionLabelProp="label"
                            onChange={handleChange}
                            onDropdownVisibleChange={(open) =>
                                open && setKeywordHotel('')
                            }
                            suffixIcon={
                                <img className="arrow-down-icon" src={ArrowDown} alt="" />
                            }
                            dropdownMatchSelectWidth={false}
                            placeholder="Select hotel"
                            dropdownRender={(menu) => (
                                <>
                                    <div style={{ padding: 8 }}>
                                        <Input
                                            placeholder={t(
                                                'common.topbar.staffHeader.hotelDropdownList.searchBar.placeholder',
                                            )}
                                            ref={inputRef}
                                            value={keywordHotel}
                                            onChange={onSearchChange}
                                            allowClear
                                            className="topbar-hotel-select-input"
                                            autoFocus
                                        />
                                    </div>
                                    <div className="topbar-hotel-dropdown-menu">
                                        {menu}
                                    </div>
                                </>
                            )}
                        >
                            {items.map((item) => (
                                <Option
                                    key={item.id}
                                    value={item.id}
                                    label={
                                        <Tooltip placement="bottom" title={item.name}>
                                            <div
                                                style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {item.name}
                                            </div>
                                        </Tooltip>
                                    }
                                >
                                    <div className="topbar-hotel-dropdown-option">
                                        <span className="topbar-hotel-dropdown-option-code">
                                            {item.autoGeneratedCode}
                                        </span>
                                        <span className="topbar-hotel-dropdown-option-name">
                                            {item.name}
                                        </span>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </li>
                </ul>
            </div>
        </Header>
    );
}
