import React, { useEffect, useMemo, useState } from 'react';
import { ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, notification, Table, Pagination, Card, Tag, Modal } from 'antd';
import type { TableProps } from 'antd/es/table';
import {
    checkUserPermission,
    downloadFile,
    exportCSVFile,
} from '~common/commonFunctions';
import {
    AdminGroup,
    AntdOrderDirection,
    cellAutoGeneratedCodeStyle,
    cellTextErrorStyle,
    CsvOption,
    OrderDirection,
    UserGroup,
} from '~common/constants';
import {
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    i18ExportKey,
    FILE_NAME,
    GuestClassificationListColumn,
    OrderBy,
    initGuestClassificationListQuery,
} from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { guestClassificationService } from '../../../../services/guest-classification.api';
import {
    fetchGuestClassificationList,
    guestClassificationListQuerySelector,
    guestClassificationListSelector,
    showLoadingListSelector,
    totalPageSelector,
    totalGuestClassificationsSelector,
    setGuestClassificationListQuery,
    setSelectedGuestClassification,
    setShowForm,
} from '../../../../guest-classification.reducer';
import { useAppSelector, useAppDispatch } from '~hooks';
import { ModalConfirmDeletion, CsvDropdown } from '~components';
import { cloneDeep, orderBy } from 'lodash';
import { ISorter } from '~common/interfaces';
import { ColumnsType } from 'antd/lib/table';
import {
    IGuestClassification,
    IGuestClassificationExportCsvQuery,
} from '../../../../interfaces';
import './GuestClassificationList.scss';
import { guestClassifcationSchema } from '~common/validatorSchema';

function GuestClassificationList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const guestClassificationList = useAppSelector(guestClassificationListSelector);
    const guestClassificationListQuery = useAppSelector(
        guestClassificationListQuerySelector,
    );
    const pageCount = useAppSelector(totalPageSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);
    const totalGuestClassifications = useAppSelector(totalGuestClassificationsSelector);
    const isReadOnly = !checkUserPermission([
        ...AdminGroup,
        UserGroup.HOTEL_OWNER,
        UserGroup.HOTEL_OPERATOR,
    ]);

    const onChange: TableProps<IGuestClassification>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setGuestClassificationListQuery({
                    ...guestClassificationListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setGuestClassificationListQuery({
                    ...guestClassificationListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onSelectChange = (_selectedRowKeys: React.Key[]) => {
        const guestClassifications: IGuestClassification[] = [];
        _selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundGuestClass = guestClassificationList.find(
                (guestClassification: IGuestClassification) =>
                    guestClassification.id === selectedRowKey,
            );
            if (foundGuestClass) guestClassifications.push(foundGuestClass);
        });
        setSelectedRowKeys(_selectedRowKeys);
    };

    useEffect(() => {
        return () => {
            dispatch(
                setGuestClassificationListQuery(
                    cloneDeep(initGuestClassificationListQuery),
                ),
            );
        };
    }, []);
    const fetchData = () => {
        dispatch(fetchGuestClassificationList());
    };

    useEffect(() => {
        if (
            guestClassificationList.length === 0 &&
            guestClassificationListQuery?.page &&
            guestClassificationListQuery?.page > 1
        ) {
            const page = guestClassificationListQuery?.page - 1;
            dispatch(
                setGuestClassificationListQuery({
                    ...guestClassificationListQuery,
                    page,
                }),
            );
        }
    }, [guestClassificationList]);

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await guestClassificationService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('guestClassification.list.message.deleteSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            notification.error({
                message: response.message,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [guestClassificationListQuery]);

    const onChangePage = (page: number) => {
        dispatch(
            setGuestClassificationListQuery({ ...guestClassificationListQuery, page }),
        );
    };

    const onClickOneRow = (record: IGuestClassification) => {
        return {
            onClick: () => {
                if (!record.isSeedData && !isReadOnly) {
                    dispatch(setShowForm(true));
                    dispatch(setSelectedGuestClassification(record));
                }
            },
        };
    };

    const selectedRows = useMemo(() => {
        const rows: IGuestClassification[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundGuestClass = guestClassificationList.find(
                (guestClassification: IGuestClassification) =>
                    guestClassification.id === selectedRowKey,
            );
            if (foundGuestClass) rows.push(foundGuestClass);
        });
        return rows;
    }, [selectedRowKeys, guestClassificationList]);

    const exportCsv = async (query: IGuestClassificationExportCsvQuery) => {
        const response = await guestClassificationService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const showConfirmDialog = () => {
        if (isReadOnly) {
            return {
                onClick: () => {
                    return;
                },
            };
        } else {
            const hasCanNotDeleteItem = selectedRows.some(
                (row) => row.isSeedData === true,
            );
            if (hasCanNotDeleteItem) {
                Modal.error({
                    content: t('guestClassification.list.confirm.cantDelete'),
                    okText: t('common.ok'),
                });
            } else {
                ModalConfirmDeletion({
                    title: t('guestClassification.list.delete.title'),
                    buttonCancelText: t(
                        'guestClassification.list.delete.buttonCancelText',
                    ),
                    buttonDeleteText: t(
                        'guestClassification.list.delete.buttonDeleteText',
                    ),
                    okButtonProps: { danger: true },
                    deletedItems: selectedRows.map((row) => row.name.toString()),
                    onClickButtonDelete: onConfirmDeletion,
                });
            }
        }
    };

    const sortDataToCsv = (datas: IGuestClassification[]) => {
        const newDatas = datas.sort(function (data1, data2) {
            return data2.id - data1.id;
        });
        return newDatas;
    };
    const convertDataToCsv = (guestClassifications: IGuestClassification[]) => {
        const dataOutput: any[] = [];
        const newGuestClassifications = sortDataToCsv(guestClassifications);
        newGuestClassifications.forEach((guestClassification) => {
            const data = {
                name: guestClassification.name,
                autoGeneratedCode: guestClassification.autoGeneratedCode,
                color: guestClassification.color,
            };
            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportGuestClassificationList = async (
        guestClassifications: IGuestClassification[],
    ) => {
        try {
            // Create header file csv
            const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

            // Convert to file csv
            const roomDataCsvs = convertDataToCsv(guestClassifications);
            await exportCSVFile(exportColumns, filename, roomDataCsvs, i18ExportKey);
        } catch (error) {
            throw error;
        }
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all
                await exportCsv({
                    keyword: guestClassificationListQuery.keyword,
                    name: guestClassificationListQuery.name,
                    autoGeneratedCode: guestClassificationListQuery.autoGeneratedCode,
                    orderBy: guestClassificationListQuery.orderBy,
                    orderDirection: guestClassificationListQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportGuestClassificationList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getGuestClassificationCellTextStyle = (
        guestClassification: IGuestClassification,
    ) => {
        return guestClassifcationSchema.isValidSync(guestClassification)
            ? {}
            : cellTextErrorStyle;
    };

    const guestClassificationColumns: ColumnsType<IGuestClassification> = [
        {
            title: t('guestClassification.list.guestClassificationListColumn.id'),
            width: '30%',
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (guestClassification: IGuestClassification) => {
                return <div>{guestClassification.autoGeneratedCode}</div>;
            },
            key: GuestClassificationListColumn.ID,
            sorter: true,
        },
        {
            title: t('guestClassification.list.guestClassificationListColumn.category'),
            width: '40%',
            onCell: (guestClassification: IGuestClassification) => {
                return {
                    style: getGuestClassificationCellTextStyle(guestClassification),
                };
            },
            render: (guestClassification: IGuestClassification) => {
                return <div>{guestClassification.name}</div>;
            },
            key: GuestClassificationListColumn.NAME,
            sorter: true,
            ellipsis: true,
        },
        {
            title: t(
                'guestClassification.list.guestClassificationListColumn.categoryColor',
            ),
            width: '30%',
            dataIndex: '',
            key: GuestClassificationListColumn.COLOR,
            render: (guestClassification: IGuestClassification) => {
                let colorCode = `#${guestClassification.color}`;
                return <Tag className="category-color-tag" color={colorCode}></Tag>;
            },
        },
    ];

    return (
        <div className="guest-classification-list-wrapper">
            <Card
                className="guest-classification-card"
                title={
                    <div className="guest-classification-list-header">
                        {!isReadOnly && selectedRows.length > 0 && (
                            <div className="list-header-left">
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    onClick={showConfirmDialog}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )}
                        <div className="list-header-right">
                            <CsvDropdown
                                onChange={onChangeCsvOption}
                                hasSelectedColumns={selectedRows.length > 0}
                                hasImportOption={false}
                            />
                            <ReloadOutlined className="size-icon" onClick={onRefresh} />
                        </div>
                    </div>
                }
            >
                <Table
                    rowSelection={rowSelection}
                    columns={guestClassificationColumns}
                    dataSource={guestClassificationList}
                    onChange={onChange}
                    pagination={false}
                    rowKey="id"
                    loading={showLoadingList}
                    scroll={{ y: 400 }}
                    onRow={onClickOneRow}
                    rowClassName={(record) => {
                        if (record.isSeedData) {
                            return '';
                        }
                        return 'guest-classification-row';
                    }}
                />
            </Card>

            {pageCount > 1 && (
                <Pagination
                    className="guest-classification-list-pagination"
                    defaultCurrent={guestClassificationListQuery.page}
                    current={guestClassificationListQuery.page}
                    total={totalGuestClassifications}
                    pageSize={guestClassificationListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default GuestClassificationList;
