import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExceptionOutlined,
    FileDoneOutlined,
    RedEnvelopeOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Popconfirm, Space } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './HeaderReceipt.scss';
import { PaymentSetting } from '~features/room-booking/constants';
import { useAppSelector, useAppDispatch } from '~hooks';
import {
    roomBookingStateSelector,
    setShowBulkPaymentGroupBookingModal,
} from '~features/room-booking/reducers/room-booking.reducer';
import { IRoomBookingItemReceipt } from '~features/room-booking/interfaces';
import { useNavigate } from 'react-router-dom';
type Props = {
    isEditMode: boolean;
    selectedRowKeys: React.Key[];
    changeAllItemToRepresentativeRoom: (
        representativeBooking?: IRoomBookingItemReceipt,
    ) => void;
    changeAllRoomFeeToRepresentativeRoom: (
        representativeBooking?: IRoomBookingItemReceipt,
    ) => void;
    showDeleteReceiptItemConfirm: () => void;
    changeEditMode: () => void;
};
function HeaderReceipt(props: Props) {
    const {
        isEditMode,
        selectedRowKeys,
        changeAllItemToRepresentativeRoom,
        changeAllRoomFeeToRepresentativeRoom,
        showDeleteReceiptItemConfirm,
        changeEditMode,
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [openPaymentSettingPopConfirm, setOpenPaymentSettingPopConfirm] =
        useState(false);
    const [selectedPaymentSetting, setSelectedPaymentSetting] = useState<string | null>(
        null,
    );

    const { roomBookingReceipt } = useAppSelector(roomBookingStateSelector);

    const changePaymentSetting = (key: string) => {
        setOpenPaymentSettingPopConfirm(true);
        setSelectedPaymentSetting(key);
    };

    const paymentSettingOptions = useMemo(() => {
        return Object.values(PaymentSetting).map((setting) => {
            return {
                label: t(`roomBooking.detail.receipt.paymentSetting.${setting}`),
                key: setting,
                onClick: () => {
                    changePaymentSetting(setting);
                },
            };
        });
    }, []);

    const handleVisibleChange = (isVisible: boolean) => {
        if (openPaymentSettingPopConfirm) {
            setOpenPaymentSettingPopConfirm(isVisible);
        } else {
            setOpenPaymentSettingPopConfirm(false);
        }
    };

    const changePaymentSettingConfirm = () => {
        const representativeBooking =
            roomBookingReceipt?.roomBooking?.roomBookingItems?.find((roomBookingItem) => {
                return roomBookingItem.isRepresentativeRoom;
            });
        if (selectedPaymentSetting === PaymentSetting.ALL_ITEMS) {
            changeAllItemToRepresentativeRoom(representativeBooking);
        } else {
            changeAllRoomFeeToRepresentativeRoom(representativeBooking);
        }
    };

    const showBulkPaymentGroupBookingModal = () => {
        dispatch(setShowBulkPaymentGroupBookingModal(true));
    };
    const splitReceipt = () => {
        navigate(`split-receipt`);
    };

    const splitInvoice = () => {
        navigate(`split-invoice`);
    };

    return (
        <div className="header-receipt">
            <div className="header-receipt-title">
                <h2>{t('roomBooking.detail.receipt.title')}</h2>
                <p>{`(${t('common.standardTimeTitle')})`}</p>
            </div>

            <div
                className={!isEditMode ? 'header-table j-end' : 'header-table j-between'}
            >
                {isEditMode && (
                    <div className="right-header-table">
                        <Popconfirm
                            title={
                                <div className="change-booking-status-title">
                                    {selectedPaymentSetting ===
                                    PaymentSetting.ALL_ROOM_FREE ? (
                                        <span className="change-payment-setting-option">
                                            {t(
                                                `roomBooking.detail.receipt.paymentSetting.allRoomCharges`,
                                            )}
                                        </span>
                                    ) : (
                                        <span className="change-payment-setting-option">
                                            {t(
                                                `roomBooking.detail.receipt.paymentSetting.allDetails`,
                                            )}
                                        </span>
                                    )}
                                    <span>
                                        {t(
                                            `roomBooking.detail.receipt.paymentSetting.questionChangeConfirm`,
                                        )}
                                    </span>
                                </div>
                            }
                            open={openPaymentSettingPopConfirm}
                            placement="topRight"
                            overlayClassName="change-payment-setting-popconfirm"
                            okText={t(`roomBooking.detail.receipt.button.yes`)}
                            cancelText={t(`roomBooking.detail.receipt.button.no`)}
                            onOpenChange={handleVisibleChange}
                            onConfirm={changePaymentSettingConfirm}
                        >
                            <Dropdown menu={{ items: paymentSettingOptions }}>
                                <Button>
                                    <Space>
                                        {t(
                                            'roomBooking.detail.receipt.paymentSetting.title',
                                        )}
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Popconfirm>
                        {selectedRowKeys?.length > 0 && (
                            <Button
                                type="text"
                                shape="circle"
                                onClick={showDeleteReceiptItemConfirm}
                                icon={<DeleteOutlined />}
                            />
                        )}
                    </div>
                )}
                {!isEditMode && (
                    <div className="left-header-button-action">
                        <Button onClick={changeEditMode}>
                            <EditOutlined />
                            {t('roomBooking.detail.receipt.button.edit')}
                        </Button>
                        <Button onClick={splitReceipt}>
                            <FileDoneOutlined />
                            {t('roomBooking.detail.receipt.button.splitReceipt')}
                        </Button>
                        {/* Temporary hide
                        <Button onClick={splitInvoice}>
                            <ExceptionOutlined />
                            {t('roomBooking.detail.receipt.button.splitInvoice')}
                        </Button> */}
                        <Button onClick={showBulkPaymentGroupBookingModal}>
                            <RedEnvelopeOutlined />
                            {t('roomBooking.detail.receipt.button.bulkPayment')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeaderReceipt;
