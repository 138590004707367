import i18next from 'i18next';
import { Content, PageSize, TDocumentDefinitions } from 'pdfmake/interfaces';
import localStorageAuthService from '~common/authStorage';
import {
    formatMoney,
    makeRowWithCellSpan,
    textToChunksWithMaxLength,
} from '~common/commonFunctions';
import { PrintingOrientation, PrintingPaperSize, TaxType } from '~common/constants';
import {
    ReceiptItemType,
    FacilityBookingStatus,
} from '~features/facility-booking/constants';
import { convertSplitReceipt } from '~features/facility-booking/helper.split-receipt';
import { calculateTaxSaleItem } from '~features/room-booking/helper';
import { TAX_10_PERCENTAGE, TAX_8_PERCENTAGE } from '~features/room-booking/constants';
import { BankAccountResponseBody } from '~features/hotel/interfaces';
import customDayjs, { parseDate } from '~plugins/dayjs';
import {
    IFacilityBooking,
    IPaymentMethod,
    IReceiptByGroup,
    ISplitFacilityBookingReceiptTableData,
} from './../../../../interfaces';

interface IOptions {
    orientation: PrintingOrientation;
    setTotalPage: (totalPage: number) => void;
    paperSize: PrintingPaperSize;
}

const { t } = i18next;

export const generateSplitFacilityBookingReceiptPdf = (
    receiptByGroupList: IReceiptByGroup[],
    facilityBookingDetail: IFacilityBooking | null,
    options: IOptions,
    logNumber: number,
    isInvoice: boolean,
    bankAccounts: BankAccountResponseBody[],
    totalPaidAmount: Record<string, number>,
): TDocumentDefinitions => {
    const {
        orientation = PrintingOrientation.PORTRAIT,
        setTotalPage,
        paperSize = PrintingPaperSize.A4,
    } = options;

    let content: Content[] = [];

    for (let i = 1, n = receiptByGroupList.length; i <= n; i += 1) {
        content = content.concat(
            generateSplitRoomBookingContent(
                receiptByGroupList[i - 1],
                facilityBookingDetail,
                orientation,
                i !== n,
                isInvoice,
                bankAccounts,
                totalPaidAmount[receiptByGroupList[i - 1]!.group!.id] || 0,
            ) as Content,
        );
    }

    return {
        pageSize: paperSize.toUpperCase() as PageSize,
        pageOrientation: orientation,
        pageMargins: [28, 80, 24, 10],
        header: (currentPage, pageCount) => ({
            stack: [
                {
                    text: `${currentPage}/${pageCount}`,
                    margin: [0, 0, 0, 10],
                },
                {
                    text: `${isInvoice ? t('facilityBooking.splitReceipt.print.invoice') : t('facilityBooking.splitReceipt.print.receipt')}`,
                    alignment: 'center',
                    style: 'header',
                    margin: [0, -16, 0, 0],
                },
                {
                    text: `${t(
                        'facilityBooking.splitReceipt.print.issueDate',
                    )}: ${customDayjs().fmYYYYMMDDHHmmJp()}`,
                },
                {
                    text: `${
                        isInvoice ?
                            t('facilityBooking.splitReceipt.print.invoiceNumber') + ': IV' :
                            t('facilityBooking.splitReceipt.print.receiptNumber') + ': RC'
                    }-${
                        facilityBookingDetail?.autoGeneratedCode || ''
                    }-${logNumber}`,
                },
            ],
            style: 'pageHeader',
            alignment: 'right',
            margin: [40, 12, 24, 0],
        }),
        footer: (currentPage, pageCount) => {
            setTotalPage(pageCount);

            return {
                text: `${currentPage}/${pageCount}`,
                style: 'pageFooter',
                alignment: 'right',
                margin: [0, 0, 30, 0],
            };
        },
        content,
        styles: {
            pageHeader: {
                fontSize: 10,
            },
            header: {
                fontSize: 18,
                bold: true,
            },
        },
    };
};

const getPaymentMethodList = (data: ISplitFacilityBookingReceiptTableData[]) => {
    const paymentMethodList: {
        paymentMethod: IPaymentMethod;
        quantity: number;
        amount: number;
        bookingDate?: string;
        bookingDetail?: string;
        printDate?: string;
    }[] = [];

    data.forEach((item) => {
        if (item.type === ReceiptItemType.PAYMENT && item.paymentMethod) {
            paymentMethodList.push({
                paymentMethod: item.paymentMethod,
                quantity: 1,
                amount: item.amount || 0,
                bookingDate: item.bookingDate,
                bookingDetail: item.bookingDetail,
                printDate: item.printDate,
            });
        }
    });

    return paymentMethodList;
};

const generateSplitRoomBookingContent = (
    receiptByGroup: IReceiptByGroup,
    facilityBookingDetail: IFacilityBooking | null,
    orientation: PrintingOrientation,
    pageBreak?: boolean,
    isInvoice?: boolean,
    bankAccounts?: BankAccountResponseBody[],
    totalPaidAmount?: number,
) => {
    const NUMBER_OF_COLUMNS = 20;
    const hotel = localStorageAuthService.getSelectedHotel();
    const { t } = i18next;

    const convertedTableData = convertSplitReceipt(receiptByGroup?.receipt).reduce(
        (data: ISplitFacilityBookingReceiptTableData[], item) => {
            if (item.status === FacilityBookingStatus.CANCELLED) return data;
            data.push(...(item.children || []));
            return data;
        },
        [],
    );

    const paymentMethodList = getPaymentMethodList(convertedTableData);

    const totalPrice = (() => {
        let totalSale = 0;
        let totalPayment = 0;
        let totalTax = 0;
        let totalOtherTax = 0;
        let total10PercentTax = 0;
        let total8PercentTax = 0;
        let total10PercentSale = 0;
        let total8PercentSale = 0;

        convertedTableData.forEach((receiptItem) => {
            if (receiptItem.type === ReceiptItemType.PAYMENT) {
                totalPayment += receiptItem?.amount || 0;
            } else {
                totalSale += receiptItem?.amount || 0;
            }

            const tax = receiptItem?.receiptItemDetail?.saleItem?.tax;
            if (tax) {
                let taxValue = 0;
                taxValue += calculateTaxSaleItem(
                    receiptItem?.receiptItemDetail?.saleItem?.tax || null,
                    receiptItem?.receiptItemDetail?.quantity || 0,
                    receiptItem?.receiptItemDetail?.unitPrice || 0,
                );
                receiptItem.children?.forEach((item: any) => {
                    taxValue += calculateTaxSaleItem(
                        item?.saleItem?.tax || null,
                        item?.quantity || 0,
                        item?.unitPrice || 0,
                    );
                });
                totalTax += taxValue;
                if (tax?.type === TaxType.TAX_1) {
                    total10PercentTax += taxValue;
                    total10PercentSale += receiptItem?.receiptItemDetail?.amount || 0;
                } else if (tax?.type === TaxType.TAX_2) {
                    total8PercentTax += taxValue;
                    total8PercentSale += receiptItem?.receiptItemDetail?.amount || 0;
                }
            }
        });

        return {
            totalSale,
            totalPayment,
            totalTax,
            totalOtherTax,
            total10PercentTax,
            total8PercentTax,
            total10PercentSale,
            total8PercentSale,
        };
    })();

    return [
        {
            columns: [
                {
                    stack: [
                        {
                            text: receiptByGroup.guestName || '\n',
                        },
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 0,
                                    x2: 200,
                                    y2: 0,
                                    lineColor: '#00A0C3',
                                },
                            ],
                        },
                    ],
                    width: 218,
                },
                {
                    text: `${t('facilityBooking.splitReceipt.print.nameSubText')}`,
                    bold: true,
                    width: 50,
                },
                {
                    stack: [
                        {
                            text: hotel?.name ?? '\n',
                            bold: true,
                        },
                        {
                            text: hotel?.companyName ?? '',
                            margin: [0, 0, 0, 0],
                        },
                        {
                            text: hotel?.postalCode ? `〒${hotel?.postalCode}` : '',
                            margin: [0, 0, 0, 0],
                        },
                        {
                            text: textToChunksWithMaxLength(
                                hotel?.address,
                                28,
                                true,
                            ).join(`\n`),
                        },
                        {
                            text: hotel?.picPhoneNumber ? `TEL：${hotel?.picPhoneNumber}` : '',
                            margin: [0, 0, 0, 0],
                        },
                        {
                            text: `${t('facilityBooking.splitReceipt.print.registeredNumber')}: ${hotel?.registeredNumber || ''}`,
                            margin: [0, 0, 0, 0],
                        },
                    ],
                    width: '*',
                },
            ],
        },
        {
            text: isInvoice ? `${t('facilityBooking.splitReceipt.print.invoiceAmount')}` : `${t('facilityBooking.splitReceipt.print.amount')}`,
            bold: true,
            alignment: 'left',
            fontSize: 14,
        },
        {
            table: {
                widths: [190],
                body: [
                    [
                        {
                            columns: [
                                {
                                    text:
                                        `￥ ${formatMoney(
                                            Number(totalPaidAmount)
                                        )}` ??
                                        '\n',
                                    bold: true,
                                    alignment: 'left',
                                    fontSize: 18,
                                },
                            ],
                        },
                    ],
                ],
            },
            layout: {
                hLineWidth: (i: number) => i === 1 ? 2 : 0,
                vLineWidth: () => 0,
                hLineColor: '#00A0C3',
            },
            margin: [0, 0, 0, 10],
        },
        ...(!isInvoice ? [
            {
                columns: [
                    {
                        width: '*',
                        text: `${t(
                            'facilityBooking.splitReceipt.print.provisoSubText',
                        )}          ${
                            receiptByGroup.proviso?.trim()
                                ? receiptByGroup.proviso +
                                t(
                                    'facilityBooking.splitReceipt.billToTab.item.billProvisoSubText',
                                )
                                : ''
                        }`,
                        alignment: 'left',
                    },
                    {
                        width: 56,
                        table: {
                            widths: [56],
                            heights: [56],
                            body: [
                                [
                                    {
                                        text: textToChunksWithMaxLength(
                                            `${t(
                                                'facilityBooking.splitReceipt.print.stampPlaceholder',
                                            )}`,
                                            2,
                                            true,
                                        ).join(`\n`),
                                        margin: [15, 10, 0, 0],
                                    },
                                ],
                            ],
                            alignment: 'right',
                        },
                        layout: {
                            hLineStyle: () => ({
                                dash: { length: 3, space: 3 },
                            }),
                            vLineStyle: () => ({
                                dash: { length: 3, space: 3 },
                            }),
                        },
                    },
                ],
                margin: [0, 10, 10, 20],
            },
        ] : [{
            text: '',
            margin: [0, 10, 0, 0],
        }]),
        {
            columns: [
                {
                    stack: [
                        {
                            text: `${t(
                                'facilityBooking.splitReceipt.print.reservationNumber',
                            )}`,
                            bold: true,
                        },
                        {
                            text: `${t(
                                'facilityBooking.splitReceipt.print.reservation',
                            )} ${facilityBookingDetail?.autoGeneratedCode || ''}`,
                        },
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 0,
                                    x2: 200,
                                    y2: 0,
                                    lineColor: '#00A0C3',
                                },
                            ],
                        },
                    ],
                },
                {
                    stack: [
                        {
                            text: `${t('facilityBooking.splitReceipt.print.stayPeriod')}`,
                            bold: true,
                        },
                        {
                            columns: [
                                {
                                    width: 'auto',
                                    text: `${t(
                                        'facilityBooking.splitReceipt.print.dateText',
                                    )}`,
                                    margin: [0, 0, 20, 0],
                                },
                                {
                                    width: '*',
                                    text: `${parseDate(
                                        facilityBookingDetail?.startDatetime,
                                    )?.utc()?.fmYYYYMMDDJp()} 〜 ${parseDate(
                                        facilityBookingDetail?.endDatetime,
                                    )?.utc()?.fmYYYYMMDDJp()}`,
                                },
                            ],
                        },
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 0,
                                    x2: 260,
                                    y2: 0,
                                    lineColor: '#00A0C3',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            text: `${t('facilityBooking.splitReceipt.print.bookingDetails')}`,
            bold: true,
            margin: [0, 10, 0, 5],
        },
        {
            table: {
                widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                    .map(() => '*')
                    .concat('auto'),
                body: [
                    makeRowWithCellSpan([
                        {
                            colSpan: 4,
                            stack: [
                                {
                                    text: `${t(
                                        'facilityBooking.splitReceipt.print.table.bookingDate',
                                    )}`,
                                    alignment: 'center',
                                },
                            ],
                        },
                        {
                            colSpan: 7,
                            stack: [
                                {
                                    text: `${t(
                                        'facilityBooking.splitReceipt.print.table.bookingDetail',
                                    )}`,
                                    alignment: 'center',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: `${t(
                                        'facilityBooking.splitReceipt.print.table.unitPrice',
                                    )}`,
                                    alignment: 'center',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: `${t(
                                        'facilityBooking.splitReceipt.print.table.quantity',
                                    )}`,
                                    alignment: 'center',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: isInvoice ? `${t('facilityBooking.splitReceipt.print.invoiceAmount')}` : `${t('facilityBooking.splitReceipt.print.amount')}`,
                                    alignment: 'center',
                                },
                            ],
                        },
                    ]),
                    ...convertedTableData
                        .filter((item) => item.type !== ReceiptItemType.PAYMENT)
                        .map((item) => {
                            return makeRowWithCellSpan([
                                {
                                    colSpan: 4,
                                    stack: [
                                        {
                                            text: `${parseDate(
                                                item?.printDate || item?.bookingDate,
                                            )?.fmYYYYMMDDJp()}`,
                                            alignment: 'center',
                                        },
                                    ],
                                },
                                {
                                    colSpan: 7,
                                    stack: [
                                        {
                                            text: textToChunksWithMaxLength(
                                                item.saleItem?.name || item.bookingDetail,
                                                22,
                                                true,
                                            ) + (item?.receiptItemDetail?.saleItem?.tax?.type === TaxType.TAX_2 ? '※': ''),

                                            alignment: 'center',
                                        },
                                    ],
                                },
                                {
                                    colSpan: 3,
                                    stack: [
                                        {
                                            text:
                                                item.unitPrice || item.unitPrice === 0
                                                    ? `￥${formatMoney(item.unitPrice)}`
                                                    : '',
                                            alignment: 'right',
                                            margin: [0, 0, 5, 0],
                                        },
                                    ],
                                },
                                {
                                    colSpan: 3,
                                    stack: [
                                        {
                                            text: item.quantity,
                                            alignment: 'center',
                                        },
                                    ],
                                },
                                {
                                    colSpan: 3,
                                    stack: [
                                        {
                                            text:
                                                item.amount || item.amount === 0
                                                    ? `￥${formatMoney(item.amount)}`
                                                    : '',
                                            alignment: 'right',
                                            margin: [0, 0, 5, 0],
                                        },
                                    ],
                                },
                            ]);
                        }),
                    makeRowWithCellSpan([
                        {
                            colSpan: 4,
                            stack: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            colSpan: 7,
                            stack: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: `${t(
                                        'facilityBooking.splitReceipt.print.table.total',
                                    )}`,
                                    alignment: 'right',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: `￥${formatMoney(totalPrice.totalSale)}`,
                                    bold: true,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: 4,
                            stack: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            colSpan: 7,
                            stack: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: '',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: t(
                                        'facilityBooking.splitReceipt.print.totalTaxAmount',
                                    ),
                                    alignment: 'right',
                                },
                            ],
                        },
                        {
                            colSpan: 3,
                            stack: [
                                {
                                    text: `￥${formatMoney(totalPrice.totalTax)}`,
                                    bold: true,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                    ]),
                ],
            },
            layout: {
                hLineColor: '#D8D9D9',
                vLineColor: '#D8D9D9',
                fillColor: (rowIndex: number) => rowIndex === 0 ? '#EEF8F9' : '#FFFFFF',
            },
        },
        {
            columns: [
                {
                    stack: [
                        {
                            text: `※ ${t(
                                'facilityBooking.splitReceipt.print.reducedSalesTaxRate',
                            )}`,
                            alignment: 'left',
                            margin: [0, 5, 0, 5],
                        },
                    ]
                },
            ],
        },
        {
            table: {
                widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                    .map(() => 11)
                    .concat(0),
                body: [
                    makeRowWithCellSpan([
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `${TAX_10_PERCENTAGE}% ${t(
                                        'facilityBooking.splitReceipt.print.target',
                                    )}`,
                                    alignment: 'left',
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `￥${formatMoney(
                                        totalPrice.total10PercentSale - totalPrice.total10PercentTax,
                                    )}`,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: t(
                                        'facilityBooking.splitReceipt.print.taxAmount',
                                    ),
                                    alignment: 'left',
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `￥${formatMoney(
                                        totalPrice.total10PercentTax,
                                    )}`,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `${TAX_8_PERCENTAGE}% ${t(
                                        'facilityBooking.splitReceipt.print.target',
                                    )}`,
                                    alignment: 'left',
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `￥${formatMoney(
                                        totalPrice.total8PercentSale - totalPrice.total8PercentTax,
                                    )}`,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: t(
                                        'facilityBooking.splitReceipt.print.taxAmount',
                                    ),
                                    alignment: 'left',
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `￥${formatMoney(
                                        totalPrice.total8PercentTax,
                                    )}`,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                    ]),
                    makeRowWithCellSpan([
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: t(
                                        'facilityBooking.splitReceipt.print.otherTaxAmount',
                                    ),
                                    alignment: 'left',
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `￥${formatMoney(
                                        totalPrice.totalOtherTax,
                                    )}`,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: t(
                                        'facilityBooking.splitReceipt.print.otherTaxAmount',
                                    ),
                                    alignment: 'left',
                                },
                            ],
                        },
                        {
                            colSpan: 5,
                            stack: [
                                {
                                    text: `￥${formatMoney(
                                        totalPrice.totalOtherTax,
                                    )}`,
                                    alignment: 'right',
                                    margin: [0, 0, 5, 0],
                                },
                            ],
                        },
                    ]),
                ],
            },
            layout: {
                hLineColor: '#D8D9D9',
                vLineColor: '#D8D9D9',
                fillColor: () => {
                    return '#FFFFFF';
                },
            },
        },
        ...(paymentMethodList.length ? [
            {
                text: `${t('facilityBooking.splitReceipt.print.paymentDetails')}`,
                bold: true,
                margin: [0, 20, 0, 5],
            },
            {
                table: {
                    widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                        .map(() => '*')
                        .concat('auto'),
                    body: [
                        makeRowWithCellSpan([
                            {
                                colSpan: 4,
                                stack: [
                                    {
                                        text: `${t(
                                            'facilityBooking.splitReceipt.print.table.bookingDate',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 7,
                                stack: [
                                    {
                                        text: `${t(
                                            'facilityBooking.splitReceipt.print.table.bookingDetail',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: `${t(
                                            'facilityBooking.splitReceipt.print.table.unitPrice',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: `${t(
                                            'facilityBooking.splitReceipt.print.table.quantity',
                                        )}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                            {
                                colSpan: 3,
                                stack: [
                                    {
                                        text: isInvoice ? `${t('facilityBooking.splitReceipt.print.invoiceAmount')}` : `${t('facilityBooking.splitReceipt.print.amount')}`,
                                        alignment: 'center',
                                    },
                                ],
                            },
                        ]),
                        ...paymentMethodList.map((paymentMethod) => {
                            return makeRowWithCellSpan([
                                {
                                    colSpan: 4,
                                    stack: [
                                        {
                                            text: `${parseDate(
                                                paymentMethod?.printDate ||
                                                    paymentMethod?.bookingDate,
                                            )?.fmYYYYMMDDJp()}`,
                                            alignment: 'center',
                                        },
                                    ],
                                },
                                {
                                    colSpan: 7,
                                    stack: [
                                        {
                                            text:
                                                paymentMethod?.bookingDetail ||
                                                paymentMethod.paymentMethod.name,
                                            alignment: 'center',
                                        },
                                    ],
                                },
                                {
                                    colSpan: 3,
                                    stack: [
                                        {
                                            text: '',
                                            alignment: 'right',
                                            margin: [0, 0, 5, 0],
                                        },
                                    ],
                                },
                                {
                                    colSpan: 3,
                                    stack: [
                                        {
                                            text: paymentMethod.quantity,
                                            alignment: 'center',
                                        },
                                    ],
                                },
                                {
                                    colSpan: 3,
                                    stack: [
                                        {
                                            text: `￥${formatMoney(paymentMethod.amount)}`,
                                            alignment: 'right',
                                            margin: [0, 0, 5, 0],
                                        },
                                    ],
                                },
                            ]);
                        }),
                    ],
                },
                layout: {
                    hLineColor: '#D8D9D9',
                    vLineColor: '#D8D9D9',
                    fillColor: (rowIndex: number) => {
                        return rowIndex === 0 || rowIndex === 4 ? '#EEF8F9' : '#FFFFFF';
                    },
                },
            },
        ] : []),
        ...(isInvoice && bankAccounts?.length ? [
            {
                text: `${t('facilityBooking.splitReceipt.print.bankAccounts')}`,
                bold: true,
                margin: [0, 20, 0, 5],
            },
            {
                table: {
                    widths: Array.from(Array(NUMBER_OF_COLUMNS - 1).keys())
                        .map(() => '*')
                        .concat('auto'),
                    body: [
                        makeRowWithCellSpan([
                            {
                                colSpan: 20,
                                stack: [{
                                    text: bankAccounts.map(bankAccount =>
                                        `${bankAccount.bankName} ${t('facilityBooking.splitReceipt.print.bank')}  ` +
                                        `${bankAccount.branchName} ${t('facilityBooking.splitReceipt.print.branch')}   ` +
                                        `${t('facilityBooking.splitReceipt.print.accountNumber')} ${bankAccount.accountNumber}   ` +
                                        `${t('facilityBooking.splitReceipt.print.accountOwner')} ${bankAccount.accountOwner}\n`
                                    )
                                    .join('')
                                }, {
                                    text: t('facilityBooking.splitReceipt.print.bankAccountNote')
                                }]
                            }
                        ]),
                    ],
                },
                layout: {
                    hLineColor: '#D8D9D9',
                    vLineColor: '#D8D9D9',
                },
            },
        ] : []),
        ...(pageBreak ? [
            {
                text: '',
                pageBreak: 'after'
            }
        ] : [])
    ];
};
