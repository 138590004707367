import { CaretDownOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showConfirm } from '~common/notification';
import { InputNumber, InputText, SelectSaleItem, SingleDatePicker } from '~components';
import {
    MAX_LENGTH_PRICE,
    ReceiptByGroupMapIndex,
    ReceiptItemType,
} from '~features/facility-booking/constants';
import {
    convertSplitReceipt,
    updateNewFacilityReceiptItemDetail,
} from '~features/facility-booking/helper.split-receipt';
import {
    IFacilityBookingReceipt,
    IFacilityBookingReceiptItem,
    IFacilityBookingReceiptItemDetail,
    IGroup,
    ISplitFacilityBookingReceiptTableData,
} from '~features/facility-booking/interfaces';
import {
    addFacilityBookingReceiptItemsInGroupMap,
    receiptByGroupMapSelector,
    removeFacilityBookingReceiptItemsInGroupMap,
    setReceiptByGroupMap,
    updateFacilityBookingReceiptItemsInGroupAllMap,
} from '~features/facility-booking/reducers/split-receipt.reducer';
import { updateFacilityBookingSplitReceiptSchema } from '~features/facility-booking/schema';
import { SplitRoomBookingReceiptColumn } from '~features/room-booking/constants';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import SelectGroup from '../SelectGroup/SelectGroup';
import './ReceiptDetailItem.scss';
import { parseDate } from '~plugins/dayjs';
import { IPrintingReceiptItemDetail } from '~features/room-booking/interfaces';
import { INPUT_TEXT_MAX_LENGTH } from '~common/constants';

interface IProps {
    group: IGroup | null;
    groupList: IGroup[];
    receipt: IFacilityBookingReceipt;
}

function ReceiptDetailItem(props: IProps) {
    const { groupList, receipt } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const receiptByGroupMap = useAppSelector(receiptByGroupMapSelector);
    const { control, setValue } = useForm({
        resolver: yupResolver(updateFacilityBookingSplitReceiptSchema),
    });
    const [receiptTableData, setReceiptTableData] = useState<
        ISplitFacilityBookingReceiptTableData[]
    >([]);
    const [expandRowIds, setExpandRowIds] = useState<string[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        const tableData = convertSplitReceipt(receipt);
        setReceiptTableData(tableData);

        setExpandRowIds(
            tableData.map((receipt) => {
                return receipt.id;
            }),
        );
    }, [receipt]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const receiptRowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    /**
     * @param fromGroupId old group of the receiptItemDetails (sale items, etc.)
     * @param toGroupId the group receiptItemDetails will be moved in
     * @param facilityBooking
     * @param receiptItem
     * @param receiptItemDetails
     *
     * This methods remove receiptItemDetails in the facility booking receipt of the old group (if existed)
     * Then add receiptItemDetails to the facility booking receipt of the new group
     * Finally, update groupId of the receiptItemDetails in the key: 'ALL' of the group map to the new groupId
     */
    const moveFacilityBookingReceiptItems = (
        fromGroupId: string | undefined,
        toGroupId: string,
        receiptItem: IFacilityBookingReceiptItem,
        receiptItemDetails: IFacilityBookingReceiptItemDetail[],
    ) => {
        if (fromGroupId) {
            dispatch(
                removeFacilityBookingReceiptItemsInGroupMap({
                    groupId: fromGroupId?.toString() || null,
                    receiptItem,
                    receiptItemDetails,
                }),
            );
        }

        dispatch(
            addFacilityBookingReceiptItemsInGroupMap({
                groupId: toGroupId?.toString() || null,
                receiptItem,
                receiptItemDetails,
            }),
        );

        dispatch(
            updateFacilityBookingReceiptItemsInGroupAllMap({
                groupId: toGroupId?.toString() || null,
                receiptItem,
                receiptItemDetails,
            }),
        );
    };

    const _showChangeGroupReceiptItemsConfirm = (groupId: string) => {
        const toGroup = groupList.find((r) => r.id === groupId);
        showConfirm({
            title: t(
                'facilityBooking.splitReceipt.selectSplitBillTypePanel.confirmationText',
                {
                    name: toGroup?.name,
                },
            ),
            cancelText: t('common.buttonCancelText'),
            okText: t(
                'facilityBooking.splitReceipt.selectSplitBillTypePanel.buttonConfirmText',
            ),
            onOk() {
                onChangeGroupReceiptItems(groupId);
            },
            onCancel() {
                setSelectedRowKeys([]);
            },
        });
    };

    const onChangeGroupReceiptItems = (groupId: string) => {
        changeSelectedFacilityBookingItemsGroup(groupId);
        setSelectedRowKeys([]);
    };

    const changeSelectedFacilityBookingItemsGroup = (groupId: string) => {
        receipt.facilityBooking.receiptItems.forEach((receiptItem) => {
            const toUpdateReceiptItemDetails = receiptItem.receiptItemDetails.filter(
                (receiptItemDetail) => {
                    return selectedRowKeys.includes(
                        `fac_${receiptItem.id}_${receiptItemDetail.id}`,
                    );
                },
            );

            // Group receiptItemDetails to group of receiptItemDetail with the same groupId
            const groupIdMap: { [id: string]: IFacilityBookingReceiptItemDetail[] } = {};

            for (const receiptItemDetail of toUpdateReceiptItemDetails) {
                const { groupId } = receiptItemDetail;
                if (!groupId) continue;
                if (groupIdMap[groupId]) {
                    groupIdMap[groupId].push(receiptItemDetail);
                } else {
                    groupIdMap[groupId] = [receiptItemDetail];
                }
            }

            // Remove receiptItemDetails in the group of the group list
            // We don't use `moveFacilityBookingReceiptItems` method because
            // There have a chance of receiptItemDetails when change group in bulk have different group
            Object.keys(groupIdMap).forEach((groupId) => {
                dispatch(
                    removeFacilityBookingReceiptItemsInGroupMap({
                        groupId,
                        receiptItem,
                        receiptItemDetails: groupIdMap[groupId],
                    }),
                );
            });

            dispatch(
                addFacilityBookingReceiptItemsInGroupMap({
                    groupId,
                    receiptItem,
                    receiptItemDetails: toUpdateReceiptItemDetails,
                }),
            );

            dispatch(
                updateFacilityBookingReceiptItemsInGroupAllMap({
                    groupId,
                    receiptItem,
                    receiptItemDetails: toUpdateReceiptItemDetails,
                }),
            );
        });
    };

    const onChangeSingleReceiptItem = (
        previousGroupId: string | undefined,
        groupId: string,
        item: ISplitFacilityBookingReceiptTableData,
    ) => {
        setSelectedRowKeys(selectedRowKeys.filter((key) => key !== item.id));

        if (isFacilityBookingReceiptItem(item.id)) {
            moveFacilityBookingReceiptItems(
                previousGroupId?.toString(),
                groupId,
                item.receiptItem as IFacilityBookingReceiptItem,
                [item.receiptItemDetail as IFacilityBookingReceiptItemDetail],
            );
            return;
        }
    };

    const expandRow = (
        expanded: boolean,
        record: ISplitFacilityBookingReceiptTableData,
    ) => {
        if (expanded) {
            setExpandRowIds([...expandRowIds, record.id]);
        } else {
            const _expandRowIds = expandRowIds.filter((id) => {
                return id !== record.id;
            });
            setExpandRowIds([..._expandRowIds]);
        }
    };

    const isFacilityBookingReceiptItem = (id: string) => {
        return id.split('_')?.[0] === 'fac';
    };

    const isId = (item: ISplitFacilityBookingReceiptTableData) => {
        return item.name;
    };

    const isPaymentType = (item: ISplitFacilityBookingReceiptTableData) => {
        return item?.type === ReceiptItemType.PAYMENT;
    };

    const displayBookingId = (item: ISplitFacilityBookingReceiptTableData) => {
        if (item.name) {
            return `${t(item.name)}${(item.index || 0) + 1} ${item.autoGeneratedCode}`;
        }
        return '';
    };

    const onChangeSaleItem =
        (item: ISplitFacilityBookingReceiptTableData) =>
        (newSaleItem: ISaleItemDropdown) => {
            const { groupId, receiptItem } = item;

            const updatedReceipt = cloneDeep(receiptByGroupMap);
            const updatedReceiptItem = updatedReceipt[
                groupId!
            ].receipt.facilityBooking.receiptItems.find(
                (item) => item.id === receiptItem?.id,
            );
            const updatedReceiptItemDetail = updatedReceiptItem?.receiptItemDetails.find(
                (receiptItemDetail) =>
                    item.receiptItemDetail?.id === receiptItemDetail.id,
            );
            if (!updatedReceiptItemDetail) return;
            const { saleItem } = updatedReceiptItemDetail;
            updatedReceiptItemDetail.saleItemId = newSaleItem.id;

            if (!saleItem) return;
            saleItem.id = newSaleItem.id;
            saleItem.name = newSaleItem.name;

            dispatch(setReceiptByGroupMap(updatedReceipt));
        };

    const updatePriceSaleItem = (
        item: ISplitFacilityBookingReceiptTableData,
        newUnitPrice: number,
    ) => {
        const { groupId, receiptItem } = item;

        const updatedReceipt = cloneDeep(receiptByGroupMap);
        let updatedReceiptItem = updatedReceipt[
            groupId!
        ].receipt.facilityBooking.receiptItems.find(
            (item) => item.id === receiptItem?.id,
        );
        updatedReceiptItem?.receiptItemDetails.forEach((receiptItemDetail) => {
            if (
                receiptItemDetail.saleItem !== null &&
                item.receiptItemDetail?.id === receiptItemDetail.id
            ) {
                receiptItemDetail.unitPrice = newUnitPrice;
                receiptItemDetail.amount = newUnitPrice * receiptItemDetail.quantity;
            }
        });

        updatedReceiptItem = updatedReceipt[
            ReceiptByGroupMapIndex.ALL
        ].receipt.facilityBooking.receiptItems.find(
            (item) => item.id === receiptItem?.id,
        );
        updatedReceiptItem?.receiptItemDetails.forEach((receiptItemDetail) => {
            if (
                receiptItemDetail.saleItem !== null &&
                item.receiptItemDetail?.id === receiptItemDetail.id
            ) {
                receiptItemDetail.unitPrice = newUnitPrice;
                receiptItemDetail.amount = newUnitPrice * receiptItemDetail.quantity;
            }
        });

        dispatch(setReceiptByGroupMap(updatedReceipt));
    };

    useEffect(() => {
        receiptTableData.forEach((item) => {
            item.children?.forEach((item) => {
                setValue(`${item.id}.unitPrice`, item.receiptItemDetail?.unitPrice);
                setValue(`${item.id}.quantity`, item.receiptItemDetail?.quantity);
                setValue(`${item.id}.amount`, item.receiptItemDetail?.amount);
            });
        });
    }, [receiptTableData]);

    const updateReceiptItemDetail = (
        item: ISplitFacilityBookingReceiptTableData,
        newReceiptItemDetail: IPrintingReceiptItemDetail,
    ) => {
        const updatedReceipt = cloneDeep(receiptByGroupMap);
        updateNewFacilityReceiptItemDetail(
            updatedReceipt[item.groupId!],
            item,
            newReceiptItemDetail,
        );
        updateNewFacilityReceiptItemDetail(
            updatedReceipt[ReceiptByGroupMapIndex.ALL],
            item,
            newReceiptItemDetail,
        );
        dispatch(setReceiptByGroupMap(updatedReceipt));
    };

    const roomBookingReceiptColumns: ColumnsType<ISplitFacilityBookingReceiptTableData> =
        [
            {
                title: t('facilityBooking.splitReceipt.splitGroup'),
                onCell: (item: ISplitFacilityBookingReceiptTableData) => {
                    return {
                        style: { color: '#1c3e86' },
                        colSpan: isId(item) ? 4 : 1,
                    };
                },
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return isId(item) ? (
                        <span>{displayBookingId(item)}</span>
                    ) : (
                        <SelectGroup
                            selectedGroupId={item.groupId || undefined}
                            groupList={groupList}
                            onChangeValue={(
                                previousValue: string | undefined,
                                nextValue: string,
                            ) =>
                                onChangeSingleReceiptItem(previousValue, nextValue, item)
                            }
                            style={{ width: '100%' }}
                        />
                    );
                },
                key: SplitRoomBookingReceiptColumn.SPLIT_GROUP,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.date',
                ),
                ellipsis: true,
                onCell: (item: ISplitFacilityBookingReceiptTableData) => {
                    return {
                        colSpan: isId(item) ? 0 : 1,
                    };
                },
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return (
                        <SingleDatePicker
                            required
                            label=""
                            name={`${item.id}.date`}
                            defaultValue={parseDate(
                                item.facilityBooking?.startDatetime,
                            ).utc()}
                            control={control}
                            disabledDate={(current) =>
                                current.isBefore(
                                    parseDate(item.facilityBooking?.startDatetime).utc(),
                                    'day',
                                ) ||
                                current.isAfter(
                                    parseDate(item.facilityBooking?.endDatetime).utc(),
                                    'day',
                                )
                            }
                            onChange={(_, formatString) => {
                                updateReceiptItemDetail(item, {
                                    printDate: formatString,
                                });
                            }}
                        />
                    );
                },
                key: SplitRoomBookingReceiptColumn.DATE,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.bookingDetails',
                ),
                ellipsis: true,
                onCell: (item: ISplitFacilityBookingReceiptTableData) => {
                    return {
                        colSpan: isId(item) ? 0 : 1,
                    };
                },
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return item.receiptItemDetail?.saleItemId ? (
                        <SelectSaleItem
                            label=""
                            placeholder={t(
                                'facilityBooking.detail.receipt.saleItem.placeholder',
                            )}
                            name={`${item.id}.saleItemId`}
                            control={control}
                            required
                            dropdownStyle={{
                                minWidth: '400px',
                                maxWidth: '600px',
                            }}
                            showArrow={true}
                            showSearch={true}
                            optionLabelProp="label"
                            filterOption={(input, option) => {
                                return ((option!.label || '') as unknown as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                            }}
                            onChange={onChangeSaleItem(item)}
                            selectedValue={
                                item.receiptItemDetail?.saleItemId || undefined
                            }
                            selectedSaleItemIds={[]}
                            dropdownMatchSelectWidth={false}
                        />
                    ) : (
                        <InputText
                            label=""
                            placeholder={t(
                                'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.bookingDetails',
                            )}
                            name={`${item.id}.bookingDetail`}
                            control={control}
                            defaultValue={item.bookingDetail}
                            maxLength={INPUT_TEXT_MAX_LENGTH}
                            required
                            onBlur={(e) => {
                                updateReceiptItemDetail(item, {
                                    bookingDetail: e.target.value,
                                });
                            }}
                        />
                    );
                },
                key: SplitRoomBookingReceiptColumn.BOOKING_DETAILS,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.guestName',
                ),
                ellipsis: true,
                onCell: (item: ISplitFacilityBookingReceiptTableData) => {
                    return {
                        colSpan: isId(item) ? 0 : 1,
                    };
                },
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return <>{item.guest?.yomigana}</>;
                },
                key: SplitRoomBookingReceiptColumn.GUEST_NAME,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.roomName',
                ),
                ellipsis: true,
                onCell: (item: ISplitFacilityBookingReceiptTableData) => {
                    return {
                        colSpan: isId(item) ? 0 : 1,
                    };
                },
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return <>{item.room?.name}</>;
                },
                key: SplitRoomBookingReceiptColumn.ROOM_NAME,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.unitPrice',
                ),
                width: '150px',
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return (
                        !isId(item) &&
                        item.type !== ReceiptItemType.PAYMENT && (
                            <>
                                <InputNumber
                                    label=""
                                    prefix="¥"
                                    name={`${item.id}.unitPrice`}
                                    maxLength={MAX_LENGTH_PRICE}
                                    control={control}
                                    allowClear
                                    onBlur={(e) => {
                                        if (item.saleItem?.id) {
                                            updatePriceSaleItem(
                                                item,
                                                Number(e.target.value),
                                            );
                                        } else {
                                            updateReceiptItemDetail(item, {
                                                unitPrice: Number(e.target.value),
                                            });
                                        }
                                    }}
                                />
                            </>
                        )
                    );
                },
                key: SplitRoomBookingReceiptColumn.UNIT_PRICE,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.quantity',
                ),
                width: '80px',
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return (
                        !isId(item) &&
                        item.type !== ReceiptItemType.PAYMENT && (
                            <InputNumber
                                label=""
                                name={`${item.id}.quantity`}
                                control={control}
                                allowClear
                                onChange={(e) =>
                                    updateReceiptItemDetail(item, {
                                        quantity: Number(e.target.value),
                                    })
                                }
                            />
                        )
                    );
                },
                key: SplitRoomBookingReceiptColumn.QUANTITY,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.amount',
                ),
                width: '150px',
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return (
                        !isId(item) &&
                        (isPaymentType(item) ? (
                            <InputNumber
                                label=""
                                name={`${item.id}.amount`}
                                control={control}
                                allowClear
                                onChange={(e) =>
                                    updateReceiptItemDetail(item, {
                                        amount: Number(e.target.value),
                                    })
                                }
                            />
                        ) : (
                            <span className="total-earnings">{`¥${item.amount}`}</span>
                        ))
                    );
                },
                key: SplitRoomBookingReceiptColumn.AMOUNT,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.checkOutRoom',
                ),
                ellipsis: true,
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return <>{item.checkOutRoom?.name}</>;
                },
                key: SplitRoomBookingReceiptColumn.CHECK_OUT_ROOM,
            },
            {
                title: t(
                    'facilityBooking.splitReceipt.receiptDetailTab.item.receiptTableColumn.paymentMethod',
                ),
                ellipsis: true,
                render: (item: ISplitFacilityBookingReceiptTableData) => {
                    return <>{item.paymentMethod?.name}</>;
                },
                key: SplitRoomBookingReceiptColumn.PAYMENT_METHOD,
            },
        ];

    return (
        <div className="split-facility-booking-receipt-detail-item-wrapper">
            <div className="change-group-booking-receipt-select">
                {!!selectedRowKeys.length && (
                    <>
                        <span>
                            {t(
                                'facilityBooking.splitReceipt.receiptDetailTab.selectGroup',
                            )}
                        </span>
                        <SelectGroup
                            groupList={groupList}
                            onChange={_showChangeGroupReceiptItemsConfirm}
                            style={{ minWidth: '120px' }}
                        />
                    </>
                )}
            </div>
            <Table
                rowSelection={{
                    ...receiptRowSelection,
                    checkStrictly: false,
                }}
                columns={roomBookingReceiptColumns}
                dataSource={receiptTableData}
                pagination={false}
                rowClassName={(record) => {
                    return record.children?.length
                        ? 'row-expandable row-sale-item'
                        : 'row-sale-item';
                }}
                expandable={{
                    expandRowByClick: true,
                    expandedRowKeys: expandRowIds,
                    onExpand: expandRow,
                    expandIcon: ({ expanded, record }) =>
                        record?.level === 0 ? (
                            <span className="expand-icon">
                                <CaretDownOutlined
                                    className={classNames({
                                        active: !expanded,
                                        inactive: expanded,
                                    })}
                                />
                            </span>
                        ) : (
                            <></>
                        ),
                }}
                rowKey="id"
            />
        </div>
    );
}

export default ReceiptDetailItem;
