import {
    DeleteOutlined,
    DownOutlined,
    PrinterOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import {
    Button,
    Dropdown,
    Modal,
    Pagination,
    Popconfirm,
    Space,
    Table,
    notification,
} from 'antd';
import type { TableProps } from 'antd/es/table';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ColumnsType } from 'antd/lib/table';
import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    downloadFile,
    exportCsvFileWithXlsx,
    filterExportCSVBody,
    formatMoney,
    replaceBreakLine,
} from '~common/commonFunctions';
import { AntdOrderDirection, CsvOption, OrderDirection } from '~common/constants';
import { ISorter } from '~common/interfaces';
import { showConfirm } from '~common/notification';
import { CsvDropdown, ModalConfirmDeletion, TextTruncate } from '~components';
import FacilityBookingPrintingModal from '~features/facility-booking/components/FacilityBookingPrintingModal/FacilityBookingPrintingModal';
import {
    ChangingBookingStatusesMap,
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    FacilityBookingColumn,
    FacilityBookingOrderBy,
    FacilityBookingStatus,
    exportColumns,
    i18ExportKey,
} from '~features/facility-booking/constants';
import {
    IFacilityBooking,
    IFacilityBookingExportCsvQuery,
    IUpdateFacilityBookingStatus,
} from '~features/facility-booking/interfaces';
import FacilityBookingCheckInForm from '~features/facility-booking/pages/FacilitySchedulePage/components/FacilityBookingCheckInForm/FacilityBookingCheckInForm';
import {
    facilityBookingListQuerySelector,
    facilityBookingListSelector,
    fetchFacilityBookingList,
    setFacilityBookingListQuery,
    setIsShowAccommodationCardPrintingModal,
    setShowLoading,
    showLoadingSelector,
    totalFacilityBookingsSelector,
    totalPageSelector,
    updateFacilityBookingStatus,
} from '~features/facility-booking/reducers/facility-booking.reducer';
import {
    printFacilityBookingListSelector,
    setIsShowFacilityBookingPrintingModal,
} from '~features/facility-booking/reducers/print-facility-booking.reducer';
import { facilityBookingService } from '~features/facility-booking/services/facility-booking.service';
import { getBookingId } from '~features/room-booking/helper';
import { ICheckOutErrorItem } from '~features/room-booking/interfaces';
import { BookingErrorModal } from '~features/room-booking/pages/RoomBookingListPage/components/RoomBookingList/BookingErrorModal';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import FacilityBookingAccommodationCardPrintPopup from '../FacilityBookingAccommodationCardPrintPopup/FacilityBookingAccommodationCardPrintPopup';
import './FacilityBookingList.scss';

interface ICSVData {
    autoGeneratedCode: string;
    yomigana: string;
    fullName: string;
    phone: string;
    facilityName: string;
    status: string;
    bookingDate: string;
    startDatetime: string;
    endDatetime: string;
    roomBookingId: string;
    paymentStatus: string;
    totalAmount: string;
    memo: string;
}

function FacilityBookingList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [openChangeStatusPopConfirm, setOpenChangeStatusPopConfirm] = useState(false);
    const [selectedBookingStatus, setSelectedBookingStatus] = useState('');
    const [facilityBookingStatusOptions, setFacilityBookingStatusOptions] = useState<
        ItemType[]
    >([]);

    const facilityBookingListQuery = useAppSelector(facilityBookingListQuerySelector);
    const pageCount = useAppSelector(totalPageSelector);
    const totalFacilityBookings = useAppSelector(totalFacilityBookingsSelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const facilityBookingList = useAppSelector(facilityBookingListSelector);
    const { isShowFacilityBookingPrintingModal } = useAppSelector(
        printFacilityBookingListSelector,
    );

    const fetchData = async () => {
        const response = await dispatch(
            fetchFacilityBookingList(facilityBookingListQuery),
        );
        if (fetchFacilityBookingList.fulfilled.match(response)) {
            if (
                response.payload?.success &&
                response.payload?.data?.items?.length === 0 &&
                facilityBookingListQuery.page &&
                facilityBookingListQuery?.page > 1
            ) {
                dispatch(
                    setFacilityBookingListQuery({
                        ...facilityBookingListQuery,
                        page: facilityBookingListQuery.page - 1,
                    }),
                );
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [facilityBookingListQuery]);

    const onChangePage = (page: number) => {
        dispatch(setFacilityBookingListQuery({ ...facilityBookingListQuery, page }));
    };

    const selectedFacilityBookings = useMemo(() => {
        return facilityBookingList.filter((booking) => {
            return selectedRowKeys.includes(booking.id);
        });
    }, [selectedRowKeys]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getGuestNameWithBooking = (facilityBooking: IFacilityBooking) => {
        const { autoGeneratedCode, guest } = facilityBooking;
        if (!guest) return autoGeneratedCode;
        const { yomigana, fullName } = guest;
        const itemName = `${autoGeneratedCode} - ${yomigana}`;
        return fullName ? `${itemName} - ${fullName}` : itemName;
    };

    const onConfirmDeletion = async () => {
        const facilityBookings = selectedFacilityBookings.filter((booking) => {
            return (
                booking.status === FacilityBookingStatus.STARTED ||
                booking.status === FacilityBookingStatus.RESERVED
            );
        });

        if (facilityBookings.length) {
            Modal.error({
                title: t('facilityBooking.list.deleteConfirmDialog.titleDelete'),
                content: (
                    <>
                        <span>
                            {t(
                                'facilityBooking.list.deleteConfirmDialog.deleteByStatusError',
                            )}
                        </span>
                        <ul className="vertical-delete-list">
                            {facilityBookings.map((item, index) => (
                                <li key={index}>{getGuestNameWithBooking(item)}</li>
                            ))}
                        </ul>
                    </>
                ),
                okText: t('facilityBooking.list.deleteConfirmDialog.closeButton'),
            });
            return;
        }
        const selectedIds = selectedFacilityBookings.map((booking) => {
            return booking.id;
        });
        dispatch(setShowLoading(true));
        const response = await facilityBookingService.bulkDelete(selectedIds);
        if (response.success) {
            notification.success({
                message: t('facilityBooking.list.message.deleteRoomTypesSuccess'),
            });
            refreshData();
        } else {
            dispatch(setShowLoading(false));
            notification.error({
                message: response.message,
            });
        }
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            okButtonProps: { danger: true },
            description: t('facilityBooking.list.deleteConfirmDialog.description'),
            deletedItems: selectedFacilityBookings.map((booking) =>
                getGuestNameWithBooking(booking),
            ),
            buttonDeleteText: t('facilityBooking.list.deleteConfirmDialog.deleteButton'),
            buttonCancelText: t('facilityBooking.list.deleteConfirmDialog.cancelButton'),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const handleChangStatus = (status: string) => {
        setOpenChangeStatusPopConfirm(true);
        setSelectedBookingStatus(status);
    };

    const onChange: TableProps<IFacilityBooking>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setFacilityBookingListQuery({
                    ...facilityBookingListQuery,
                    orderBy: FacilityBookingOrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setFacilityBookingListQuery({
                    ...facilityBookingListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const showFacilityBookingDetail = (facilityBooking: IFacilityBooking) => {
        window.open(`/facility-booking/${facilityBooking.id}/detail`);
    };

    const goToRoomBookingDetail = (booking: IFacilityBooking) => {
        if (booking.roomBooking?.id) {
            window.open(`/room-booking/${booking.roomBooking?.id}/detail`);
        }
    };

    const facilityBookingColumns: ColumnsType<IFacilityBooking> = [
        {
            title: t('facilityBooking.list.column.autoGeneratedCode'),
            onCell: () => {
                return {
                    style: { color: '#1c3e86' },
                };
            },
            render: (booking: IFacilityBooking) => {
                return <span>{booking.autoGeneratedCode}</span>;
            },
            key: FacilityBookingColumn.ID,
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('facilityBooking.list.column.yomigana'),
            render: (booking: IFacilityBooking) => {
                return (
                    <div className="text-truncate">{booking?.guest?.yomigana || ''}</div>
                );
            },
            key: FacilityBookingColumn.YOMIGANA,
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('facilityBooking.list.column.fullName'),
            render: (booking: IFacilityBooking) => {
                return (
                    <div className="text-truncate">{booking.guest?.fullName || ''}</div>
                );
            },
            key: FacilityBookingColumn.GUEST_FULL_NAME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.mobilePhoneNumber'),
            render: (booking: IFacilityBooking) => {
                return <span>{booking.guest?.mobilePhoneNumber}</span>;
            },
            key: FacilityBookingColumn.GUEST_PHONE_NUMBER,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.facilityName'),
            render: (booking: IFacilityBooking) => {
                return <div className="text-truncate">{booking.facility?.name}</div>;
            },
            key: FacilityBookingColumn.FACILITY_NAME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.status'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span
                        className={`facility-booking-status facility-booking-status-${booking.status}`}
                    >
                        {t(`facilityBooking.list.bookingStatus.${booking.status}`)}
                    </span>
                );
            },
            key: FacilityBookingColumn.STATUS,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.bookingDate'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking?.createdAt
                            ? parseDate(booking.createdAt)?.fmYYYYMMDD('-')
                            : ''}
                    </span>
                );
            },
            key: FacilityBookingColumn.BOOKING_DATE,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.startDatetime'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking?.startDatetime
                            ? parseDate(booking.startDatetime)?.fmYYYYMMDDHHmm('-')
                            : ''}
                    </span>
                );
            },
            key: FacilityBookingColumn.START_DATE_TIME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.endDatetime'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking.endDatetime
                            ? parseDate(booking.endDatetime)?.fmYYYYMMDDHHmm('-')
                            : ''}
                    </span>
                );
            },
            key: FacilityBookingColumn.END_DATE_TIME,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.roomBookingId.title'),
            render: (booking: IFacilityBooking) => {
                return booking.roomBooking?.id ? (
                    <span>
                        {t('facilityBooking.list.column.roomBookingId.yes')} (ID:{' '}
                        <a
                            style={{ color: '#4DBDD5' }}
                            onClick={(e) => {
                                goToRoomBookingDetail(booking);
                                e.stopPropagation();
                            }}
                        >
                            {getBookingId(booking)}
                        </a>
                        )
                    </span>
                ) : (
                    <span>{t('facilityBooking.list.column.roomBookingId.no')}</span>
                );
            },
            key: FacilityBookingColumn.ROOM_BOOKING_ID,
            sorter: true,
        },
        {
            title: t('facilityBooking.list.column.paymentStatus.title'),
            render: (booking: IFacilityBooking) => {
                return (
                    <span>
                        {booking.totalAmount > 0
                            ? t('facilityBooking.list.column.paymentStatus.not')
                            : t('facilityBooking.list.column.paymentStatus.already')}
                    </span>
                );
            },
            key: FacilityBookingColumn.PAYMENT_STATUS,
            width: '100px',
        },
        {
            title: t('facilityBooking.list.column.paymentAmount'),
            render: (booking: IFacilityBooking) => {
                return (
                    <div>
                        ￥{' '}
                        {booking.totalAmount > 0
                            ? `${formatMoney(booking.totalAmount)}`
                            : '0'}
                    </div>
                );
            },
            key: FacilityBookingColumn.PAYMENT_AMOUNT,
        },
        {
            title: t('facilityBooking.list.column.memo'),
            sorter: true,
            render: (booking: IFacilityBooking) => {
                return <TextTruncate text={booking.memo || ''} />;
            },
            key: FacilityBookingColumn.MEMO,
        },
    ];

    useEffect(() => {
        const statusMenus = Object.values(FacilityBookingStatus).filter((status) => {
            return status !== FacilityBookingStatus.ALL;
        });

        setFacilityBookingStatusOptions(
            statusMenus.map((status) => {
                return {
                    label: t(`facilityBooking.list.bookingStatus.${status}`),
                    key: status.toString(),
                    onClick: () => {
                        handleChangStatus(status.toString());
                    },
                };
            }),
        );
    }, [t]);

    const exportCsv = async (query: IFacilityBookingExportCsvQuery) => {
        const response = await facilityBookingService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const convertDataToCsv = (facilities: IFacilityBooking[]) => {
        const dataOutput: ICSVData[] = [];
        facilities.forEach((facility) => {
            const data = {
                autoGeneratedCode: facility.autoGeneratedCode,
                yomigana: facility.guest?.yomigana || '',
                fullName: facility.guest?.fullName || '',
                phone: facility.guest?.mobilePhoneNumber || '',
                facilityName: facility.facility?.name || '',
                status: t(`facilityBooking.list.bookingStatus.${facility.status}`),
                bookingDate: facility?.createdAt
                    ? parseDate(facility.createdAt)?.fmYYYYMMDD('-')
                    : '',
                startDatetime: facility?.startDatetime
                    ? parseDate(facility.startDatetime)?.fmYYYYMMDDHHmm('-')
                    : '',
                endDatetime: facility?.endDatetime
                    ? parseDate(facility.endDatetime)?.fmYYYYMMDDHHmm('-')
                    : '',
                roomBookingId:
                    getBookingId(facility) ||
                    t('facilityBooking.list.column.roomBookingId.no'),
                paymentStatus: facility.paymentId
                    ? t('facilityBooking.list.column.paymentStatus.already')
                    : t('facilityBooking.list.column.paymentStatus.not'),
                totalAmount: facility.totalAmount ? `${facility.totalAmount}` : '0',
                memo: replaceBreakLine(facility.memo || ''),
            };
            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportFacilityBookingList = async () => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const roomDataCsvs = convertDataToCsv(selectedFacilityBookings);
        const heading = exportColumns.map((key) =>
            t(`${i18ExportKey}.exportColumns.${key}`),
        );
        exportCsvFileWithXlsx(roomDataCsvs, filename, heading);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all facility booking
                const body = { ...facilityBookingListQuery };
                filterExportCSVBody(body);
                await exportCsv(body);
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportFacilityBookingList();
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };

    const showFinishedErrorModal = (bookingIds: number[]) => {
        const bookingErrors = selectedFacilityBookings.filter((booking) =>
            bookingIds.includes(booking.id),
        );
        BookingErrorModal({
            title: t('facilityBooking.schedule.modalCheckoutFailed.title'),
            okText: t('facilityBooking.schedule.modalCheckoutSuccess.okBtn'),
            description: t('facilityBooking.schedule.modalCheckoutFailed.description'),
            errorItems: bookingErrors.map((item) => getGuestNameWithBooking(item)),
        });
    };

    const _updateFacilityBookingItemStatus = async (
        body: IUpdateFacilityBookingStatus,
    ) => {
        const response = await dispatch(updateFacilityBookingStatus(body));
        setOpenChangeStatusPopConfirm(false);
        if (updateFacilityBookingStatus.fulfilled.match(response)) {
            if (response.payload?.success) {
                let message = null;
                if (body.status === FacilityBookingStatus.FINISHED) {
                    message = t('roomBooking.list.checkoutDialog.checkoutSuccess');
                }
                notification.success({
                    message:
                        message || t('facilityBooking.list.statusModalConfirm.success'),
                });
                refreshData();
            } else {
                if (body.status === FacilityBookingStatus.FINISHED) {
                    if (response.payload?.errors?.length) {
                        const bookingIds = response.payload?.errors?.map((error) => {
                            return (error as ICheckOutErrorItem).bookingId;
                        });
                        showFinishedErrorModal(bookingIds);
                    }
                } else {
                    Modal.error({
                        content: response.payload?.message || '',
                        okText: t('facilityBooking.list.statusModalConfirm.okText'),
                    });
                }
            }
            setOpenChangeStatusPopConfirm(false);
        }
    };

    const checkBookingCheckIn = () => {
        return selectedFacilityBookings.filter((item) => {
            return parseDate(item.startDatetime).isAfter(customDayjs(), 'minute');
        });
    };

    const changeBookingStatusConfirm = async () => {
        for (const facilityBooking of selectedFacilityBookings) {
            if (
                !ChangingBookingStatusesMap[facilityBooking.status]?.includes(
                    selectedBookingStatus as FacilityBookingStatus,
                )
            ) {
                Modal.error({
                    title: t('facilityBooking.list.statusModalConfirm.title'),
                    content: t(
                        'facilityBooking.list.statusModalConfirm.errorChangeStatus',
                        {
                            beforeStatus: t(
                                `facilityBooking.list.bookingStatus.${facilityBooking.status}`,
                            ),
                            afterStatus: t(
                                `facilityBooking.list.bookingStatus.${selectedBookingStatus}`,
                            ),
                        },
                    ),
                    okText: t('facilityBooking.list.statusModalConfirm.okText'),
                });
                setOpenChangeStatusPopConfirm(false);
                return;
            }
        }

        if (selectedBookingStatus === FacilityBookingStatus.STARTED) {
            const bookingErrors = checkBookingCheckIn();
            if (bookingErrors.length) {
                BookingErrorModal({
                    title: t('facilityBooking.schedule.modalCheckInFailed.title'),
                    okText: t('facilityBooking.schedule.modalCheckInFailed.okBtn'),
                    description: t(
                        'facilityBooking.schedule.modalCheckInFailed.description',
                    ),
                    errorItems: bookingErrors.map((item) =>
                        getGuestNameWithBooking(item),
                    ),
                });
                return;
            }
        }

        _updateFacilityBookingItemStatus({
            status: selectedBookingStatus as FacilityBookingStatus,
            items: selectedFacilityBookings.map((item, index) => {
                return {
                    id: item.id,
                    order: index + 1,
                };
            }),
        });
    };

    const changeBookingStatusCancel = () => {
        setOpenChangeStatusPopConfirm(false);
    };

    const refreshData = () => {
        fetchData();
        setSelectedRowKeys([]);
    };

    const handleVisibleChange = (isVisible: boolean) => {
        if (openChangeStatusPopConfirm) {
            setOpenChangeStatusPopConfirm(isVisible);
        } else {
            setOpenChangeStatusPopConfirm(false);
        }
    };

    const closePrintModal = () => {
        showConfirm({
            title: t('facilityBooking.detail.cancelTitle'),
            onOk() {
                dispatch(setIsShowFacilityBookingPrintingModal(false));
            },
        });
    };

    const showAccommodationCardPrintingPopup = () => {
        dispatch(setIsShowAccommodationCardPrintingModal(true));
    };

    return (
        <div className="facility-booking-list-wrapper">
            <div className="facility-booking-header">
                {selectedRowKeys?.length > 0 && (
                    <div className="list-header-left">
                        <Button
                            type="text"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={showConfirmDialog}
                        />
                        <Popconfirm
                            title={
                                <div className="change-booking-status-title">
                                    <span className="title-status-pop-confirm">
                                        {t('facilityBooking.list.statusPopConfirm.title')}
                                    </span>
                                    <br />
                                    {t('facilityBooking.list.statusPopConfirm.content', {
                                        status: t(
                                            `facilityBooking.list.bookingStatus.${selectedBookingStatus}`,
                                        ),
                                    })}
                                </div>
                            }
                            open={openChangeStatusPopConfirm}
                            placement="bottomRight"
                            okText={t('facilityBooking.list.statusPopConfirm.okText')}
                            cancelText={t(
                                'facilityBooking.list.statusPopConfirm.cancelText',
                            )}
                            overlayClassName="change-booking-status-popconfirm"
                            onOpenChange={handleVisibleChange}
                            onConfirm={changeBookingStatusConfirm}
                            onCancel={changeBookingStatusCancel}
                        >
                            <Dropdown
                                menu={{ items: facilityBookingStatusOptions }}
                                className="status-dropdown"
                            >
                                <Button>
                                    <Space>
                                        {t('facilityBooking.list.dropdown.title')}
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Popconfirm>
                    </div>
                )}

                <div className="list-header-right">
                    <CsvDropdown
                        onChange={onChangeCsvOption}
                        hasSelectedColumns={selectedFacilityBookings.length > 0}
                        hasImportOption={false}
                    />
                    <Button
                        type="text"
                        shape="circle"
                        icon={
                            <PrinterOutlined
                                onClick={showAccommodationCardPrintingPopup}
                            />
                        }
                    />
                    <Button
                        type="text"
                        shape="circle"
                        icon={<ReloadOutlined className="size-icon" />}
                        onClick={refreshData}
                    />
                </div>
            </div>

            <Table
                locale={{
                    triggerDesc: t('dashboard.sort.desc'),
                    triggerAsc: t('dashboard.sort.asc'),
                    cancelSort: t('dashboard.sort.cancel'),
                }}
                rowSelection={rowSelection}
                columns={facilityBookingColumns}
                dataSource={facilityBookingList}
                onChange={onChange}
                pagination={false}
                rowKey="id"
                loading={showLoading}
                scroll={{ x: 2000, y: 380 }}
                className="table-scrollbar"
                rowClassName={'facility-booking-row'}
                onRow={(record) => {
                    return {
                        // click row
                        onClick: () => {
                            showFacilityBookingDetail(record);
                        },
                    };
                }}
            />

            {pageCount > 1 && (
                <Pagination
                    className="facility-booking-pagination"
                    defaultCurrent={facilityBookingListQuery.page}
                    current={facilityBookingListQuery.page}
                    total={totalFacilityBookings}
                    pageSize={facilityBookingListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
            <FacilityBookingCheckInForm fetchFacilityBookingList={refreshData} />
            <FacilityBookingPrintingModal
                isShowFacilityBookingPrintingModal={isShowFacilityBookingPrintingModal}
                onClose={closePrintModal}
            />
            <FacilityBookingAccommodationCardPrintPopup />
        </div>
    );
}

export default FacilityBookingList;
