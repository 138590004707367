import { Button, Form, Select, Switch } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerType } from '~common/constants';
import { SingleDatePicker } from '~components';
import {
    salesByCategorySelector,
    setExportCsv,
    setFilterDate,
    setFilterRoomTypeId,
    setIsShowByDay,
    setShowSalesByCategoryTable,
} from '~features/report/reducers/sales-by-category.reducer';
import { salesByCategoryFilterSchema } from '~features/report/schema';
import {
    getRoomTypeListForDropdown,
    roomTypeDropdownOptionsSelector,
} from '~features/room-type/room-type.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import './FilterForm.scss';
import dayjs from '~plugins/dayjs';
import { SalesByCategoryDisplayMode } from '~features/report/constants';
import { reportingServiceV2 } from '~features/report/reportV2.service';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '~queries/queries';

function FilterForm() {
    const allOptionValue = 0;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roomTypeList = useAppSelector(roomTypeDropdownOptionsSelector);
    const { showLoadingSalesByCategoryTable, showSalesByCategoryTable } = useAppSelector(
        salesByCategorySelector,
    );
    const [date, setDate] = useState<Dayjs>(customDayjs());
    const [roomTypeId, setRoomTypeId] = useState<number>(allOptionValue);
    const [showByDay, setShowByDay] = useState(false);
    const { control, setValue } = useForm({
        resolver: yupResolver(salesByCategoryFilterSchema),
    });

    useEffect(() => {
        dispatch(getRoomTypeListForDropdown({}));
        dispatch(setShowSalesByCategoryTable(false));
        setValue(`day`, customDayjs());
    }, []);

    const fetchSalesByCategoryData = async () => {
        const startDate = dayjs(date).startOf('month').format('YYYY-MM-DD');
        const endDate = dayjs(date).endOf('month').format('YYYY-MM-DD');
        const params = {
            datePeriod: [startDate, endDate],
            roomTypeId: roomTypeId ?? null,
            displayMode: showByDay
                ? SalesByCategoryDisplayMode.DAILY
                : SalesByCategoryDisplayMode.MONTHLY,
        };
        const response = await reportingServiceV2.getSalesByCategory(params);
        if (response.success) {
            dispatch(setShowSalesByCategoryTable(true));
            return response.data;
        } else {
            throw Error(response.message);
        }
    };

    const { refetch, isFetching } = useQuery({
        queryKey: [CacheKeys.getSalesByRoomTypesKey],
        queryFn: fetchSalesByCategoryData,
        enabled: false,
    });

    const roomTypeListOptions = useMemo(() => {
        return [
            {
                value: allOptionValue,
                label: t('report.salesByCategory.filterForm.allOption'),
            },
            ...roomTypeList,
        ];
    }, [roomTypeList]);

    const onChangeDate = (date: Dayjs | null) => {
        if (date) {
            setDate(date);
        }
    };

    const onChangeRoomType = (value: number) => {
        setRoomTypeId(value);
    };

    const onChangeShowMode = (checked: boolean) => {
        setShowByDay(checked);
    };

    const onClickButtonFilter = async () => {
        dispatch(setFilterRoomTypeId(roomTypeId));
        dispatch(setFilterDate(date.fmYYYYMMDDHHmmss()));
        dispatch(setIsShowByDay(showByDay));
        refetch();
    };

    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };

    return (
        <div className="report-sale-by-category-filter-form-wrapper">
            <Form className="filter-form-content">
                <Form.Item label={t('report.salesByCategory.filterForm.pickerLabel')}>
                    <SingleDatePicker
                        placeholder={t('report.placeholder.selectDate')}
                        value={date}
                        allowClear={false}
                        name="day"
                        picker={DatePickerType.MONTH}
                        onChange={onChangeDate}
                        control={control}
                    />
                </Form.Item>
                <Form.Item label={t('report.salesByCategory.filterForm.roomType')}>
                    <Select
                        onChange={onChangeRoomType}
                        dropdownMatchSelectWidth={false}
                        className="room-type-select"
                        value={roomTypeId}
                    >
                        {roomTypeListOptions.map((roomType) => {
                            return (
                                <Select.Option
                                    value={roomType.value}
                                    key={roomType.value}
                                >
                                    {roomType.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label={t('report.salesByCategory.filterForm.showMode')}>
                    <Switch checked={showByDay} onChange={onChangeShowMode} />
                </Form.Item>
                <Button type="primary" onClick={onClickButtonFilter} loading={isFetching}>
                    {t('report.salesByCategory.filterForm.filterButton')}
                </Button>
            </Form>
            {showSalesByCategoryTable && (
                <Button onClick={exportCSV} className="export-csv-btn">
                    {t('report.exportCSV')}
                </Button>
            )}
        </div>
    );
}

export default FilterForm;
