import {
    Button,
    Card,
    Drawer,
    Empty,
    notification,
    Radio,
    RadioChangeEvent,
    Spin,
    Switch,
} from 'antd';
import {
    bulkUpdateRoomCleanings,
    fetchRoomCleaningList,
    roomCleaningListQuerySelector,
    roomCleaningListSelector,
    setRoomCleaningListQuery,
    showLoadingSelector,
    totalPageSelector,
} from '~features/room-cleaning/reducers/room-cleaning.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    IUpdateResult,
    IRoomCleaning,
    IRoomCleaningBulkUpdateFormItem,
} from '~features/room-cleaning/interfaces';
import './RoomCleaningListMweb.scss';
import { useTranslation } from 'react-i18next';
import { CleaningStatus } from '~features/room-booking/constants';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { keyBy, merge, values } from 'lodash';
import { initRoomCleaningListQuery } from '~features/room-cleaning/constants';
import dayjs from '~plugins/dayjs';
import { InputTextArea } from '~components';
import { Control, FieldValues, UseFormReset } from 'react-hook-form';
import { showConfirm } from '~common/notification';

type Iprops = {
    control: Control<FieldValues, unknown>;
    reset: UseFormReset<FieldValues>;
};

function RoomCleaningListMweb({ control, reset }: Iprops) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roomCleaningListQuery = useAppSelector(roomCleaningListQuerySelector);
    const currentPageRoomCleaningData = useAppSelector(roomCleaningListSelector);
    const totalPage = useAppSelector(totalPageSelector);
    const showLoading = useAppSelector(showLoadingSelector);

    const [roomCleaningList, setRoomCleaningList] = useState(currentPageRoomCleaningData);
    const [isShowUncleanedRoom, setIsShowUncleanedRoom] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [memoTextArea, setMemoTextArea] = useState('');
    const [selectedRoomCleaning, setSelectedRoomCleaning] = useState<
        IRoomCleaning | undefined
    >(undefined);

    const fetchData = () => {
        dispatch(fetchRoomCleaningList());
    };

    useEffect(() => {
        dispatch(setRoomCleaningListQuery(initRoomCleaningListQuery));
    }, []);

    useEffect(() => {
        fetchData();
    }, [roomCleaningListQuery]);

    useEffect(() => {
        // handle edge case when filter uncleaned room, but all room already cleaned
        if (roomCleaningListQuery.page === 1 && !currentPageRoomCleaningData.length) {
            setRoomCleaningList([]);
        } else {
            // merge roomCleaningList with new data from the next page
            const mergedList = merge(
                keyBy(roomCleaningList, 'id'),
                keyBy(currentPageRoomCleaningData, 'id'),
            );
            setRoomCleaningList(values(mergedList));
        }
    }, [currentPageRoomCleaningData]);

    const onFilterChange = (e: RadioChangeEvent) => {
        setIsShowUncleanedRoom(e.target.value);
    };

    useEffect(() => {
        setRoomCleaningList([]);
        dispatch(
            setRoomCleaningListQuery({
                ...roomCleaningListQuery,
                cleaningStatus: isShowUncleanedRoom
                    ? CleaningStatus.UNCLEANED
                    : undefined,
                page: 1,
            }),
        );
    }, [isShowUncleanedRoom]);

    const onChangePage = () => {
        dispatch(
            setRoomCleaningListQuery({
                ...roomCleaningListQuery,
                page: (roomCleaningListQuery.page || 1) + 1,
            }),
        );
    };

    const updateRoomCleaningList = (roomCleaning: IRoomCleaning) => {
        // merge updated data to roomCleaningList
        const mergedList = merge(
            keyBy(roomCleaningList, 'id'),
            keyBy([roomCleaning], 'id'),
        );
        setRoomCleaningList(values(mergedList));
    };

    const updateRoomCleaningData = useCallback(
        async (roomCleaning: IRoomCleaningBulkUpdateFormItem) => {
            const response = await dispatch(bulkUpdateRoomCleanings([roomCleaning]));
            const result: IUpdateResult = { success: false };
            if (bulkUpdateRoomCleanings.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomCleaning.list.updateSuccessMessage'),
                    });
                    result.success = true;
                } else {
                    notification.error({
                        message: response.payload?.errors?.[0]?.message || '',
                    });
                }
            }
            return result;
        },
        [],
    );

    const openMemoDrawer = (roomCleaning: IRoomCleaning) => {
        reset({ drawer: { memo: roomCleaning?.memo || '' } });
        setSelectedRoomCleaning(roomCleaning);
        setMemoTextArea(roomCleaning?.memo || '');
        setIsDrawerOpen(true);
    };
    const closeMemoDrawer = () => {
        setSelectedRoomCleaning(undefined);
        setMemoTextArea('');
        setIsDrawerOpen(false);
    };
    const submitlUpdateMemo = async () => {
        if (selectedRoomCleaning) {
            const result = await updateRoomCleaningData({
                id: selectedRoomCleaning?.id,
                cleaningStatus: selectedRoomCleaning?.cleaningStatus,
                memo: memoTextArea,
            });
            if (result.success) {
                updateRoomCleaningList({ ...selectedRoomCleaning, memo: memoTextArea });
                closeMemoDrawer();
            }
        }
    };
    const deleteMemo = async (roomCleaning: IRoomCleaning) => {
        showConfirm({
            title: t('roomCleaning.list.confirm.titleUpdateMemo'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            async onOk() {
                const result = await updateRoomCleaningData({
                    id: roomCleaning?.id,
                    cleaningStatus: roomCleaning?.cleaningStatus,
                    memo: '',
                });
                if (result.success) {
                    updateRoomCleaningList({ ...roomCleaning, memo: '' });
                }
            },
        });
    };
    const onChangeCleaningStatus = (value: string, roomCleaning: IRoomCleaning) => {
        showConfirm({
            title: t('roomCleaning.list.confirm.titleSwitchStatus'),
            cancelText: t('roomCleaning.list.confirm.buttonCancelText'),
            okText: t('roomCleaning.list.confirm.buttonDeleteText'),
            async onOk() {
                const updatedCleaningStatus =
                    value === CleaningStatus.CLEANED
                        ? CleaningStatus.UNCLEANED
                        : CleaningStatus.CLEANED;
                const result = await updateRoomCleaningData({
                    id: roomCleaning?.id,
                    cleaningStatus: updatedCleaningStatus,
                    memo: roomCleaning.memo,
                });
                if (result.success) {
                    if (isShowUncleanedRoom) {
                        // refresh data when toggle room to unclean
                        dispatch(
                            setRoomCleaningListQuery({
                                ...roomCleaningListQuery,
                                page: 1,
                            }),
                        );
                        // remove cleaned data from uncleaned list
                        setRoomCleaningList(
                            roomCleaningList.filter(
                                (room) => room.id !== roomCleaning.id,
                            ),
                        );
                    } else {
                        updateRoomCleaningList({
                            ...roomCleaning,
                            cleaningStatus: updatedCleaningStatus,
                        });
                    }
                }
            },
        });
    };

    return (
        <>
            <div className="filter-room-cleaning-wrapper">
                <Radio.Group
                    defaultValue={false}
                    buttonStyle="solid"
                    onChange={onFilterChange}
                    value={isShowUncleanedRoom}
                >
                    <Radio.Button value={false}>
                        {t('roomCleaning.list.filter.all')}
                    </Radio.Button>
                    <Radio.Button value={true}>
                        {t('roomCleaning.list.filter.notCleaned')}
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div className="room-cleaning-list-mweb-wrapper">
                {roomCleaningList.map((roomCleaning) => (
                    <div key={roomCleaning.id}>
                        <Card className="room-cleaning-card">
                            <div className="room-cleaning-content">
                                <div className="room-cleaning-row">
                                    <div className="font-bold text-lg">
                                        {roomCleaning.name}
                                    </div>
                                    <div className="cleaning-status text-md">
                                        <span
                                            className={`cleaning-status-text-${
                                                roomCleaning.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                                    ? 'cleaned'
                                                    : 'uncleaned'
                                            }`}
                                        >
                                            {roomCleaning.cleaningStatus ===
                                            CleaningStatus.CLEANED
                                                ? t(
                                                      'roomCleaning.list.cleanStatus.cleaned',
                                                  )
                                                : t(
                                                      'roomCleaning.list.cleanStatus.notCleaned',
                                                  )}
                                        </span>
                                        <Switch
                                            checked={
                                                roomCleaning.cleaningStatus ===
                                                CleaningStatus.CLEANED
                                            }
                                            onChange={() => {
                                                onChangeCleaningStatus(
                                                    roomCleaning.cleaningStatus,
                                                    roomCleaning,
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="room-cleaning-row">
                                    <div
                                        className={`room-cleaning-status room-cleaning-status-${roomCleaning.occupationStatus}`}
                                    >
                                        {t(
                                            `roomCleaning.list.roomStatus.${roomCleaning.occupationStatus}`,
                                        )}
                                    </div>
                                    <div>
                                        {roomCleaning?.bookingStartDate &&
                                            roomCleaning?.bookingEndDate && (
                                                <span>
                                                    {dayjs(
                                                        roomCleaning.bookingStartDate,
                                                    ).format('YYYY-MM-DD')}{' '}
                                                    →{' '}
                                                    {dayjs(
                                                        roomCleaning.bookingEndDate,
                                                    ).format('YYYY-MM-DD')}
                                                </span>
                                            )}
                                    </div>
                                </div>
                                {roomCleaning?.memo ? (
                                    <>
                                        <div>{roomCleaning.memo}</div>
                                        <div className="room-cleaning-memo-action-wrapper">
                                            <Button
                                                type="text"
                                                onClick={() =>
                                                    openMemoDrawer(roomCleaning)
                                                }
                                            >
                                                <EditOutlined className="text-md" />
                                                <span className="text-md">
                                                    {t(
                                                        'roomCleaning.list.action.editMemo',
                                                    )}
                                                </span>
                                            </Button>
                                            <Button
                                                type="text"
                                                onClick={() => deleteMemo(roomCleaning)}
                                                loading={showLoading}
                                            >
                                                <DeleteOutlined className="text-md" />
                                                <span className="text-md">
                                                    {t('roomCleaning.list.action.delete')}
                                                </span>
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <Button
                                            type="text"
                                            onClick={() => openMemoDrawer(roomCleaning)}
                                        >
                                            <PlusOutlined className="text-md" />
                                            <span className="text-md">
                                                {t('roomCleaning.list.action.addMemo')}
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                ))}
                {roomCleaningList.length === 0 && showLoading && (
                    <Spin className="loading-spinner" />
                )}
                {roomCleaningList.length === 0 && !showLoading && (
                    <Empty
                        description={t(`roomCleaning.list.filter.noData`)}
                        style={{ marginTop: '32px' }}
                    />
                )}
                {roomCleaningList.length > 0 &&
                    totalPage > 1 &&
                    (roomCleaningListQuery.page || 1) <= totalPage && (
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => onChangePage()}
                            loading={showLoading}
                            block
                        >
                            {t('roomCleaning.list.action.loadMore')}
                        </Button>
                    )}
                <Drawer
                    title={t(
                        `roomCleaning.list.action.${
                            selectedRoomCleaning?.memo ? 'editMemo' : 'addMemo'
                        }`,
                    )}
                    placement="bottom"
                    onClose={() => closeMemoDrawer()}
                    open={isDrawerOpen}
                >
                    <InputTextArea
                        label=""
                        rows={6}
                        placeholder={t('roomCleaning.list.memoInput')}
                        name="drawer.memo"
                        control={control}
                        onChange={(e) => setMemoTextArea(e.target.value)}
                    />
                    <div className="room-cleaning-drawer-action">
                        <Button
                            className="room-cleaning-drawer-action-btn"
                            size="large"
                            onClick={() => closeMemoDrawer()}
                        >
                            {t('roomCleaning.list.action.cancel')}
                        </Button>
                        <Button
                            className="room-cleaning-drawer-action-btn"
                            type="primary"
                            size="large"
                            onClick={() => submitlUpdateMemo()}
                            loading={showLoading}
                        >
                            {t('roomCleaning.list.action.save')}
                        </Button>
                    </div>
                </Drawer>
            </div>
        </>
    );
}

export default RoomCleaningListMweb;
