import React, { useEffect, useMemo, useState } from 'react';
import {
    ReloadOutlined,
    PrinterOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import {
    Table,
    Pagination,
    Switch,
    Card,
    Button,
    Modal,
    Spin,
    RadioChangeEvent,
    Radio,
} from 'antd';
import type { TableProps } from 'antd/es/table';
import { AntdOrderDirection, OrderDirection } from '~common/constants';

import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '~hooks';
import { InputText } from '~components';
import { IBodyResponse, IGetListResponse, ISorter } from '~common/interfaces';
import { ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import {
    fetchRoomCleaningList,
    roomCleaningListQuerySelector,
    roomCleaningListSelector,
    setRoomCleaningListQuery,
    showLoadingSelector,
    totalRoomCleaningsSelector,
    totalPageSelector,
    setSelectedRoomCleaningList,
} from '~features/room-cleaning/reducers/room-cleaning.reducer';
import { IRoomCleaning } from '~features/room-cleaning/interfaces';
import {
    RoomCleaningListColumn,
    initRoomCleaningListQuery,
    CleaningStatus,
} from '~features/room-cleaning/constants';
import './RoomCleaningList.scss';
import {
    fetchPrintRoomCleaningList,
    isPrintRoomCleaningListLoadingSelector,
    isShowRoomCleaningPrintingModalSelector,
    printRoomCleaningListQuerySelector,
    setIsShowRoomCleaningPrintingModal,
} from '~features/room-cleaning/reducers/print-room-cleaning.reducer';
import { IRoomBooking } from '~features/room-booking/interfaces';
import RoomCleaningPrintModal from '../../RoomCleaningPrintModal/RoomCleaningPrintModal';
import dayjs from '~plugins/dayjs';
import { ArrowRightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Control, FieldValues, UseFormReset } from 'react-hook-form';

type Iprops = {
    control: Control<FieldValues, unknown>;
    reset: UseFormReset<FieldValues>;
    editingKey: number | undefined;
    setEditingKey: (key: number | undefined) => void;
    onChangeCleaningStatus: (value: string, roomCleaning: IRoomCleaning) => void;
    onChangeMemoRoomCleaning: (value: string, roomCleaning: IRoomCleaning) => void;
    onUpdateMemo: (roomCleaning: IRoomCleaning) => void;
};
function RoomCleaningList({
    control,
    reset,
    editingKey,
    setEditingKey,
    onChangeMemoRoomCleaning,
    onUpdateMemo,
    onChangeCleaningStatus,
}: Iprops) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const printRoomCleaningListQuery = useAppSelector(printRoomCleaningListQuerySelector);
    const isPrintRoomCleaningListLoading = useAppSelector(
        isPrintRoomCleaningListLoadingSelector,
    );
    const roomCleaningListQuery = useAppSelector(roomCleaningListQuerySelector);
    const roomCleaningList = useAppSelector(roomCleaningListSelector);

    const [isShowUncleanedRoom, setIsShowUncleanedRoom] = useState(false);
    const showLoading = useAppSelector(showLoadingSelector);
    const totalRoomClean = useAppSelector(totalRoomCleaningsSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const isShowRoomCleaningPrintingModal = useAppSelector(
        isShowRoomCleaningPrintingModalSelector,
    );

    const onChange: TableProps<IRoomCleaning>['onChange'] = (
        pagination,
        filters,
        sorter,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (_field) {
            dispatch(
                setRoomCleaningListQuery({
                    ...roomCleaningListQuery,
                    orderBy: order
                        ? _field || RoomCleaningListColumn.ID
                        : RoomCleaningListColumn.ID,
                    orderDirection:
                        order === AntdOrderDirection.ASC
                            ? OrderDirection.ASC
                            : OrderDirection.DESC,
                }),
            );
        }
    };

    useEffect(() => {
        dispatch(setRoomCleaningListQuery(cloneDeep(initRoomCleaningListQuery)));
    }, []);

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const onSelectChange = (_selectedRowKeys: React.Key[]) => {
        const rooms: IRoomCleaning[] = [];
        _selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundRoom = roomCleaningList.find(
                (room: IRoomCleaning) => room.id === selectedRowKey,
            );
            if (foundRoom) rooms.push(foundRoom);
        });
        setSelectedRowKeys(_selectedRowKeys);
    };
    const fetchData = () => {
        dispatch(fetchRoomCleaningList());
    };

    useEffect(() => {
        if (
            roomCleaningList.length === 0 &&
            roomCleaningListQuery?.page &&
            roomCleaningListQuery?.page > 1
        ) {
            const page = roomCleaningListQuery.page - 1;
            dispatch(setRoomCleaningListQuery({ ...roomCleaningListQuery, page }));
        }
    }, [roomCleaningList]);

    useEffect(() => {
        return () => {
            fetchData();
        };
    }, [roomCleaningListQuery]);

    const onChangePage = (page: number) => {
        dispatch(setRoomCleaningListQuery({ ...roomCleaningListQuery, page }));
    };

    useEffect(() => {
        dispatch(setSelectedRoomCleaningList(selectedRows));
    }, [selectedRowKeys, roomCleaningList]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onFilterChange = (e: RadioChangeEvent) => {
        setIsShowUncleanedRoom(e.target.value);
    };
    useEffect(() => {
        dispatch(
            setRoomCleaningListQuery({
                ...roomCleaningListQuery,
                cleaningStatus: isShowUncleanedRoom
                    ? CleaningStatus.UNCLEANED
                    : undefined,
                page: 1,
            }),
        );
    }, [isShowUncleanedRoom]);

    const selectedRows = useMemo(() => {
        const rows: IRoomCleaning[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundHotel = roomCleaningList.find(
                (room: IRoomCleaning) => room.id === selectedRowKey,
            );
            if (foundHotel) rows.push(foundHotel);
        });
        return rows;
    }, [selectedRowKeys, roomCleaningList]);

    const isEditingMemo = (record: IRoomCleaning) => record.id === editingKey;
    const onClickEditMemo = (roomCleaning: IRoomCleaning) => {
        reset({ memo: roomCleaning.memo });
        setEditingKey(roomCleaning.id as number);
    };

    const cancelEditMemo = () => {
        setEditingKey(undefined);
    };
    const roomCleaningColumns: ColumnsType<IRoomCleaning> = [
        {
            title: t('roomCleaning.list.roomCleaningListColumn.roomName'),
            width: '20%',
            render: (roomCleaning: IRoomCleaning) => {
                return (
                    <div className="room-cleaning-item-field">{roomCleaning.name}</div>
                );
            },
            key: RoomCleaningListColumn.NAME,
            sorter: true,
            fixed: 'left',
        },
        {
            title: t('roomCleaning.list.roomCleaningListColumn.roomType'),
            width: '25%',
            render: (roomCleaning: IRoomCleaning) => {
                return (
                    <div className="room-cleaning-item-field">
                        {roomCleaning.roomType.name}
                    </div>
                );
            },
            key: RoomCleaningListColumn.ROOM_TYPE,
            sorter: true,
        },
        {
            title: t('roomCleaning.list.roomCleaningListColumn.stayDate'),
            width: '25%',
            render: (roomCleaning: IRoomCleaning) => {
                if (roomCleaning.bookingStartDate && roomCleaning.bookingEndDate) {
                    return (
                        <>
                            {dayjs(roomCleaning.bookingStartDate).format('YYYY-MM-DD')}{' '}
                            <ArrowRightOutlined />{' '}
                            {dayjs(roomCleaning.bookingEndDate).format('YYYY-MM-DD')}
                        </>
                    );
                }
                return <></>;
            },
        },
        {
            title: t('roomCleaning.list.roomCleaningListColumn.occupationStatus'),
            width: '25%',
            sorter: (a, b) => {
                const statusA = a.occupationStatus || '';
                const statusB = b.occupationStatus || '';
                return statusA.localeCompare(statusB);
            },
            render: (roomCleaning: IRoomCleaning) => (
                <>{_.startCase(_.toUpper(roomCleaning.occupationStatus)) || ''}</>
            ),
        },
        {
            title: t('roomCleaning.list.roomCleaningListColumn.cleaningStatus'),
            width: '25%',
            sorter: true,
            render: (roomCleaning: IRoomCleaning) => {
                return (
                    <span className="cleaning-status">
                        <Switch
                            size="default"
                            checked={
                                roomCleaning.cleaningStatus === CleaningStatus.CLEANED
                                    ? true
                                    : false
                            }
                            onChange={() => {
                                onChangeCleaningStatus(
                                    roomCleaning.cleaningStatus,
                                    roomCleaning,
                                );
                            }}
                        />
                        <span
                            className={`cleaning-status-text-${
                                roomCleaning.cleaningStatus === CleaningStatus.CLEANED
                                    ? 'cleaned'
                                    : 'uncleaned'
                            }`}
                        >
                            {roomCleaning.cleaningStatus === CleaningStatus.CLEANED
                                ? t('roomCleaning.list.cleanStatus.cleaned')
                                : t('roomCleaning.list.cleanStatus.notCleaned')}
                        </span>
                    </span>
                );
            },
            key: RoomCleaningListColumn.STATUS,
        },
        {
            title: t('roomCleaning.list.roomCleaningListColumn.memo'),
            width: '30%',
            render: (roomCleaning: IRoomCleaning, item, index) => {
                return isEditingMemo(item) ? (
                    <InputText
                        label=""
                        name={`items.${index}.memo`}
                        control={control}
                        onChange={(e) => {
                            onChangeMemoRoomCleaning(e.target.value, roomCleaning);
                        }}
                        defaultValue={roomCleaning?.memo || ''}
                        autoFocus
                        placeholder={t('roomCleaning.list.memoInput')}
                        addonAfter={
                            <div className="memo-icon-wrapper">
                                <CheckOutlined
                                    className="memo-icon"
                                    onClick={() => {
                                        onUpdateMemo(roomCleaning);
                                    }}
                                />
                                <CloseOutlined
                                    className="memo-icon"
                                    onClick={() => {
                                        cancelEditMemo();
                                    }}
                                />
                            </div>
                        }
                    />
                ) : (
                    <div
                        className="room-cleaning-input-memo"
                        onClick={() => onClickEditMemo(roomCleaning)}
                    >
                        {roomCleaning?.memo ? (
                            roomCleaning?.memo
                        ) : (
                            <div className="input-memo-placeholder">
                                {t('roomCleaning.list.memoInput')}
                            </div>
                        )}
                    </div>
                );
            },
            key: RoomCleaningListColumn.MEMO,
        },
    ];

    const onPrintRoomCleaning = async () => {
        await fetchPrintingData();
        dispatch(setIsShowRoomCleaningPrintingModal(true));
    };

    const fetchPrintingData = async () => {
        const response = await dispatch(
            fetchPrintRoomCleaningList(printRoomCleaningListQuery),
        );
        if (
            (response?.payload as IBodyResponse<IGetListResponse<IRoomBooking>>)?.success
        ) {
            return;
        }
        Modal.error({
            content: t('roomCleaning.printing.notification.fetchFailed'),
            okText: t('common.ok'),
        });
    };

    return (
        <div className="room-cleaning-list-wrapper">
            <Spin spinning={isPrintRoomCleaningListLoading}>
                <Card
                    className="room-cleaning-card"
                    title={
                        <div className="room-cleaning-list-header">
                            <div className="room-cleaning-list-header-left">
                                <Button
                                    type="text"
                                    shape="circle"
                                    icon={
                                        <PrinterOutlined onClick={onPrintRoomCleaning} />
                                    }
                                />
                                <Radio.Group
                                    defaultValue={false}
                                    buttonStyle="solid"
                                    onChange={onFilterChange}
                                    value={isShowUncleanedRoom}
                                >
                                    <Radio.Button value={false}>
                                        {t('roomCleaning.list.filter.all')}
                                    </Radio.Button>
                                    <Radio.Button value={true}>
                                        {t('roomCleaning.list.filter.notCleaned')}
                                    </Radio.Button>
                                </Radio.Group>
                            </div>

                            <div className="room-cleaning-list-header-right">
                                <ReloadOutlined
                                    className="size-icon"
                                    onClick={onRefresh}
                                />
                            </div>
                        </div>
                    }
                >
                    <Table
                        rowSelection={rowSelection}
                        columns={roomCleaningColumns}
                        dataSource={roomCleaningList}
                        onChange={onChange}
                        pagination={false}
                        rowKey="id"
                        className="room-cleaning-list-table"
                        loading={showLoading}
                        scroll={{ x: 700 }}
                    />
                </Card>

                {pageCount > 1 && (
                    <Pagination
                        className="room-cleaning-list-pagination"
                        defaultCurrent={roomCleaningListQuery.page}
                        current={roomCleaningListQuery.page}
                        total={totalRoomClean}
                        pageSize={roomCleaningListQuery.limit}
                        onChange={onChangePage}
                        showSizeChanger={false}
                    />
                )}

                <RoomCleaningPrintModal
                    isShowRoomCleaningPrintingModal={isShowRoomCleaningPrintingModal}
                />
            </Spin>
        </div>
    );
}

export default RoomCleaningList;
