import { Button } from 'antd';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat } from '~common/constants';
import { SingleDatePicker } from '~components';
import {
    getSalesPaymentList,
    salesPaymentStateSelector,
    setExportCsv,
    setIsShowTable,
} from '~features/report/reducers/report-sales-payment.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { Dayjs, parseDate } from '~plugins/dayjs';
import './FilterForm.scss';
import { useForm } from '~plugins/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { salesPaymentFilterSchema } from '~features/report/schema';

function FilterForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedDate, setSelectedDate] = useState<string>(customDayjs().fmYYYYMMDD());
    const { isShowTable } = useAppSelector(salesPaymentStateSelector);
    const { control, setValue } = useForm({
        resolver: yupResolver(salesPaymentFilterSchema),
    });

    const clickDisplayButton = () => {
        if (!isShowTable) {
            dispatch(setIsShowTable(true));
        }
        fetchData(selectedDate);
    };

    useEffect(() => {
        setValue(`day`, customDayjs());
    }, []);
    const changeDate = (date: Dayjs | null, dateString: string) => {
        setSelectedDate(dateString);
        if (isShowTable) {
            fetchData(parseDate(date)?.fmYYYYMMDD());
        }
    };

    const fetchData = (date: string) => {
        dispatch(
            getSalesPaymentList([
                parseDate(date)?.fmYYYYMMDDHHmmss(),
                parseDate(date).endOf('day')?.fmYYYYMMDDHHmmss(),
            ]),
        );
    };

    const exportCSV = () => {
        dispatch(setExportCsv(true));
    };

    return (
        <div className="filter-sales-payment-wrapper">
            <span>{t('report.salesPayment.filterForm.dateLabel')}</span>
            <SingleDatePicker
                value={parseDate(selectedDate)}
                format={DateFormat.YYYY_MM_DD_HYPHEN}
                allowClear={false}
                onChange={changeDate}
                placeholder={t('report.placeholder.selectDate')}
                name={'day'}
                control={control}
            />
            <Button
                type="primary"
                className="display-button"
                onClick={clickDisplayButton}
            >
                {t('report.salesPayment.filterForm.displayButton')}
            </Button>
            {isShowTable && (
                <Button onClick={exportCSV} className="export-csv-btn">
                    {t('report.exportCSV')}
                </Button>
            )}
        </div>
    );
}

export default FilterForm;
