import { Button, Form, Radio, RadioChangeEvent, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { RangePicker } from '~components';
import {
    AggregationMethod,
    ReportTableCsvFileName,
    ReportTableId,
    SaleType,
} from '~features/report/constants';
import { exportCSVReport, exportFacilityCSVReport } from '~features/report/helper';
import {
    fetchSalesSummaryData,
    setShowSalesSummaryTable,
    setFacilityShowSalesSummaryTable,
    showLoadingSalesSummaryTableSelector,
    showSalesSummaryTableSelector,
} from '~features/report/reducers/sales-summary.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { CacheKeys } from '~queries/queries';
import { reportServiceV2 } from '~features/report/services/v2/report.service';
import { reportingServiceV2 } from '~features/report/reportV2.service';
import './FilterForm.scss';
import { SaleReportType } from '../../SalesSummaryPage';
import localStorageAuthService from '~common/authStorage';

function FilterForm({ salesReportType }: { salesReportType: SaleReportType }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showSalesSummaryTable = useAppSelector(showSalesSummaryTableSelector);
    const { control, setValue, getValues } = useForm();
    const [aggregationMethod, setAggregationMethod] = useState(AggregationMethod.DAILY);
    const [saleType, setSaleType] = useState(SaleType.SALE_ITEM);

    useEffect(() => {
        dispatch(setShowSalesSummaryTable(false));
        dispatch(setFacilityShowSalesSummaryTable(false));
        setAggregationMethod(AggregationMethod.DAILY);
        setSaleType(SaleType.SALE_ITEM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentTime = customDayjs();
        if (aggregationMethod === AggregationMethod.DAILY) {
            setValue('aggregationPeriod', [
                currentTime.startOf('month'),
                currentTime.endOf('month'),
            ]);
        } else {
            setValue('aggregationPeriod', [
                currentTime.startOf('month'),
                currentTime.endOf('month'),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregationMethod]);

    const onChangeAggregationMethod = (e: RadioChangeEvent) => {
        setAggregationMethod(e.target.value);
    };

    const onChangeSaleType = (checked: boolean) => {
        if (checked) {
            setSaleType(SaleType.SALE_GROUP);
        } else {
            setSaleType(SaleType.SALE_ITEM);
        }
    };

    const fetchReceiptSummaryDataTest = async () => {
        const aggregationPeriod = getValues()?.aggregationPeriod;
        const startDate = parseDate(aggregationPeriod?.[0]).startOf('day');
        let endDate = parseDate(aggregationPeriod?.[1]).endOf('day');

        if (aggregationMethod === AggregationMethod.MONTH) {
            endDate = endDate.endOf('month');
        }

        const response = await reportServiceV2.getReceiptSummaryData({
            startDateOfStay: startDate.fmYYYYMMDDHHmmss(),
            endDateOfStay: endDate.fmYYYYMMDDHHmmss(),
        });

        if (response.success) {
            return response.data;
        }
        throw Error(response.message);
    };

    const { isLoading: isLoadingReceiptSummaryData, refetch } = useQuery({
        queryKey: [CacheKeys.getSalesSummaryReceiptDetails],
        queryFn: fetchReceiptSummaryDataTest,
        enabled: false,
    });

    const getFilterValues = () => {
        const aggregationPeriod = getValues()?.aggregationPeriod;
        const startDate = parseDate(aggregationPeriod?.[0]).startOf('day');
        let endDate = parseDate(aggregationPeriod?.[1]).endOf('day');

        const previousStartDate = startDate.subtract(1, 'year');
        let previousEndDate = endDate.subtract(1, 'year');

        if (aggregationMethod === AggregationMethod.MONTH) {
            endDate = endDate.endOf('month');
            previousEndDate = previousEndDate.endOf('month');
        }

        return {
            datePeriod: [startDate.fmYYYYMMDDHHmmss(), endDate.fmYYYYMMDDHHmmss()],
            previousDatePeriod: [
                previousStartDate.fmYYYYMMDDHHmmss(),
                previousEndDate.fmYYYYMMDDHHmmss(),
            ],
        };
    };

    const hotelId = +localStorageAuthService.getHotelId();
    const facilityQuery = useQuery({
        queryKey: [CacheKeys.facilitySalesReport, hotelId],
        queryFn: async () => {
            const { datePeriod } = getFilterValues();
            const response = await reportingServiceV2.getFacilitySalesReport({
                dateFrom: datePeriod[0],
                dateTo: datePeriod[1],
                saleType,
            });
            return response.data;
        },
        enabled: false,
    });

    const {
        isLoading: isLoadingSalesSummaryData,
        refetch: isRefetchingSalesSummaryData,
    } = useQuery({
        queryKey: [CacheKeys.getSalesSummaryReportKey],
        queryFn: async () => {
            const { datePeriod } = getFilterValues();
            const response = await reportingServiceV2.getSalesSummaryReport({
                datePeriod,
                isShownBySalesGroup: saleType === SaleType.SALE_GROUP,
            });
            if (response.success) {
                return response.data;
            }
            throw Error(response.message);
        },
        enabled: false,
    });

    const onClickButtonFilter = async () => {
        if (salesReportType === 'room') {
            await Promise.all([isRefetchingSalesSummaryData(), refetch()]);
            dispatch(setShowSalesSummaryTable(true));
        } else {
            await facilityQuery.refetch();
            dispatch(setFacilityShowSalesSummaryTable(true));
        }
    };

    const exportCSV = () => {
        if (salesReportType === 'room') {
            return exportCSVReport(
                ReportTableId.SALES_SUMMARY,
                `${ReportTableCsvFileName.SALES_SUMMARY}.csv`,
            );
        }
        exportFacilityCSVReport(
            ReportTableId.FACILITY_SALES_SUMMARY,
            `${ReportTableCsvFileName.FACILITY_SALES_SUMMARY}.csv`,
        );
    };

    return (
        <div className="report-sales-summary-filter-form-wrapper">
            <Form className="filter-form-content">
                <Form.Item label={t('report.salesSummary.filterForm.toggleButton')}>
                    <Switch
                        checked={saleType === SaleType.SALE_GROUP}
                        onChange={onChangeSaleType}
                    />
                </Form.Item>
                <Form.Item
                    label={t('report.salesSummary.filterForm.aggregationMethod.label')}
                >
                    <Radio.Group
                        value={aggregationMethod}
                        onChange={onChangeAggregationMethod}
                    >
                        {Object.values(AggregationMethod).map((value) => {
                            return (
                                <Radio.Button value={value} key={value}>
                                    {t(
                                        `report.salesSummary.filterForm.aggregationMethod.${value}`,
                                    )}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                </Form.Item>
                <RangePicker
                    name="aggregationPeriod"
                    label={t('report.salesSummary.filterForm.aggregationPeriod')}
                    control={control}
                    allowClear={false}
                    picker={
                        aggregationMethod === AggregationMethod.DAILY ? 'date' : 'month'
                    }
                    placeholder={
                        aggregationMethod === AggregationMethod.DAILY
                            ? [
                                  t('report.placeholder.rangeDatePicker.start'),
                                  t('report.placeholder.rangeDatePicker.end'),
                              ]
                            : [
                                  t('report.placeholder.rangeMonthPicker.start'),
                                  t('report.placeholder.rangeMonthPicker.end'),
                              ]
                    }
                />
                <Button
                    type="primary"
                    onClick={onClickButtonFilter}
                    loading={
                        salesReportType === 'room'
                            ? isLoadingSalesSummaryData || isLoadingReceiptSummaryData
                            : facilityQuery.isLoading
                    }
                >
                    {t('report.salesSummary.filterForm.filterButton')}
                </Button>
            </Form>
            {((salesReportType === 'room' && showSalesSummaryTable) ||
                facilityQuery.data) && (
                <Button onClick={exportCSV}>{t('report.exportCSV')}</Button>
            )}
        </div>
    );
}

export default FilterForm;
