import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, notification } from 'antd';
import { cloneDeep, sumBy, unionBy, uniq } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MAX_PRICE, MIN_PRICE, TaxType } from '~common/constants';
import { ModalConfirmDeletion } from '~components';
import { getPaymentMethodDropdown } from '~features/payment-method/reducers/paymentMethod.reducer';
import {
    ReceiptFormField,
    ReceiptItemBookingType,
    ReceiptItemDetailType,
    ReceiptItemType,
    RoomBookingDetailPageTabPane,
    SplitPaymentMethods,
} from '~features/room-booking/constants';
import {
    calculatePriceOnTable,
    calculateSalesTaxAmount,
    calculateTaxSaleItem,
    convertRoomBookingReceipt,
    convertTreeToList,
    findReceiptRowById,
    getAutoGeneratedCode,
    getReceiptItemTax,
    isCanceledBooking,
    shouldNotDeleteReceiptItemsList,
} from '~features/room-booking/helper';
import {
    IBookingReceiptTableData,
    IPaymentRoomBookingItem,
    IRoomBookingItemReceipt,
    IUpdateRoomBookingReceipt,
    IUpdateRoomBookingReceiptItem,
    IUpdateRoomBookingReceiptItemFlat,
    IUpdateRoomBookingReceiptItemTable,
    TaxSummary,
} from '~features/room-booking/interfaces';
import {
    bulkDeleteReceiptItemDetail,
    getRoomBookingReceipt,
    roomBookingStateSelector,
    updateRoomBookingReceipt,
} from '~features/room-booking/reducers/room-booking.reducer';
import { receiptSchema } from '~features/room-booking/schema';
import { ISaleItemDropdown } from '~features/sale-item/interfaces';
import { getSaleItemListForDropdown } from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch } from '~hooks';
import dayjs, { parseDate, todayDayjs } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import HeaderReceipt from './HeaderReceipt/HeaderReceipt';
import PaymentModal from './PaymentModal/PaymentModal';
import ReceiptBottomButtonGroup from './ReceiptBottomButtonGroup/ReceiptBottomButtonGroup';
import ReceiptTable from './ReceiptTable/ReceiptTable';
import './RoomBookingReceiptTabPane.scss';
import SummaryTotalReceipt from './SummaryTotalReceipt/SummaryTotalReceipt';
import { CacheKeys } from '~queries/queries';
import { useQuery } from '@tanstack/react-query';
import { BulkPaymentGroupBookingModal } from '../RoomBookingDetailTabPane/BulkPaymentGroupBookingModal/BulkPaymentGroupBookingModal';

function RoomBookingReceiptTabPane() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [receiptTableData, setReceiptTableData] = useState<IBookingReceiptTableData[]>(
        [],
    );
    const [isSingleRoomBooking, setIsSingleRoomBooking] = useState(true);
    const deleteReceiptItemDetailIds = useRef<number[]>([]);
    const errorAmountRowIds = useRef<string[]>([]);

    const { roomBookingReceipt, selectedRoomBookingDetail, activeDetailTabPane } =
        useSelector(roomBookingStateSelector);
    const { control, handleSubmit, reset, setValue, getValues } = useForm({
        resolver: yupResolver(receiptSchema),
    });
    const resetReceipt = (isCancel?: boolean) => {
        setSelectedRowKeys([]);
        const _receiptTableData = convertRoomBookingReceipt(roomBookingReceipt);
        if (isCancel) {
            reset();
        } else {
            reset(initForm(convertTreeToList(_receiptTableData, [])));
        }
        setReceiptTableData(_receiptTableData);
        deleteReceiptItemDetailIds.current = [];
    };

    const cancelEdit = () => {
        setIsEditMode(false);
        resetReceipt(true);
    };

    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
        return () => {
            cancelEdit();
        };
    }, []);

    useEffect(() => {
        if (
            activeDetailTabPane ===
            RoomBookingDetailPageTabPane.ROOM_BOOKING_LIST_TAB_PANE
        ) {
            cancelEdit();
        }
    }, [activeDetailTabPane]);

    useEffect(() => {
        const tableData = convertRoomBookingReceipt(roomBookingReceipt);
        setReceiptTableData(tableData);
        if (
            roomBookingReceipt?.roomBooking?.roomBookingItems &&
            roomBookingReceipt?.roomBooking?.roomBookingItems?.length > 1
        ) {
            setIsSingleRoomBooking(false);
        }
    }, [roomBookingReceipt]);

    const totalPrice = useMemo(() => {
        let totalSale = 0;
        let totalPayment = 0;
        let totalPrepaidAmount = 0;
        let total10PercentSale = 0;
        let total8PercentSale = 0;
        let totalOtherTax = 0;
        const taxes: TaxSummary[] = [];

        receiptTableData.forEach((receiptBookingItem) => {
            if (isCanceledBooking(receiptBookingItem.bookingStatus)) return;
            const { pricingSettings } = receiptBookingItem;
            receiptBookingItem.children?.forEach((receiptItem) => {
                if (receiptItem.status === ReceiptItemType.PAYMENT) {
                    totalPayment += receiptItem?.amount || 0;
                    if (receiptItem?.paymentMethod?.name?.includes(SplitPaymentMethods.PREPAID)) {
                        totalPrepaidAmount += receiptItem?.amount || 0;
                    }
                } else {
                    totalSale += receiptItem?.amount || 0;
                }
                taxes.push(getReceiptItemTax(receiptItem, pricingSettings || []));
                receiptItem?.children?.forEach((receiptItemDetail) => {
                    taxes.push(getReceiptItemTax(receiptItemDetail, pricingSettings || []));
                });
            });
        });

        taxes.forEach(({ taxType, taxAmount, taxableAmount }) => {
            if (taxType === TaxType.TAX_1) {
                total10PercentSale += taxableAmount || 0;
                return;
            }
            if (taxType === TaxType.TAX_2) {
                total8PercentSale += taxableAmount || 0;
                return;
            }
            totalOtherTax += taxAmount || 0;
        });

        if (total10PercentSale !== 0) {
            if (totalPrepaidAmount >= total10PercentSale) {
                total10PercentSale = 0;
            } else {
                total10PercentSale -= totalPrepaidAmount;
            }
        }

        const total10PercentTax = calculateSalesTaxAmount(1.1, total10PercentSale);
        const total8PercentTax = calculateSalesTaxAmount(1.08, total8PercentSale);

        return {
            totalSale,
            totalPayment,
            totalTax: total10PercentTax + total8PercentTax + totalOtherTax,
        };
    }, [receiptTableData]);

    const paymentRoomBookingItemOptions = useMemo(() => {
        const paymentRoomBookingItems: IPaymentRoomBookingItem[] = [];
        roomBookingReceipt?.roomBooking?.roomBookingItems?.forEach((roomBookingItem) => {
            if (roomBookingItem.room) {
                paymentRoomBookingItems.push({
                    id: roomBookingItem.id,
                    room: roomBookingItem.room,
                });
            }
        });
        const _options = paymentRoomBookingItems.map((item) => {
            return {
                value: item.id,
                label: item.room?.name,
            };
        });
        return unionBy(_options, 'label');
    }, [roomBookingReceipt]);

    const isRoomBookingReceiptItem = (id: string) => {
        return id.split('_')?.[0] === 'hol';
    };

    const selectedItemTotalAmount = () => {
        if (!selectedRowKeys.length) return 1;

        const removeKeys: (string | number)[] = [];
        selectedRowKeys.forEach((key) => {
            const splitKeys = (key as string).split('_');
            if (splitKeys.length === 4) {
                const parentKey = `${splitKeys[0]}_${splitKeys[1]}_${splitKeys[2]}`;
                const findParentKey = selectedRowKeys.find((key) => key === parentKey);
                if (findParentKey) {
                    removeKeys.push(key);
                }
            }
        });
        const _selectedRowKeys = selectedRowKeys.filter(
            (key) => !removeKeys.includes(key) && (key as string).split('_')?.length > 2,
        );
        let totalAmount = 0;
        _selectedRowKeys.forEach((key) => {
            const receiptRow = findReceiptRowById(receiptTableData, key as string);
            if (receiptRow?.status !== ReceiptItemType.PAYMENT) {
                totalAmount += receiptRow?.amount || 0;
            }
        });
        return totalAmount;
    };

    const addSaleItem = (item: IBookingReceiptTableData, type: ReceiptItemType) => {
        const selectedTotalAmount = selectedItemTotalAmount();
        let paymentRoomBookingItem: IPaymentRoomBookingItem | null = {
            id: Number(item.id?.split('_')?.[1]),
            room: item.children?.[0]?.room || null,
        };
        if (!isRoomBookingReceiptItem(item.id)) {
            const bookingItem = roomBookingReceipt?.roomBooking?.roomBookingItems?.find(
                (roomBookingItem) => {
                    return roomBookingItem.room?.id === item.children?.[0]?.room?.id;
                },
            );

            if (bookingItem) {
                paymentRoomBookingItem = {
                    id: bookingItem.id,
                    room: bookingItem.room,
                };
            }
        }

        const isExistOption = paymentRoomBookingItemOptions.some(
            (item) => item.value === paymentRoomBookingItem?.id,
        );
        if (!isExistOption) {
            paymentRoomBookingItem = null;
        }

        let newItemRow: IBookingReceiptTableData = {
            id: `${item.id}_${Date.now()}`,
            bookingDate: item.startDatetime,
            status: type,
            isAddition: true,
            paymentRoomBookingItem,
            level: 1,
            bookingType: isRoomBookingReceiptItem(item.id)
                ? ReceiptItemBookingType.ROOM_BOOKING_ITEM
                : ReceiptItemBookingType.FACILITY_BOOKING,
            startDatetime: item.startDatetime,
            endDatetime: item.endDatetime,
            bookingCreationDate: item.bookingCreationDate,
        };

        if (type === ReceiptItemType.PAYMENT) {
            newItemRow = {
                ...newItemRow,
                amount: selectedTotalAmount,
                paymentMethod: null,
                receiptItemDetailType: ReceiptItemDetailType.SALE_ITEM,
            };
            setValue(`${newItemRow.id}.amount`, selectedTotalAmount);
            setValue(
                `${newItemRow.id}.receiptItemDetailType`,
                ReceiptItemDetailType.PAYMENT,
            );
        } else {
            newItemRow = {
                ...newItemRow,
                representativeGuest: item?.children?.[0]?.representativeGuest || null,
                room: item?.children?.[0]?.room || null,
                quantity: 1,
                unitPrice: 0,
                amount: 0,
                paymentMethod: null,
                saleItem: null,
                receiptItemDetailType: ReceiptItemDetailType.SALE_ITEM,
            };
            setValue(`${newItemRow.id}.quantity`, 1);
            setValue(`${newItemRow.id}.unitPrice`, 0);
            setValue(
                `${newItemRow.id}.receiptItemDetailType`,
                ReceiptItemDetailType.SALE_ITEM,
            );
        }
        const newReceiptTableData = [...receiptTableData];
        const row: IBookingReceiptTableData | undefined = newReceiptTableData.find(
            (receiptRow) => {
                return receiptRow.id === item.id;
            },
        );
        if (!row) return;
        if (row?.children) {
            row.children?.push(newItemRow);
        } else {
            row.children = [newItemRow];
        }
        setValue(`${newItemRow.id}.type`, newItemRow.status);
        setValue(`${newItemRow.id}.paymentRoomBookingItem`, paymentRoomBookingItem?.id);
        setValue(`${newItemRow.id}.isAddition`, true);
        setValue(
            `${newItemRow.id}.bookingDate`,
            parseDate(item.startDatetime)?.fmYYYYMMDD('-'),
        );
        setReceiptTableData(newReceiptTableData);
    };

    const checkAmount = (id: string, amount: number) => {
        const validate = amount <= MAX_PRICE && amount >= MIN_PRICE;
        const idIndex = errorAmountRowIds.current.indexOf(id);
        if (validate) {
            if (idIndex > -1) {
                errorAmountRowIds.current.splice(idIndex, 1);
            }
        } else {
            if (idIndex === -1) {
                errorAmountRowIds.current.push(id);
            }
        }
        return validate;
    };

    const onChangeSaleItem = (saleItem: ISaleItemDropdown, id: string) => {
        setValue(`${id}.saleItemId`, saleItem?.id);
        setValue(`${id}.unitPrice`, saleItem?.price || 0, { shouldValidate: true });
        const _receiptDataTable = cloneDeep(receiptTableData);
        const receiptRow = findReceiptRowById(_receiptDataTable, id);
        if (!receiptRow) return;
        const newSaleItemTax = calculateTaxSaleItem(
            saleItem?.tax || null,
            receiptRow.quantity || 0,
            saleItem.price || 0,
        );
        receiptRow.saleItem = {
            id: saleItem.id,
            name: saleItem.name,
            tax: saleItem.tax,
            autoGeneratedCode: saleItem.autoGeneratedCode || '',
        };
        receiptRow.unitPrice = saleItem?.price || 0;
        receiptRow.amount = (saleItem?.price || 0) * (receiptRow?.quantity || 0);
        receiptRow.isErrorAmount = !checkAmount(id, receiptRow.amount || 0);
        receiptRow.saleItemTax = newSaleItemTax;
        setValue(`${id}.amount`, receiptRow.amount, { shouldValidate: true });
        calculatePriceOnTable(_receiptDataTable);
        setReceiptTableData(_receiptDataTable);
    };

    const changeUnitPriceOrQuantity = (
        value: number,
        id: string,
        fieldUpdate: string,
    ) => {
        const _receiptDataTable = cloneDeep(receiptTableData);
        const receiptRow = findReceiptRowById(_receiptDataTable, id);
        if (!receiptRow) return;
        let newSaleItemTax = 0;
        switch (fieldUpdate) {
            case ReceiptFormField.QUANTITY:
                newSaleItemTax = calculateTaxSaleItem(
                    receiptRow.saleItem?.tax || null,
                    value || 0,
                    receiptRow.unitPrice || 0,
                );
                receiptRow.quantity = value || 0;
                receiptRow.amount = (value || 0) * (receiptRow?.unitPrice || 0);
                receiptRow.saleItemTax = newSaleItemTax;
                setValue(`${receiptRow.id}.amount`, receiptRow.amount, {
                    shouldValidate: true,
                });
                break;

            case ReceiptFormField.UNIT_PRICE:
                newSaleItemTax = calculateTaxSaleItem(
                    receiptRow.saleItem?.tax || null,
                    receiptRow.quantity || 0,
                    value || 0,
                );
                receiptRow.unitPrice = value || 0;
                receiptRow.amount = (value || 0) * (receiptRow?.quantity || 0);
                receiptRow.saleItemTax = newSaleItemTax;
                setValue(`${receiptRow.id}.amount`, receiptRow.amount, {
                    shouldValidate: true,
                });
                break;
            case ReceiptFormField.AMOUNT:
                receiptRow.amount = value || 0;
                if (!isPaymentItem(receiptRow) && !!receiptRow?.quantity) {
                    receiptRow.unitPrice = Math.floor(
                        receiptRow.amount / receiptRow.quantity,
                    );
                    receiptRow.saleItemTax = calculateTaxSaleItem(
                        receiptRow.saleItem?.tax || null,
                        receiptRow.quantity || 0,
                        receiptRow.unitPrice || 0,
                    );
                    setValue(`${receiptRow.id}.unitPrice`, receiptRow.unitPrice, {
                        shouldValidate: true,
                    });
                }
                break;
            default:
                break;
        }
        receiptRow.isErrorAmount = !checkAmount(id, receiptRow.amount || 0);
        calculatePriceOnTable(_receiptDataTable);
        setReceiptTableData(_receiptDataTable);
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const getPaymentRoomBookingItem = (receiptRow: IBookingReceiptTableData) => {
        const paymentRoomBookingItem = receiptRow.paymentRoomBookingItem;
        if (!paymentRoomBookingItem) return null;
        const { room } = paymentRoomBookingItem;
        const option = paymentRoomBookingItemOptions.find((item) => {
            return item.label === room?.name;
        });
        return option?.value || null;
    };

    const initForm = (receipts: IBookingReceiptTableData[]) => {
        let formData = {};
        for (const receiptRow of receipts) {
            if (receiptRow.isCheckout || receiptRow.level === 0) continue;
            let bookingDate = receiptRow.bookingDate
                ? parseDate(receiptRow.bookingDate)?.fmYYYYMMDD('-')
                : null;
            if (
                receiptRow.children &&
                receiptRow.level === 1 &&
                receiptRow.status === ReceiptItemType.RECEIPT
            ) {
                bookingDate = todayDayjs.fmYYYYMMDD('-');
            }

            const commonForm = {
                type: receiptRow.status,
                bookingType: receiptRow.bookingType,
                paymentRoomBookingItem: getPaymentRoomBookingItem(receiptRow),
                bookingDate: bookingDate,
                receiptItemDetailType: receiptRow.receiptItemDetailType,
            };
            if (receiptRow.status === ReceiptItemType.PAYMENT) {
                formData = {
                    ...formData,
                    [`${receiptRow.id}`]: {
                        ...commonForm,
                        amount: receiptRow.amount,
                        paymentMethodId: receiptRow.paymentMethod?.id,
                    },
                };
            } else {
                formData = {
                    ...formData,
                    [`${receiptRow.id}`]: {
                        ...commonForm,
                        quantity: receiptRow.quantity,
                        unitPrice: receiptRow.unitPrice,
                        saleItemId:
                            receiptRow.receiptItemDetailType ===
                                ReceiptItemDetailType.SALE_ITEM &&
                            receiptRow.saleItem === null
                                ? receiptRow.saleItemId
                                : receiptRow.saleItem?.id,
                        amount: receiptRow.amount,
                    },
                };
            }
        }
        return formData;
    };

    const changeEditMode = () => {
        setIsEditMode(true);
        const receipts = convertTreeToList(receiptTableData, []);
        reset(initForm(receipts));
    };

    const changeAllItemToRepresentativeRoom = (
        representativeBooking?: IRoomBookingItemReceipt,
    ) => {
        const formData = getValues();
        const keys = Object.keys(formData).filter((key) => key.split('_')?.length === 3);
        // exist representative booking
        if (representativeBooking?.id) {
            keys.forEach((key) => {
                setValue(`${key}.paymentRoomBookingItem`, representativeBooking?.id);
            });
            return;
        }
    };

    const changeAllRoomFeeToRepresentativeRoom = (
        representativeBooking?: IRoomBookingItemReceipt,
    ) => {
        const formData = getValues();
        const keys = Object.keys(formData).filter((key) => key.split('_')?.length === 3);

        // exist representative booking
        if (representativeBooking?.id) {
            for (const key of keys) {
                const receiptRow = findReceiptRowById(receiptTableData, key);

                if (receiptRow?.facility || receiptRow?.plan) {
                    setValue(`${key}.paymentRoomBookingItem`, representativeBooking?.id);
                    continue;
                }

                if (formData[key]?.isAddition) {
                    setValue(
                        `${key}.paymentRoomBookingItem`,
                        receiptRow?.paymentRoomBookingItem?.id,
                    );
                    continue;
                }

                if (
                    formData[key]?.saleItemId ||
                    formData[key].type === ReceiptItemType.PAYMENT
                ) {
                    setValue(
                        `${key}.paymentRoomBookingItem`,
                        receiptRow?.paymentRoomBookingItem?.id,
                    );
                    continue;
                }
            }
        }
    };

    const validateAmountAllTable = () => {
        let isValidateAmount = true;
        const _tableData = cloneDeep(receiptTableData);
        const _receiptItemList = convertTreeToList(_tableData, []);
        _receiptItemList.forEach((item) => {
            if (item.children) return;
            const isError = !checkAmount(item.id, item.amount || 0);
            item.isErrorAmount = isError;
            if (isError) isValidateAmount = false;
        });
        setReceiptTableData(_tableData);
        return isValidateAmount;
    };

    const saveReceipt = () => {
        handleSubmit((data) => {
            // check price or amount is too large
            if (errorAmountRowIds.current.length > 0 || !roomBookingReceipt) return;
            if (!validateAmountAllTable()) {
                notification.error({
                    message: t('roomBooking.detail.receipt.message.priceOfAmountToLarge'),
                });
                return;
            }

            const receiptsItemsPrev = roomBookingReceipt.roomBooking.roomBookingItems
                .filter((roomBookingItem) => roomBookingItem.receiptItems)
                .flatMap(
                    (filteredRoomBookingItem) => filteredRoomBookingItem.receiptItems,
                );
            const receiptsPrev = receiptsItemsPrev.flatMap((item) =>
                item?.receiptItemDetails.map((detail) => {
                    const result: IUpdateRoomBookingReceiptItemFlat = {
                        type: item.type,
                        id: item.id,
                        planId: item.planId ?? undefined,
                        paymentRoomBookingItemId:
                            item.paymentRoomBookingItemId ?? undefined,
                        paymentMethodId: item.paymentMethodId ?? undefined,
                        bookingType: item.bookingType,
                        bookingId: item.bookingId,
                        detail: {
                            id: detail.id,
                            saleItemId: detail.saleItemId,
                            unitPrice: detail.unitPrice,
                            quantity: detail.quantity,
                            amount: detail.amount,
                            boughtAt: detail.boughtAt ?? undefined,
                            payAt: detail.payAt ?? undefined,
                            type: detail.type as ReceiptItemDetailType,
                        },
                    };
                    return result;
                }),
            );

            const receiptsNew = Object.entries(data).map(
                ([key, val]: [string, IUpdateRoomBookingReceiptItemTable]) => {
                    const [, bookingId, itemId, detailId] = key.split('_');
                    const result: IUpdateRoomBookingReceiptItemFlat = {
                        id: Number(itemId),
                        bookingId: Number(bookingId),
                        type: val.type,
                        paymentMethodId: val.paymentMethodId ?? undefined,
                        paymentRoomBookingItemId: val.paymentRoomBookingItem ?? undefined,
                        detail: {
                            id: detailId ? Number(detailId) : undefined,
                            saleItemId: val.saleItemId ?? null,
                            unitPrice: val.unitPrice ? Number(val.unitPrice) : undefined,
                            quantity: val.quantity ? Number(val.quantity) : undefined,
                            amount: Number(val.amount),
                            boughtAt:
                                val.type === ReceiptItemType.RECEIPT
                                    ? dayjs(val.bookingDate).fmYYYYMMDDHHmmss()
                                    : undefined,
                            payAt:
                                val.type === ReceiptItemType.PAYMENT
                                    ? dayjs(val.bookingDate).fmYYYYMMDDHHmmss()
                                    : undefined,
                            type: val.receiptItemDetailType,
                        },
                        isAddition: val.isAddition,
                    };
                    return result;
                },
            );

            const createdUpdated: IUpdateRoomBookingReceiptItemFlat[] =
                receiptsNew.filter((r) => r.isAddition);
            const deletedIds: number[] = [];

            receiptsPrev.forEach((receiptPrev) => {
                if (!receiptPrev) return;

                const receiptNew = receiptsNew.find(
                    (n) =>
                        n.id === receiptPrev.id && n.detail.id === receiptPrev.detail.id,
                );
                if (!receiptNew) {
                    if (receiptPrev.detail.id) deletedIds.push(receiptPrev.detail.id);
                    return;
                }

                if (
                    receiptNew.detail.type === ReceiptItemDetailType.SALE_ITEM &&
                    receiptNew.detail.boughtAt &&
                    (receiptNew.detail.boughtAt !==
                        dayjs(receiptPrev.detail.boughtAt)
                            .startOf('day')
                            .fmYYYYMMDDHHmmss() ||
                        receiptNew.detail.saleItemId !== receiptPrev.detail.saleItemId ||
                        receiptNew.detail.unitPrice !== receiptPrev.detail.unitPrice ||
                        receiptNew.detail.quantity !== receiptPrev.detail.quantity ||
                        receiptNew.detail.amount !== receiptPrev.detail.amount ||
                        receiptNew.paymentRoomBookingItemId !==
                            receiptPrev.paymentRoomBookingItemId)
                ) {
                    createdUpdated.push(receiptNew);
                } else if (
                    receiptNew.detail.type === ReceiptItemDetailType.PAYMENT &&
                    (receiptNew.detail.payAt !==
                        dayjs(receiptPrev.detail.payAt)
                            .startOf('day')
                            .fmYYYYMMDDHHmmss() ||
                        receiptNew.detail.amount !== receiptPrev.detail.amount ||
                        receiptNew.paymentMethodId !== receiptPrev.paymentMethodId ||
                        receiptNew.paymentRoomBookingItemId !==
                            receiptPrev.paymentRoomBookingItemId)
                ) {
                    createdUpdated.push(receiptNew);
                } else {
                    if (receiptNew.detail.amount !== receiptPrev.detail.amount) {
                        const updated = structuredClone(receiptPrev);
                        updated.detail.unitPrice = receiptNew.detail.unitPrice;
                        updated.detail.amount = receiptNew.detail.amount;
                        updated.detail.boughtAt = dayjs(updated.detail.boughtAt)
                            .startOf('day')
                            .fmYYYYMMDDHHmmss();
                        delete updated.planId;

                        createdUpdated.push(updated);
                    }
                }
            });

            const grouped = createdUpdated.reduce(
                (result, { detail: { type, ...detail }, ...current }) => {
                    if (!result[current.id]) {
                        result[current.id] = {
                            ...current,
                            receiptItemDetails: [],
                        };
                    }
                    result[current.id].receiptItemDetails?.push({ ...detail });
                    return result;
                },
                {} as Record<
                    number,
                    IUpdateRoomBookingReceiptItem & {
                        isAddition?: boolean;
                    }
                >,
            );
            const flatten = Object.values(grouped);

            if (roomBookingReceipt.roomBooking.roomBookingItems.length > 1) {
                const parentChangeSettlementRoom: Omit<
                    IUpdateRoomBookingReceiptItemFlat,
                    'detail'
                >[] = [];
                receiptsItemsPrev
                    .filter((item) => item?.planId)
                    .forEach((prev) => {
                        const parentNew = receiptsNew.find(
                            (n) => n.paymentRoomBookingItemId && n.id === prev?.id,
                        );
                        if (
                            parentNew &&
                            parentNew.paymentRoomBookingItemId !==
                                prev?.paymentRoomBookingItemId
                        ) {
                            const { detail, ...parent } = parentNew;
                            parentChangeSettlementRoom.push(parent);
                        }
                    });
                parentChangeSettlementRoom.forEach((parent) => {
                    const foundIdx = flatten.findIndex((i) => i.id === parent.id);
                    if (foundIdx !== -1) {
                        flatten[foundIdx].paymentRoomBookingItemId =
                            parent.paymentRoomBookingItemId!;
                    } else {
                        flatten.push({ ...parent, receiptItemDetails: [] });
                    }
                });
            }

            const sanitized = flatten.map(({ isAddition, id, ...item }) => ({
                ...item,
                ...(!isAddition && { id }),
                paymentRoomBookingItemId: item.paymentRoomBookingItemId ?? item.bookingId,
                bookingType: ReceiptItemBookingType.ROOM_BOOKING_ITEM,
            }));

            saveReceiptItem(deletedIds, {
                id: roomBookingReceipt.id,
                body: { items: sanitized },
            });
        })();
    };

    const saveReceiptItem = async (
        deleted: number[],
        createdUpdated: IUpdateRoomBookingReceipt,
    ) => {
        const deleteReceiptItems = async (
            ids: number[],
        ): Promise<{ success: boolean; errorMessage?: string }> => {
            if (!ids.length) return { success: true };

            const response = await dispatch(bulkDeleteReceiptItemDetail(ids));
            if (bulkDeleteReceiptItemDetail.fulfilled.match(response)) {
                if (response.payload?.success) {
                    return { success: true };
                } else {
                    return { success: false, errorMessage: response.payload?.message };
                }
            }
            return { success: false };
        };

        const createUpdateReceiptItems = async (
            receiptUpdated: IUpdateRoomBookingReceipt,
        ): Promise<{ success: boolean; errorMessage?: string }> => {
            if (!receiptUpdated.body.items.length) return { success: true };

            const response = await dispatch(updateRoomBookingReceipt(receiptUpdated));
            if (updateRoomBookingReceipt.fulfilled.match(response)) {
                if (response.payload?.success) {
                    return { success: true };
                } else {
                    return { success: false, errorMessage: response.payload?.message };
                }
            }
            return { success: false };
        };

        const [deleteResult, createUpdateResult] = await Promise.all([
            deleteReceiptItems(deleted),
            createUpdateReceiptItems(createdUpdated),
        ]);

        if (deleteResult.success && createUpdateResult.success) {
            notification.success({
                message: t('roomBooking.detail.updateSuccessMessage'),
            });
            if (selectedRoomBookingDetail?.id) {
                await dispatch(getRoomBookingReceipt(selectedRoomBookingDetail?.id));
            }
            setSelectedRowKeys([]);
            deleteReceiptItemDetailIds.current = [];
            setIsEditMode(false);
            reset();
        } else {
            if (!deleteResult.success) {
                notification.error({
                    message: deleteResult.errorMessage || 'Error while deleting receipt',
                });
            }
            if (!createUpdateResult.success) {
                notification.error({
                    message:
                        createUpdateResult.errorMessage || 'Error while updating receipt',
                });
            }
        }
    };

    const displayBookingId = (item: IBookingReceiptTableData) => {
        if (item.name) {
            const autoGeneratedCode = getAutoGeneratedCode(item.autoGeneratedCode || '');
            if (isRoomBookingReceiptItem(item.id) && !isSingleRoomBooking) {
                return `${t(item.name)}${
                    (item.indexLevel0 || 0) + 1
                } ${autoGeneratedCode}`;
            } else {
                return `${t(item.name)} ${autoGeneratedCode}`;
            }
        }
        return '';
    };

    const isPaymentItem = (item: IBookingReceiptTableData) => {
        return item.level === 1 && item.status === ReceiptItemType.PAYMENT;
    };

    const removeSelectedReceiptItem = () => {
        const _receiptDataTable = cloneDeep(receiptTableData);
        receiptTableData.forEach((receipt, index) => {
            if (receipt.children) {
                const receiptItems = receipt.children?.filter((item) => {
                    return !selectedRowKeys.includes(item.id);
                });
                _receiptDataTable[index].children = [...cloneDeep(receiptItems)];

                receipt.children.forEach((receiptItemDetail, receiptItemDetailIndex) => {
                    if (receiptItemDetail.children) {
                        const _receiptItemDetails = receiptItemDetail.children?.filter(
                            (item) => {
                                return !selectedRowKeys.includes(item.id);
                            },
                        );
                        const receiptItem =
                            _receiptDataTable[index].children?.[receiptItemDetailIndex];
                        if (receiptItem) {
                            receiptItem.children = [...cloneDeep(_receiptItemDetails)];
                            receiptItem.amount = sumBy(_receiptItemDetails, 'amount');
                        }
                    }
                });
            }
        });

        setReceiptTableData(_receiptDataTable);
        const formData = getValues();
        selectedRowKeys.forEach((key) => delete formData[key]);
        reset(formData);
        setSelectedRowKeys([]);
    };

    const showDeleteReceiptItemError = (receiptItem: IBookingReceiptTableData[]) => {
        const roomBookingItemIndexes = receiptItem
            .filter((item) => isRoomBookingReceiptItem(item.id))
            .map((item) => item.indexLevel0);
        const uniqRoomBookingItemsIndexes = uniq(roomBookingItemIndexes);
        const bookingErrors: string[] = [];
        uniqRoomBookingItemsIndexes.forEach((index) => {
            if (
                index !== undefined &&
                roomBookingReceipt?.roomBooking?.roomBookingItems?.[index]
            ) {
                const autoGeneratedCode = getAutoGeneratedCode(
                    roomBookingReceipt?.roomBooking?.autoGeneratedCode || '',
                );
                let name = `${t('roomBooking.detail.roomItem')}${
                    index + 1
                } ${autoGeneratedCode}`;
                if (isSingleRoomBooking) {
                    name = `${t('roomBooking.detail.roomItem')} ${autoGeneratedCode}`;
                }
                name = `${name} - ${t('roomBooking.detail.receipt.saleItemDefault')}`;
                bookingErrors.push(name);
            }
        });

        const facilityBookingIndexes = receiptItem
            .filter((item) => !isRoomBookingReceiptItem(item.id))
            .map((item) => item.indexLevel0);
        uniq(facilityBookingIndexes);
        const stayFacilityBookingItemIds: (number | undefined)[] = [];
        receiptItem.forEach((item) => {
            if (
                !isRoomBookingReceiptItem(item.id) &&
                item.receiptItemDetailType === ReceiptItemDetailType.STAY_PRICE
            ) {
                stayFacilityBookingItemIds.push(item.indexLevel0);
            }
        });
        facilityBookingIndexes.forEach((index) => {
            if (index !== undefined) {
                let name = `${t('roomBooking.detail.facilityItem')} ${
                    roomBookingReceipt?.facilityBookings?.[index]?.autoGeneratedCode
                }`;
                if (stayFacilityBookingItemIds.includes(index)) {
                    name = `${name} - ${t('roomBooking.detail.receipt.saleItemDefault')}`;
                }
                bookingErrors.push(name);
            }
        });

        Modal.error({
            title: t('roomBooking.detail.deleteReceiptErrorModal.title'),
            content: (
                <>
                    <span>
                        {t('roomBooking.detail.deleteReceiptErrorModal.description')}
                    </span>
                    <ul>
                        {bookingErrors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                </>
            ),
            okText: t('common.buttonCloseText'),
        });
    };

    const deleteReceiptItem = () => {
        const receiptItemList = convertTreeToList(receiptTableData, []);
        const keys = selectedRowKeys.filter(
            (key) => (key as string).split('_').length > 2,
        );
        const selectedReceiptItem = receiptItemList.filter((item) =>
            keys.includes(item.id),
        );

        const receiptItemsNotForDeletion =
            shouldNotDeleteReceiptItemsList(selectedReceiptItem);

        if (receiptItemsNotForDeletion.length) {
            showDeleteReceiptItemError(receiptItemsNotForDeletion);
            return;
        }

        removeSelectedReceiptItem();
        const deleteItemIds = selectedReceiptItem
            .filter((item) => {
                return !item.isAddition && !item.isCheckout;
            })
            .map((item) => {
                if (item.level === 1) {
                    return item.receiptItemDetailId || 0;
                } else {
                    return +item.id?.split('_')?.[3] || 0;
                }
            });

        deleteReceiptItemDetailIds.current = [
            ...deleteItemIds,
            ...deleteReceiptItemDetailIds.current,
        ];
    };

    const showDeleteReceiptItemConfirm = () => {
        const _bookingIds = selectedRowKeys.map((rowKey) => {
            const keys = (rowKey as string).split('_');
            if (keys.length > 2) {
                return `${keys[0]}_${keys[1]}`;
            }
            return rowKey;
        });
        const bookingIds = uniq(_bookingIds);
        const bookingNames = bookingIds.map((id) => {
            const receiptRow = findReceiptRowById(receiptTableData, id as string);
            if (receiptRow) {
                return displayBookingId(receiptRow);
            }
            return '';
        });

        ModalConfirmDeletion({
            deletedItems: bookingNames,
            okButtonProps: { danger: true },
            buttonDeleteText: t('common.buttonDeleteText'),
            buttonCancelText: t('common.buttonCancelText'),
            onClickButtonDelete: deleteReceiptItem,
        });
    };

    const fetchPaymentMethodDropdown = async (bookingId: number) => {
        return await dispatch(
            getPaymentMethodDropdown({
                bookingId,
            }),
        );
    };

    useQuery({
        queryKey: [CacheKeys.getPaymentMethodDropdownKey, roomBookingReceipt?.bookingId],
        queryFn: () =>
            fetchPaymentMethodDropdown(roomBookingReceipt?.bookingId as number),
        enabled: true,
    });

    return (
        <div className="room-booking-receipt-tab-pane">
            <HeaderReceipt
                isEditMode={isEditMode}
                selectedRowKeys={selectedRowKeys}
                changeEditMode={changeEditMode}
                changeAllItemToRepresentativeRoom={changeAllItemToRepresentativeRoom}
                changeAllRoomFeeToRepresentativeRoom={
                    changeAllRoomFeeToRepresentativeRoom
                }
                showDeleteReceiptItemConfirm={showDeleteReceiptItemConfirm}
            />
            <ReceiptTable
                control={control}
                isEditMode={isEditMode}
                selectedRowKeys={selectedRowKeys}
                receiptTableData={receiptTableData}
                paymentRoomBookingItemOptions={paymentRoomBookingItemOptions}
                onSelectChange={onSelectChange}
                addSaleItem={addSaleItem}
                displayBookingId={displayBookingId}
                onChangeSaleItem={onChangeSaleItem}
                changeUnitPriceOrQuantity={changeUnitPriceOrQuantity}
            />
            <SummaryTotalReceipt
                totalPrice={totalPrice}
                totalTax={totalPrice.totalTax}
                isHaveErrorPrice={roomBookingReceipt?.roomBooking?.isHaveErrorPrice}
            />
            <ReceiptBottomButtonGroup
                isEditMode={isEditMode}
                saveReceipt={saveReceipt}
                resetReceipt={resetReceipt}
                cancelEdit={cancelEdit}
            />
            <PaymentModal
                totalPrice={totalPrice}
                totalTax={totalPrice.totalTax}
                paymentRoomBookingItemOptions={paymentRoomBookingItemOptions}
            />
            <BulkPaymentGroupBookingModal roomBookingReceipt={roomBookingReceipt} />
        </div>
    );
}

export default RoomBookingReceiptTabPane;
