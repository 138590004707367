const roomCleaningList = {
    title: '部屋管理',
    breadcrumbs: {
        home: 'ホーム',
        roomCleaning: '部屋管理',
    },
    tabs: {
        roomManagement: 'ルームインジケーター',
        cleaningList: '清掃一覧',
    },
    cleanAllRoomBtn: '全てを清掃済みに変更',
    cleanSelectedRoom: '選択項目を清掃済みに変更',
    todayBtn: '本日',
    roomCleaningListColumn: {
        roomName: '部屋名',
        roomType: '部屋タイプ',
        cleaningStatus: '清掃状況',
        memo: 'メモ',
        stayDate: 'C/I - C/O 日付',
        occupationStatus: 'ステータス',
    },
    cleanStatus: {
        notCleaned: '未清掃',
        cleaned: '清掃済み',
    },
    memoInput: '備考を入力',
    confirm: {
        titleCleanSelectedRoom: '清掃済みに変更します。よろしいでしょうか？',
        titleCleanAllRoom: '清掃済みに変更します。よろしいでしょうか？',
        titleSwitchStatus: 'ステータスを変更してもよろしいですか？',
        titleUpdateMemo: 'メモを更新してもよろしいですか？',
        titleCleanSelectedMemo: '選択中のメモをクリアしますか？',
        titleCleanAllMemo: '全てのメモをクリアしますか？',
        titleDirtySelectedRoom: '選択中の部屋を未清掃に変更しますか？',
        titleDirtyAllRoom: '全ての部屋を未清掃に変更しますか？',
        cancelTitle: 'キャンセルしてもよろしいですか？',
        buttonCancelText: 'いいえ',
        buttonDeleteText: 'はい',
    },
    updateSuccessMessage: '部屋の清掃状況が更新されました',
    cleaningOption: {
        dirtyAllRoom: 'すべての部屋を未清掃に変更',
        clearAllRoomMemo: 'すべてのメモをクリア',
        dirtyRoomSelection: '選択した部屋を未清掃に変更',
        clearRoomMemoSelection: '選択したメモをクリア',
    },
    roomStatus: {
        occupied: '滞在',
        empty: '空室',
        checked_out: '出発',
    },
    columns: {
        roomName: '部屋名',
        status: 'ステータス',
        cleaningStatus: '清掃状況',
    },
    filter: {
        all: '全て',
        notCleaned: '未清掃',
        noData: 'データなし',
    },
    action: {
        addMemo: 'メモを作成',
        editMemo: 'メモを更新',
        delete: 'メモを削除',
        save: '保存',
        cancel: 'キャンセル',
        loadMore: 'もっと見る',
    },
};

export default roomCleaningList;
