import { Button, Pagination, Table, Typography } from 'antd';
import { CsvDropdown } from '~components';
import { ReloadOutlined } from '@ant-design/icons';

import './RoomBookingHistoryList.scss';
import { ColumnsType } from 'antd/lib/table';
import {
    RoomBookingHistoryColumn,
    ROOM_BOOKING_HISTORY_FILE_NAME,
    exportRoomBookingHistoryColumns,
    EXPORT_CSV_ROOM_BOOKING_HISTORY_FILE_NAME,
    i18RoomBookingHistoryExportKey,
    OrderBy,
} from '~features/guest/constants';
import { useTranslation } from 'react-i18next';
import {
    IBookingHistoryExportCsvQuery,
    IRoomBookingHistory,
    IRoomBookingHistoryForTable,
} from '~features/guest/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    getRoomBookingHistoryDataSelector,
    getRoomBookingHistoryData,
    roomBookingHistoryQuerySelector,
    setRoomBookingHistoryQuery,
    showLoadingRoomBookingHistorySelector,
    totalRoomBookingSelector,
} from '~features/guest/reducers/guest.reducer';
import { downloadFile, exportCSVFile, parseDateTime } from '~common/commonFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import {
    AntdOrderDirection,
    CsvOption,
    DEFAULT_LIMIT_FOR_PAGINATION,
    OrderDirection,
} from '~common/constants';
import { guestService } from '~features/guest/services/guest.service';
import { TableProps } from 'antd/es/table';
import { ISorter } from '~common/interfaces';
import { orderBy } from 'lodash';
import { getAutoGeneratedCode } from '~features/guest/helper';

function RoomBookingHistoryList() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { guestId } = useParams();
    const { Title } = Typography;
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const roomBookingHistoryData = useAppSelector(getRoomBookingHistoryDataSelector);
    const roomBookingHistoryQuery = useAppSelector(roomBookingHistoryQuerySelector);
    const showLoading = useAppSelector(showLoadingRoomBookingHistorySelector);
    const totalRoomBooking = useAppSelector(totalRoomBookingSelector);

    const fetchData = () => {
        dispatch(getRoomBookingHistoryData(Number(guestId)));
    };

    useEffect(() => {
        fetchData();
    }, [roomBookingHistoryQuery]);

    const onRefresh = async () => {
        await fetchData();
    };
    const convertToTableData = (data: IRoomBookingHistory | null) => {
        if (!data) return [];
        return data?.bookings?.items.map((item) => {
            return {
                id: item?.id,
                fullName: data.guest?.fullName,
                yomigana: data.guest?.yomigana,
                autoGeneratedCode: item?.autoGeneratedCode,
                checkInTime: item?.roomBookingItems?.[0]?.checkInTime,
                checkOutTime: item?.roomBookingItems?.[0]?.checkOutTime,
                startDateOfStay: item?.roomBookingItems?.[0]?.startDateOfStay,
                endDateOfStay: item?.roomBookingItems?.[0]?.endDateOfStay,
                roomName: item?.roomBookingItems?.[0]?.room?.name,
            };
        });
    };

    const tableData = convertToTableData(roomBookingHistoryData);

    const pageCount = Math.ceil(
        totalRoomBooking /
            (roomBookingHistoryQuery.limit || DEFAULT_LIMIT_FOR_PAGINATION),
    );

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const selectedRows = useMemo(() => {
        const rows: IRoomBookingHistoryForTable[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundRoomBooking = tableData.find(
                (roomBooking) => roomBooking.id === selectedRowKey,
            );
            if (foundRoomBooking) rows.push(foundRoomBooking);
        });
        return rows;
    }, [selectedRowKeys]);

    const editRecord = (roomBooking: IRoomBookingHistoryForTable) => {
        return {
            onClick: () => {
                if (roomBooking.id) {
                    window.open(`/room-booking/${roomBooking?.id}/detail`);
                }
            },
        };
    };

    const onChange: TableProps<IRoomBookingHistoryForTable>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra,
    ) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setRoomBookingHistoryQuery({
                    ...roomBookingHistoryQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setRoomBookingHistoryQuery({
                    ...roomBookingHistoryQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onChangePage = (page: number) => {
        setSelectedRowKeys([]);
        dispatch(setRoomBookingHistoryQuery({ ...roomBookingHistoryQuery, page }));
    };

    const exportCsv = async (query: IBookingHistoryExportCsvQuery) => {
        const response = await guestService.exportCsvRoomBookingHistory(
            Number(guestId),
            query,
        );
        if (response.success) {
            downloadFile(ROOM_BOOKING_HISTORY_FILE_NAME, response.data.filePath);
        }
    };

    const convertDataToCsv = (roomBookingHistory: IRoomBookingHistoryForTable[]) => {
        const dataOutput: any[] = [];
        roomBookingHistory.forEach((roomBooking) => {
            const data = {
                autoGeneratedCode: getAutoGeneratedCode(roomBooking.autoGeneratedCode),
                fullName: roomBooking.fullName,
                yomigana: roomBooking.yomigana,
                roomName: roomBooking.roomName,
                checkInTime: `${parseDateTime(
                    roomBooking.startDateOfStay ? roomBooking.startDateOfStay : '',
                )} ${roomBooking.checkInTime || ''}`,
                checkOutTime: `${parseDateTime(
                    roomBooking.endDateOfStay ? roomBooking.endDateOfStay : '',
                )} ${roomBooking.checkOutTime || ''}`,
            };

            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportRoomBookingHistory = async (
        roomBookingHistory: IRoomBookingHistoryForTable[],
    ) => {
        try {
            // Create header file csv
            const filename = `${EXPORT_CSV_ROOM_BOOKING_HISTORY_FILE_NAME}_${new Date().getTime()}.csv`;

            const roomBookingHistoryDataCsv = convertDataToCsv(roomBookingHistory);
            await exportCSVFile(
                exportRoomBookingHistoryColumns,
                filename,
                roomBookingHistoryDataCsv,
                i18RoomBookingHistoryExportKey,
            );

            return {
                filename,
                filePath: `${process.env.FILE_STORAGE_BASE_URL}/${process.env.FILE_STORAGE_GUEST_CSV_FOLDER}/${filename}`,
            };
        } catch (error) {
            throw error;
        }
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all guest
                await exportCsv({
                    orderBy: roomBookingHistoryQuery.orderBy,
                    orderDirection: roomBookingHistoryQuery.orderDirection,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportRoomBookingHistory(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                // ToDo: import
                break;
            }
            default:
                break;
        }
    };

    const roomBookingHistoryColumn: ColumnsType<IRoomBookingHistoryForTable> = [
        {
            title: t('guest.detail.roomBookingHistory.column.autoGeneratedCode'),
            key: RoomBookingHistoryColumn.AUTO_GENERATED_CODE,
            sorter: true,
            dataIndex: RoomBookingHistoryColumn.AUTO_GENERATED_CODE,
            width: '140px',
            ellipsis: true,
            render: (autoGeneratedCode: string) => {
                return (
                    <div className="text-truncate">
                        <a>{getAutoGeneratedCode(autoGeneratedCode)}</a>
                    </div>
                );
            },
        },
        {
            title: t('guest.detail.roomBookingHistory.column.yomigana'),
            key: RoomBookingHistoryColumn.YOMIGANA,
            dataIndex: RoomBookingHistoryColumn.YOMIGANA,
            ellipsis: true,
        },
        {
            title: t('guest.detail.roomBookingHistory.column.fullName'),
            key: RoomBookingHistoryColumn.FULL_NAME,
            dataIndex: RoomBookingHistoryColumn.FULL_NAME,
            ellipsis: true,
        },
        {
            title: t('guest.detail.roomBookingHistory.column.roomName'),
            key: RoomBookingHistoryColumn.ROOM_NAME,
            dataIndex: RoomBookingHistoryColumn.ROOM_NAME,
            ellipsis: true,
        },
        {
            title: t('guest.detail.roomBookingHistory.column.checkInTime'),
            key: RoomBookingHistoryColumn.CHECK_IN_TIME,
            render: (roomBookingHistory: IRoomBookingHistoryForTable) => {
                return (
                    <div>
                        <span>
                            {roomBookingHistory.startDateOfStay
                                ? parseDateTime(roomBookingHistory.startDateOfStay)
                                : ''}
                        </span>
                        <span> {roomBookingHistory.checkInTime}</span>
                    </div>
                );
            },
        },
        {
            title: t('guest.detail.roomBookingHistory.column.checkOutTime'),
            key: RoomBookingHistoryColumn.CHECK_OUT_TIME,
            render: (roomBookingHistory: IRoomBookingHistoryForTable) => {
                return (
                    <div>
                        <span>
                            {roomBookingHistory.endDateOfStay
                                ? parseDateTime(roomBookingHistory.endDateOfStay)
                                : ''}
                        </span>
                        <span> {roomBookingHistory.checkOutTime}</span>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <div className="guest-room-booking-history-list-wrapper">
                <div className="guest-room-booking-history-list-header">
                    <span></span>
                    <Title level={5} className="guest-room-booking-history-list-title">
                        {t('guest.detail.roomBookingHistory.headerTitle')}
                    </Title>
                    <div className="guest-header-right">
                        <CsvDropdown
                            onChange={onChangeCsvOption}
                            hasSelectedColumns={selectedRows.length > 0}
                            hasImportOption={false}
                        />
                        <Button type="text" className="btn">
                            <ReloadOutlined onClick={onRefresh} />
                        </Button>
                    </div>
                </div>
                <Table
                    className="guest-room-booking-history-list-table"
                    columns={roomBookingHistoryColumn}
                    onChange={onChange}
                    rowSelection={rowSelection}
                    pagination={false}
                    dataSource={tableData}
                    rowKey="id"
                    loading={showLoading}
                    rowClassName={'guest-room-booking-row'}
                    onRow={editRecord}
                />
            </div>
            {pageCount > 1 && (
                <Pagination
                    defaultCurrent={roomBookingHistoryQuery.page}
                    className="guest-room-booking-history-pagination"
                    total={totalRoomBooking}
                    pageSize={roomBookingHistoryQuery.limit}
                    showSizeChanger={false}
                    onChange={onChangePage}
                />
            )}
        </>
    );
}

export default RoomBookingHistoryList;
