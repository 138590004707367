import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, notification, Row } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageType, HttpStatus } from '~common/constants';
import { showConfirm } from '~common/notification';
import { InputText, RightDrawerLayout, SelectSaleItem } from '~components';
import { getSaleGroupFormId } from '~features/sale-group/helper';
import {
    createSaleGroup,
    fetchSaleGroupList,
    getSaleGroupDetail,
    selectedSaleGroupSelector,
    setSelectedSaleGroup,
    setShowForm,
    showFormSelector,
    showLoadingSelector,
    updateSaleGroup,
} from '~features/sale-group/reducers/sale-group.reducer';
import { bulkCreateSaleGroupsSchema } from '~features/sale-group/schema';
import {
    getSaleItemListForDropdown,
    saleItemListDropdownSelector,
} from '~features/sale-item/reducers/sale-item.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import { ISaleGroupForm, ISaleGroupUpdateForm, ISaleItem } from '../../../../interfaces';
import './SaleGroupForm.scss';
import { MEAL_GROUP } from '~features/sale-group/constants';
import { BREAKFAST, DINNER, LUNCH } from '~features/sale-item/constants';
import { sortBy } from 'lodash';
import { compareFormData } from '~common/commonFunctions';
import { useEscape } from '~common/useHooks';

function SaleGroupForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const saleItemOptions = useAppSelector(saleItemListDropdownSelector);
    const selectedSaleGroup = useAppSelector(selectedSaleGroupSelector);
    const { control, handleSubmit, setError, reset, getValues, setValue } = useForm({
        resolver: yupResolver(bulkCreateSaleGroupsSchema),
    });

    const [salesItemList, setSalesItemList] = useState<ISaleItem[]>([
        {
            feId: `0_${Date.now().toString()}`,
        },
    ]);

    const showForm = useAppSelector(showFormSelector);
    const showLoading = useAppSelector(showLoadingSelector);
    const closeDialog = (reload?: boolean) => {
        dispatch(setShowForm(false));
        setSalesItemList([
            {
                feId: `0_${Date.now().toString()}`,
            },
        ]);
        dispatch(setSelectedSaleGroup(null));
        reset();
        if (reload) {
            dispatch(fetchSaleGroupList());
        }
    };

    const makeFormData = (): ISaleGroupForm => {
        const { name, saleItems = [] } = getValues();
        return {
            name,
            saleItemIds: saleItems
                .filter((item: ISaleItem) => item.id)
                .map((item: ISaleItem) => item.id),
        };
    };

    useEffect(() => {
        dispatch(getSaleItemListForDropdown({}));
    }, []);

    useEffect(() => {
        if (showForm) {
            if (selectedSaleGroup?.id) {
                _getSaleGroupDetail();
            }
        } else {
            reset({
                name: '',
            });
        }
    }, [showForm, selectedSaleGroup]);

    useEffect(() => {
        setValue('saleItems', salesItemList);
        salesItemList.forEach((saleItem, index) => {
            setValue(`saleItems.${index}.saleItemId`, saleItem.id);
        });
    }, [salesItemList]);

    const _showConfirm = () => {
        const currentFormData = makeFormData();
        if (compareFormData(currentFormData, oldFormData.current)) {
            closeDialog();
            return;
        }
        showConfirm({
            title: t('saleGroup.form.confirm.cancelTitle'),
            cancelText: t('saleGroup.form.confirm.buttonCancelText'),
            okText: t('saleGroup.form.confirm.buttonDeleteText'),
            onOk() {
                closeDialog();
            },
        });
    };
    useEscape(closeDialog);

    const oldFormData = useRef<{
        name?: string;
        saleItemIds?: number[];
    }>({ name: '', saleItemIds: [] });

    const initFormData = (data: ISaleGroupForm) => {
        const formData = {
            name: data?.name,
            saleItemIds: data?.saleItemIds,
        };
        oldFormData.current = formData;
        reset(formData);
    };

    const _getSaleGroupDetail = useCallback(async () => {
        if (!selectedSaleGroup?.id) return;
        const response = await dispatch(getSaleGroupDetail(selectedSaleGroup.id));
        if (getSaleGroupDetail.fulfilled.match(response)) {
            const { success, data, message } = response.payload;
            if (success) {
                const saleItems = sortBy(data?.saleItems || [], 'id');
                initFormData({
                    name: data?.name,
                    saleItemIds: saleItems?.map((item) => {
                        return item.id;
                    }),
                });
                setSalesItemList(saleItems);
            } else {
                notification.error({
                    message: message || '',
                });
            }
        }
    }, [selectedSaleGroup, updateSaleGroup, reset]);

    const _createSaleGroup = useCallback(async (formData: ISaleGroupForm) => {
        const response = await dispatch(createSaleGroup(formData));

        if (createSaleGroup.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('saleGroup.form.message.createSuccess'),
                });
                closeDialog(true);
                return;
            }
            notification.error({
                message: t('saleGroup.form.message.createError'),
                description: response.payload?.errors?.[0]?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                setError(
                    error.key,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
    }, []);

    const _updateSaleGroup = useCallback(async (formData: ISaleGroupUpdateForm) => {
        const response = await dispatch(updateSaleGroup(formData));
        if (updateSaleGroup.fulfilled.match(response)) {
            if (response?.payload?.success) {
                notification.success({
                    message: t('saleGroup.form.message.updateSuccess'),
                });

                closeDialog(true);
                return;
            }
            if (response?.payload?.status === HttpStatus.ITEM_NOT_FOUND) {
                closeDialog(true);
            }
            notification.error({
                message: t('saleGroup.form.message.updateError'),
                description: response?.payload?.errors?.[0].message || '',
            });
        }
    }, []);

    const changeSalesItem = (value: ISaleItem, index: number) => {
        const newSaleItemList = cloneDeep(salesItemList);
        newSaleItemList[index].id = value.id;
        setSalesItemList(newSaleItemList);
    };

    const addSalesItem = () => {
        setSalesItemList([
            ...salesItemList,
            {
                feId: `0_${Date.now().toString()}`,
            },
        ]);
    };

    const onRemoveSalesItem = (saleItem: ISaleItem) => {
        if (salesItemList.length === 1) return;
        if (saleItem?.autoGeneratedCode) {
            const items = salesItemList.filter((item) => {
                return item.autoGeneratedCode !== saleItem.autoGeneratedCode;
            });
            setSalesItemList(items);
        } else {
            const items = salesItemList.filter((item) => {
                return item.feId !== saleItem.feId;
            });
            setSalesItemList(items);
        }
    };

    const onSubmit = () => {
        handleSubmit(async (data) => {
            const _formData = makeFormData();
            if (selectedSaleGroup) {
                _updateSaleGroup({
                    id: selectedSaleGroup.id,
                    body: _formData,
                });
            } else {
                _createSaleGroup(_formData);
            }
        })();
    };

    const isSaleItemDefaultOfMealGroup = (saleItem: ISaleItem) => {
        if (selectedSaleGroup?.name === MEAL_GROUP && saleItem.name) {
            return [BREAKFAST, LUNCH, DINNER].includes(saleItem.name);
        }
        return false;
    };

    return (
        <RightDrawerLayout
            open={showForm}
            onClose={_showConfirm}
            onSubmit={onSubmit}
            title={
                selectedSaleGroup
                    ? t('saleGroup.form.editTitle')
                    : t('saleGroup.form.title')
            }
            cancelText={t('common.buttonCancelText')}
            submitText={
                selectedSaleGroup
                    ? t('saleGroup.form.editButton')
                    : t('saleGroup.form.submitBtn')
            }
            className="sale-group-form-drawer"
            busy={showLoading}
            loading={showLoading}
            submitId="sale-group-form-submit"
        >
            <Form layout="vertical" autoComplete="off" scrollToFirstError>
                <Row gutter={8}>
                    <Col span={24}>
                        <InputText
                            label={t('saleGroup.form.name')}
                            placeholder={t('saleGroup.form.namePlaceholder')}
                            id={getSaleGroupFormId('name')}
                            name={`name`}
                            required
                            className="input-sale-group-name"
                            control={control}
                            disabled={selectedSaleGroup?.isSeedData}
                        />
                    </Col>
                </Row>

                {salesItemList.map((item, index) => {
                    return (
                        <Row
                            key={
                                item.autoGeneratedCode
                                    ? item.autoGeneratedCode
                                    : item.feId
                            }
                        >
                            <Col
                                span={
                                    salesItemList.length > 1 &&
                                    !isSaleItemDefaultOfMealGroup(item)
                                        ? 23
                                        : 24
                                }
                            >
                                <SelectSaleItem
                                    label={
                                        index === 0
                                            ? t('saleGroup.form.salesItemLabel')
                                            : ''
                                    }
                                    required
                                    placeholder={t('saleGroup.form.salesItemPlaceHolder')}
                                    onChange={(value) => changeSalesItem(value, index)}
                                    name={`saleItems.${index}.saleItemId`}
                                    control={control}
                                    showArrow={false}
                                    id={getSaleGroupFormId(
                                        `saleItems.${index}.saleItemId`,
                                    )}
                                    showSearch={true}
                                    optionLabelProp="label"
                                    filterOption={(input, option) => {
                                        return (
                                            (option!.label || '') as unknown as string
                                        )
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                    selectedValue={
                                        salesItemList?.[index]?.id || undefined
                                    }
                                    selectedSaleItemIds={compact(
                                        salesItemList.map((saleItem) => saleItem.id),
                                    )}
                                    disabled={isSaleItemDefaultOfMealGroup(item)}
                                />
                            </Col>
                            {salesItemList.length > 1 &&
                                !isSaleItemDefaultOfMealGroup(item) && (
                                    <Col span={1}>
                                        <Form.Item
                                            label={index === 0 ? ' ' : null}
                                            className="btn-delete"
                                        >
                                            <DeleteOutlined
                                                onClick={() => {
                                                    onRemoveSalesItem(item);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                        </Row>
                    );
                })}

                <Row>
                    <Col span={24}>
                        <Button
                            type="dashed"
                            block
                            className="addition"
                            disabled={salesItemList.length >= saleItemOptions.length}
                            onClick={addSalesItem}
                        >
                            <PlusCircleOutlined />
                            {t('saleGroup.form.addition')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </RightDrawerLayout>
    );
}

export default SaleGroupForm;
