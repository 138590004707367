import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadCSVFile, formatMoney } from '~common/commonFunctions';
import {
    AggregationMethod,
    PaymentSummaryColumn,
    ReportTableCsvFileName,
    ReportTableId,
} from '~features/report/constants';
import { IPaymentMethod, IPaymentSummaryTable } from '~features/report/interfaces';
import {
    exportCsvPaymentSummarySelector,
    paymentSummarySelector,
    selectedAggregationMethodSelector,
    selectedDateSelector,
    setExportCsv,
    showLoadingPaymentSummaryTableSelector,
} from '~features/report/reducers/payment-summary.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { parseDate } from '~plugins/dayjs';
import './PaymentSummaryTable.scss';
import { convertPaymentSummaryReportData } from '~features/report/helper';

function PaymentSummaryTable() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const showLoadingPaymentSummaryTable = useAppSelector(
        showLoadingPaymentSummaryTableSelector,
    );
    const paymentSummaryList = useAppSelector(paymentSummarySelector);
    const selectedDate = useAppSelector(selectedDateSelector);
    const selectedAggregationMethod = useAppSelector(selectedAggregationMethodSelector);
    const isExportCsv = useAppSelector(exportCsvPaymentSummarySelector);

    const paymentSummaryData: IPaymentSummaryTable[] = useMemo(() => {
        return paymentSummaryList.map((paymentSummary) => {
            return {
                id: paymentSummary.paymentMethod.id,
                date: parseDate(paymentSummary.date)?.fmYYYYMMDD(),
                paymentMethod: paymentSummary.paymentMethod,
                daylyAccumulative: paymentSummary.totalAmount,
            };
        });
    }, [paymentSummaryList]);

    const filteredPaymentSummaryData: IPaymentSummaryTable[] = useMemo(() => {
        return paymentSummaryData.reduce((acc, curr) => {
            const key = `${curr.id}-${curr.date}`;
            if (!acc[key]) {
                acc[key] = {
                    id: curr.id,
                    date: curr.date,
                    paymentMethod: curr.paymentMethod,
                    daylyAccumulative: 0,
                };
            }
            acc[key].daylyAccumulative += Number(curr.daylyAccumulative);

            return acc;
        }, {} as any);
    }, [paymentSummaryData]);

    const paymentList = useMemo(() => {
        return convertPaymentSummaryReportData(
            filteredPaymentSummaryData,
            selectedDate,
            selectedAggregationMethod,
        );
    }, [filteredPaymentSummaryData]);

    const isEnableScroll = useMemo(() => {
        if (selectedAggregationMethod === AggregationMethod.MONTH) return 6000;
        return 0;
    }, [selectedAggregationMethod]);

    const exportCSVFile = () => {
        // append data to this file
        let tableHeader = [
            t('report.paymentSummary.table.paymentMethod'),
            ...paymentList.headers,
            t('report.paymentSummary.table.monthlyAccumulative'),
        ];
        let csvContent = `\uFEFF${tableHeader.join()}`;
        // append content
        (paymentList.paymentMethods || []).forEach((paymentMethod) => {
            const p: string[] = [`"${paymentMethod.name}"`];
            paymentList.headers?.forEach((date) => {
                const value =
                    paymentList.values?.[`payment_method_${paymentMethod.id}`]?.[date];
                const fieldData = t('common.yen', {
                    number: Number(value?.daylyAccumulative || 0) || 0,
                });
                p.push(fieldData);
            });
            p.push(
                t('common.yen', {
                    number:
                        Number(
                            paymentList.totalOfPaymentMethodByMonth?.[
                                `payment_method_${paymentMethod.id}`
                            ],
                        ) || 0,
                }),
            );
            csvContent = `${csvContent}\n${p.join()}`;
        });

        // append summary
        const summaryByDate = paymentList.headers?.map((date) => {
            const value = paymentList.totalOfPaymentMethodByDay[date];
            return t('common.yen', {
                number: Number(value || 0) || 0,
            });
        });
        const summaryRow = [
            t('report.paymentSummary.total'),
            ...summaryByDate,
            t('common.yen', {
                number: Number(paymentList.totalValuesOfMonth || 0) || 0,
            }),
        ];
        csvContent = `${csvContent}\n${summaryRow.join()}`;
        downloadCSVFile(csvContent, `${ReportTableCsvFileName.PAYMENT_SUMMARY}.csv`);
    };

    useEffect(() => {
        if (isExportCsv) {
            exportCSVFile();
            dispatch(setExportCsv(false));
        }
    }, [isExportCsv]);

    return (
        <div className="report-payment-summary-table-wrapper">
            <Table
                className="payment-summary-table"
                rowKey="id"
                id={ReportTableId.PAYMENT_SUMMARY}
                dataSource={paymentList.paymentMethods}
                scroll={{ x: isEnableScroll }}
                pagination={false}
                loading={showLoadingPaymentSummaryTable}
                summary={(data) => {
                    return (
                        <Table.Summary.Row className="summary-table">
                            <Table.Summary.Cell index={0}>
                                {t('report.paymentSummary.total')}
                            </Table.Summary.Cell>
                            {paymentList.headers.map((header, index) => {
                                const value =
                                    paymentList.totalOfPaymentMethodByDay[header];
                                return (
                                    <Table.Summary.Cell
                                        index={index}
                                        key={`${header}_${index}`}
                                    >
                                        {`${t('report.paymentSummary.unit')}${formatMoney(
                                            Number(value || 0),
                                        )}`}
                                    </Table.Summary.Cell>
                                );
                            })}
                            <Table.Summary.Cell index={32}>
                                {`${t('report.paymentSummary.unit')}${formatMoney(
                                    Number(paymentList.totalValuesOfMonth || 0),
                                )}`}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            >
                <Column
                    title={`${t('report.paymentSummary.table.paymentMethod')}`}
                    width={300}
                    key={PaymentSummaryColumn.PAYMENT_METHOD}
                    fixed="left"
                    render={(_, item: IPaymentMethod) => {
                        return <div>{item.name}</div>;
                    }}
                />
                {paymentList.headers.map((header, index) => {
                    return (
                        <Column
                            title={header}
                            key={header}
                            render={(item: IPaymentMethod) => {
                                const value =
                                    paymentList.values?.[`payment_method_${item.id}`]?.[
                                        header
                                    ];
                                return (
                                    <div>{`${t(
                                        'report.paymentSummary.unit',
                                    )}${formatMoney(
                                        Number(value?.daylyAccumulative || 0),
                                    )}`}</div>
                                );
                            }}
                        />
                    );
                })}
                <Column
                    title={`${t('report.paymentSummary.table.monthlyAccumulative')}`}
                    key={PaymentSummaryColumn.MONTHLY_ACCUMULATIVE}
                    render={(_, item: IPaymentMethod) => {
                        const value =
                            paymentList.totalOfPaymentMethodByMonth?.[
                                `payment_method_${item.id}`
                            ];
                        return (
                            <div>{`${t('report.paymentSummary.unit')}${formatMoney(
                                Number(value || 0),
                            )}`}</div>
                        );
                    }}
                    width="200px"
                />
            </Table>
        </div>
    );
}

export default PaymentSummaryTable;
