import { DownOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './SingleCollapse.scss';

interface ISingleCollapseProps extends CollapseProps {
    expand: boolean;
    onChange: () => void;
    title: string;
}

export const SingleCollapse = (props: ISingleCollapseProps) => {
    const { expand, onChange, title, ...rest } = props;
    const headerClass = classNames({
        active: expand,
        deactive: !expand,
    });
    return (
        <Collapse
            {...props}
            bordered={false}
            className="single-collapse"
            activeKey={expand ? 'single_collapse' : ''}
            onChange={onChange}
        >
            <Collapse.Panel
                header={
                    <div className="header">
                        <div className="title">{title}</div>
                        <DownOutlined className={headerClass} />
                    </div>
                }
                key="single_collapse"
                className="create-room-type-collapse-panel"
            >
                {props.children}
            </Collapse.Panel>
        </Collapse>
    );
};
