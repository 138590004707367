import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { GlobalOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { useIsMobile } from '~common/useHooks';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    currentLanguageSelector,
    setCurrentLanguage,
} from '~common/reducers/app.reducer';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { SupportLanguage } from '~common/constants';
import { logoutSession } from '~plugins/amplify';
import { useAuth } from '../../../../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AccountSVG } from '~assets/images/account.svg';
import './OtherMenu.scss';

interface IOtherMenu {
    collapsed: boolean;
}

const OtherMenu: React.FC<IOtherMenu> = ({ collapsed }) => {
    const { t } = useTranslation();
    const auth = useAuth();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const currentLang = useAppSelector(currentLanguageSelector);

    const languageSubMenu: ItemType[] = useMemo(() => {
        return [
            {
                label: t('common.language.ja'),
                key: SupportLanguage.JA,
                onClick: ({ key }) => {
                    dispatch(setCurrentLanguage(key));
                },
            },
            {
                label: t('common.language.en'),
                key: SupportLanguage.EN,
                onClick: ({ key }) => {
                    dispatch(setCurrentLanguage(key));
                },
            },
        ];
    }, []);

    const userSubMenu: ItemType[] = [
        {
            label: auth.user?.email || '',
            key: 'email',
            icon: <UserOutlined />,
        },
        {
            label: t('common.logout'),
            key: 'logout',
            onClick: async () => {
                await logoutSession();
                auth.logout();
                navigate('/login');
            },
            icon: <LogoutOutlined />,
        },
    ];

    const otherMenus: ItemType[] = useMemo(() => {
        const logoHeight = collapsed && !isMobile ? 24 : 20;
        return [
            {
                label: t('common.language.lang'),
                key: 'other-menu_language',
                icon: <GlobalOutlined />,
                children: languageSubMenu,
            },
            {
                label: auth.user?.email || '',
                key: 'other-menu_user',
                icon: <AccountSVG style={{ height: logoHeight }} />,
                children: userSubMenu,
            },
        ];
    }, [collapsed, auth, currentLang]);

    return (
        <div className="sider-other_container">
            <Menu
                className={classNames('sider-other_menu', {
                    collapsed: collapsed && !isMobile,
                })}
                mode="vertical"
                items={otherMenus}
                triggerSubMenuAction="click"
                selectable={false}
            />
        </div>
    );
};

export default OtherMenu;
