import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HistorySearchField } from '~features/guest/constants';
import {
    guestSelector,
    setBookingHistorySearchField,
} from '~features/guest/reducers/guest.reducer';
import { useAppSelector } from '~hooks';

const { Option } = Select;

function SelectField() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { bookingHistorySearchField } = useAppSelector(guestSelector);

    const handleChange = (columnName: HistorySearchField) => {
        dispatch(setBookingHistorySearchField(columnName));
    };

    return (
        <Select
            defaultValue={HistorySearchField.ALL}
            onChange={handleChange}
            value={bookingHistorySearchField || HistorySearchField.ALL}
            dropdownMatchSelectWidth={false}
        >
            {Object.values(HistorySearchField).map((columnName, index) => {
                return (
                    <Option value={columnName} key={index}>
                        {t(`guest.detail.searchColumn.${columnName}`)}
                    </Option>
                );
            })}
        </Select>
    );
}

export default SelectField;
