/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'antd';
import { Calendar, SingleSelect, Checkbox } from '~components';
import { SettingOutlined, EditOutlined, InfoCircleFilled } from '@ant-design/icons';
import './UpdateRankCalendar.scss';
import { useEffect, useRef, useState } from 'react';

import { Dayjs, parseDate, todayDayjs } from '~plugins/dayjs';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    rankCalendarFormSelector,
    selectedRankCalendarDetailSelector,
    setRankCalendarForm,
    setShowForm,
} from '~features/rank-calendar/reducers/rank-calendar.reducer';
import CalendarHeader from '~features/rank-calendar/components/CalendarHeader/CalendarHeader';
import {
    DECEMBER,
    JANUARY,
    TllRank,
    TllRankColors,
    TllRankDropdown,
} from '~common/constants';
import { CalendarDay } from '~features/rank-calendar/constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ModalUpdateDurations from '~features/rank-calendar/components/ModalUpdateDurations/ModalUpdateDurations';
import { parseDateTime } from '~common/commonFunctions';
import { getPriorityRank, mergePlanRankDurations } from '~features/rank-calendar/helper';

interface IProp {
    control: any;
    setValue: any;
}

function UpdateRankCalendar({ control, setValue }: IProp) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [rankCalendarYear, setRankCalendarYear] = useState(todayDayjs.year());
    const [rankCalendarMonth, setRankCalendarMonth] = useState(todayDayjs.month() + 1);
    const [calendarDate, setCalendarDate] = useState(
        parseDate(`${rankCalendarYear}-${rankCalendarMonth}-${todayDayjs.date()}`),
    );
    const rankCalendarDetail = useAppSelector(selectedRankCalendarDetailSelector);
    const rankCalendarForm = useAppSelector(rankCalendarFormSelector);
    const [selectedDate, setSelectedDate] = useState<string[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDateDuration, setSelectedDateDuration] = useState({
        startDate: '',
        endDate: '',
    });
    const [isChecked, setIsChecked] = useState(false);
    const objectPlanDurations = useRef<{ [key: string]: { tllRank: TllRank | null } }>(
        {},
    );

    const onClickButtonPrevious = () => {
        if (rankCalendarMonth === JANUARY) {
            setRankCalendarMonth(DECEMBER);
            setRankCalendarYear(rankCalendarYear - 1);
            return;
        }
        setRankCalendarMonth(rankCalendarMonth - 1);
    };

    const onClickButtonNext = () => {
        if (rankCalendarMonth === DECEMBER) {
            setRankCalendarMonth(JANUARY);
            setRankCalendarYear(rankCalendarYear + 1);
            return;
        }
        setRankCalendarMonth(rankCalendarMonth + 1);
    };

    const headerRender = () => {
        return (
            <>
                <CalendarHeader
                    rankCalendarMonth={rankCalendarMonth}
                    rankCalendarYear={rankCalendarYear}
                    onClickButtonPrevious={onClickButtonPrevious}
                    onClickButtonNext={onClickButtonNext}
                />
                <div className="rank-calendar-note">
                    <InfoCircleFilled className="rank-calendar-note-icon" />
                    {t('rankCalendar.update.note')}
                </div>
                <div className="rank-calendar-day-list">
                    {Object.values(CalendarDay).map((day) => {
                        return (
                            <p className="rank-calendar-day" key={day}>
                                {t(`rankCalendar.detail.calendarDay.${day}`)}
                            </p>
                        );
                    })}
                </div>
            </>
        );
    };

    useEffect(() => {
        setCalendarDate(
            parseDate(
                `${rankCalendarYear}-${rankCalendarMonth}-${todayDayjs
                    .startOf('month')
                    .date()}`,
            ),
        );
    }, [rankCalendarMonth, rankCalendarYear]);

    useEffect(() => {
        if (selectedDateDuration.startDate && selectedDateDuration.endDate) {
            const betweenDates = [];
            let startDate = parseDate(selectedDateDuration.startDate);
            let endDate = parseDate(selectedDateDuration.endDate);

            if (startDate > endDate) {
                const temporaryDate = startDate;
                startDate = endDate;
                endDate = temporaryDate;
            }

            let currentDate = startDate.add(1, 'day');

            while (currentDate < endDate) {
                betweenDates.push(currentDate.fmYYYYMMDD());
                currentDate = currentDate.add(1, 'day');
            }
            betweenDates.forEach((date) => {
                if (!selectedDate.includes(date)) {
                    setSelectedDate((previousSelectedDate) => {
                        return [...previousSelectedDate, date];
                    });
                }
                setValue(`checkbox.${date}`, isChecked);
            });
            setSelectedDateDuration({
                startDate: selectedDateDuration.endDate,
                endDate: '',
            });
        }
    }, [selectedDateDuration]);

    const findRank = (date: Dayjs) => {
        let rank = '';
        switch (date.day()) {
            case 1:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    rankCalendarForm,
                    CalendarDay.MONDAY,
                );
                break;
            case 2:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    rankCalendarForm,
                    CalendarDay.TUESDAY,
                );
                break;
            case 3:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    rankCalendarForm,
                    CalendarDay.WEDNESDAY,
                );
                break;
            case 4:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    rankCalendarForm,
                    CalendarDay.THURSDAY,
                );
                break;
            case 5:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    rankCalendarForm,
                    CalendarDay.FRIDAY,
                );
                break;
            case 6:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    rankCalendarForm,
                    CalendarDay.SATURDAY,
                );
                break;
            case 0:
                rank = getPriorityRank(
                    date,
                    rankCalendarDetail,
                    rankCalendarForm,
                    CalendarDay.SUNDAY,
                );
                break;
            default:
                break;
        }
        return rank;
    };
    useEffect(() => {
        const daysInMonth = parseDate(
            `${rankCalendarYear}-${rankCalendarMonth}`,
        ).daysInMonth();
        for (let i = 1; i <= daysInMonth; i++) {
            const date = parseDate(`${rankCalendarYear}-${rankCalendarMonth}-${i}`);
            setValue(date.fmYYYYMMDD(), findRank(date));
        }
    }, [rankCalendarForm, rankCalendarMonth]);

    const onSelectDate = (date: Dayjs) => {
        setCalendarDate(date);
        if (date.month() + 1 !== rankCalendarMonth) {
            setRankCalendarMonth(date.month() + 1);
        }
        if (date.year() !== rankCalendarYear) {
            setRankCalendarYear(date.year());
        }
    };
    const showAdvancedSetting = () => {
        dispatch(setShowForm(true));
    };

    const dateFullCellRender = (date: Dayjs) => {
        if (date.month() + 1 !== rankCalendarMonth) {
            return (
                <div className="date-cell">
                    <div className="date-cell-header">
                        <span>{date.date()}</span>
                    </div>
                </div>
            );
        }

        const rank = findRank(date);
        let colorIndex = 0;
        colorIndex = Object.values(TllRank).indexOf(rank as TllRank);

        const onClickCheckBox = (value: CheckboxChangeEvent) => {
            if (value.target.checked && !selectedDate.includes(date.fmYYYYMMDD())) {
                setSelectedDate([...selectedDate, date.fmYYYYMMDD()]);
            }

            if (!value.target.checked) {
                setSelectedDate(
                    selectedDate.filter((selectedDate) => {
                        return selectedDate !== date.fmYYYYMMDD();
                    }),
                );
            }

            setIsChecked(value.target.checked);
            setSelectedDateDuration({
                ...selectedDateDuration,
                startDate: date.fmYYYYMMDD(),
            });

            if (
                selectedDate.length >= 1 &&
                value.nativeEvent.shiftKey &&
                selectedDateDuration.startDate &&
                value.target.checked === isChecked
            ) {
                setSelectedDateDuration({
                    ...selectedDateDuration,
                    endDate: date.fmYYYYMMDD(),
                });
            }
        };

        const onChangeRank = (value: TllRank) => {
            objectPlanDurations.current[`${parseDateTime(date.fmYYYYMMDD())}`] = {
                tllRank: value,
            };
            if (rankCalendarForm) {
                dispatch(
                    setRankCalendarForm(
                        mergePlanRankDurations(
                            {
                                ...rankCalendarForm,
                                planRankDurations: [
                                    ...rankCalendarForm.planRankDurations,
                                    {
                                        tllRank: value,
                                        startDate: date.fmYYYYMMDD(),
                                        endDate: date.fmYYYYMMDD(),
                                    },
                                ],
                            },
                            rankCalendarForm,
                        ),
                    ),
                );
            }
        };

        return (
            <div className="date-cell">
                <div className="date-cell-header">
                    <span>{date.date()}</span>
                    <Checkbox
                        label=""
                        name={`checkbox.${date.fmYYYYMMDD()}`}
                        className="date-cell-checkbox"
                        control={control}
                        defaultValue={false}
                        onChange={onClickCheckBox}
                    />
                </div>
                <div className="date-cell-content">
                    {
                        <SingleSelect
                            label=""
                            name={`${date.fmYYYYMMDD()}`}
                            options={TllRankDropdown}
                            onChange={onChangeRank}
                            control={control}
                            showSearch={false}
                            className="date-cell-content-select"
                            style={{
                                backgroundColor: `${TllRankColors[colorIndex]}`,
                            }}
                        />
                    }
                </div>
            </div>
        );
    };

    const onCancelModal = () => {
        setShowModal(false);
        setSelectedDateDuration({ startDate: '', endDate: '' });
    };

    const onClickButtonBulkUpdate = () => {
        setShowModal(true);
    };

    return (
        <div className="update-rank-calendar-wrapper">
            <div className="update-rank-calendar-header">
                <Button
                    onClick={onClickButtonBulkUpdate}
                    disabled={selectedDate.length < 1}
                    className={selectedDate.length < 1 ? 'disabled-btn' : ''}
                >
                    <EditOutlined />
                    {t('rankCalendar.update.bulkUpdateBtn')}
                </Button>
                <Button onClick={showAdvancedSetting}>
                    <SettingOutlined />
                    {t('rankCalendar.update.advancedSettingBtn')}
                </Button>
            </div>
            <Calendar
                mode="month"
                headerRender={headerRender}
                dateFullCellRender={dateFullCellRender}
                disabledDate={(currentDate) => currentDate.diff(todayDayjs, 'day') < 0}
                value={calendarDate}
                onSelect={onSelectDate}
                className="rank-calendar-update-content"
            />
            <ModalUpdateDurations
                control={control}
                showModal={showModal}
                onCancelModal={onCancelModal}
                selectedDate={selectedDate}
                setValue={setValue}
                objectPlanDurations={objectPlanDurations}
                setSelectedDate={setSelectedDate}
            />
        </div>
    );
}

export default UpdateRankCalendar;
