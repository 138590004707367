import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Modal, notification, Pagination, Table } from 'antd';
import type { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';
import { orderBy } from 'lodash';
import trim from 'lodash/trim';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    checkUserPermission,
    downloadFile,
    exportCSVFile,
} from '~common/commonFunctions';
import {
    AdminGroup,
    AntdOrderDirection,
    CsvOption,
    OrderDirection,
    cellTextErrorStyle,
    cellAutoGeneratedCodeStyle,
} from '~common/constants';
import { ISorter } from '~common/interfaces';
import { roomSchema } from '~common/validatorSchema';
import { CsvDropdown, ModalConfirmDeletion, TextTruncate } from '~components';
import { useAppDispatch, useAppSelector } from '~hooks';
import ImportCsv from '../../../../components/ImportCsv/ImportCsv';
import {
    exportColumns,
    EXPORT_CSV_FILE_NAME,
    FILE_NAME,
    i18ExportKey,
    ImportRoomColumn,
    initRoomListQuery,
    OrderBy,
    RoomColumn,
} from '../../constants';
import { IImportRoom, IRoom, IRoomExportCsvQuery } from '../../interfaces';
import {
    fetchRoomList,
    getRoomListByIds,
    roomListQuerySelector,
    roomListSelector,
    setEditRoom,
    setImportCsvFileName,
    setImportRoomList,
    setIsShowUpdateIndividualRoomForm,
    setRoomListQuery,
    setShowLoadingList,
    showLoadingListSelector,
    totalPageSelector,
    totalRoomsSelector,
} from '../../room.reducer';
import { roomService } from '../../services/room.service';
import './RoomList.scss';

interface IDataOutput {
    name: string;
    autoGeneratedCode: string;
    roomTypeName: string;
}

function RoomList() {
    const { t } = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const roomList = useAppSelector(roomListSelector);
    const roomListQuery = useAppSelector(roomListQuerySelector);
    const totalRooms = useAppSelector(totalRoomsSelector);
    const pageCount = useAppSelector(totalPageSelector);
    const showLoadingList = useAppSelector(showLoadingListSelector);
    const dispatch = useAppDispatch();
    const isReadOnly = !checkUserPermission([...AdminGroup]);
    const importCsvRef = useRef<{
        onClickImport: () => void;
    }>(null);

    useEffect(() => {
        return () => {
            dispatch(setRoomListQuery({ ...initRoomListQuery }));
        };
    }, []);

    useEffect(() => {
        if (roomList.length === 0 && roomListQuery?.page && roomListQuery?.page > 1) {
            dispatch(
                setRoomListQuery({
                    ...roomListQuery,
                    page: pageCount || 1,
                }),
            );
        }
    }, [roomList]);

    const onChange: TableProps<IRoom>['onChange'] = (pagination, filters, sorter) => {
        const { field, order, columnKey } = sorter as ISorter;
        const _field = field || columnKey;
        if (!order) {
            dispatch(
                setRoomListQuery({
                    ...roomListQuery,
                    orderBy: OrderBy.ID,
                    orderDirection: OrderDirection.DESC,
                }),
            );
        }
        if (_field && order) {
            const _order =
                order === AntdOrderDirection.ASC
                    ? OrderDirection.ASC
                    : OrderDirection.DESC;
            dispatch(
                setRoomListQuery({
                    ...roomListQuery,
                    orderBy: _field,
                    orderDirection: _order,
                }),
            );
        }
    };

    const onSelectChange = (_selectedRowKeys: React.Key[]) => {
        const rows: IRoom[] = [];
        _selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundRoom = roomList.find((room: IRoom) => room.id === selectedRowKey);
            if (foundRoom) rows.push(foundRoom);
        });
        setSelectedRowKeys(_selectedRowKeys);
    };

    const selectedRows = useMemo(() => {
        const rows: IRoom[] = [];
        selectedRowKeys.forEach((selectedRowKey: React.Key) => {
            const foundRoom = roomList.find((room: IRoom) => room.id === selectedRowKey);
            if (foundRoom) rows.push(foundRoom);
        });
        return rows;
    }, [selectedRowKeys, roomList]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onConfirmDeletion = async () => {
        const selectedIds = selectedRows.map((row) => {
            return row.id;
        });
        const response = await roomService.bulkDelete({
            ids: selectedIds,
        });
        if (response.success) {
            notification.success({
                message: t('room.list.message.deleteRoomsSuccess'),
            });
            setSelectedRowKeys([]);
            fetchData();
        } else {
            response?.errors?.forEach((error) => {
                if (error?.key === 'roomBooking') {
                    const cannotDeleteRooms = selectedRows.filter((room) => {
                        return error?.ids?.includes(room.id);
                    });
                    Modal.error({
                        title: t('room.list.deleteErrorModal.title'),
                        content: (
                            <>
                                <span>{t('room.list.deleteErrorModal.description')}</span>
                                <ul className="cannot-delete-room-list">
                                    {cannotDeleteRooms?.map((room) => (
                                        <li key={room?.id}>{room?.name}</li>
                                    ))}
                                </ul>
                            </>
                        ),
                        centered: true,
                    });
                } else {
                    notification.error({
                        message: response.message,
                    });
                }
            });
        }
    };

    const showConfirmDialog = () => {
        ModalConfirmDeletion({
            okButtonProps: { danger: true },
            deletedItems: selectedRows.map((row) => row.name.toString()),
            buttonCancelText: t('room.list.delete.buttonCancelText'),
            buttonDeleteText: t('room.list.delete.buttonDeleteText'),
            onClickButtonDelete: onConfirmDeletion,
        });
    };

    const fetchData = () => {
        dispatch(fetchRoomList());
    };

    const onChangePage = (page: number) => {
        dispatch(setRoomListQuery({ ...roomListQuery, page }));
    };

    const onRefresh = async () => {
        await fetchData();
        setSelectedRowKeys([]);
    };

    const exportCsv = async (query: IRoomExportCsvQuery) => {
        const response = await roomService.exportCsv(query);
        if (response.success) {
            downloadFile(FILE_NAME, response.data.filePath);
        }
    };

    const convertDataToCsv = (rooms: IRoom[]) => {
        const dataOutput: IDataOutput[] = [];
        rooms.forEach((room) => {
            const data = {
                name: room.name,
                autoGeneratedCode: room.autoGeneratedCode,
                roomTypeName: room.roomType.name,
            };
            dataOutput.push(data);
        });
        return orderBy(dataOutput, ['autoGeneratedCode'], ['desc']);
    };

    const exportRoomList = async (rooms: IRoom[]) => {
        // Create header file csv
        const filename = `${EXPORT_CSV_FILE_NAME}_${new Date().getTime()}.csv`;

        // Convert to file csv
        const roomDataCsvs = convertDataToCsv(rooms);
        await exportCSVFile(exportColumns, filename, roomDataCsvs, i18ExportKey);
    };

    const onChangeCsvOption = async (value: CsvOption) => {
        switch (value) {
            case CsvOption.EXPORT_ALL: {
                // export all room
                await exportCsv({
                    keyword: roomListQuery.keyword,
                    name: roomListQuery.name,
                    autoGeneratedCode: roomListQuery.autoGeneratedCode,
                    roomTypeName: roomListQuery.roomTypeName,
                    orderDirection: roomListQuery.orderDirection,
                    orderBy: roomListQuery.orderBy,
                });
                break;
            }
            case CsvOption.EXPORT_SELECTION: {
                // export selection
                exportRoomList(selectedRows);
                break;
            }
            case CsvOption.IMPORT: {
                if (importCsvRef?.current) {
                    importCsvRef.current.onClickImport();
                }
                break;
            }
            default:
                break;
        }
    };

    useEffect(() => {
        fetchData();
    }, [roomListQuery]);

    const onClickButtonEdit = () => {
        if (!selectedRows.length) return;

        const selectedConnectingRoomCount = selectedRows.filter(
            (room: IRoom) => room?.roomType?.isConnectingRoomType,
        ).length;
        if (
            selectedConnectingRoomCount === selectedRows.length &&
            selectedRows.length === 1
        ) {
            // Show form to edit a connecting room
            dispatch(setEditRoom(selectedRows[0]));
        } else if (selectedConnectingRoomCount === 0) {
            // Call api to get room list by room's ids
            dispatch(getRoomListByIds(selectedRows.map((room) => room.id)));
            // Show form to bulk edit individual rooms
            dispatch(setIsShowUpdateIndividualRoomForm(true));
        }
        return;
    };

    const shouldDisableButtonEdit = useMemo(() => {
        if (!selectedRows.length) return true;
        const selectedConnectingRoomCount = selectedRows.filter(
            (room: IRoom) => room?.roomType?.isConnectingRoomType,
        ).length;
        if (
            (selectedConnectingRoomCount === 1 && selectedRows.length === 1) ||
            selectedConnectingRoomCount === 0
        ) {
            return false;
        }
        return true;
    }, [selectedRows]);

    const editRecord = (room: IRoom) => {
        if (isReadOnly) {
            return;
        }
        dispatch(getRoomListByIds([room.id]));
        dispatch(setIsShowUpdateIndividualRoomForm(true));
    };

    const mapRoomDataToImportCsv = (rooms: IImportRoom[]) => {
        const importRooms: IImportRoom[] = [];

        rooms.forEach((room, index) => {
            importRooms.push({
                order: index + 1,
                name: trim(room.name),
                roomTypeName: trim(room.roomTypeName),
            });
        });

        dispatch(setImportRoomList(importRooms));
    };

    const setImportRoomCsvFileName = (fileName: string) => {
        dispatch(setImportCsvFileName(fileName));
    };

    const handleLoadFile = () => {
        dispatch(setShowLoadingList(true));
    };

    const getRoomCellTextStyle = (room: IRoom) => {
        return roomSchema.isValidSync(room) ? {} : cellTextErrorStyle;
    };

    const roomColumns: ColumnsType<IRoom> = [
        {
            title: t('room.list.roomColumn.id'),
            width: '120px',
            onCell: () => {
                return {
                    style: cellAutoGeneratedCodeStyle,
                };
            },
            render: (room: IRoom) => {
                return <span>{room.autoGeneratedCode}</span>;
            },
            key: RoomColumn.AUTO_GENERATED_CODE,
            sorter: true,
        },
        {
            title: t('room.list.roomColumn.name'),
            width: '38%',
            dataIndex: RoomColumn.NAME,
            key: RoomColumn.NAME,
            sorter: true,
            onCell: (room: IRoom) => {
                return {
                    style: getRoomCellTextStyle(room),
                };
            },
            render: (name: string) => {
                return <TextTruncate text={name} />;
            },
        },
        {
            title: t('room.list.roomColumn.type'),
            dataIndex: '',
            key: RoomColumn.TYPE,
            onCell: (room: IRoom) => {
                return {
                    style: getRoomCellTextStyle(room),
                };
            },
            render: (room: IRoom) => {
                return <TextTruncate text={room.roomType?.name} />;
            },
            sorter: true,
        },
    ];

    return (
        <div className="room-list-wrapper">
            <ImportCsv
                importHeader={[ImportRoomColumn.NAME, ImportRoomColumn.TYPE]}
                mapData={mapRoomDataToImportCsv}
                setFilename={setImportRoomCsvFileName}
                handleLoadFile={handleLoadFile}
                ref={importCsvRef}
                previousPage={'/front-settings/room/import-csv'}
            />
            <Card
                className="room-card"
                title={
                    <div className="room-list-header">
                        {!isReadOnly && selectedRows.length > 0 && (
                            <div className="list-header-left">
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    disabled={shouldDisableButtonEdit}
                                    onClick={onClickButtonEdit}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    type="text"
                                    className="btn-icon"
                                    onClick={showConfirmDialog}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )}

                        <div className="list-header-right">
                            <div>
                                {t('room.list.numberOfRoom')}
                                {totalRooms}
                            </div>
                            <CsvDropdown
                                onChange={onChangeCsvOption}
                                hasSelectedColumns={selectedRows.length > 0}
                                hasImportOption={!isReadOnly}
                            />
                            <ReloadOutlined className="size-icon" onClick={onRefresh} />
                        </div>
                    </div>
                }
            >
                <Table
                    locale={{
                        triggerDesc: t('dashboard.sort.desc'),
                        triggerAsc: t('dashboard.sort.asc'),
                        cancelSort: t('dashboard.sort.cancel'),
                    }}
                    rowSelection={rowSelection}
                    columns={roomColumns}
                    dataSource={roomList}
                    onChange={onChange}
                    pagination={false}
                    rowKey="id"
                    loading={showLoadingList}
                    scroll={{ y: 400 }}
                    rowClassName="room-row"
                    onRow={(record) => {
                        return {
                            // click row
                            onClick: () => {
                                editRecord(record);
                            },
                        };
                    }}
                />
            </Card>

            {pageCount > 1 && (
                <Pagination
                    className="room-pagination"
                    defaultCurrent={roomListQuery.page}
                    current={roomListQuery.page}
                    total={totalRooms}
                    pageSize={roomListQuery.limit}
                    onChange={onChangePage}
                    showSizeChanger={false}
                />
            )}
        </div>
    );
}

export default RoomList;
